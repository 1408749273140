import { ClientSavedSearchUpdateBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { clientSavedSearchApi } from 'src/common/services';
import { getUpdateMessageText } from 'src/common/utils';
import { ClientSavedSearchesDispatch } from '../SavedSearchesSection.reducer';

export const updateClientSavedSearchMetadata = (
  dispatch: ClientSavedSearchesDispatch,
  clientId: number,
  searchId: number,
  clientSavedSearchUpdateBody: ClientSavedSearchUpdateBody
) => {
  dispatch({ type: 'Update' });

  clientSavedSearchApi
    .updateClientSavedSearch({
      clientId,
      searchId,
      clientSavedSearchUpdateBody
    })
    .then(({ data }) => {
      dispatch({
        type: 'UpdateSuccess',
        payload: data
      });
      message.success(getUpdateMessageText('client saved search').success);
    })
    .catch(error => {
      dispatch({
        type: 'UpdateFailure',
        payload: error
      });
      message.error(getUpdateMessageText('client saved search').error);
    });
};
