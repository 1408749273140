import { Skeleton, Row } from 'antd';
import React, { FC, useEffect, useReducer } from 'react';
import { useUserAccount } from 'src/common/hooks';
import {
  ClientApiListClientsRequest,
  ContactStatusChartData
} from '@elm-street-technology/crm-axios-client';
import {
  initialContactStatusChartDataState,
  contactStatusChartDataReducer,
  getContactStatusChartData
} from './ContactStatusChartData.reducer';
import { PipelineFunnelSvg } from '../PipelineFunnelSvg';

interface ContactsPipelineFunnelChartProps {
  width: number;
  height: number;
  timeframe?: string;
  onLegendCLick?: (status: ClientApiListClientsRequest['status']) => void;
}

export const ContactsPipelineFunnelChart: FC<ContactsPipelineFunnelChartProps> = ({
  width,
  height,
  timeframe,
  onLegendCLick
}) => {
  const { user } = useUserAccount();

  const [{ data, loading, error }, dispatch] = useReducer(
    contactStatusChartDataReducer,
    initialContactStatusChartDataState
  );

  useEffect(() => {
    getContactStatusChartData(dispatch, user.id, timeframe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, user]);

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height }}>
        Data could not be retrieved
      </Row>
    );
  }
  if (loading) {
    return (
      <Row justify="center" style={{ height }}>
        <Skeleton />
      </Row>
    );
  }
  return data ? (
    <PipelineFunnelSvg
      data={normalize(data)}
      width={width}
      height={height}
      fields={['marketing', 'engaged', 'active', 'pending', 'sold']}
      hiddenFields={['archived', 'cold', 'none']}
      wrapLength={5}
      onLegendCLick={onLegendCLick}
    />
  ) : null;
};

const normalize = (data: ContactStatusChartData) => ({
  new: data.newClient,
  marketing: data.marketing,
  engaged: data.engaged,
  active: data.active,
  pending: data.pending,
  sold: data.sold,
  cold: data.cold,
  archived: data.archived,
  none: data.none
});
