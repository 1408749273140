import { Col, Layout } from 'antd';
import styled from 'styled-components';

export const HelpBadge = styled.div`
  margin-left: 14px;
  margin-right: 14px;
`;

export const HelpText = styled.span`
  color: black;
`;

export const Head = styled(Layout.Header)`
  background-color: ${({ theme }: { theme: Record<string, string> }) =>
    theme.whiteBackGround};
  color: black;
  padding: 0px;
  position: fixed;
  z-index: 10;
  width: 100%;
`;

export const HeaderIconsWrapper = styled.div`
  display: flex;
  min-width: 100px;
  justify-content: space-around;
  align-items: center;
`;

export const ElevateColIconWrapper = styled(Col)`
  place-content: space-around;
  display: flex;
  border-right: 2px solid black;
  flex: 0 0 220px;
  border-right: 2px solid
    ${({ theme }: { theme: Record<string, string> }) => theme.border};
`;

export const HeaderColIconsWrapper = styled(Col)`
  margin-left: auto;
  margin-right: 16px;
  border-left: 2px solid
    ${({ theme }: { theme: Record<string, string> }) => theme.border};
`;
