import { Dispatch, Reducer } from 'react';
import { Preset } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { captureMessage } from '@sentry/react';
import { presetApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils/textUtils';

interface QuickEmailTemplatesState {
  data: Preset[];
  isLoading: boolean;
  error: Response;
  isError: boolean;
}

export const initialQuickEmailTemplatesState: QuickEmailTemplatesState = {
  data: [],
  isLoading: false,
  error: {} as Response,
  isError: false
};

export type QuickEmailTemplatesActions =
  | { type: 'GetQuickEmailTemplates' }
  | {
      type: 'GetQuickEmailTemplatesSuccess';
      payload: Preset[];
    }
  | { type: 'GetQuickEmailTemplatesFailure'; payload: Response };

export const quickEmailTemplatesReducer: Reducer<
  QuickEmailTemplatesState,
  QuickEmailTemplatesActions
> = (state, action) => {
  switch (action.type) {
    case 'GetQuickEmailTemplates':
      return {
        ...state,
        isLoading: true
      };
    case 'GetQuickEmailTemplatesSuccess':
      return {
        ...state,
        data: action.payload,
        isLoading: false
      };
    case 'GetQuickEmailTemplatesFailure':
      return {
        ...state,
        error: action.payload,
        isError: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export const getQuickEmailTemplates = (
  dispatch: Dispatch<QuickEmailTemplatesActions>
) => {
  dispatch({ type: 'GetQuickEmailTemplates' });
  presetApi
    .listPresets()
    .then(({ data }) =>
      dispatch({ type: 'GetQuickEmailTemplatesSuccess', payload: data })
    )
    .catch(error => {
      captureMessage(error);
      const fetchMessage = getFetchMessageText('Quick Email Templates');
      message.error(fetchMessage.error);
      dispatch({
        type: 'GetQuickEmailTemplatesFailure',
        payload: error
      });
    });
};
