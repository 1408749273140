import React from 'react';

const MyTeamIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g clipPath="url(#clip0_80_672)">
      <path
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M10.72 9.473c.904.47 1.678 1.227 2.243 2.173.112.187.167.28.187.41.04.264-.134.588-.37.692-.117.052-.247.052-.509.052m-2.79-6.101c.918-.475 1.55-1.46 1.55-2.599 0-1.138-.632-2.124-1.55-2.598M8.24 4.1C8.24 5.702 6.99 7 5.45 7 3.908 7 2.66 5.702 2.66 4.1c0-1.601 1.25-2.9 2.79-2.9 1.541 0 2.79 1.299 2.79 2.9zm-7.095 7.371C2.135 9.93 3.695 8.933 5.45 8.933c1.755 0 3.315.996 4.304 2.538.217.338.325.507.312.723a.723.723 0 01-.245.475c-.165.13-.394.13-.85.13H1.93c-.457 0-.685 0-.851-.13a.723.723 0 01-.245-.475c-.013-.216.096-.385.312-.723z"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_672">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MyTeamIcon;
