import React from 'react';

function Icon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 17 12" version="1.1">
      <title>List View</title>
      <desc>Created with Sketch.</desc>
      <g
        id="CRM-Agent-Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CRM-Dashboard-Desktop---Client-Details-Copy"
          transform="translate(-1519.000000, -2169.000000)"
          stroke="#2E7FC3"
        >
          <g id="Group-16" transform="translate(910.000000, 421.000000)">
            <g id="Group-15-Copy">
              <g id="List-View" transform="translate(610.000000, 1749.000000)">
                <g id="List-Icon">
                  <path
                    d="M0,5 C7.80408174e-17,5.63725163 0.516594519,6.15384615 1.15384615,6.15384615 L13.8461538,6.15384615 C14.4834055,6.15384615 15,5.63725163 15,5 C15,4.36274837 14.4834055,3.84615385 13.8461538,3.84615385 L1.15384615,3.84615385 C0.516594519,3.84615385 -7.80408174e-17,4.36274837 0,5 Z"
                    id="Path"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0,1.15384615 C7.80408174e-17,1.79109779 0.516594519,2.30769231 1.15384615,2.30769231 L13.8461538,2.30769231 C14.4834055,2.30769231 15,1.79109779 15,1.15384615 C15,0.516594519 14.4834055,5.49072589e-16 13.8461538,0 L1.15384615,0 C0.516594519,1.17061226e-16 -7.80408174e-17,0.516594519 0,1.15384615 Z"
                    id="Path"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <line
                    x1="3.46528779"
                    y1="3.84615385"
                    x2="3.46528779"
                    y2="6.15384615"
                    id="Path-3"
                    strokeWidth="0.5"
                  />
                  <path
                    d="M0,8.84615385 C7.80408174e-17,9.48340548 0.516594519,10 1.15384615,10 L13.8461538,10 C14.4834055,10 15,9.48340548 15,8.84615385 C15,8.20890221 14.4834055,7.69230769 13.8461538,7.69230769 L1.15384615,7.69230769 C0.516594519,7.69230769 -7.80408174e-17,8.20890221 0,8.84615385 Z"
                    id="Path"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <line
                    x1="3.46528779"
                    y1="7.69230769"
                    x2="3.46528779"
                    y2="10"
                    id="Path-3"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="3.46528779"
                    y1="0"
                    x2="3.46528779"
                    y2="2.30769231"
                    id="Path-3"
                    strokeWidth="0.5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
