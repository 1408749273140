import { User, Client } from '@elm-street-technology/crm-axios-client';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useGlobalDrawer } from 'src/common/hooks';

import { TextListItem, EmailListItem, CallListItem } from './index';
import {
  ClientCommunicationType,
  ClientCommunicationEmailType,
  ClientCommunicationTextType,
  ClientCommunicationCallType
} from '../CommunicationSection.types';

const MessageSubheader = styled(ListSubheader)`
  &.MuiListSubheader-sticky {
    position: relative;
    font-size: 18px;
  }
`;

interface Props {
  user: User;
  client: Client;
  communications: ClientCommunicationType[];
}

export const CommunicationList: FC<Props> = ({
  client,
  communications,
  user
}) => {
  const { quickEmail, quickCall, quickMessage } = useGlobalDrawer();

  const [listState, setListState] = useState(() => {
    const initialList = {};
    communications.forEach(listItem => {
      initialList[`${listItem.id}-${listItem.type}`] = false;
    });

    return initialList;
  });

  const handleClick = (id: string) =>
    setListState(prevListState => {
      const toggledList = { ...prevListState };
      toggledList[id] = !prevListState[id];

      return toggledList;
    });

  const handleEmail = (client: Client) => quickEmail([client]);
  const handleCall = (client: Client) => quickCall(client);
  const handleText = (client: Client) => quickMessage(client);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <MessageSubheader id="nested-list-subheader">
          {client.displayName}
        </MessageSubheader>
      }
    >
      {communications.map(communication => {
        const id = `${communication.id}-${communication.type}`;
        if (communication.type === 'text') {
          return (
            <TextListItem
              key={id}
              communicationText={communication as ClientCommunicationTextType}
              client={client}
              expanded={listState[id]}
              handleClick={handleClick}
              handleTextReply={handleText}
              user={user}
            />
          );
        }
        if (communication.type === 'call') {
          return (
            <CallListItem
              key={id}
              communicationCall={communication as ClientCommunicationCallType}
              client={client}
              expanded={listState[id]}
              handleClick={handleClick}
              handleCallBack={handleCall}
              user={user}
            />
          );
        }
        if (communication.type === 'email') {
          return (
            <EmailListItem
              key={id}
              communicationEmail={communication as ClientCommunicationEmailType}
              client={client}
              expanded={listState[id]}
              handleClick={handleClick}
              handleEmailReply={handleEmail}
              user={user}
            />
          );
        }
        return null;
      })}
    </List>
  );
};
