import React from 'react';

const ChevronArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path d="M0 0H12V12H0z" />
          <path
            stroke="#082746"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2 8.57l3.187-3.704a1 1 0 011.505-.012L10 8.57h0"
          />
        </g>
      </g>
    </svg>
  );
};

export default ChevronArrowUp;
