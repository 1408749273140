import React, { useEffect, useContext, FC, useMemo, useReducer } from 'react';
import { List, Button, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { NotesStateContext, NotesDispatchContext } from './Notes.context';
import { listClientNotes } from './actions';
import { NoteEditor, NoteCardItem } from './components';
import { notesReducer, initialNotesState } from './Notes.reducer';
import { ClientRecordSectionTitle } from '../index';

interface Props {
  clientId: number;
}

const Notes: FC<Props> = ({ clientId }) => {
  const { view, notes, loading } = useContext(NotesStateContext);
  const dispatch = useContext(NotesDispatchContext);

  const handleNewNoteClick = () => dispatch({ type: 'ShowAdd' });

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Notes</ClientRecordSectionTitle>}
      extra={
        view === 'List' && (
          <Button
            size="large"
            onClick={handleNewNoteClick}
            icon={<PlusOutlined />}
          >
            New Note
          </Button>
        )
      }
    >
      {useMemo(() => {
        if (view === 'Edit') {
          return <NoteEditor clientId={clientId} />;
        }

        return (
          <List
            loading={loading}
            itemLayout="horizontal"
            size="large"
            dataSource={notes}
            renderItem={note => <NoteCardItem key={note.id} note={note} />}
          />
        );
      }, [clientId, view, notes, loading])}
    </PageHeader>
  );
};

export const NotesTab: FC<Props> = ({ clientId }) => {
  const [state, dispatch] = useReducer(notesReducer, initialNotesState);

  useEffect(() => {
    listClientNotes(dispatch, clientId);
  }, [clientId]);

  return (
    <NotesStateContext.Provider value={state}>
      <NotesDispatchContext.Provider value={dispatch}>
        <Notes clientId={clientId} />
      </NotesDispatchContext.Provider>
    </NotesStateContext.Provider>
  );
};
