import React, { FC, CSSProperties } from 'react';
import { Grid } from 'antd';

const style = {
  container: (xs?: boolean): CSSProperties => ({
    marginBottom: xs ? 20 : 36
  }),
  header: (xs?: boolean): CSSProperties => ({
    marginBottom: xs ? 16 : 24
  }),
  title: {
    fontSize: 17,
    weight: 400,
    color: '#082746'
  },
  subtitle: { color: '#082746', fontSize: 14, marginTop: 8 }
};

interface Props {
  title?: string;
  subtitle?: string;
}

export const ClientFormSection: FC<Props> = ({ children, title, subtitle }) => {
  const { xs } = Grid.useBreakpoint();

  return (
    <div style={style.container(xs)}>
      <div style={style.header(xs)}>
        {title && <div style={style.title}>{title}</div>}
        {subtitle && <div style={style.subtitle}>{subtitle}</div>}
      </div>
      {children}
    </div>
  );
};
