import { Col, Row, Skeleton } from 'antd';
import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useReducer
} from 'react';
import { FlexibleXYPlot, VerticalBarSeries, YAxis } from 'react-vis';
import { useUserAccount } from 'src/common/hooks';
import {
  initialLeadStatusBySourceChartDataState,
  leadStatusBySourceChartDataReducer,
  getLeadStatusBySourceChartData
} from './LeadStatusBySourceChartData.reducer';
import {
  LabelCol,
  HintContent,
  HintTriangle,
  ColorCircle,
  GRAPH_COLORS,
  HintStyled as Hint,
  HorizontalGridLinesStyled as HorizontalGridLines
} from './LeadsBySourceChart.styled';

interface HintData {
  x: string;
  y: number;
}

interface LeadStatusesBySourceItem {
  sourceName: string;
  new: number;
  active: number;
  pending: number;
}

interface LeadsBySourceChartProps {
  height?: number;
  timeframe?: string;
}

export const LeadsBySourceChart: FC<LeadsBySourceChartProps> = ({
  height,
  timeframe
}) => {
  const { user } = useUserAccount();
  const [hintVisible, setHintVisible] = useState<boolean>(false);
  const [hintData, setHintData] = useState<HintData>({} as HintData);

  const [
    { data: leadStatusBySourceChartData, loading, error },
    dispatch
  ] = useReducer(
    leadStatusBySourceChartDataReducer,
    initialLeadStatusBySourceChartDataState
  );

  useEffect(() => {
    getLeadStatusBySourceChartData(dispatch, user.id, timeframe);
  }, [timeframe, user]);

  const statusesBySource: LeadStatusesBySourceItem[] = useMemo(
    () =>
      leadStatusBySourceChartData.map(data => ({
        sourceName: data.name || '',
        new: data.newClient || 0,
        active: data.active || 0,
        pending: data.pending || 0
      })) || [],
    [leadStatusBySourceChartData]
  );

  const sources = useMemo(
    () =>
      statusesBySource
        .map((item: LeadStatusesBySourceItem) => ({
          sourceName: item.sourceName,
          total: item.new + item.pending + item.active
        }))
        .sort(({ total: a }, { total: b }) => b - a)
        .map(obj => obj.sourceName)
        .slice(0, 6),
    [statusesBySource]
  );

  const xAxisRow = useMemo(
    () => sources.map(source => <LabelCol key={source}>{source}</LabelCol>),
    [sources]
  );

  const data = useMemo(
    () => ({
      new: sources.map(source => {
        const item = statusesBySource.find(
          ({ sourceName }) => sourceName === source
        );
        return {
          x: source,
          y: item ? item.new : 0,
          color: GRAPH_COLORS.new,
          opacity: 0.9
        };
      }),
      pending: sources.map(source => {
        const item = statusesBySource.find(
          ({ sourceName }) => sourceName === source
        );
        return {
          x: source,
          y: item ? item.pending : 0,
          color: GRAPH_COLORS.pending,
          opacity: 0.9
        };
      }),
      active: sources.map(source => {
        const item = statusesBySource.find(
          ({ sourceName }) => sourceName === source
        );
        return {
          x: source,
          y: item ? item.active : 0,
          color: GRAPH_COLORS.active,
          opacity: 0.9
        };
      })
    }),
    [sources, statusesBySource]
  );

  const handleValueMouseOver = ({ x, y }) => {
    setHintData({ x, y });
    setHintVisible(true);
  };

  const handleValueMouseOut = () => {
    setHintVisible(false);
  };

  const getHintContent = useCallback(() => {
    const { x } = hintData;
    const newTotal = data.new.find(d => d.x === x)?.y || 0;
    const pendingTotal = data.pending.find(d => d.x === x)?.y || 0;
    const activeTotal = data.active.find(d => d.x === x)?.y || 0;

    return (
      <HintContent>
        <h3>{x}</h3>
        <Row align="middle" gutter={16}>
          <Col>
            <ColorCircle theme={{ status: 'new' }} />
          </Col>
          <Col>{newTotal} New</Col>
        </Row>
        <Row align="middle" gutter={16}>
          <Col>
            <ColorCircle theme={{ status: 'pending' }} />
          </Col>
          <Col>{pendingTotal} Pending</Col>
        </Row>
        <Row align="middle" gutter={16}>
          <Col>
            <ColorCircle theme={{ status: 'active' }} />
          </Col>
          <Col>{activeTotal} Active</Col>
        </Row>
      </HintContent>
    );
  }, [hintData, data]);

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height }}>
        Data could not be retrieved
      </Row>
    );
  }
  if (loading) {
    return (
      <Row justify="center" style={{ height }}>
        <Skeleton />
      </Row>
    );
  }
  return (
    <div style={{ height }}>
      <FlexibleXYPlot xType="ordinal" stackBy="y">
        <VerticalBarSeries
          data={data.new}
          colorType="literal"
          onValueMouseOver={handleValueMouseOver}
          onValueMouseOut={handleValueMouseOut}
          stack
        />
        <VerticalBarSeries
          data={data.active}
          colorType="literal"
          onValueMouseOver={handleValueMouseOver}
          onValueMouseOut={handleValueMouseOut}
          stack
        />
        <VerticalBarSeries
          data={data.pending}
          colorType="literal"
          onValueMouseOver={handleValueMouseOver}
          onValueMouseOut={handleValueMouseOut}
          stack
        />
        <YAxis
          tickFormat={value => value}
          style={{ text: { fontSize: '10px', fill: '#485465' } }}
        />
        <HorizontalGridLines />
        {hintVisible && (
          <Hint
            value={hintData}
            align={{ vertical: 'top', horizontal: 'right' }}
          >
            {getHintContent()}
            <HintTriangle />
          </Hint>
        )}
      </FlexibleXYPlot>

      <Row style={{ marginTop: '-30px' }}>
        <Col flex="40px" />
        <Col flex="auto">
          <Row justify="space-around">{xAxisRow}</Row>
        </Col>
      </Row>
    </div>
  );
};
