import React, { FC } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { Button } from 'antd';

interface SaveSearchButtonProps {
  onClick?: ButtonProps['onClick'];
  disabled?: ButtonProps['disabled'];
  loading?: ButtonProps['loading'];
}

export const SaveSearchButton: FC<SaveSearchButtonProps> = ({
  onClick,
  disabled,
  loading,
  children
}) => (
  <Button
    style={{ borderRadius: 5, fontSize: 12, backgroundColor: '#d95d58' }}
    type="primary"
    size="large"
    danger
    onClick={onClick}
    disabled={disabled}
    loading={loading}
  >
    {children ?? 'Save Search'}
  </Button>
);
