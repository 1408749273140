import React, { FC, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { Row, Col } from 'antd';
import {
  ClientListItem,
  Client
} from '@elm-street-technology/crm-axios-client';
import Call from 'src/crm/assets/icons/call';
import Mail from 'src/crm/assets/icons/mail';
import Message from 'src/crm/assets/icons/message';
import { EkataQuickActionButton, QuickActionButton } from './components';

export interface QuickActionButtonRowProps {
  client: Client | ClientListItem;
  onTextClick: (client: Client | ClientListItem) => void;
  onCallClick: (client: Client | ClientListItem) => void;
  onEkataClick: (client: Client | ClientListItem) => void;
  onEmailClick: (client: Client | ClientListItem) => void;
}

export const QuickActionButtonRow: FC<QuickActionButtonRowProps> = ({
  client,
  onTextClick,
  onCallClick,
  onEkataClick,
  onEmailClick
}) => {
  const {
    disableCall,
    disableEmail,
    disableText,
    enableEkata
  } = useQuickActionFlags(client);

  return (
    <Row gutter={8} onClick={e => e.stopPropagation()} wrap={false}>
      <Col span={8}>
        <QuickActionButton
          label="Text"
          name="message-client-action"
          disabled={disableText}
          onClick={() => onTextClick(client)}
          icon={<Icon component={Message} />}
        />
      </Col>
      <Col span={8}>
        {enableEkata ? (
          <EkataQuickActionButton onClick={() => onEkataClick(client)} />
        ) : (
          <QuickActionButton
            label="Call"
            name="call-client-action"
            disabled={disableCall}
            onClick={() => onCallClick(client)}
            icon={<Icon component={Call} />}
          />
        )}
      </Col>
      <Col span={8}>
        <QuickActionButton
          label="Email"
          name="email-client-action"
          disabled={disableEmail}
          onClick={() => onEmailClick(client)}
          icon={<Icon component={Mail} />}
        />
      </Col>
    </Row>
  );
};

const useQuickActionFlags = (client: Client | ClientListItem) =>
  useMemo(() => {
    const disableText = !client.canText || !client.isTextable;
    const disableEmail = !client.isEmailable || !client.canEmail;
    const disableCall = !client.isCallable || !client.canCall;

    const enableEkata =
      (disableCall &&
        client?.sellerFields?.current_residents?.some(
          ({ phones }) => phones && phones.length > 0
        )) ||
      false;

    return {
      disableText,
      disableCall,
      disableEmail,
      enableEkata
    };
  }, [client]);
