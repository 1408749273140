export const yesNoInputToBoolean = (value: string | undefined) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return undefined;
};

export const textInputToNumberArray = (rawInput: string) =>
  rawInput?.match(/(\d)+/g)?.map(match => Number(match)) || undefined;

export const booleanToYesNoInput = (bool: boolean | string | undefined) => {
  if (bool === true || bool === 'true') {
    return 'true';
  }

  if (bool === false || bool === 'false') {
    return 'false';
  }
  return undefined;
};

export const numberToTextInput = (num: number | undefined) =>
  num ? String(num) : '';

export const stringToTextInput = (str: string | undefined) => str || '';

export const mixedArrayToTextInput = (
  list: string[] | number[] | string | number | undefined
) => (!list ? '' : Array.isArray(list) ? list.join(', ') : String(list));

export const numberToNumButtonGroupInput = (num: number | undefined) =>
  num ? String(num) : undefined;

export const stringToSingleSelect = (str: string | undefined) =>
  str || undefined;

export const singleSelectToString = (str: string | undefined) =>
  str || undefined;

export const singleSelectToStringArray = (str: string | undefined) =>
  str ? [str] : undefined;

export const singleSelectToNumberArray = (str: string | undefined) =>
  Number(str) ? [Number(str)] : undefined;

export const singleSelectToNumber = (str: string | undefined) =>
  Number(str) || undefined;

export const multiSelectInputToStringArray = (arr: string[] | undefined) =>
  arr && arr.length > 0 ? arr : undefined;

export const numButtonGroupInputToNumber = (value: string | undefined) =>
  Number(value) || undefined;

export const textInputToNumber = (str: string | undefined) =>
  Number(str) || undefined;

export const textInputToString = (str: string | undefined) => str || undefined;

export const textInputToStringArray = (rawInput: string) =>
  rawInput?.match(/[A-Za-z0-9-]+/g)?.map(match => String(match)) || undefined;

// City/state has a comma in them, so we cannot split them out like others
export const citiesStringArrayToMultiSelectInput = (
  arr: string[] | undefined | string
) => {
  if (!arr) {
    return [];
  }
  if (!Array.isArray(arr)) {
    return [arr];
  }
  return arr;
};

export const stringArrayToMultiSelectInput = (
  arr: string[] | undefined | string
) => {
  if (!arr) {
    return [];
  }
  if (!Array.isArray(arr)) {
    const multiSelectArray: string[] = [];
    arr.split(',').forEach(value => multiSelectArray.push(value.trim()));
    return multiSelectArray;
  }

  return arr;
};

// Todo: Remove this merge stuff once the corresponding
// pieces are in place in crm
export const mergeStringIntoArray = (
  base: string[] | undefined,
  addition: string | undefined
) => {
  if (!base && !addition) {
    return undefined;
  }
  const merged: string[] = [];
  if (base) {
    merged.push(...base);
  }
  if (addition) {
    textInputToStringArray(addition)?.forEach(item => merged.push(item));
  }
  return merged
    .filter(item => item.length)
    .filter((value, index, self) => self.indexOf(value) === index);
};
