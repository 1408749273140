import * as React from 'react';

function SvgPrequalified(props) {
  return (
    <svg width="37px" height="34px" viewBox="0 0 37 34" version="1.1">
      <title>Prequalified</title>
      <g
        id="Icons/BuyerSeller/Prequalified-Icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Account-Icon"
          stroke="#082746"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="1.25" y1="11.7" x2="18.75" y2="1.3" id="Line-3" />
          <line x1="18.25" y1="1.3" x2="35.75" y2="11.7" id="Line-4" />
          <path
            d="M7,16 L7,31 C7,32.1045695 7.8954305,33 9,33 L29,33 C30.1045695,33 31,32.1045695 31,31 L31,16 L31,16"
            id="Path"
          />
        </g>
        <path
          d="M18.4189526,27 L18.4079528,27 C18.129959,26.997 17.8659648,26.8779982 17.678969,26.6719951 L14.2600449,22.9069388 C13.8880532,22.4989327 13.9190525,21.8659232 14.3280434,21.4949177 C14.7370343,21.1239121 15.3700203,21.1549126 15.7400121,21.5629187 L18.4349522,24.528963 L25.2738003,17.3118551 C25.6547918,16.9108491 26.2877778,16.8948489 26.6877689,17.2738545 C27.08876,17.6538602 27.1057596,18.2868697 26.725768,18.6878757 L19.1449364,26.6879953 C18.9559406,26.8879983 18.6929465,27 18.4189526,27"
          id="Icon"
          fill="#082746"
        />
      </g>
    </svg>
  );
}

export default SvgPrequalified;
