import React, { useEffect, useState, useReducer } from 'react';
import {
  Client,
  ClientListItem,
  ClientCommunicationCall
} from '@elm-street-technology/crm-axios-client';
import { Input, Button as AntdButton } from 'antd';
import { useLastMessage } from 'use-socketio';
import styled from 'styled-components';

import { CommonSmDrawerPanel } from 'src/common/components';
import { Flex } from 'src/crm/components';

import { StatusContainer, CallNotesContainer } from './QuickCall.styled';
import {
  initialCreateCallState,
  createCallReducer,
  createClientCommunicationCall
} from './QuickCall.reducer';
import {
  quickCallCreateNoteReducer,
  initialCreateNoteState,
  createClientNote
} from './QuickCallCreateNote.reducer';
import { CallProgressSteps } from './CallProgressSteps';

interface ButtonProps {
  actiontype?: 'Default' | 'Outlined';
  externalbackground?: string;
  externalcolor?: string;
}

const Button = styled(AntdButton)<ButtonProps>`
  background-color: ${({ theme, actiontype, externalbackground }) =>
    actiontype === 'Outlined'
      ? theme.whiteBackGround
      : externalbackground || theme.secondaryBlue};

  color: ${({ theme, actiontype, externalcolor: externalColor }) =>
    externalColor ||
    (actiontype === 'Outlined' ? theme.secondaryBlue : theme.whiteBackGround)};
  border-color: ${({ theme, actiontype, externalbackground }) =>
    externalbackground ||
    (actiontype === 'Outlined' ? theme.secondaryBlue : 'inherit')};
  border-radius: 3px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.03);
  font-size: 12px;
  :hover,
  :active,
  :focus {
    border-color: ${({ theme, actiontype, externalbackground }) =>
      externalbackground ||
      (actiontype === 'Outlined' ? theme.secondaryBlue : 'inherit')};
    color: ${({ theme, actiontype }) =>
      actiontype === 'Outlined' ? theme.secondaryBlue : theme.whiteBackGround};
    background-color: ${({ theme, actiontype, externalbackground }) =>
      externalbackground ||
      (actiontype === 'Outlined'
        ? theme.whiteBackGround
        : theme.secondaryBlue)};
  }
  > span {
    color: ${({ theme, actiontype }) =>
      actiontype === 'Outlined' ? theme.secondaryBlue : theme.whiteBackGround};
  }
`;

interface QuickCallProps {
  client: Client | ClientListItem;
  onClose: () => void;
}

export const QuickCallDrawer: React.FC<QuickCallProps> = ({
  client,
  onClose
}) => {
  const { data: currentCall } = useLastMessage('call');

  const [, createNoteDispatch] = useReducer(
    quickCallCreateNoteReducer,
    initialCreateNoteState
  );

  const [, dispatch] = useReducer(createCallReducer, initialCreateCallState);

  const [steps, setSteps] = useState<ClientCommunicationCall[]>([]);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    createClientCommunicationCall(dispatch, client.id);
  }, []);

  useEffect(() => {
    if (
      currentCall &&
      currentCall.clientId === client.id &&
      currentCall.outcome !== steps[0]?.outcome
    ) {
      setSteps(steps => [...steps, currentCall]);
    }
  }, [currentCall]);

  const onNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setNotes(event.target.value);
  };

  const handleSaveChangesClick = () => {
    createClientNote(createNoteDispatch, client.id, notes);
  };

  const handleCallNotesContainerSubmit = () =>
    createClientNote(createNoteDispatch, client.id, notes);

  return (
    <CommonSmDrawerPanel
      title={<div>Calling: {client.displayName}</div>}
      onClose={onClose}
    >
      {!currentCall ? (
        <div>Loading...</div>
      ) : (
        <Flex flexDirection="column">
          <StatusContainer>
            <h3>Status</h3>
            {steps.length === 0 ? (
              <div> Calling {client.displayName}...</div>
            ) : (
              <CallProgressSteps steps={steps} client={client} />
            )}
          </StatusContainer>
          <CallNotesContainer onSubmit={handleCallNotesContainerSubmit}>
            <h3>Call Notes</h3>
            <Input.TextArea onChange={onNotesChange} />
            <Button onClick={handleSaveChangesClick}>Save Changes</Button>
          </CallNotesContainer>
        </Flex>
      )}
    </CommonSmDrawerPanel>
  );
};
