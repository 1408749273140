import React, { FC, useEffect, useState, CSSProperties } from 'react';
import { Form, Row, Col, Tag } from 'antd';
import Icon from '@ant-design/icons';
import {
  BusinessOp as BusinessOpSvg,
  Commercial as CommercialSvg,
  Condo as CondoSvg,
  House as SingleFamilySvg,
  Mobile as MobileSvg,
  MultiFamilyHouse as MultiFamilySvg,
  ResidIncome as ResidIncomeSvg,
  TownHouse as TownhouseSvg
} from 'src/crm/assets/icons/buy_sell_prefs';
import { LandIconSvg } from 'src/crm/assets/icons/buy_sell_prefs/LandIconSvg';

const style = {
  tag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 58,
    width: 85
  } as CSSProperties
};

interface Props {
  onChange: (listingTypes: string[]) => void;
  initialValue?: string[];
}

export const BuyerListingTypesFormItem: FC<Props> = ({
  onChange,
  initialValue
}) => {
  const [listingTypes, setListingTypes] = useState(initialValue || []);

  useEffect(() => {
    onChange(listingTypes);
  }, [listingTypes]);

  const isChecked = (listingType: string) => listingTypes.includes(listingType);

  const handleBuyerListingTypeClick = (listingType: string) => e =>
    setListingTypes(previous =>
      previous.includes(listingType)
        ? previous.filter(type => type !== listingType)
        : [...previous, listingType]
    );

  return (
    <Form.Item name={['buyerFields', 'listingTypes']} label="Property Type">
      <Row gutter={[16, 16]}>
        <Col>
          <Tag
            color={isChecked('Single Family') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Single Family')}
            style={style.tag}
          >
            <>
              <Icon component={SingleFamilySvg} />
              Single Family
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Condo') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Condo')}
            style={style.tag}
          >
            <>
              <Icon component={CondoSvg} />
              Condo
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Townhouse') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Townhouse')}
            style={style.tag}
          >
            <>
              <Icon component={TownhouseSvg} />
              Townhouse
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('MultiFamily') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('MultiFamily')}
            style={style.tag}
          >
            <>
              <Icon component={MultiFamilySvg} />
              Multi-Family
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Mobile') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Mobile')}
            style={style.tag}
          >
            <>
              <Icon component={MobileSvg} />
              Mobile
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Commercial') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Commercial')}
            style={style.tag}
          >
            <>
              <Icon component={CommercialSvg} />
              Commercial
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Business Opportunity') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Business Opportunity')}
            style={style.tag}
          >
            <>
              <Icon component={BusinessOpSvg} />
              Business Op.
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Residential Income') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Residential Income')}
            style={style.tag}
          >
            <>
              <Icon component={ResidIncomeSvg} />
              Resid. Income
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isChecked('Land') ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Land')}
            style={style.tag}
          >
            <>
              <Icon component={LandIconSvg} />
              Land
            </>
          </Tag>
        </Col>
      </Row>
    </Form.Item>
  );
};
