import React, { useState, FC, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Empty, Skeleton, Pagination, PageHeader, Divider, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { Listing } from '@elm-street-technology/crm-axios-client';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { navigateToListingsPdpDrawer } from 'src/common/utils';
import { TABLET } from 'src/common/constants';

import { ClientListingCard, ViewSelection } from './components';
import { actionDate, makeColumns } from './ClientListings.utils';
import { ClientRecordSectionTitle } from '../index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CRMTable = styled<any>(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 15px;
    background-color: #f3f5f7;
    thead {
      border-spacing: 0;
      background-color: white;
      font-size: 12px;
    }
    tbody {
      color: ${({ theme }) => theme.secondaryBlue};
      border-spacing: 0;
      background-color: white;
      tr.ant-table-row-selected:hover > td {
        background-color: white;
        border-top: 1px solid ${({ theme }) => theme.primaryBlue};
        border-bottom: 1px solid ${({ theme }) => theme.primaryBlue};
      }
      tr.ant-table-row-selected:hover > td:first-child {
        background-color: white;
        border-left: 1px solid ${({ theme }) => theme.primaryBlue};
      }
      tr.ant-table-row-selected:hover > td:last-child {
        background-color: white;
        border-right: 1px solid ${({ theme }) => theme.primaryBlue};
      }
      tr {
        cursor: pointer;
      }
      tr.ant-table-row-selected > td {
        background-color: white;
      }
      tr.unviewed-row td:first-child:before {
        display: inline-block;
        width: 7px;
        height: 100%;
        background-color: ${({ theme }) => theme.primaryBlue};
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @media only screen and (max-width: ${TABLET}) {
    table {
      border-spacing: 0 3px;
      tbody {
        tr td:first-child:before {
          width: 4px;
        }
      }
    }
  }
`;

const CardListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  background: ${({ theme }) => theme.defaultBackGround};
`;

const ListingsDrawerTable = styled(CRMTable)`
  table {
    border-spacing: 0 4.5px;
    td {
      padding: 0 4px;
      font-size: 12px;
    }
  }
`;

const ClientListingsWrapper = styled(CardListContainer)`
  > div:nth-child(odd) {
    margin: 12px 12px 12px 0;
  }
  > div:nth-child(even) {
    margin: 12px 0;
  }
`;

interface Props {
  listings: Listing[];
  pastTenseActionStr: string;
  totalNumber: number;
  showTotalViews: boolean;
  actionType: string;
  pagination: TablePaginationConfig;
  onChange?: (pagination: TablePaginationConfig) => void;
}

export const ClientListings: FC<Props> = ({
  totalNumber,
  listings,
  pastTenseActionStr,
  showTotalViews,
  actionType,
  pagination,
  onChange
}) => {
  const navigate = useNavigate();
  const [isListView, setListView] = useState(true);
  const isSmallerThanTablet = useMediaQuery({ maxWidth: TABLET });

  useEffect(() => {
    if (isSmallerThanTablet) {
      setListView(false);
    }
  }, [isSmallerThanTablet]);

  const listView = (
    <ListingsDrawerTable
      onRow={listing => ({
        onClick: () => navigateToListingsPdpDrawer(navigate, listing.id)
      })}
      rowKey="id"
      pagination={{ ...pagination, pageSize: pagination.pageSize || 25 }}
      columns={makeColumns(
        pastTenseActionStr,
        isSmallerThanTablet,
        showTotalViews,
        actionType
      )}
      dataSource={listings}
      onChange={onChange && onChange}
    />
  );

  const cardView = (
    <>
      <ClientListingsWrapper>
        {listings.length !== 0 ? (
          listings.map(listingValue => {
            const lastActionAt = actionDate(actionType, listingValue);
            const lastInteraction = `${pastTenseActionStr} ${lastActionAt &&
              moment(lastActionAt).fromNow()}`;
            const viewedListing = {
              ...listingValue,
              viewed: true
            };
            return (
              <ClientListingCard
                key={viewedListing.id}
                listing={viewedListing}
                lastInteractionDesc={lastInteraction}
              />
            );
          })
        ) : (
          <div>
            <Empty />
          </div>
        )}
      </ClientListingsWrapper>
      <Pagination
        simple
        pageSize={pagination.pageSize || 25}
        current={pagination.current}
        total={pagination.total}
        onChange={page => {
          onChange && onChange({ ...pagination, current: page });
        }}
      />
    </>
  );

  return (
    <PageHeader
      title={
        <ClientRecordSectionTitle>{`${totalNumber} ${pastTenseActionStr} Listings`}</ClientRecordSectionTitle>
      }
      footer={<Divider />}
    >
      {!isSmallerThanTablet && (
        <ViewSelection setListView={setListView} isListView={isListView} />
      )}
      <Skeleton loading={!listings}>
        {isListView ? listView : cardView}
      </Skeleton>
    </PageHeader>
  );
};
