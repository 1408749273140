import { List, PageHeader, Avatar } from 'antd';
import React, { useEffect, useReducer, FC } from 'react';
import {
  listClientHistory,
  clientHistoryReducer,
  initialClientHistoryState
} from './ClientHistory.reducer';
import { getHistoryIcons, getHistoryColors } from './HistoryTab.utils';
import { ClientRecordSectionTitle } from '../index';

interface Props {
  clientId: number;
}

export const HistoryTab: FC<Props> = ({ clientId }) => {
  const [{ data: history, loading }, dispatch] = useReducer(
    clientHistoryReducer,
    initialClientHistoryState
  );

  useEffect(() => {
    listClientHistory(dispatch, clientId);
  }, [clientId]);

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>History</ClientRecordSectionTitle>}
    >
      <List
        itemLayout="horizontal"
        size="large"
        loading={loading}
        dataSource={history}
        renderItem={({ category, fromNow, metadata }) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{ backgroundColor: getHistoryColors(category) }}
                  icon={getHistoryIcons(category)}
                />
              }
              title={
                metadata.title && (
                  // eslint-disable-next-line react/no-danger
                  <div dangerouslySetInnerHTML={{ __html: metadata.title }} />
                )
              }
              description={fromNow}
            />
          </List.Item>
        )}
      />
    </PageHeader>
  );
};
