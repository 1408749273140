import {
  PropertyPreferences,
  ListingTypeEnum,
  PropertyPreferencesTypeEnum,
  ClientListItemTypeEnum,
  ClientTypeEnum,
  ClientListItemStatusEnum,
  ClientStatusEnum
} from '@elm-street-technology/crm-axios-client';
import { isEmpty, isNil } from 'lodash';
import { BasicListingTypeEnum } from '@elm-street-technology/mls-axios-client';
import { capitalize } from 'src/common/utils';

export function clientRoleToDisplay(
  role?: ClientListItemTypeEnum | ClientTypeEnum
): string {
  switch (role) {
    case ClientListItemTypeEnum.Buyer:
      return 'Buyer';
    case ClientListItemTypeEnum.BuyerSeller:
      return 'Buyer/Seller';
    case ClientListItemTypeEnum.Family:
      return 'Family';
    case ClientListItemTypeEnum.Friend:
      return 'Friend';
    case ClientListItemTypeEnum.PastClient:
      return 'Past Client';
    case ClientListItemTypeEnum.Personal:
      return 'Personal';
    case ClientListItemTypeEnum.Renter:
      return 'Renter';
    case ClientListItemTypeEnum.Seller:
      return 'Seller';
    case ClientListItemTypeEnum.Vendor:
      return 'Vendor';
    case ClientListItemTypeEnum.CrmAgent:
      return 'CRM Agent';
    case ClientListItemTypeEnum.ProspectiveAgent:
      return 'Prospective Agent';
    default:
      return '';
  }
}

export function clientStatusToDisplay(
  status?: ClientStatusEnum | ClientListItemStatusEnum
) {
  switch (status) {
    case ClientStatusEnum.New:
    case ClientListItemStatusEnum.New:
      return 'New';
    case ClientStatusEnum.Marketing:
    case ClientListItemStatusEnum.Marketing:
      return 'Marketing';
    case ClientStatusEnum.Engaged:
    case ClientListItemStatusEnum.Engaged:
      return 'Engaged';
    case ClientStatusEnum.Active:
    case ClientListItemStatusEnum.Active:
      return 'Active';
    case ClientStatusEnum.Pending:
    case ClientListItemStatusEnum.Pending:
      return 'Pending';
    case ClientStatusEnum.Sold:
    case ClientListItemStatusEnum.Sold:
      return 'Sold';
    case ClientStatusEnum.Cold:
    case ClientListItemStatusEnum.Cold:
      return 'Cold';
    case ClientStatusEnum.Archived:
    case ClientListItemStatusEnum.Archived:
      return 'Archived';
    case ClientStatusEnum.None:
    case ClientListItemStatusEnum.None:
    default:
      return 'None';
  }
}

export function listingTypesToDisplay(types: PropertyPreferencesTypeEnum[]) {
  return types.map(type => listingTypeToDisplay(type)).join(', ');
}

export function listingTypeToDisplay(
  type: BasicListingTypeEnum | ListingTypeEnum | PropertyPreferencesTypeEnum
) {
  switch (type) {
    case BasicListingTypeEnum.BusinessOpportunity:
      return 'Business Op';
    case BasicListingTypeEnum.Commercial:
      return 'Commercial';
    case BasicListingTypeEnum.Condo:
      return 'Condo';
    case BasicListingTypeEnum.Land:
      return 'Land';
    case BasicListingTypeEnum.Mobile:
      return 'Mobile';
    case BasicListingTypeEnum.Multifamily:
      return 'Multi-family';
    case BasicListingTypeEnum.ResidentialIncome:
      return 'Resid. Income';
    case BasicListingTypeEnum.SingleFamily:
      return 'Single Family';
    case BasicListingTypeEnum.Townhouse:
      return 'Town House';
    case BasicListingTypeEnum.Other:
    default:
      return 'Other';
  }
}

export const removeHtmlTags = (rawStr: string) =>
  rawStr.replace(/(<([^>]+)>)/gi, '');

const hasValue = (value: Partial<PropertyPreferences>): boolean => {
  if (isNil(value) || value === 0) {
    return false;
  }
  return typeof value === 'boolean' || !isEmpty(value);
};

export const propertyPrefAdditionalToString = (
  propertyPrefs: Partial<PropertyPreferences>
) => {
  const lines = [] as string[];
  for (const key in propertyPrefs) {
    if (key && hasValue(propertyPrefs[key])) {
      const title = capitalize(
        key.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
      );
      const prefValue = Array.isArray(propertyPrefs[key])
        ? `(${propertyPrefs[key].join(', ')})`
        : propertyPrefs[key] === true
        ? 'Yes'
        : propertyPrefs[key] === false
        ? 'No'
        : propertyPrefs[key];
      lines.push(`${title}: ${prefValue}`);
    }
  }
  return lines.length ? `${lines.join(', ')}` : '';
};

export const formatDollarAmount = (
  num: number,
  fixedThousand = 2,
  fixedMilion = 2
): string => {
  if (num >= 1000000) {
    return millionMFormat(num, fixedMilion);
  }
  if (num >= 1000) {
    return thousandKFormat(num, fixedThousand);
  }
  return String(num);
};

export const thousandKFormat = (num: number, fixed = 2) =>
  num > 999
    ? `${Number((num / 1000).toFixed(fixed)).toLocaleString()}k`
    : String(num);

export const millionMFormat = (num: number, fixed = 2) =>
  num > 999
    ? `${Number((num / 1000000).toFixed(fixed)).toLocaleString()}m`
    : String(num);

export const formatDollarPriceRange = (
  priceMin: number,
  priceMax: number
): string | null => {
  if (priceMin && priceMax) {
    return `$${formatDollarAmount(priceMin)} - $${formatDollarAmount(
      priceMax
    )}`;
  }
  if (priceMin) {
    return `> $${formatDollarAmount(priceMin)}`;
  }
  if (priceMax) {
    return `< $${formatDollarAmount(priceMax)}`;
  }
  return null;
};
