import React from 'react';

export const ClientsTabMobileIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g stroke="rgba(0, 0, 0, 0.65)">
        <path d="M.621 2.195v11a2 2 0 002 2h13.394a2 2 0 002-2v-11a2 2 0 00-2-2H2.621a2 2 0 00-2 2z" />
        <path
          fill="#FFF"
          d="M21.015 2.195v15a2 2 0 002 2H36.41a2 2 0 002-2v-15a2 2 0 00-2-2H23.015a2 2 0 00-2 2z"
        />
        <path d="M21.015 24.088v12a2 2 0 002 2H36.41a2 2 0 002-2v-12a2 2 0 00-2-2H23.015a2 2 0 00-2 2z" />
        <path
          fill="#FFF"
          d="M.621 20.088v16a2 2 0 002 2h13.394a2 2 0 002-2v-16a2 2 0 00-2-2H2.621a2 2 0 00-2 2z"
        />
      </g>
    </g>
  </svg>
);
