import { ClientAffiliateData } from '@elm-street-technology/crm-axios-client';

export const AFFILIATE_DATA_KEY_ORDER: Array<keyof ClientAffiliateData> = [
  'fullName',
  'title',
  'location',
  'age',
  'bio',
  'website',
  'facebook',
  'linkedIn',
  'twitter',
  'topics',
  'interests',
  'education',
  'occupation',
  'householdIncome',
  'homeOwnerStatus',
  'homeMarketValue',
  'lengthOfResidence'
];

export const affiliateDataToDisplay = key => {
  switch (key) {
    case 'fullName':
      return 'Name';
    case 'title':
      return 'Title';
    case 'location':
      return 'Location';
    case 'age':
      return 'Age';
    case 'bio':
      return 'Bio';
    case 'website':
      return 'Website';
    case 'facebook':
      return 'Facebook';
    case 'linkedIn':
      return 'LinkedIn';
    case 'twitter':
      return 'Twitter';
    case 'topics':
      return 'Topics';
    case 'interests':
      return 'Interests';
    case 'education':
      return 'Education';
    case 'occupation':
      return 'Occupation';
    case 'householdIncome':
      return 'Household Income';
    case 'homeOwnerStatus':
      return 'Home Owner Status';
    case 'homeMarketValue':
      return 'Home Market Value';
    case 'lengthOfResidence':
      return 'Length of Residence';
    default:
      return '';
  }
};
