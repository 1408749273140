import React, { FC } from 'react';
import { Input, Form, Row, Col } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import { ClientFormSection } from './index';

interface Props {
  client?: Client;
}

export const ImportantDatesSection: FC<Props> = ({ client }) => (
  <ClientFormSection
    title="Important Dates"
    subtitle="If you don't know the year for a date, but you know the month and day, it's ok to fake the year!"
  >
    <Row gutter={[8, 0]}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Birthdate (MM-DD-YYY)"
          name="birthdate"
          initialValue={client?.birthdate?.split('T')?.[0]}
        >
          <Input type="date" size="large" />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Anniversary (MM-DD-YYY)"
          name="anniversary"
          initialValue={client?.anniversary?.split('T')?.[0]}
        >
          <Input type="date" size="large" />
        </Form.Item>
      </Col>
    </Row>
  </ClientFormSection>
);
