import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#082746">
          <g fillRule="nonzero" transform="translate(0 6)">
            <path d="M16.667 13.485l-2.633-2.64c-.524-.523-1.393-.507-1.936.037l-1.326 1.33-.262-.146c-.838-.466-1.984-1.103-3.19-2.313C6.11 8.54 5.472 7.389 5.006 6.549c-.049-.089-.096-.175-.143-.256l.89-.891.438-.44c.544-.544.559-1.414.036-1.94L3.595.382C3.072-.14 2.203-.124 1.659.42l-.742.748.02.02A4.303 4.303 0 00.326 2.27a4.49 4.49 0 00-.272 1.096c-.348 2.889.97 5.529 4.544 9.112 4.94 4.951 8.922 4.577 9.094 4.56a4.44 4.44 0 001.097-.277 4.274 4.274 0 001.075-.61l.016.014.752-.738c.542-.545.558-1.415.035-1.94z" />
          </g>
          <text
            fontFamily="Roboto-Medium, Roboto"
            fontSize="9"
            fontWeight="400"
          >
            <tspan x="14.942" y="8">
              2
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
