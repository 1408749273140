import React, { FC, useReducer } from 'react';
import {
  initialGlobalDrawerState,
  globalDrawerReducer
} from './globalDrawerReducer';
import {
  GlobalDrawerStateContext,
  GlobalDrawerDispatchContext
} from './GlobalDrawerContext';

export const GlobalDrawerProvider: FC = ({ children }) => {
  const [state, setState] = useReducer(
    globalDrawerReducer,
    initialGlobalDrawerState
  );
  return (
    <GlobalDrawerDispatchContext.Provider value={setState}>
      <GlobalDrawerStateContext.Provider value={state}>
        {children}
      </GlobalDrawerStateContext.Provider>
    </GlobalDrawerDispatchContext.Provider>
  );
};
