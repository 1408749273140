import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <circle id="b" cx="16.763" cy="16.763" r="16.763" />
        <filter
          id="a"
          width="192.5%"
          height="192.5%"
          x="-46.2%"
          y="-34.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <g>
              <g>
                <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                <use fill="#2E7FC2" xlinkHref="#b" />
              </g>
            </g>
            <g transform="translate(10 9)">
              <path
                stroke="#FFF"
                strokeWidth="0.5"
                d="M12.083 0L12.083 5.833"
              />
              <path stroke="#FFF" strokeWidth="0.5" d="M9.167 2.917L15 2.917" />
              <g fill="#FFF" transform="translate(0 1.667)">
                <g>
                  <path
                    fillRule="nonzero"
                    d="M12.934 6.57a.399.399 0 00-.399.398v4.37c0 .661-.536 1.196-1.197 1.197H1.995c-.66 0-1.196-.536-1.197-1.197V1.995c0-.66.536-1.196 1.197-1.197h4.37a.399.399 0 100-.798h-4.37A1.997 1.997 0 000 1.995v9.343a1.997 1.997 0 001.995 1.995h9.343a1.997 1.997 0 001.995-1.995v-4.37a.399.399 0 00-.399-.399z"
                  />
                  <g transform="translate(4.167 3.333)">
                    <path d="M0 5.45v.894c0 .178.146.323.327.323h5.18a.325.325 0 00.326-.323v-.893c-.003-1.26-1.037-2.279-2.31-2.279h-.081a1.628 1.628 0 001.1-1.807A1.64 1.64 0 002.916 0C2.11 0 1.422.578 1.29 1.366a1.628 1.628 0 001.1 1.806h-.084C1.035 3.174.003 4.192 0 5.451z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
