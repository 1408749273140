import { Upload, Form, Input, Button, Row, Col } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState, FC, useContext } from 'react';
import { Flex, FloatLabel } from 'src/crm/components';
import { DocsStateContext, DocsDispatchContext } from '../DocsTab.context';
import { createDoc } from '../actions';

import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '.';

interface DocCreatorProps {
  clientId: number;
}

export const DocCreator: FC<DocCreatorProps> = ({ clientId }) => {
  const { loading } = useContext(DocsStateContext);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useContext(DocsDispatchContext);
  const [floatValues, setFloatValues] = useState({ name: '', description: '' });

  const [form] = Form.useForm();

  const handleUploadRemove = () => {
    setFileList([]);
  };

  const handleBeforeUpload = file => {
    setFileList([file]);
    return false;
  };

  const handleCancelClick = () => {
    dispatch({ type: 'ShowList' });
  };

  const handleFormFinish = values => {
    if (fileList.length) {
      createDoc(
        dispatch,
        clientId,
        values.name,
        values.description,
        fileList[0]
      );
    }
  };

  const handleFormValuesChange = formValues =>
    setFloatValues(previous => ({
      ...previous,
      ...formValues
    }));

  const nameInput = (
    <FloatLabel label="name" name="name" value={floatValues.name}>
      <Form.Item name="name" initialValue="" rules={[{ required: true }]}>
        <Input disabled={loading} maxLength={MAX_NAME_LENGTH} />
      </Form.Item>
    </FloatLabel>
  );

  const descriptionTextArea = (
    <FloatLabel
      label="description"
      name="description"
      value={floatValues.description}
    >
      <Form.Item
        name="description"
        initialValue=""
        rules={[{ required: true }]}
      >
        <Input.TextArea
          disabled={loading}
          showCount
          maxLength={MAX_DESCRIPTION_LENGTH}
        />
      </Form.Item>
    </FloatLabel>
  );

  const fileUpload = (
    <Form.Item name="upload" rules={[{ required: true }]}>
      <Upload
        disabled={loading}
        fileList={fileList}
        multiple={false}
        showUploadList={{
          showRemoveIcon: true
        }}
        onRemove={handleUploadRemove}
        beforeUpload={handleBeforeUpload}
      >
        <Button size="large" type="primary">
          Choose A File
        </Button>
      </Upload>
    </Form.Item>
  );

  const buttons = (
    <Row gutter={[8, 8]} justify="end">
      <Col>
        <Button size="large" onClick={handleCancelClick}>
          Cancel
        </Button>
      </Col>
      <Col>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            loading={loading}
            disabled={loading}
            htmlType="submit"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <Form
      form={form}
      initialValues={undefined}
      validateMessages={{
        required: 'This field is required!'
      }}
      onFinish={handleFormFinish}
      onValuesChange={handleFormValuesChange}
    >
      <Flex
        alignContent="center"
        justifyContent="center"
        flexDirection="column"
      >
        {nameInput}
        {descriptionTextArea}
        {fileUpload}
        {buttons}
      </Flex>
    </Form>
  );
};
