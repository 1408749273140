import React from 'react';

const RecruitingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g
      stroke="#172945"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      clipPath="url(#clip0_80_669)"
    >
      <path d="M12.802 13.134l-2.256-2.386m1.611-4.089c0 3.2-2.453 5.793-5.48 5.793-3.026 0-5.48-2.593-5.48-5.793S3.652.866 6.678.866c3.027 0 5.48 2.594 5.48 5.793z" />
      <path d="M8.997 9.026c0-.607 0-.91-.094-1.15a1.269 1.269 0 00-.666-.704c-.227-.099-.514-.099-1.088-.099h-.923c-.574 0-.86 0-1.087.1a1.269 1.269 0 00-.667.704c-.094.24-.094.542-.094 1.15M7.92 4.468c0 .72-.551 1.302-1.231 1.302S5.456 5.188 5.456 4.47c0-.719.551-1.302 1.232-1.302.68 0 1.231.583 1.231 1.302z" />
    </g>
    <defs>
      <clipPath id="clip0_80_669">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default RecruitingIcon;
