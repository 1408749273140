import React from 'react';

const IncomingEmail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="17"
      viewBox="0 0 34 17"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#F15953">
          <path d="M18.9 0H2.1C.945 0 .01.945.01 2.1L0 14.7c0 1.155.945 2.1 2.1 2.1h16.8c1.155 0 2.1-.945 2.1-2.1V2.1C21 .945 20.055 0 18.9 0zm0 4.2l-8.4 5.25L2.1 4.2V2.1l8.4 5.25 8.4-5.25v2.1z" />
        </g>
        <g
          stroke="#F15953"
          strokeLinecap="round"
          strokeWidth="2"
          transform="rotate(-90 18 -7)"
        >
          <path d="M3.167 8L3.167 0" />
          <path
            strokeLinejoin="round"
            d="M5.66666667 2L3.16666667 0 0.666666667 2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IncomingEmail;
