import React from 'react';

function Icon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 15 13" version="1.1">
      <title>Controls/Cards Selected</title>
      <desc>Created with Sketch.</desc>
      <g
        id="CRM-Agent-Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="CRM-Dashboard-Desktop---Client-Details-Copy"
          transform="translate(-1545.000000, -1589.000000)"
          stroke="#2B85D9"
        >
          <g id="Group-16" transform="translate(910.000000, 421.000000)">
            <g id="Group-15-Copy">
              <g
                id="Controls/Cards-Selected"
                transform="translate(636.000000, 1169.000000)"
              >
                <g id="Dashboard-Icon">
                  <g id="Card-Icon">
                    <path
                      d="M0,2 L0,2.95 C-5.77301251e-16,4.0545695 0.8954305,4.95 2,4.95 L3.76177285,4.95 C4.86634235,4.95 5.76177285,4.0545695 5.76177285,2.95 L5.76177285,2 C5.76177285,0.8954305 4.86634235,-2.02906125e-16 3.76177285,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                      id="Path"
                    />
                    <path
                      d="M7.20221607,2 L7.20221607,2.95 C7.20221607,4.0545695 8.09764657,4.95 9.20221607,4.95 L10.9639889,4.95 C12.0685584,4.95 12.9639889,4.0545695 12.9639889,2.95 L12.9639889,2 C12.9639889,0.8954305 12.0685584,-2.02906125e-16 10.9639889,0 L9.20221607,0 C8.09764657,2.02906125e-16 7.20221607,0.8954305 7.20221607,2 Z"
                      id="Path"
                    />
                    <path
                      d="M7.20221607,7.878125 L7.20221607,8.828125 C7.20221607,9.9326945 8.09764657,10.828125 9.20221607,10.828125 L10.9639889,10.828125 C12.0685584,10.828125 12.9639889,9.9326945 12.9639889,8.828125 L12.9639889,7.878125 C12.9639889,6.7735555 12.0685584,5.878125 10.9639889,5.878125 L9.20221607,5.878125 C8.09764657,5.878125 7.20221607,6.7735555 7.20221607,7.878125 Z"
                      id="Path"
                    />
                    <path
                      d="M0,7.878125 L0,8.828125 C1.3527075e-16,9.9326945 0.8954305,10.828125 2,10.828125 L3.76177285,10.828125 C4.86634235,10.828125 5.76177285,9.9326945 5.76177285,8.828125 L5.76177285,7.878125 C5.76177285,6.7735555 4.86634235,5.878125 3.76177285,5.878125 L2,5.878125 C0.8954305,5.878125 -1.3527075e-16,6.7735555 0,7.878125 Z"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
