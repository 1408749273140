import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <title>Map Icon</title>
    <g
      id="CRM-Listing"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g
        id="CRM-Desktop---Listing-V2-Filter-Row"
        transform="translate(-1476.000000, -339.000000)"
        fillRule="nonzero"
        stroke="#595959"
      >
        <g id="Group-2" transform="translate(1477.000000, 340.000000)">
          <path
            d="M19.9382642,19.3910196 L17.8326172,4.26398884 C17.8116256,4.11299908 17.7398041,3.9822141 17.6368879,3.90760659 L12.3370689,0.0639205843 C12.2682836,0.013954714 12.191175,-0.00590876725 12.1154274,0.00173628394 L13.0796257,16.2207124 L13.047327,16.239552 L19.3900233,19.9499274 C19.4416385,19.9800298 19.4964992,19.9949103 19.5510982,19.9949103 C19.6375248,19.9949103 19.7231662,19.9578455 19.7935743,19.8859684 C19.9086352,19.7688353 19.9642812,19.5782551 19.9382642,19.3910196 Z"
            id="Path"
          />
          <path
            d="M6.54350339,3.73845983 L1.12312692,0.0571629051 C1.05816302,0.0129990826 0.986132135,-0.00502139524 0.915200558,0.00119020886 C0.9339935,-0.000311497627 0.952891138,1.55203874e-14 0.971631732,0.00173628394 L0.00743341985,16.2207124 L6.38242851,19.9499957 C6.43388662,19.9800298 6.4887997,19.9949786 6.54350339,19.9949786 L6.54350339,19.9949786 L6.54350339,3.73845983 L6.54350339,3.73845983 Z"
            id="Path"
          />
          <path
            d="M12.1153751,0.0014632464 C12.0632364,0.0067192191 12.0116736,0.0248079563 11.9638799,0.0572994239 L6.54350339,3.73852809 L6.54350339,19.9950469 C6.54350339,19.9950469 6.54350339,19.9950469 6.54350339,19.9950469 C6.59825943,19.9950469 6.65322486,19.9801663 6.70463062,19.9500639 L13.0796257,16.2207807 L12.1153751,0.0014632464 Z"
            id="Path"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const ListingsMapIcon = props => <Icon component={Svg} {...props} />;
