import { ClientSellerFields } from '@elm-street-technology/crm-axios-client';
import { useReducer, useCallback, useMemo } from 'react';
import isNumber from 'lodash/isNumber';
import {
  initialCmaListingsState,
  cmaListingsReducer,
  listCMAComparables
} from './cmaListingsReducer';

export const useCmaListings = () => {
  const [state, dispatch] = useReducer(
    cmaListingsReducer,
    initialCmaListingsState
  );

  const getCmaListings = useCallback(
    ({
      baths,
      beds,
      estimatedListPrice,
      listingType,
      competingPctPriceRange,
      competingWithinMiles,
      streetAddress1,
      city,
      state,
      zipCode
    }: ClientSellerFields) => {
      const location = `${streetAddress1 ? `${streetAddress1}, ` : ''}${
        city ? `${city}, ` : ''
      }${state ? `${state}, ` : ''}${zipCode ? `${zipCode}, ` : ''}`;

      listCMAComparables(dispatch, {
        location,
        beds: isNumber(Number(beds)) ? Number(beds) : undefined,
        baths: isNumber(Number(baths)) ? Number(baths) : undefined,
        price: estimatedListPrice ?? undefined,
        radius: competingWithinMiles,
        type: listingType,
        limit: competingPctPriceRange ? competingPctPriceRange / 100 : 0.2
      });
    },
    []
  );

  return useMemo(() => ({ ...state, getCmaListings }), [state]);
};
