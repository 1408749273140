import { Dispatch, Reducer } from 'react';
import { AxiosError } from 'axios';
import { Task } from '@elm-street-technology/crm-axios-client';

export interface TasksState {
  tasks: Task[];
  loading: boolean;
  error: AxiosError | null;
  view: 'List' | 'Edit';
  editTask: Task | null;
}

export type TasksActions =
  | { type: 'GetList' }
  | { type: 'GetListSuccess'; payload: Task[] }
  | { type: 'GetListFailure'; payload: AxiosError }
  | { type: 'Update' }
  | { type: 'UpdateSuccess'; payload: Task }
  | { type: 'UpdateFailure'; payload: AxiosError }
  | { type: 'Create' }
  | { type: 'CreateSuccess'; payload: Task }
  | { type: 'CreateFailure'; payload: AxiosError }
  | { type: 'Delete' }
  | { type: 'DeleteSuccess'; payload: Task['id'] }
  | { type: 'DeleteFailure'; payload: AxiosError }
  | { type: 'ShowList' }
  | { type: 'ShowEdit'; payload: Task }
  | { type: 'ShowAdd' };

export type TasksDispatch = Dispatch<TasksActions>;

export const initialTasksState: TasksState = {
  tasks: [],
  loading: false,
  error: null,
  view: 'List',
  editTask: null
};

export const tasksReducer: Reducer<TasksState, TasksActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'GetList':
      return { ...state, loading: true };
    case 'GetListSuccess':
      return {
        ...state,
        tasks: sortTasksByDueDateDescending(action.payload),
        loading: false
      };
    case 'GetListFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'Update':
      return { ...state, loading: true };
    case 'UpdateSuccess':
      return replaceUpdatedTask(state, action);
    case 'UpdateFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'Create':
      return { ...state, loading: true };
    case 'CreateSuccess':
      return {
        ...state,
        tasks: sortTasksByDueDateDescending([action.payload, ...state.tasks]),
        loading: false,
        view: 'List',
        editTask: null
      };
    case 'CreateFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'Delete':
      return {
        ...state,
        loading: true
      };
    case 'DeleteSuccess':
      return removeDeletedTask(state, action);
    case 'DeleteFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'ShowList':
      return {
        ...state,
        view: 'List',
        editTask: null
      };
    case 'ShowAdd':
      return {
        ...state,
        view: 'Edit',
        editTask: null
      };
    case 'ShowEdit':
      return {
        ...state,
        view: 'Edit',
        editTask: action.payload
      };
    default:
      return state;
  }
};

const replaceUpdatedTask: Reducer<
  TasksState,
  { type: 'UpdateSuccess'; payload: Task }
> = (state, action) => {
  const updatedIndex = state.tasks.findIndex(
    ({ id }) => id === action.payload.id
  );

  if (updatedIndex > -1) {
    const tasks = [...state.tasks];
    tasks.splice(updatedIndex, 1, action.payload);
    return {
      ...state,
      tasks,
      loading: false,
      view: 'List',
      editTask: null
    };
  }

  return {
    ...state,
    loading: false,
    view: 'List',
    editTask: null
  };
};

const removeDeletedTask: Reducer<
  TasksState,
  { type: 'DeleteSuccess'; payload: Task['id'] }
> = (state, action) => {
  const deletedIndex = state.tasks.findIndex(({ id }) => id === action.payload);

  if (deletedIndex > -1) {
    const tasks = [...state.tasks];
    tasks.splice(deletedIndex, 1);
    return {
      ...state,
      tasks,
      loading: false
    };
  }

  return {
    ...state,
    loading: false
  };
};

const sortTasksByDueDateDescending = (tasks: Task[]) => {
  const sortedTasks = [...tasks];

  sortedTasks.sort(({ dueDateIsoStr: a }, { dueDateIsoStr: b }) =>
    a < b ? 1 : a > b ? -1 : 0
  );

  return sortedTasks;
};
