import * as React from 'react';

function SvgBathDesktop(props) {
  return (
    <svg width={36} height={33} {...props}>
      <g fill="#082746" fillRule="nonzero">
        <path d="M5.105 31C2.29 31 0 28.823 0 26.15v-6.386C0 19.342.336 19 .75 19h34.5c.414 0 .75.342.75.764v6.386c0 2.673-2.29 4.85-5.105 4.85H5.105zM1.5 20.527v5.623c0 1.831 1.617 3.322 3.605 3.322h25.79c1.988 0 3.605-1.49 3.605-3.322v-5.623h-33z" />
        <path d="M11.07 33H6.92a.759.759 0 01-.767-.75v-2.766c0-.414.343-.75.767-.75h4.15c.424 0 .768.336.768.75v2.766c0 .414-.344.75-.767.75zm-3.384-1.5h2.617v-1.266H7.687V31.5h-.001zM29.233 33H25.08a.759.759 0 01-.767-.75v-2.766c0-.414.344-.75.767-.75h4.152c.424 0 .767.336.767.75v2.766c0 .414-.343.75-.767.75zm-3.385-1.5h2.618v-1.266h-2.618V31.5zM2.768 19.484a.759.759 0 01-.768-.75V5.69C2 2.552 4.805 0 8.252 0c2.53 0 4.377 1.082 5.343 3.131a.743.743 0 01-.375.995.775.775 0 01-1.018-.366c-.717-1.521-2.01-2.26-3.95-2.26-2.601 0-4.718 1.88-4.718 4.19v13.044c0 .414-.343.75-.767.75z" />
        <path d="M11.727 8.884a.713.713 0 01-.552-.267A.794.794 0 0111.01 8l.749-5.107a1.026 1.026 0 011.144-.885l5.271.728c.299.04.542.27.613.578a.786.786 0 01-.293.81l-6.352 4.623a.703.703 0 01-.414.136zm1.402-5.3l-.424 2.892 3.407-2.48-2.983-.411zm1.253 7.599a.712.712 0 01-.56-.277l-.308-.39a.793.793 0 01.101-1.07.703.703 0 011.018.092l.308.39c.18.229.219.546.1.815a.726.726 0 01-.66.44zm2.953-.955a.712.712 0 01-.56-.275l-.309-.39a.798.798 0 01-.122-.756.738.738 0 01.56-.489.709.709 0 01.683.266l.307.39c.18.229.218.545.098.814a.726.726 0 01-.657.44zm1.969-1.907a.712.712 0 01-.56-.278l-.308-.39a.793.793 0 01.101-1.07.703.703 0 011.018.092l.308.39c.18.23.218.546.098.814a.726.726 0 01-.657.44v.002zM16.35 15a.712.712 0 01-.56-.276l-.308-.39a.793.793 0 01.095-1.08.703.703 0 011.025.1l.308.391c.18.229.219.546.099.815a.726.726 0 01-.659.44zm2.954-.955a.714.714 0 01-.56-.275l-.308-.39a.795.795 0 01.094-1.08.704.704 0 011.025.1l.308.39a.8.8 0 01.097.814.728.728 0 01-.656.441zm1.969-1.907a.712.712 0 01-.56-.278l-.308-.39a.793.793 0 01.101-1.07.703.703 0 011.018.092l.308.39c.18.23.219.547.1.815a.726.726 0 01-.66.44z" />
      </g>
    </svg>
  );
}

export default SvgBathDesktop;
