import * as d3 from 'd3';
import React, {
  FC,
  useLayoutEffect,
  useEffect,
  useRef,
  useReducer
} from 'react';
import { Skeleton, Row } from 'antd';
import { useUserAccount } from 'src/common/hooks';
import {
  initialContactPipelineChartDataState,
  contactPipelineChartDataReducer,
  getContactPipelineChartData
} from './ContactPipelineChartData.reducer';

interface RadialChartProps {
  height: number;
  timeframe?: string;
}

export const ContactsPipelineRadialChart: FC<RadialChartProps> = ({
  height,
  timeframe
}) => {
  const { user } = useUserAccount();
  const d3Container = useRef<SVGSVGElement>(null);

  const [{ data, loading, error }, dispatch] = useReducer(
    contactPipelineChartDataReducer,
    initialContactPipelineChartDataState
  );

  useEffect(() => {
    getContactPipelineChartData(dispatch, user.id, timeframe);
  }, [timeframe, user]);

  useLayoutEffect(() => {
    if (data) {
      const radialData = [
        {
          name: 'In Pipeline',
          value: data.inPipeline
        },
        {
          name: 'Out of Pipeline',
          value: data.outOfPipeline
        }
      ];

      const options = {
        wrapLength: 5,
        height,
        width: 350
      };

      makeRadialChart(d3Container.current, radialData, options);
    }
  }, [data, height]);

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height }}>
        Data could not be retrieved
      </Row>
    );
  }
  if (loading) {
    return (
      <Row justify="center" style={{ height }}>
        <Skeleton />
      </Row>
    );
  }
  return <svg ref={d3Container} />;
};

const normalizeData = data => {
  const max = data.reduce((acc, x) => acc + x.value, 0);

  // outer to inner
  const colors = ['#4ea5f2', '#e56345', '#facb3e', '#6dbc6d'];
  let i = -1;
  return data
    .sort((a, b) => (b.hidden ? -1 : 1))
    .map(item => {
      if (!item.hidden) {
        i += 1;
      }

      return {
        index: !item.hidden ? i : null,
        hidden: item.hidden,
        value: item.value,
        color: item.hidden ? '#d3d7db' : colors[i],
        name: item.name,
        max,
        endAngle: (item.value * 2 * Math.PI) / max
      };
    });
};

const drawRadial = (g, size, unfilteredData) => {
  const bgcolor = '#d3d7db';
  const data = unfilteredData.filter(d => d.color);

  const inner: number[] = [];
  const outer: number[] = [];

  const innerRadius = d => {
    let radius = inner[d.index];
    if (radius) {
      return radius;
    }

    if (d.index === 0) {
      radius = size / 2;
    } else {
      radius = outer[d.index - 1] - 9;
    }

    inner[d.index] = radius;

    return radius;
  };

  const outerRadius = d => {
    let radius = outer[d.index];
    if (radius) {
      return radius;
    }

    // based on the previous inner radius + stroke width
    radius = inner[d.index] - 9;
    outer[d.index] = radius;
    return radius;
  };

  const progress = d3
    .arc()
    .startAngle(0)
    .endAngle(d => d.endAngle)
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  const background = d3
    .arc()
    .startAngle(0)
    .endAngle(2 * Math.PI)
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  const field = g
    .append('g')
    .selectAll('g')
    .data(data)
    .enter()
    .append('g');

  field
    .append('path')
    .attr('d', d => progress(d))
    .style('fill', d => d.color)
    .attr('transform', `translate(${size / 2},${size / 2})`);

  field
    .append('path')
    .style('fill', bgcolor)
    .style('opacity', 0.2)
    .attr('d', background)
    .attr('transform', `translate(${size / 2},${size / 2})`);
};

const drawCenter = (g, size, data) => {
  const center = g
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('transform', `translate(${size / 2}, ${size / 2})`)
    .style(
      'font-family',
      "'Roboto', 'Myriad Set Pro', 'Lucida Grande', 'Helvetica Neue', Helvetica, Arial"
    );

  center
    .append('tspan')
    .text(data[0].max.toString())
    .style('fill', 'black')
    .style('font-size', '36px')
    .attr('dy', '0.1em')
    .attr('x', 0);

  center
    .append('tspan')
    .text('TOTAL')
    .style('fill', '#444444')
    .style('font-size', '22px')
    .attr('dy', '1.1em')
    .attr('x', 0);
};

const drawLegend = (g, size, rawData, options) => {
  const data = rawData
    .map((d, i) =>
      i % options.wrapLength === 0
        ? rawData.slice(i, i + options.wrapLength)
        : null
    )
    .filter(d => d);
  const radius = 13;

  const legend = g.append('g');

  const groups = legend
    .selectAll('g')
    .data(data)
    .enter()
    .append('g')
    .attr('transform', (d, i) => `translate(${i * 125},0)`);

  groups
    .selectAll('circle')
    .data(d => d)
    .enter()
    .append('circle')
    .attr('cx', size + 2 * radius)
    .attr('cy', (d, i) => radius + i * radius * 3)
    .attr('r', radius)
    .style('fill', d => d.color);

  groups
    .selectAll('text')
    .data(d => d)
    .enter()
    .append('text')
    .attr('x', size + 3.5 * radius)
    .attr('y', (d, i) => radius + i * radius * 3 + radius * 0.3)
    .text(d => d.value)
    .attr('font-family', 'sans-serif')
    .attr('font-size', `${radius * 1.4}px`)
    .attr('fill', 'black')
    .append('tspan')
    .text(d => d.name)
    .attr('font-size', `${radius}px`)
    .attr('dx', '0.25em')
    .attr('dy', '-0.2em')
    .attr('fill', '#444444');
};

const makeRadialChart = (selector, data, options) => {
  const { height, width } = options;

  const svg = d3.select(selector);

  svg.selectAll('*').remove();

  svg
    .attr('viewBox', ` 0 0 ${width} ${height}`)
    .attr('width', '100%')
    .attr('height', height);

  if (!data.length || !data.reduce((acc, d) => Math.max(acc, d.value), 0)) {
    svg
      .append('text')
      .text('No data for graph')
      .attr('fill', 'black')
      .attr('x', 25)
      .attr('y', 25);
    return;
  }

  const margin = 10;
  const g = svg.append('g').attr('transform', `translate(${margin},${margin})`);

  const normalData = normalizeData(data);

  const size = height - margin * 2;
  drawRadial(g, size, normalData);
  drawCenter(g, size, normalData);
  drawLegend(g, size, normalData, options);
};
