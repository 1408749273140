import React, { FC } from 'react';
import { CMAListing } from '@elm-street-technology/crm-axios-client';
import { List, ListProps } from 'antd';
import { CmaListingCard } from './CmaListingCard';

interface Props {
  cmaListings: CMAListing[];
  loading?: boolean;
  pagination?: ListProps<CMAListing>['pagination'];
  onListingClick: (listing: CMAListing) => void;
}

export const CmaListingCardList: FC<Props> = ({
  cmaListings,
  loading,
  pagination,
  onListingClick
}) => (
  <List
    grid={{ column: 1, gutter: 16 }}
    dataSource={cmaListings}
    loading={loading}
    pagination={pagination}
    renderItem={cmaListing => (
      <List.Item
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CmaListingCard
          cmaListing={cmaListing}
          onCardClick={() => onListingClick(cmaListing)}
        />
      </List.Item>
    )}
  />
);
