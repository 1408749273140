import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { contributorHistoryApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const listClientContributorHistory = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetContributorHistoryPending' });

  contributorHistoryApi
    .listClientContributorHistory({ clientId })
    .then(({ data }) =>
      dispatch({
        type: 'GetContributorHistorySuccess',
        payload: data
      })
    )
    .catch(error => {
      message.error(getFetchMessageText('contributor history').error);
      dispatch({ type: 'GetContributorHistoryFailure' });
    });
};
