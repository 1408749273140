import * as Sentry from '@sentry/react';
import { User, UserRolesEnum } from '@elm-street-technology/crm-axios-client';

export const initializeSentryUser = (user: User) => {
  Sentry.setUser({
    id: `${user.id}`,
    email: user.email,
    name: user.displayName,
    tenant: user.tenant,
    roles: user.roles,
    datastoreProviderIds: user.datastoreProviderIds,
    isBroker: user.roles?.includes(UserRolesEnum.Broker),
    isTeamAdmin: user.roles?.includes(UserRolesEnum.TeamAdmin),
    isSuperAdmin: user.roles?.includes(UserRolesEnum.SuperAdmin)
  });
};
