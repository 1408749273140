import * as React from 'react';

const SvgDashboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <g stroke="#172945" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5.833 1.75H1.75v4.083h4.083V1.75zM12.25 1.75H8.167v4.083h4.083V1.75zM12.25 8.167H8.167v4.083h4.083V8.167zM5.833 8.167H1.75v4.083h4.083V8.167z" />
      </g>
    </g>
  </svg>
);

export default SvgDashboard;
