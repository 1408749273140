import styled from 'styled-components';
import { Button, Input, Row, Col } from 'antd';
import React, { useState, FC, useContext } from 'react';
import { NotesStateContext, NotesDispatchContext } from '../Notes.context';
import { updateClientNote, createClientNote } from '../actions';

const InputTextArea = styled(Input.TextArea)`
  margin-top: 80px;
  margin-bottom: 80px;
`;

const ButtonRow = styled(Row)`
  margin: 26px 40px 26px 0px;
`;

const CancelEditButton = styled(Button)`
  width: 144px;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #a7a7a7;
  font-size: 15px;
  color: #a7a7a7;
`;

const SubmitEditButton = styled(Button)`
  width: 144px;
  height: 40px;
  margin-left: 16px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  border: ${({ theme }) => `solid 1px ${theme.border}`};
  background-color: ${({ theme }) => theme.primaryBlue};

  font-size: 15px;
  color: ${({ theme }) => theme.whiteBackGround};
`;

interface Props {
  clientId: number;
}

export const NoteEditor: FC<Props> = ({ clientId }) => {
  const { editNote, loading } = useContext(NotesStateContext);
  const dispatch = useContext(NotesDispatchContext);

  const [description, setDescription] = useState(editNote?.description || '');

  const handleSubmitClick = () => {
    if (editNote) {
      updateClientNote(dispatch, clientId, editNote.id, description);
    } else {
      createClientNote(dispatch, clientId, description);
    }
  };

  const handleCancelClick = () => dispatch({ type: 'ShowList' });

  return (
    <>
      <Row justify="center">
        <Col span={19}>
          <InputTextArea
            autoFocus
            value={description}
            placeholder="Description (Required)"
            autoSize={{ minRows: 7 }}
            maxLength={2000}
            onChange={e => {
              setDescription(e.target.value);
            }}
            showCount
          />
        </Col>
      </Row>

      <ButtonRow justify="end">
        <Col>
          <CancelEditButton onClick={handleCancelClick}>
            Cancel
          </CancelEditButton>
        </Col>
        <Col>
          <SubmitEditButton
            loading={loading}
            disabled={description === ''}
            onClick={handleSubmitClick}
          >
            {editNote ? 'Saves Changes' : 'Save Note'}
          </SubmitEditButton>
        </Col>
      </ButtonRow>
    </>
  );
};
