import { Dispatch, Reducer } from 'react';
import {
  Client,
  ClientListItem
} from '@elm-street-technology/crm-axios-client';

export interface GlobalDrawerState {
  key: 'Call' | 'Message' | 'Email' | 'ListingsEmail' | '';
  client: Client | ClientListItem | null;
  clients: ClientListItem[] | Client[];
  listingIds: string[];
}

export type GlobalDrawerActions =
  | { type: 'OpenQuickCall'; payload: Client | ClientListItem }
  | { type: 'OpenQuickMessage'; payload: Client | ClientListItem }
  | { type: 'OpenQuickEmail'; payload: Client[] | ClientListItem[] }
  | { type: 'OpenQuickListingsEmail'; payload: string[] }
  | { type: 'CloseDrawer' };

export type GlobalDrawerDispatch = Dispatch<GlobalDrawerActions>;

export const initialGlobalDrawerState: GlobalDrawerState = {
  key: '',
  client: null,
  clients: [],
  listingIds: []
};

export const globalDrawerReducer: Reducer<
  GlobalDrawerState,
  GlobalDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'OpenQuickCall':
      return {
        ...initialGlobalDrawerState,
        client: action.payload,
        key: 'Call'
      };
    case 'OpenQuickMessage':
      return {
        ...initialGlobalDrawerState,
        client: action.payload,
        key: 'Message'
      };
    case 'OpenQuickEmail':
      return {
        ...initialGlobalDrawerState,
        clients: action.payload,
        key: 'Email'
      };
    case 'OpenQuickListingsEmail':
      return {
        ...initialGlobalDrawerState,
        listingIds: action.payload,
        key: 'ListingsEmail'
      };
    case 'CloseDrawer':
      return initialGlobalDrawerState;
    default:
      return state;
  }
};
