import { Empty, PageHeader, Divider } from 'antd';
import React, { useEffect, useReducer } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import { useUserAccount } from 'src/common/hooks';
import {
  initialClientCommunicationsState,
  clientCommunicationReducer,
  listClientCommunication
} from './CommunicationSection.reducer';
import { CommunicationList } from './components';
import { ClientRecordSectionTitle } from '../index';

interface Props {
  client: Client;
}
export const CommunicationSection: React.FC<Props> = ({ client }) => {
  const { user } = useUserAccount();
  const [{ data: communications }, dispatch] = useReducer(
    clientCommunicationReducer,
    initialClientCommunicationsState
  );

  useEffect(() => {
    listClientCommunication(dispatch, client.id);
  }, [client.id]);

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Communication</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      {communications.length ? (
        <CommunicationList
          client={client}
          user={user}
          communications={communications}
        />
      ) : (
        <Empty />
      )}
    </PageHeader>
  );
};
