import React, { CSSProperties, useEffect, useState, FC } from 'react';
import MomentUtils from '@date-io/moment';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withProfiler } from '@sentry/react';
import { Layout, Grid, Spin } from 'antd';
import { UserRolesEnum, User } from '@elm-street-technology/crm-axios-client';
import { ThemeProvider } from 'styled-components';
import { SocketIOProvider } from 'use-socketio';
import {
  FeatureTogglesProvider,
  UserAccountContext,
  UserAccountDispatchContext
} from 'src/common/contexts';
import {
  identifyPendoUser,
  initializeSentryUser,
  userApi
} from 'src/common/services';
import GlobalStyles from 'src/GlobalStyle';
import DEFAULT_THEME from 'src/themes';
import { LoadingIndicator } from 'src/crm/components/LoadingIndicator';
import { routes } from 'src/routes';
import {
  CenteredContainer,
  GlobalDrawerSwitch,
  GlobalPhoneNumberModal,
  GlobalTwilioRefreshModal
} from 'src/common/components';
import { GlobalDrawerProvider } from 'src/common/hooks';
import { ProfileProvider } from './crm/pages/Profile/Profile.context';

import './App.css';
import { Header, Nav } from './crm/components';
import { muiTheme, CRM_HOST, SOCKET_IO_URL } from './common/constants';

const style = {
  content: (isBannerHidden: boolean, md?: boolean) => {
    const margin = md ? '64px 0 0 1rem' : '64px 0';

    return {
      height: isBannerHidden ? 'calc(100vh - 64px)' : 'calc(100vh - 128px)',
      top: 64,
      paddingBottom: 64,
      paddingRight: '1rem',
      overflow: 'auto',
      margin
    } as CSSProperties;
  }
};

declare global {
  interface Window {
    // Google tag manager's global variable
    dataLayer: Array<{}>;
    // uploadcare's global variable
    uploadcare: Record<'openDialog', Function>;
  }
}

const App: FC = () => {
  const { md } = Grid.useBreakpoint();

  const [user, setUser] = useState<User | null>(null);

  const [isBannerHidden, setIsBannerHidden] = useState(() => {
    return localStorage.getItem('hideAiBanner') === 'true';
  });

  const carouselItems = [
    'Elevate AI: Reacquaint yourself with a contact in seconds with lead summary.',
    'Elevate AI: Quickly digest a listing with listing summary.'
  ];
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveItemIndex(prevIndex => {
        const newIndex = (prevIndex + 1) % carouselItems.length;
        return newIndex;
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, [carouselItems.length]);

  useEffect(() => {
    localStorage.setItem('hideAiBanner', `${isBannerHidden}`);
  }, [isBannerHidden]);

  const handleAiBannerDismiss = () => {
    setIsBannerHidden(!isBannerHidden);
  };

  useEffect(() => {
    userApi
      .getMe()
      .then(({ data: user }) => {
        if (user?.roles.includes(UserRolesEnum.SuperAdmin)) {
          window.location.replace(`${CRM_HOST}/admin/users`);
        }

        identifyPendoUser(user);
        initializeSentryUser(user);
        sessionStorage.setItem('mlsAccessToken', user.mlsAccessToken || '');
        setUser(user);
      })
      .catch(error => {
        window.location.href = `${CRM_HOST}/login`;
      });
  }, []);

  DEFAULT_THEME.showAiHeader = !isBannerHidden;

  if (!user || user.roles.includes(UserRolesEnum.SuperAdmin)) {
    return (
      <CenteredContainer>
        <Spin indicator={<LoadingIndicator />} size="large" />
      </CenteredContainer>
    );
  }

  const layout = (
    <Layout>
      <Header />
      <Layout>
        <Nav />
        <Layout.Content style={style.content(isBannerHidden, md)}>
          {routes}
          <GlobalDrawerSwitch />
          <ProfileProvider>
            <GlobalPhoneNumberModal userId={user.id} />
            <GlobalTwilioRefreshModal userId={user.id} />
          </ProfileProvider>
        </Layout.Content>
      </Layout>
    </Layout>
  );
  const fullPath = `/users/${user.id}/advanced-ai`;
  const renderCarousel = () => (
    <div className="aiBanner">
      <div className="carousel-wrapper">
        {carouselItems.map((content, index) => (
          <div
            key={index} // eslint-disable-line react/no-array-index-key
            className={`carousel-item ${
              activeItemIndex === index ? 'active' : ''
            }`}
            style={{ display: activeItemIndex === index ? 'flex' : 'none' }}
          >
            <div className="aiBanner-content">
              {content}
              <a href={fullPath} className="learnMoreButton">
                {' '}
                Learn More
              </a>
            </div>
          </div>
        ))}
        <div className="carousel-indicators">
          {carouselItems.map((_, index) => (
            <span
              key={index} // eslint-disable-line react/no-array-index-key
              className={`carousel-indicator ${
                activeItemIndex === index ? 'active' : ''
              }`}
              onClick={() => setActiveItemIndex(index)}
            />
          ))}
        </div>
      </div>
      <span className="dismissAdBanner" onClick={handleAiBannerDismiss}>
        <img src="/images/close-icon.svg" alt="Dismiss" />
      </span>
    </div>
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={DEFAULT_THEME}>
        <SocketIOProvider url={SOCKET_IO_URL}>
          <GlobalStyles />
          <MuiThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <UserAccountContext.Provider value={user}>
                <UserAccountDispatchContext.Provider value={setUser}>
                  <FeatureTogglesProvider user={user}>
                    <GlobalDrawerProvider>
                      {!isBannerHidden && renderCarousel()}
                      {layout}
                    </GlobalDrawerProvider>
                  </FeatureTogglesProvider>
                </UserAccountDispatchContext.Provider>
              </UserAccountContext.Provider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </SocketIOProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default withProfiler(App);
