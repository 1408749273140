import React, { FC, useMemo, useContext, useEffect, useReducer } from 'react';
import { List, Button, PageHeader, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DocEditor, DocCreator, DocListItem } from './components';
import { DocsStateContext, DocsDispatchContext } from './DocsTab.context';
import { listDocs } from './actions';
import { docsReducer, initialDocsState } from './DocsTab.reducer';
import { ClientRecordSectionTitle } from '../index';

interface Props {
  clientId: number;
}

const Docs: FC<Props> = ({ clientId }) => {
  const { view, docs, loading, editDoc } = useContext(DocsStateContext);
  const dispatch = useContext(DocsDispatchContext);

  const handleNewDocClick = () => {
    dispatch({ type: 'ShowAdd' });
  };

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Docs</ClientRecordSectionTitle>}
      footer={<Divider />}
      extra={
        view === 'List' && (
          <Button
            onClick={handleNewDocClick}
            size="large"
            icon={<PlusOutlined />}
          >
            New Doc
          </Button>
        )
      }
    >
      {useMemo(() => {
        if (view === 'Add') {
          return <DocCreator clientId={clientId} />;
        }

        if (view === 'Edit' && editDoc) {
          return <DocEditor clientId={clientId} editDoc={editDoc} />;
        }

        return (
          <List
            dataSource={docs}
            renderItem={doc => <DocListItem doc={doc} />}
            loading={loading}
            itemLayout="horizontal"
            size="large"
          />
        );
      }, [clientId, view, docs, editDoc, loading])}
    </PageHeader>
  );
};

export const DocsTab: FC<Props> = ({ clientId }) => {
  const [state, dispatch] = useReducer(docsReducer, initialDocsState);

  useEffect(() => {
    listDocs(dispatch, clientId);
  }, [clientId]);

  return (
    <DocsStateContext.Provider value={state}>
      <DocsDispatchContext.Provider value={dispatch}>
        <Docs clientId={clientId} />
      </DocsDispatchContext.Provider>
    </DocsStateContext.Provider>
  );
};
