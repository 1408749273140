import { captureMessage } from '@sentry/react';
import {
  BasicListingStatusEnum,
  BasicListingTypeEnum
} from '@elm-street-technology/mls-axios-client';
import {
  TaskTypeEnum,
  ListingStatusEnum,
  ListingTypeEnum,
  ClientListItemTypeEnum,
  ClientTypeEnum,
  ClientListItemStatusEnum,
  ClientStatusEnum,
  CMAListingTypeEnum,
  CMAListingStatusEnum
} from '@elm-street-technology/crm-axios-client';
import {
  BLACK,
  BLUE,
  BROWN,
  GREEN,
  LIGHT_BLUE,
  LIGHT_GREEN,
  LIGHT_ORANGE,
  LIGHT_RED,
  LIGHT_YELLOW,
  ORANGE,
  ORANGE_2,
  PINK,
  PURPLE,
  RED,
  TEAL,
  WHITE
} from 'src/themes';

export enum COLOR_TOPIC {
  // Status
  NEW,
  MARKETING,
  ENGAGED,
  ACTIVE,
  PENDING,
  SOLD,
  COLD,
  NONE,
  ARCHIVE,
  // Roles
  BUYER,
  VENDOR,
  PERSONAL,
  SELLER,
  FAMILY,
  PAST_CLIENT,
  BUYER_SELLER,
  FRIEND,
  RENTER,
  AGENT,
  CRM_AGENT,
  PROSPECTIVE_AGENT,
  // Listing Types
  SINGLE_FAMILY,
  BUSINESS,
  COMMERCIAL,
  CONDO,
  LAND,
  MOBILE,
  MULTIFAMILY,
  RESIDENTIAL,
  OTHER,
  TOWNHOUSE,
  // Listing Status
  L_ACTIVE,
  L_PENDING,
  L_SOLD
}

export interface ColorCombination {
  foreground?: string;
  background?: string;
  border?: string;
}
const TRANSPARENT = {
  foreground: 'transparent',
  background: 'transparent',
  border: 'transparent'
};

/** color, background, border; keyed to topic */
export const TOPIC_HEX_MAP = new Map<COLOR_TOPIC, ColorCombination>([
  // Status
  [
    COLOR_TOPIC.NEW,
    { foreground: BLACK, background: LIGHT_BLUE, border: TEAL }
  ],
  [
    COLOR_TOPIC.MARKETING,
    { foreground: BLACK, background: LIGHT_BLUE, border: BLUE }
  ],
  [
    COLOR_TOPIC.ENGAGED,
    { foreground: BLACK, background: LIGHT_GREEN, border: GREEN }
  ],
  [
    COLOR_TOPIC.ACTIVE,
    { foreground: BLACK, background: LIGHT_YELLOW, border: ORANGE }
  ],
  [
    COLOR_TOPIC.PENDING,
    { foreground: BLACK, background: LIGHT_ORANGE, border: ORANGE_2 }
  ],
  [COLOR_TOPIC.SOLD, { foreground: BLACK, background: LIGHT_RED, border: RED }],
  [
    COLOR_TOPIC.COLD,
    { foreground: BLACK, background: WHITE, border: '#0ebeff' }
  ],
  [COLOR_TOPIC.NONE, TRANSPARENT],
  [
    COLOR_TOPIC.ARCHIVE,
    { foreground: BLACK, background: WHITE, border: '#d99292' }
  ],
  [
    COLOR_TOPIC.CRM_AGENT,
    { foreground: BLACK, background: WHITE, border: WHITE }
  ],
  [
    COLOR_TOPIC.PROSPECTIVE_AGENT,
    { foreground: BLACK, background: WHITE, border: WHITE }
  ],
  // Roles
  [COLOR_TOPIC.BUYER, { foreground: BLUE, background: WHITE, border: BLUE }],
  [COLOR_TOPIC.VENDOR, { foreground: BROWN, background: WHITE, border: BROWN }],
  [
    COLOR_TOPIC.PERSONAL,
    { foreground: ORANGE, background: WHITE, border: ORANGE }
  ],
  [
    COLOR_TOPIC.SELLER,
    { foreground: PURPLE, background: WHITE, border: PURPLE }
  ],
  [COLOR_TOPIC.FAMILY, { foreground: TEAL, background: WHITE, border: TEAL }],
  [
    COLOR_TOPIC.PAST_CLIENT,
    { foreground: LIGHT_BLUE, background: WHITE, border: LIGHT_BLUE }
  ],
  [
    COLOR_TOPIC.BUYER_SELLER,
    { foreground: GREEN, background: WHITE, border: GREEN }
  ],
  [COLOR_TOPIC.FRIEND, { foreground: PINK, background: WHITE, border: PINK }],
  [
    COLOR_TOPIC.RENTER,
    { foreground: ORANGE, background: WHITE, border: ORANGE }
  ],
  [COLOR_TOPIC.AGENT, { foreground: BLACK, background: WHITE, border: BLACK }],
  // Listings
  [
    COLOR_TOPIC.SINGLE_FAMILY,
    { foreground: '#d95d58', background: WHITE, border: '#d95d58' }
  ],
  [
    COLOR_TOPIC.BUSINESS,
    { foreground: '#60686f', background: WHITE, border: '#60686f' }
  ],
  [
    COLOR_TOPIC.COMMERCIAL,
    { foreground: '#0270c9', background: WHITE, border: '#0270c9' }
  ],
  [
    COLOR_TOPIC.CONDO,
    { foreground: '#674bcd', background: WHITE, border: '#674bcd' }
  ],
  [
    COLOR_TOPIC.LAND,
    { foreground: '#49b26b', background: WHITE, border: '#49b26b' }
  ],
  [
    COLOR_TOPIC.MOBILE,
    { foreground: '#d958c2', background: WHITE, border: '#d958c2' }
  ],
  [
    COLOR_TOPIC.MULTIFAMILY,
    { foreground: '#3bb69a', background: WHITE, border: '#3bb69a' }
  ],
  [
    COLOR_TOPIC.RESIDENTIAL,
    { foreground: '#60686f', background: WHITE, border: '#60686f' }
  ],
  [
    COLOR_TOPIC.OTHER,
    { foreground: '#60686f', background: WHITE, border: '#60686f' }
  ],
  [
    COLOR_TOPIC.TOWNHOUSE,
    { foreground: '#cd7941', background: WHITE, border: '#cd7941' }
  ],
  [
    COLOR_TOPIC.L_ACTIVE,
    { foreground: '#082746', background: '#fff8e5', border: '#fbe199' }
  ],
  [
    COLOR_TOPIC.L_PENDING,
    { foreground: '#082746', background: '#ffeadb', border: '#ff7043' }
  ],
  [
    COLOR_TOPIC.L_SOLD,
    { foreground: '#082746', background: '#ffeded', border: '#f33b3b' }
  ]
]);

export function getClientStatusColor(
  status?: ClientStatusEnum | ClientListItemStatusEnum
): ColorCombination {
  switch (status) {
    case ClientStatusEnum.Active:
    case ClientListItemStatusEnum.Active:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.ACTIVE)!;
    case ClientStatusEnum.Engaged:
    case ClientListItemStatusEnum.Engaged:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.ENGAGED)!;
    case ClientStatusEnum.Marketing:
    case ClientListItemStatusEnum.Marketing:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.MARKETING)!;
    case ClientStatusEnum.New:
    case ClientListItemStatusEnum.New:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.NEW)!;
    case ClientStatusEnum.Pending:
    case ClientListItemStatusEnum.Pending:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.PENDING)!;
    case ClientStatusEnum.Sold:
    case ClientListItemStatusEnum.Sold:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.SOLD)!;
    case ClientStatusEnum.Cold:
    case ClientListItemStatusEnum.Cold:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.COLD)!;
    case ClientStatusEnum.None:
    case ClientListItemStatusEnum.None:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.NONE)!;
    case ClientStatusEnum.Archived:
    case ClientListItemStatusEnum.Archived:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.ARCHIVE)!;
    default:
      captureMessage(`Unexpected color for status ${status}`);
      return TRANSPARENT;
  }
}

export const getListingStatusColor = (
  status?: BasicListingStatusEnum | ListingStatusEnum | CMAListingStatusEnum
): ColorCombination => {
  switch (status) {
    case BasicListingStatusEnum.Active:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.L_ACTIVE)!;
    case BasicListingStatusEnum.OffMarket:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.PENDING)!;
    case BasicListingStatusEnum.Pending:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.L_PENDING)!;
    case BasicListingStatusEnum.Sold:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.L_SOLD)!;
    default:
      captureMessage(`Unexpected color for status ${status}`);
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.AGENT)!;
  }
};

export const getListingTypeColor = (
  status?: BasicListingTypeEnum | ListingTypeEnum | CMAListingTypeEnum
): ColorCombination => {
  switch (status) {
    case BasicListingTypeEnum.SingleFamily:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.SINGLE_FAMILY)!;
    case BasicListingTypeEnum.Condo:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.CONDO)!;
    case BasicListingTypeEnum.Townhouse:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.TOWNHOUSE)!;
    case BasicListingTypeEnum.BusinessOpportunity:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.BUSINESS)!;
    case BasicListingTypeEnum.Commercial:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.COMMERCIAL)!;
    case BasicListingTypeEnum.Land:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.LAND)!;
    case BasicListingTypeEnum.Mobile:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.MOBILE)!;
    case BasicListingTypeEnum.Multifamily:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.MULTIFAMILY)!;
    case BasicListingTypeEnum.ResidentialIncome:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.RESIDENTIAL)!;
    case BasicListingTypeEnum.Other:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.OTHER)!;
    default:
      captureMessage(`Unexpected color for status ${status}`);
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.AGENT)!;
  }
};

export const getClientRoleColor = (
  role: ClientListItemTypeEnum | ClientTypeEnum
): ColorCombination => {
  switch (role) {
    case ClientListItemTypeEnum.Seller:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.SELLER)!;
    case ClientListItemTypeEnum.Vendor:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.VENDOR)!;
    case ClientListItemTypeEnum.Renter:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.RENTER)!;
    case ClientListItemTypeEnum.Personal:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.PERSONAL)!;
    case ClientListItemTypeEnum.PastClient:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.PAST_CLIENT)!;
    case ClientListItemTypeEnum.Friend:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.FRIEND)!;
    case ClientListItemTypeEnum.Family:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.FAMILY)!;
    case ClientListItemTypeEnum.BuyerSeller:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.BUYER_SELLER)!;
    case ClientListItemTypeEnum.Buyer:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.BUYER)!;
    case ClientListItemTypeEnum.CrmAgent:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.CRM_AGENT)!;
    case ClientListItemTypeEnum.ProspectiveAgent:
      return TOPIC_HEX_MAP.get(COLOR_TOPIC.PROSPECTIVE_AGENT)!;
    default:
      return TRANSPARENT;
  }
};

export const getClientFunnelColor = (status: ClientStatusEnum) => {
  switch (status) {
    case ClientStatusEnum.New:
      return '#4ea5f2';
    case ClientStatusEnum.Marketing:
      return '#4ea5f2';
    case ClientStatusEnum.Engaged:
      return '#6dbc6d';
    case ClientStatusEnum.Active:
      return '#facb3e';
    case ClientStatusEnum.Pending:
      return '#ff7043';
    case ClientStatusEnum.Sold:
      return '#f9704a';
    default:
      return '#d3d7db';
  }
};

export const getTaskColor = (type: TaskTypeEnum | undefined) => {
  switch (type) {
    case TaskTypeEnum.MESSAGE:
      return '#98D5FD';
    case TaskTypeEnum.EMAIL:
      return '#f15953';
    case TaskTypeEnum.APPROVAL:
      return '#8774CC';
    case TaskTypeEnum.CALENDAR:
      return '#E7955D';
    case TaskTypeEnum.PHONE:
      return '#81CB96';
    case TaskTypeEnum.SOCIAL:
      return '#0270C9';
    case TaskTypeEnum.SYSTEM:
      return '#565656';
    default:
      return '#565656';
  }
};
