import {
  ClientTypeEnum,
  Client,
  ClientAssignment
} from '@elm-street-technology/crm-axios-client';
import { Tabs } from 'antd';
import React, { FC } from 'react';
import _ from 'lodash';
import { useUserAccount } from 'src/common/hooks';
import {
  ActivityTab,
  DocsTab,
  HistoryTab,
  NotesTab,
  ProfileTab,
  SellerActivityTab,
  TasksTab
} from './index';
import { ClientRecordTabKey } from '../ClientRecordDrawer.types';

export interface Props {
  client: Client;
  tabKey: ClientRecordTabKey;
  onUpdateSuccess: (client: Client) => void;
  onAssignmentSuccess: (clientAssignment: ClientAssignment) => void;
  onTabChange: (tabKey: ClientRecordTabKey) => void;
}

export const ClientRecordDrawerTabs: FC<Props> = ({
  client,
  tabKey,
  onUpdateSuccess,
  onAssignmentSuccess,
  onTabChange
}) => {
  const { user } = useUserAccount();
  const isCrmFreeUser = !!_.find(_.get(user, 'products', []), {
    shortName: 'crm-free'
  });

  return (
    <Tabs
      activeKey={tabKey}
      onChange={tabKey => onTabChange(tabKey as ClientRecordTabKey)}
      tabBarStyle={{ padding: 12 }}
    >
      <Tabs.TabPane tab="Activity" key="Activity">
        {client.type === ClientTypeEnum.Seller ? (
          <SellerActivityTab client={client} />
        ) : (
          <ActivityTab client={client} />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Profile" key="Profile">
        <ProfileTab
          client={client}
          onUpdateSuccess={onUpdateSuccess}
          onAssignmentSuccess={onAssignmentSuccess}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Notes" key="Notes">
        <NotesTab clientId={client.id} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Tasks" key="Tasks">
        <TasksTab clientId={client.id} />
      </Tabs.TabPane>
      {!isCrmFreeUser && (
        <Tabs.TabPane tab="Docs" key="Docs">
          <DocsTab clientId={client.id} />
        </Tabs.TabPane>
      )}
      <Tabs.TabPane tab="History" key="History">
        <HistoryTab clientId={client.id} />
      </Tabs.TabPane>
    </Tabs>
  );
};
