import {
  Notification,
  NotificationCategoryEnum
} from '@elm-street-technology/crm-axios-client';
import { capitalize } from 'lodash';

export const openNotifications = (notifications: Notification[]) =>
  notifications.filter(
    notification =>
      notification.category !== NotificationCategoryEnum.LeadEmailParsed
  );

export const openEmails = (notifications: Notification[]) =>
  notifications.filter(
    notification =>
      notification.category === NotificationCategoryEnum.LeadEmailParsed
  );

export const formatNotificationCategory = (category: string) =>
  capitalize(category.replace(/_/g, ' '));
