import React, { FC, useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import { tagApi } from 'src/common/services';
import { ClientFormSection } from './index';

interface Props {
  client?: Client;
}

export const TagsSection: FC<Props> = ({ client }) => {
  const [tagsOptions, setTagsOptions] = useState<string[]>([]);

  useEffect(() => {
    tagApi.listTags().then(({ data }) => setTagsOptions(data));
  }, [client]);

  return (
    <ClientFormSection title="Tags">
      <Form.Item
        name="tags"
        initialValue={client?.tags?.filter(tag => Boolean(tag))}
      >
        <Select
          size="large"
          mode="tags"
          notFoundContent={null}
          options={tagsOptions.map(tag => ({ value: tag, label: tag }))}
        />
      </Form.Item>
    </ClientFormSection>
  );
};
