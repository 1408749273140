import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useMemo,
  useLayoutEffect
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { TABLET } from 'src/common/constants';
import { useUserAccount } from 'src/common/hooks';
import { SubMenuProps } from 'antd';
import {
  ItemProps,
  isSubMenuProps,
  makeMenuData,
  makeMenuItems
} from './Nav.utils';
import { CRMMenu, CRMSider } from './Nav.styled';
import { initializeNavReducer } from './Nav.reducer';

export const Nav: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: TABLET });
  const navigate = useNavigate();

  const { user } = useUserAccount();
  const data = useMemo(() => makeMenuData(navigate, user.menu), [
    navigate,
    user
  ]);

  const initialOpenKeys = useMemo(() => {
    const subMenuKeys = data.filter(i => isSubMenuProps(i)).map(i => i.key);

    if (data.some(i => !i.href?.startsWith('/office'))) {
      return [...subMenuKeys, 'offices'];
    }

    return subMenuKeys;
  }, [data]);

  const [{ collapsed, collapsedWidth, openKeys }, dispatch] = useReducer(
    initializeNavReducer(initialOpenKeys),
    {
      collapsedWidth: isMobile ? '0' : '80',
      collapsed: isMobile,
      openKeys: isMobile ? undefined : initialOpenKeys
    }
  );

  useEffect(() => {
    dispatch({ type: isMobile ? 'MobileBreakpoint' : 'DesktopBreakpoint' });
  }, [isMobile]);

  const handleCollapse = useCallback(() => {
    dispatch({ type: isMobile ? 'MobileCollapse' : 'DesktopCollapse' });
    setTimeout(() => {
      setupNavEllipsis(data);
    }, 150);
  }, [isMobile]);

  useLayoutEffect(() => {
    setupNavEllipsis(data);
  }, []);

  return (
    <CRMSider
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      breakpoint="lg"
      width="220"
      collapsible
      zeroWidthTriggerStyle={{ backgroundColor: '#f15953' }}
      onCollapse={handleCollapse}
    >
      <CRMMenu mode="inline" {...(openKeys ? { openKeys } : undefined)}>
        <CRMMenu.Item key="1" className="menu-items" onClick={handleCollapse}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </CRMMenu.Item>
        {makeMenuItems(data, collapsed)}
      </CRMMenu>
    </CRMSider>
  );
};

const setupNavEllipsis = (data: SubMenuProps[] | ItemProps[]) => {
  const findElementWithLabel = (array, searchFor) => {
    for (const element of array) {
      if (element.label === searchFor) {
        return element;
      }

      if (element.items && element.items.length > 0) {
        const foundInChildren = findElementWithLabel(element.items, searchFor);

        if (foundInChildren) {
          return foundInChildren; // Found in the children, return it
        }
      }
    }

    return null;
  };

  const menuItems = document.querySelectorAll(
    '.ant-menu-title-content'
  ) as NodeListOf<HTMLElement>;
  const maxWidthForElement = 160;
  const maxWidthForSubheader = 180;

  menuItems.forEach(menuItem => {
    const elementWidth = menuItem.children[0]
      ? menuItem.children[0].getBoundingClientRect().width
      : 0;
    const maxWidth =
      menuItem.children[0] &&
      menuItem.children[0].className === 'submenu-header'
        ? maxWidthForSubheader
        : maxWidthForElement;
    const hasTooltip = elementWidth > maxWidth;

    if (hasTooltip) {
      const menuItemText = menuItem.children[0].innerHTML;

      const foundElement = findElementWithLabel(data, menuItemText);

      menuItem.style.overflow = 'hidden';
      menuItem.style.whiteSpace = 'nowrap';
      menuItem.style.textOverflow = 'ellipsis';
      menuItem.style.maxWidth = `${maxWidth}px`;

      if (foundElement) {
        foundElement.hasTooltip = true;
      }
    }
  });
};
