import * as React from 'react';

const SvgHelp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g clipPath="url(#clip0_8_23888)">
      <path
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M5.302 5.25a1.75 1.75 0 013.401.583c0 1.167-1.75 1.75-1.75 1.75M7 9.917h.006M12.833 7A5.833 5.833 0 111.167 7a5.833 5.833 0 0111.666 0z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_23888">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgHelp;
