import * as React from 'react';

export default function(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="17px" height="14px" viewBox="0 0 17 14" version="1.1">
      <defs>
        <circle id="path-1" cx="20" cy="20" r="20" />
        <filter
          x="-26.2%"
          y="-18.8%"
          width="152.5%"
          height="152.5%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="3"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="3"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.03 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Buttons-/email" transform="translate(-12.000000, -14.000000)">
          <g id="Icon/email">
            <g id="Oval">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
              <use fill="#2E7FC2" fillRule="evenodd" />
            </g>
            <g
              id="email-icon"
              transform="translate(12.000000, 14.000000)"
              fill="#FFFFFF"
            >
              <path d="M15.3,0 L1.7,0 C0.765,0 0.0085,0.751339286 0.0085,1.66964286 L0,11.6875 C0,12.6058036 0.765,13.3571429 1.7,13.3571429 L15.3,13.3571429 C16.235,13.3571429 17,12.6058036 17,11.6875 L17,1.66964286 C17,0.751339286 16.235,0 15.3,0 L15.3,0 Z M15.3,3.33928571 L8.5,7.51339286 L1.7,3.33928571 L1.7,1.66964286 L8.5,5.84375 L15.3,1.66964286 L15.3,3.33928571 L15.3,3.33928571 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
