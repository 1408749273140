import React from 'react';
import Icon from '@ant-design/icons';

const DarkFilterLargeSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#082746">
        <g>
          <g transform="translate(-332 -221) translate(332 221) translate(0 2.222)">
            <path
              strokeLinecap="square"
              d="M1.111 3.333L18.889 3.333M1.111 12.222L18.889 12.222"
            />
            <circle cx="14.444" cy="3.333" r="3.333" fill="#D8D8D8" />
            <circle cx="5.556" cy="12.222" r="3.333" fill="#FFF" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default props => <Icon component={DarkFilterLargeSvg} {...props} />;
