import {
  ExclamationCircleOutlined,
  EditFilled,
  DeleteFilled
} from '@ant-design/icons';
import React, { FC, useContext } from 'react';
import moment from 'moment';
import { Modal, List } from 'antd';
import { Doc } from '@elm-street-technology/crm-axios-client';
import { Download } from 'src/crm/assets/icons';
import { DocsDispatchContext } from '../DocsTab.context';
import { deleteDoc } from '../actions';

interface Props {
  doc: Doc;
}

export const DocListItem: FC<Props> = ({ doc }) => {
  const dispatch = useContext(DocsDispatchContext);

  const handleDeleteClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this doc?',
      icon: <ExclamationCircleOutlined />,
      cancelText: 'No',
      okButtonProps: {
        danger: true
      },
      okText: 'Yes - Delete',
      onOk: () => deleteDoc(dispatch, doc.clientId, doc.id)
    });
  };

  const handleEditClick = () => dispatch({ type: 'ShowEdit', payload: doc });

  return (
    <List.Item
      actions={[
        <EditFilled onClick={handleEditClick} />,
        <DeleteFilled onClick={handleDeleteClick} />
      ]}
    >
      <List.Item.Meta
        avatar={
          <a
            href={doc.signedUrl || undefined}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Download />
          </a>
        }
        title={
          <>
            <div>{doc.name}</div>
            <div>{doc.description}</div>
          </>
        }
        description={
          <div>
            Uploaded on{' '}
            {`${moment(doc.updatedDateIsoStr).format('MM/DD/YY')} ${moment(
              doc.updatedDateIsoStr
            ).format('h:mm:ss a')}`}
            <br />
            By {doc.updatedByDisplayName}
          </div>
        }
      />
    </List.Item>
  );
};
