import { useMemo, useState } from 'react';

export const useIsBuyerSeller = (initialClientType?) => {
  const [type, setClientType] = useState(initialClientType);

  return useMemo(
    () => ({
      isBuyer: type === 'buyer' || type === 'buyer/seller',
      isSeller: type === 'seller' || type === 'buyer/seller',
      setClientType
    }),
    [type]
  );
};
