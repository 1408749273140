import { useContext, useMemo, useCallback } from 'react';
import { UserRolesEnum } from '@elm-street-technology/crm-axios-client';
import { captureException } from '@sentry/react';
import { userApi } from 'src/common/services';
import { UserAccountContext, UserAccountDispatchContext } from '../contexts';

export const useUserAccount = () => {
  const user = useContext(UserAccountContext);
  const setUser = useContext(UserAccountDispatchContext);

  const roleFlags = useMemo(
    () =>
      user.roles.reduce(
        (acc, role) => {
          switch (role) {
            case UserRolesEnum.Broker:
              return { ...acc, isBroker: true };
            case UserRolesEnum.TeamAdmin:
              return { ...acc, isTeamAdmin: true };
            case UserRolesEnum.SuperAdmin:
              return { ...acc, isSuperAdmin: true };
            default:
              return acc;
          }
        },
        {
          isBroker: false,
          isTeamAdmin: false,
          isSuperAdmin: false
        }
      ),
    []
  );

  const refreshUser = useCallback(() => {
    userApi
      .getMe()
      .then(({ data }) => setUser(data))
      .catch(captureException);
  }, []);

  return { user, ...roleFlags, refreshUser };
};
