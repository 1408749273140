import { message } from 'antd';
import { docApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils';
import { DocsDispatch } from '../DocsTab.reducer';

export const listDocs = (dispatch: DocsDispatch, clientId: number) => {
  dispatch({ type: 'GetDocs' });

  docApi
    .listDocs({ clientId })
    .then(({ data }) => dispatch({ type: 'GetDocsSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'GetDocsFailure',
        payload: error
      });
      message.error(getFetchMessageText('client document').error);
    });
};
