export const CRM_HOST =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEVELOPMENT_HOST
    : window.location.origin;

export const MLS_HOST = process.env.REACT_APP_MLS_HOST;

export const SOCKET_IO_URL = `${CRM_HOST}/user`;

export const IDX_MIDDLEWARE_HOST = process.env.REACT_APP_IDX_MIDDLEWARE_HOST;

export const IDX_MIDDLEWARE_API_KEY =
  process.env.REACT_APP_IDX_MIDDLEWARE_API_KEY;

export const IDX_MIDDLEWARE_AUTH0_USER_ID =
  process.env.REACT_APP_IDX_MIDDLEWARE_AUTH0_USER_ID;

export const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const IDX_MIDDLEWARE_TEST_ACCOUNT_ID = '553';
