import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { organizationMembersApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const listOrgMemberContributors = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetOrgMembersPending' });

  organizationMembersApi
    .listOrganizationMembersContributors({ clientId })
    .then(({ data }) =>
      dispatch({
        type: 'GetOrgMembersSuccess',
        payload: data
      })
    )
    .catch(error => {
      message.error(getFetchMessageText('organization contributors').error);
      dispatch({ type: 'GetOrgMembersFailure' });
    });
};
