import { Reducer, Dispatch } from 'react';
import { combinedCommunicationApi } from 'src/common/services';
import { AxiosError } from 'axios';
import { ClientCommunicationType } from './CommunicationSection.types';

interface ListState<T> {
  data: Array<T>;
  loading: boolean;
  error: AxiosError | null;
}

type ListActions<T> =
  | { type: 'List' }
  | { type: 'ListSuccess'; payload: Array<T> }
  | { type: 'ListFailure'; payload: AxiosError };

type ClientCommunicationsState = ListState<ClientCommunicationType>;
type ClientCommunicationsActions = ListActions<ClientCommunicationType>;
type ClientCommunicationsDispatch = Dispatch<ClientCommunicationsActions>;

export const initialClientCommunicationsState: ClientCommunicationsState = {
  data: [],
  loading: false,
  error: null
};

export const clientCommunicationReducer: Reducer<
  ClientCommunicationsState,
  ClientCommunicationsActions
> = (state, action) => {
  switch (action.type) {
    case 'List':
      return {
        ...state,
        loading: true
      };
    case 'ListSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'ListFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const listClientCommunication = (
  dispatch: ClientCommunicationsDispatch,
  clientId: number
) => {
  dispatch({ type: 'List' });

  combinedCommunicationApi
    .listClientCommunication({ clientId })
    .then(({ data }) =>
      dispatch({
        type: 'ListSuccess',
        // To Do: Correct nested data typing on schema for CombinedCommunicationApi
        payload: (data as unknown) as ClientCommunicationType[]
      })
    )
    .catch(error =>
      dispatch({
        type: 'ListFailure',
        payload: error
      })
    );
};
