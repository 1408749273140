import * as React from 'react';

const CampaignIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <path
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M8.017 1.536l4.392 2.855c.155.1.232.15.289.218.05.06.087.129.11.203.025.084.025.176.025.361V9.45c0 .98 0 1.47-.19 1.845a1.75 1.75 0 01-.765.764c-.374.191-.865.191-1.845.191H3.967c-.98 0-1.47 0-1.845-.19a1.75 1.75 0 01-.765-.766c-.19-.374-.19-.864-.19-1.844V5.173c0-.185 0-.277.025-.361a.583.583 0 01.11-.203c.057-.067.134-.118.29-.218l4.39-2.855m2.035 0c-.368-.24-.552-.359-.75-.405a1.167 1.167 0 00-.534 0c-.198.046-.382.166-.75.405m2.034 0l3.612 2.348c.402.26.602.391.672.557.06.144.06.307 0 .452-.07.165-.27.295-.672.556L8.017 7.797c-.368.24-.552.36-.75.406a1.167 1.167 0 01-.534 0c-.198-.047-.382-.167-.75-.406L2.37 5.45c-.402-.26-.602-.391-.672-.556a.583.583 0 010-.452c.07-.166.27-.296.672-.557l3.612-2.348m6.559 9.547l-3.875-3.5m-3.334 0l-3.875 3.5"
      />
    </g>
  </svg>
);

export default CampaignIcon;
