import { ClientPermission } from '@elm-street-technology/crm-axios-client';
import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export type ClientPermissionActions =
  | { type: 'GetPermissionsPending' }
  | { type: 'GetPermissionsFailure' }
  | { type: 'GetPermissionsSuccess'; payload: ClientPermission[] };

export interface ClientPermissionState {
  data: ClientPermission[];
  loading: boolean;
}
export const initialClientPermissionState: ClientPermissionState = {
  data: [],
  loading: false
};
export const clientPermissionReducer: Reducer<
  ClientPermissionState,
  ClientRecordDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'GetPermissionsPending':
      return {
        ...state,
        loading: true
      };
    case 'GetPermissionsSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetPermissionsFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
