import React, { FC } from 'react';
import CardViewActive from 'src/crm/assets/CardViewActive';
import CardViewInactive from 'src/crm/assets/CardViewInactive';
import ListViewActive from 'src/crm/assets/ListViewActive';
import ListViewInactive from 'src/crm/assets/ListViewInactive';
import styled from 'styled-components';

const ViewsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 3px 0;
  padding: 0 8px;
  > span {
    margin-right: 12px;
  }
`;

interface Props {
  setListView: (type: boolean) => void;
  isListView: boolean;
}

export const ViewSelection: FC<Props> = ({ setListView, isListView }) => (
  <ViewsWrapper>
    <span onClick={() => setListView(true)}>
      {isListView ? <ListViewActive /> : <ListViewInactive />}
    </span>
    <span onClick={() => setListView(false)}>
      {!isListView ? <CardViewActive /> : <CardViewInactive />}
    </span>
  </ViewsWrapper>
);
