import { Reducer, Dispatch } from 'react';
import { ContactPipelineChartData } from '@elm-street-technology/crm-axios-client';
import { contactPipelineApi } from 'src/common/services';
import { AxiosError } from 'axios';

interface GetState<T> {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
}

type GetActions<T> =
  | { type: 'Get' }
  | { type: 'GetSuccess'; payload: T }
  | { type: 'GetFailure'; payload: AxiosError };

type ContactPipelineChartDataState = GetState<ContactPipelineChartData>;
type ContactPipelineChartDataActions = GetActions<ContactPipelineChartData>;
type ContactPipelineChartDataDispatch = Dispatch<
  ContactPipelineChartDataActions
>;

export const initialContactPipelineChartDataState: ContactPipelineChartDataState = {
  data: null,
  loading: false,
  error: null
};

export const contactPipelineChartDataReducer: Reducer<
  ContactPipelineChartDataState,
  ContactPipelineChartDataActions
> = (state, action) => {
  switch (action.type) {
    case 'Get':
      return {
        ...state,
        loading: true
      };
    case 'GetSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const getContactPipelineChartData = (
  dispatch: ContactPipelineChartDataDispatch,
  userId: number,
  timeframe?: string
) => {
  dispatch({ type: 'Get' });

  contactPipelineApi
    .getContactPipelineChartData({ userId, timeframe })
    .then(({ data }) => dispatch({ type: 'GetSuccess', payload: data }))
    .catch(error =>
      dispatch({
        type: 'GetFailure',
        payload: error
      })
    );
};
