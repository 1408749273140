export const RESPONSIVE_BAR_THEME = {
  axis: {
    legend: {
      text: {
        fontSize: '14px',
        fill: 'rgb(204,204,204)'
      }
    },
    ticks: {
      line: {
        strokeWidth: 0
      },
      text: {
        fontSize: '12px',
        fill: '#78909C'
      }
    }
  },
  tooltip: {
    container: {
      background: '#333'
    }
  }
};
