import { message } from 'antd';
import { clientTaskApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils';
import { TasksDispatch } from '../Tasks.reducer';

export const listClientTasks = (dispatch: TasksDispatch, clientId: number) => {
  dispatch({ type: 'GetList' });

  clientTaskApi
    .listClientTasks({ clientId })
    .then(({ data }) => dispatch({ type: 'GetListSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'GetListFailure',
        payload: error
      });
      message.error(getFetchMessageText('client tasks').error);
    });
};
