import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { clientApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const getClient = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetClientPending' });

  clientApi
    .getClient({ clientId })
    .then(({ data }) => dispatch({ type: 'GetClientSuccess', payload: data }))
    .catch(error => {
      message.error(getFetchMessageText('client').error);
      dispatch({ type: 'GetClientFailure', payload: error });
    });
};
