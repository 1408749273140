import React, { FC } from 'react';
import {
  Client,
  ClientListItem,
  ClientCommunicationCall
} from '@elm-street-technology/crm-axios-client';
import { Steps } from 'antd';
import { useUserAccount } from 'src/common/hooks';

interface CallProgressStepsProps {
  steps: ClientCommunicationCall[];
  client: Client | ClientListItem;
}

export const CallProgressSteps: FC<CallProgressStepsProps> = ({
  steps,
  client
}) => {
  const { user } = useUserAccount();

  return (
    <Steps progressDot direction="vertical">
      {steps.map((communicationCallStatus, index) => {
        if (!communicationCallStatus) {
          return null;
        }
        const { outcome } = communicationCallStatus;
        let title: string | null = null;
        // let description: string | null = null;
        let status: 'process' | 'wait' | 'finish' | 'error' | undefined;
        switch (outcome) {
          case 'queued':
            title = `Calling you at ${user.phone}`;
            // description = `Calling you at ${user.phone}`;
            status = 'wait';
            break;
          case 'initiated':
            title = 'You answered';
            // description = 'You answered';
            status = 'finish';
            break;
          case 'ringing':
            title = `Calling ${client.displayName}`;
            // description = `Calling ${client.displayName}`;
            status = 'finish';
            break;
          case 'in-progress':
            title = 'Connected';
            // description = 'Connected';
            status = 'finish';
            break;
          case 'completed':
            title = 'Call Ended';
            // description = 'Call Ended';
            status = 'finish';
            break;
          case 'busy':
            title = 'Busy';
            // description = 'Busy';
            status = 'error';
            break;
          case 'no-answer':
            title = 'No Answer';
            // description = 'No Answer';
            status = 'error';
            break;
          default:
            title = 'Call Failed';
            // description = 'Call Failed';
            status = 'error';
        }
        status = steps.length === index + 1 ? 'process' : undefined;

        return (
          <Steps.Step
            key={`${communicationCallStatus.id}-${communicationCallStatus.outcome}`}
            title={title}
            // description={description}
            status={status}
          />
        );
      })}
    </Steps>
  );
};
