import React, { SVGProps, FC } from 'react';

export const ArchiveGraphic: FC<SVGProps<SVGSVGElement>> = () => (
  <svg width="57px" height="60px" viewBox="0 0 57 60" version="1.1">
    <title>Graphics/ Archive</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Client-Profile-Drawer"
        transform="translate(-454.000000, -3620.000000)"
      >
        <g
          id="Graphics/-Archive"
          transform="translate(454.000000, 3620.000000)"
        >
          <g id="Archive-Graphic">
            <path
              d="M8,19 L8,35 L6.69,35 C5.71466781,35.0037257 4.88335979,35.7084386 4.72,36.67 L1,59 L1,21 C1.0032948,19.8967981 1.89679813,19.0032948 3,19 L8,19 Z"
              id="Path"
              fill="#DEDEDE"
              fillRule="nonzero"
            />
            <path
              d="M48,11 L40,11 C38.8967981,10.9967052 38.0032948,10.1032019 38,9 L38,1 L48,11 Z"
              id="Path"
              fill="#DEDEDE"
              fillRule="nonzero"
            />
            <path
              d="M54,20.996257 L54,35 C53.8810845,34.9797161 53.7606389,34.9696977 53.64,34.9700561 L48,34.9700561 L48,19 L52,19 C53.1032019,19.0032886 53.9967052,19.8951198 54,20.996257 L54,20.996257 Z"
              id="Path"
              fill="#DEDEDE"
              fillRule="nonzero"
            />
            <path
              d="M55.9715844,37.3300084 L52.6195358,57.3300006 C52.4550943,58.2915617 51.6182821,58.9962743 50.636492,59 L1,59 L4.74463091,36.6700087 C4.90907234,35.7084476 5.74588462,35.003735 6.7276747,35 L53.9885406,35 C54.1099783,34.9996502 54.2312214,35.0096873 54.3509243,35.0300093 C55.4330563,35.2278969 56.1548128,36.2521951 55.9715844,37.3300084 Z"
              id="Path"
              fill="#DCDCDC"
              fillRule="nonzero"
            />
            <g id="Group">
              <polygon
                id="Path"
                fill="#DEDEDE"
                fillRule="nonzero"
                points="16 6 26.0440529 6 26.0440529 8 16 8"
              />
              <polygon
                id="Path"
                fill="#DEDEDE"
                fillRule="nonzero"
                points="31.1894273 17 43.1894273 17 43.1894273 19 31.1894273 19"
              />
              <polygon
                id="Path"
                fill="#DEDEDE"
                fillRule="nonzero"
                points="31.1894273 23 43.1894273 23 43.1894273 25 31.1894273 25"
              />
              <polygon
                id="Path"
                fill="#DEDEDE"
                fillRule="nonzero"
                points="31.1365639 29 43.1894273 29 43.1894273 31 31.1365639 31"
              />
              <path
                d="M56.1763877,35.06 C55.9167191,34.7600094 55.5990408,34.5151436 55.2422907,34.34 L55.2422907,21 C55.2373268,19.3451972 53.8911676,18.0049422 52.2290749,18 L49.215859,18 L49.215859,11 C49.213876,10.7348879 49.1095887,10.4806836 48.9245815,10.29 L38.8805286,0.29 C38.689005,0.10580423 38.4336809,0.00197428668 38.1674009,7.99360578e-15 L8.03524229,7.99360578e-15 C7.48052457,7.99360578e-15 7.030837,0.44771525 7.030837,1 L7.030837,18 L3.01321586,18 C1.35112318,18.0049422 0.00496397348,19.3451972 -7.58277435e-15,21 L-7.58277435e-15,59 C-7.58277435e-15,59.5522847 0.449687564,60.0000138 1.00440529,60.0000138 L50.53163,60.0000138 C52.0055388,60.0044356 53.2630028,58.9392181 53.4946256,57.49 L56.8493392,37.49 C56.9916398,36.6210542 56.7458607,35.7335562 56.1763877,35.06 Z M49.215859,20 L52.2290749,20 C52.7837926,20 53.2334802,20.4477153 53.2334802,21 L53.2334802,34 L49.215859,34 L49.215859,20 Z M39.1718062,3.41 L45.790837,10 L40.1762115,10 C39.6214937,10 39.1718062,9.55228475 39.1718062,9 L39.1718062,3.41 Z M9.03964758,2 L37.1629956,2 L37.1629956,9 C37.1679596,10.6548028 38.5141188,11.9950578 40.1762115,12 L47.2070485,12 L47.2070485,34 L9.03964758,34 L9.03964758,2 Z M2.00881057,21 C2.00881057,20.4477153 2.45849814,20 3.01321586,20 L7.030837,20 L7.030837,34 L6.71947137,34 C5.24556254,33.9955644 3.98809848,35.0607819 3.75647577,36.51 L2.00881057,46.92 L2.00881057,21 Z M51.5159471,57.16 C51.4418706,57.643954 51.0233266,58.0011325 50.53163,57.9999958 L2.18960352,57.9999958 L5.73515419,36.84 C5.80923071,36.356046 6.22777477,35.9988675 6.71947137,35.9999958 L53.8762996,35.9999958 C54.1712701,35.9991531 54.4517035,36.1274417 54.6431902,36.350826 C54.834677,36.5742102 54.9178575,36.8701056 54.8706608,37.16 L51.5159471,57.16 Z"
                id="Shape"
                fill="#7C838C"
                fillRule="nonzero"
              />
              <g
                id="Icons/Contacts-Thin"
                transform="translate(11.000000, 11.000000)"
                stroke="#7C838C"
              >
                <g id="Contacts-Thin" transform="translate(0.000000, 0.000000)">
                  <g id="Group-2">
                    <path
                      d="M9.95592338,4.77372752 C9.4241965,4.77372752 8.93116171,4.94947073 8.54985297,5.24672459 C8.17564824,5.53844043 7.90914163,5.94762766 7.82458968,6.42104368 C7.74204701,6.88503546 7.84683351,7.34233256 8.07779134,7.7236359 C8.31834706,8.12078502 8.69573012,8.43653017 9.14561755,8.59278996 L10.5394058,9.07689567 L9.06393984,9.07895018 C8.1997213,9.08015356 7.41648184,9.40029137 6.84932378,9.92003878 C6.29875688,10.4245819 5.95227719,11.1178744 5.94006893,11.8864215 L6.3524573,13.3108386 L13.9721013,12.8965337 L13.9721013,11.9207655 C13.9695994,11.1332823 13.6158105,10.4223488 13.0359687,9.90981318 C12.4525148,9.39408457 11.6416658,9.07895043 10.6504451,8.59009918 C11.1504147,8.43556742 11.5540333,8.12734424 11.8102306,7.7366054 C12.0616839,7.35310188 12.170298,6.89020264 12.0863521,6.42018004 C12.0021862,5.94706461 11.7358999,5.53816301 11.3618657,5.24660498 C10.9806633,4.94945937 10.4876376,4.77372752 9.95592338,4.77372752 Z"
                      id="Path"
                      strokeWidth="0.5"
                    />
                    <path
                      d="M2.1468835,7.48727022 L2.1468835,11.5109929 L2.59532525,11.5109929 L2.1468835,7.48727022 Z"
                      id="Path"
                      strokeWidth="0.5"
                    />
                    <path
                      d="M2.90481905,0.605394057 C2.81780541,0.64865583 2.73854401,0.704109523 2.66962755,0.769631971 C2.50258535,0.928447628 2.3968835,1.14721249 2.3968835,1.39045797 L2.44682125,3.43818266 L2.90481905,0.605394057 Z M13.9233213,18.5 L2.55581648,18.0995511 C2.58939259,18.1465798 2.6275666,18.1903734 2.66963017,18.2303657 C2.84618036,18.3982223 3.09062223,18.5 3.3593662,18.5 L13.9233213,18.5 Z"
                      id="Path"
                    />
                    <path d="" id="Path" strokeWidth="0.5" />
                    <path d="" id="Path" strokeWidth="0.5" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
