import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { affiliateApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const getClientAffiliateData = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetAffiliateDataPending' });

  affiliateApi
    .getClientAffiliateData({ clientId })
    .then(({ data }) =>
      dispatch({ type: 'GetAffiliateDataSuccess', payload: data })
    )
    .catch(error => {
      message.error(getFetchMessageText('affiliate data').error);
      dispatch({ type: 'GetAffiliateDataFailure' });
    });
};
