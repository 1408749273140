import React, { useEffect, FC, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client } from '@elm-street-technology/crm-axios-client';
import { Button, Row, PageHeader, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { navigateToCreateClientSavedSearch } from 'src/common/utils';
import { SavedSearchCard } from './SavedSearchCard';
import {
  clientSavedSearchesReducer,
  initialClientSavedSearchesState
} from './SavedSearchesSection.reducer';
import {
  updateClientSavedSearchMetadata,
  listClientSavedSearches,
  deleteClientSavedSearch
} from './actions';
import { ClientRecordSectionTitle } from '../../../index';

interface Props {
  client: Client;
  canManage: boolean;
}

export const SavedSearchesSection: FC<Props> = ({ client, canManage }) => {
  const navigate = useNavigate();

  const [{ updating, savedSearches }, dispatch] = useReducer(
    clientSavedSearchesReducer,
    initialClientSavedSearchesState
  );

  useEffect(() => {
    listClientSavedSearches(dispatch, client.id);
  }, [client.id]);

  const handleAddSearchClick = () =>
    navigateToCreateClientSavedSearch(navigate, client.id);

  const savedSearchCards = savedSearches.map(savedSearch => {
    const handleDelete = () =>
      deleteClientSavedSearch(dispatch, client.id, savedSearch.id);

    const handleMorningReportsToggle = () =>
      updateClientSavedSearchMetadata(dispatch, client.id, savedSearch.id, {
        shouldReceiveNotifications: !savedSearch.shouldReceiveNotifications
      });

    const handleRealTimeReportsToggle = () =>
      updateClientSavedSearchMetadata(dispatch, client.id, savedSearch.id, {
        enableRealtimeNotifications: !savedSearch?.enableRealtimeNotifications
      });

    return (
      <SavedSearchCard
        key={savedSearch.id}
        clientId={client.id}
        savedSearch={savedSearch}
        updating={updating}
        onDelete={handleDelete}
        onMorningReportsToggle={handleMorningReportsToggle}
        onRealTimeReportsToggle={handleRealTimeReportsToggle}
      />
    );
  });

  return (
    <PageHeader
      title={
        <ClientRecordSectionTitle>Saved Searches</ClientRecordSectionTitle>
      }
      footer={<Divider />}
      extra={
        <Button
          disabled={!canManage}
          size="large"
          icon={<PlusOutlined />}
          onClick={handleAddSearchClick}
        >
          Add Search
        </Button>
      }
    >
      <Row justify="space-around">{savedSearchCards}</Row>
    </PageHeader>
  );
};
