import { message } from 'antd';
import {
  CMAListing,
  CmaApiListCMAComparablesRequest
} from '@elm-street-technology/crm-axios-client';
import { Reducer, Dispatch } from 'react';
import { getFetchMessageText } from 'src/common/utils/textUtils';
import { clientCmaApi } from 'src/common/services';

interface CmaListingsState {
  loading: boolean;
  data: CMAListing[];
}

type CmaListingsActions =
  | {
      type: 'GetCmaListingsPending';
    }
  | {
      type: 'GetCmaListingsSuccess';
      payload: CMAListing[];
    }
  | {
      type: 'GetCmaListingsFailure';
    };

export const initialCmaListingsState: CmaListingsState = {
  loading: false,
  data: []
};

export const cmaListingsReducer: Reducer<
  CmaListingsState,
  CmaListingsActions
> = (state, action) => {
  switch (action.type) {
    case 'GetCmaListingsPending':
      return { ...state, loading: true };
    case 'GetCmaListingsSuccess':
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case 'GetCmaListingsFailure':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const listCMAComparables = (
  dispatch: Dispatch<CmaListingsActions>,
  requestParameters: CmaApiListCMAComparablesRequest
) => {
  dispatch({ type: 'GetCmaListingsPending' });

  clientCmaApi
    .listCMAComparables(requestParameters)
    .then(({ data }) =>
      dispatch({ type: 'GetCmaListingsSuccess', payload: data })
    )
    .catch(() => {
      message.error(
        getFetchMessageText('Seller Lead Comparable Listings').error
      );

      dispatch({ type: 'GetCmaListingsFailure' });
    });
};
