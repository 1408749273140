import React, { FC, ReactNode, CSSProperties, MouseEventHandler } from 'react';
import { Grid } from 'antd';

export const mobileLabelStyle: CSSProperties = {
  height: 26,
  width: 80,
  marginTop: 10,
  color: '#082746',
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 'normal',
  whiteSpace: 'pre-line'
};

interface Props {
  label: string;
  icon: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ResponsiveTab: FC<Props> = ({ label, icon, onClick }) => {
  const { xs } = Grid.useBreakpoint();

  return xs ? (
    <div style={{ margin: 0, textAlign: 'center' }} onClick={onClick}>
      {icon}
      <div style={mobileLabelStyle}>{label}</div>
    </div>
  ) : (
    <>{label}</>
  );
};
