import { Reducer, Dispatch } from 'react';
import { LeadClaimChartData } from '@elm-street-technology/crm-axios-client';
import { AxiosError } from 'axios';
import { leadClaimTimeApi } from 'src/common/services';

interface GetState<T> {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
}

type GetActions<T> =
  | { type: 'Get' }
  | { type: 'GetSuccess'; payload: T }
  | { type: 'GetFailure'; payload: AxiosError };

type LeadClaimChartDataState = GetState<LeadClaimChartData>;
type LeadClaimChartDataActions = GetActions<LeadClaimChartData>;
type LeadClaimChartDataDispatch = Dispatch<LeadClaimChartDataActions>;

export const initialLeadClaimChartDataState: LeadClaimChartDataState = {
  data: null,
  loading: false,
  error: null
};

export const leadClaimChartDataReducer: Reducer<
  LeadClaimChartDataState,
  LeadClaimChartDataActions
> = (state, action) => {
  switch (action.type) {
    case 'Get':
      return {
        ...state,
        loading: true
      };
    case 'GetSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const getLeadClaimChartData = (
  dispatch: LeadClaimChartDataDispatch,
  userId: number,
  timeframe?: string
) => {
  dispatch({ type: 'Get' });

  leadClaimTimeApi
    .getLeadClaimChartData({ userId, timeframe })
    .then(({ data }) => dispatch({ type: 'GetSuccess', payload: data }))
    .catch(error =>
      dispatch({
        type: 'GetFailure',
        payload: error
      })
    );
};
