import * as React from 'react';

function SvgLand(props) {
  return (
    <svg width="76px" height="21px" viewBox="0 0 76 21" version="1.1">
      <title>Land</title>
      <g
        id="Component-Assets"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ASSETS-1" transform="translate(-226.000000, -693.000000)">
          <g
            id="Small-Client-Type-Buyer-/-Seller"
            transform="translate(226.000000, 693.000000)"
          >
            <rect
              id="Rectangle"
              stroke="#49B26B"
              fill="#FFFFFF"
              x="0.5"
              y="0.5"
              width="75"
              height="20"
              rx="10"
            />
            <text
              id="Land"
              fontFamily="Roboto-Regular, Roboto"
              fontSize="11"
              fontWeight="normal"
              fill="#49B26B"
            >
              <tspan x="26.4123535" y="10">
                Land
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgLand;
