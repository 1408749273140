import * as React from 'react';

export default function(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1">
      <defs>
        <circle id="path-1" cx="20" cy="20" r="20" />
        <filter
          x="-26.2%"
          y="-18.8%"
          width="152.5%"
          height="152.5%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="3"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="3"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.03 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Buttons-/message" transform="translate(-12.000000, -12.000000)">
          <g id="Icon/message">
            <g id="Oval">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
              <use fill="#2E7FC2" fillRule="evenodd" />
            </g>
            <path
              d="M20.6666667,26.9380178 C25.4531345,26.9380178 29.3333333,23.743267 29.3333333,19.8023422 C29.3333333,15.8614174 25.4531345,12.6666667 20.6666667,12.6666667 C15.8801988,12.6666667 12,15.8614174 12,19.8023422 C12,21.6020345 12.8091968,23.2461157 14.1443658,24.5013937 L14.1443658,28.6666667 L18.2284376,26.6517444 C19.0018603,26.8380774 19.8200882,26.9380178 20.6666667,26.9380178 Z"
              id="Icon"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
