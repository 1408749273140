import { message } from 'antd';
import { clientTaskApi } from 'src/common/services';
import { getDeleteMessageText } from 'src/common/utils';
import { TasksDispatch } from '../Tasks.reducer';

export const deleteClientTask = (
  dispatch: TasksDispatch,
  clientId: number,
  id: number
) => {
  dispatch({ type: 'Delete' });

  clientTaskApi
    .deleteClientTask({ clientId, id })
    .then(() => {
      dispatch({ type: 'DeleteSuccess', payload: id });
      message.success(getDeleteMessageText('client task').success);
    })
    .catch(error => {
      dispatch({
        type: 'DeleteFailure',
        payload: error
      });
      message.error(getDeleteMessageText('client task').error);
    });
};
