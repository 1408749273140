import React, { FC } from 'react';
import { ContributorsGraphic } from 'src/crm/assets/icons';

export const ContributorsEmptyPlaceholder: FC = () => (
  <div style={{ textAlign: 'center', margin: '1em 0' }}>
    <ContributorsGraphic />
    <div style={{ fontSize: 16, color: '#082746' }}>
      Add new contributors to help you manage, interact and work with this lead
    </div>
  </div>
);
