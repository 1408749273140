import { MutableRefObject, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';

export const useCancelableAction = (
  action: (...args) => void,
  ref: MutableRefObject<CancelTokenSource>
) =>
  useCallback((...args) => {
    ref.current.cancel();
    ref.current = axios.CancelToken.source();

    action(...args, ref.current.token);
  }, []);
