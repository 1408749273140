import * as React from 'react';

function SvgDesktop7(props) {
  return (
    <svg width="34px" height="30px" viewBox="0 0 34 30" version="1.1">
      <title>Multi-family</title>
      <g
        id="Property-Type/Multi-family-Icon/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Group-5"
          transform="translate(1.000000, 1.000000)"
          stroke="#082746"
        >
          <line
            x1="12.4861111"
            y1="14.3055556"
            x2="12.4861111"
            y2="11.0833333"
            id="Line-3"
          />
          <polygon
            id="Line-3"
            points="11.0747906 28 11.0747906 23.1666667 7.85256834 23.1666667 7.85256834 28"
          />
          <line
            x1="6.04166667"
            y1="11.0833333"
            x2="6.04166667"
            y2="14.3055556"
            id="Line-3"
          />
          <line
            x1="25.375"
            y1="14.3055556"
            x2="25.375"
            y2="11.0833333"
            id="Line-3"
          />
          <polygon
            id="Line-3"
            points="23.9636795 28 23.9636795 23.1666667 20.7414572 23.1666667 20.7414572 28"
          />
          <line
            x1="18.9305556"
            y1="11.0833333"
            x2="18.9305556"
            y2="14.3055556"
            id="Line-3"
          />
          <path
            d="M0,2 L0,26 C1.3527075e-16,27.1045695 0.8954305,28 2,28 L30.2222222,28 C31.3267917,28 32.2222222,27.1045695 32.2222222,26 L32.2222222,2 C32.2222222,0.8954305 31.3267917,-2.02906125e-16 30.2222222,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDesktop7;
