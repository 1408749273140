import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path
            fill="url(#paint0_linear_312_33096)"
            d="M17.5 11l-.785-1.569c-.265-.53-.398-.796-.575-1.026a3 3 0 00-.545-.545c-.23-.177-.495-.31-1.026-.576L13 6.5l1.569-.784c.53-.266.796-.399 1.026-.576a3 3 0 00.545-.545c.177-.23.31-.495.575-1.026L17.5 2l.785 1.569c.265.53.398.796.575 1.026a3 3 0 00.545.545c.23.177.495.31 1.026.576L22 6.5l-1.569.784c-.53.266-.796.399-1.026.576a3 3 0 00-.545.545c-.177.23-.31.495-.575 1.026L17.5 11z"
          />
          <path
            fill="url(#paint1_linear_312_33096)"
            d="M9 22l-1.179-3.064c-.282-.734-.423-1.1-.642-1.409a3 3 0 00-.706-.706c-.309-.22-.675-.36-1.409-.642L2 15l3.064-1.179c.734-.282 1.1-.423 1.409-.642a3 3 0 00.706-.706c.22-.309.36-.675.642-1.409L9 8l1.179 3.064c.282.734.423 1.1.642 1.409a3 3 0 00.706.706c.309.22.675.36 1.409.642L16 15l-3.064 1.179c-.734.282-1.1.423-1.409.642a3 3 0 00-.706.706c-.22.309-.36.675-.642 1.409L9 22z"
          />
          <path
            stroke="url(#paint2_linear_312_33096)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M17.5 11l-.785-1.569c-.265-.53-.398-.796-.575-1.026a3 3 0 00-.545-.545c-.23-.177-.495-.31-1.026-.576L13 6.5l1.569-.784c.53-.266.796-.399 1.026-.576a3 3 0 00.545-.545c.177-.23.31-.495.575-1.026L17.5 2l.785 1.569c.265.53.398.796.575 1.026a3 3 0 00.545.545c.23.177.495.31 1.026.576L22 6.5l-1.569.784c-.53.266-.796.399-1.026.576a3 3 0 00-.545.545c-.177.23-.31.495-.575 1.026L17.5 11z"
          />
          <path
            stroke="url(#paint3_linear_312_33096)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.3"
            d="M9 22l-1.179-3.064c-.282-.734-.423-1.1-.642-1.409a3 3 0 00-.706-.706c-.309-.22-.675-.36-1.409-.642L2 15l3.064-1.179c.734-.282 1.1-.423 1.409-.642a3 3 0 00.706-.706c.22-.309.36-.675.642-1.409L9 8l1.179 3.064c.282.734.423 1.1.642 1.409a3 3 0 00.706.706c.309.22.675.36 1.409.642L16 15l-3.064 1.179c-.734.282-1.1.423-1.409.642a3 3 0 00-.706.706c-.22.309-.36.675-.642 1.409L9 22z"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_312_33096"
          x1="14.5"
          x2="15"
          y1="1.5"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1890FF" />
          <stop offset="1" stopColor="#13C2C2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_312_33096"
          x1="14.5"
          x2="15"
          y1="1.5"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1890FF" />
          <stop offset="1" stopColor="#13C2C2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_312_33096"
          x1="15"
          x2="15.5"
          y1="2"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1890FF" />
          <stop offset="1" stopColor="#13C2C2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_312_33096"
          x1="15"
          x2="15.5"
          y1="2"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1890FF" />
          <stop offset="1" stopColor="#13C2C2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
