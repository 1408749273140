import { PageHeader, Card } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Client } from '@elm-street-technology/crm-axios-client';
import { ClientRecordSectionTitle, RefList } from '../../index';

const Container = styled.div`
  h2 {
    font-family: Roboto;
    font-size: 21px;
    color: #082746;
  }

  p {
    font-family: Roboto;
    font-size: 16px;
    color: #082746;
  }

  .stats-container {
    display: flex;
    justify-content: space-evenly;

    > div {
      height: 130px;
      width: 176px;
      background-color: #f6f7f8;
      border-radius: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

interface Props {
  client: Client;
  handleClick: (id: keyof RefList) => void;
  favorited: number;
  viewed: number;
}

export const ActivityOverviewSection: FC<Props> = ({
  client,
  handleClick,
  favorited,
  viewed
}) => {
  const { activity } = client;

  return (
    <PageHeader
      title={
        <ClientRecordSectionTitle>Activity Overview</ClientRecordSectionTitle>
      }
    >
      <Container>
        <div className="stats-container">
          <Card
            bordered={false}
            hoverable
            onClick={() => {
              handleClick('SEARCHED');
            }}
          >
            <h2>{activity?.numSavedSearches}</h2>
            <p>Saved Searches</p>
          </Card>
          <Card
            bordered={false}
            hoverable
            onClick={() => {
              handleClick('FAVORITED');
            }}
          >
            <h2>{favorited}</h2>
            <p>Favorited Listings</p>
          </Card>
          <Card
            bordered={false}
            hoverable
            onClick={() => {
              handleClick('VIEWED');
            }}
          >
            <h2>{viewed}</h2>
            <p>Viewed Listings</p>
          </Card>
        </div>
      </Container>
    </PageHeader>
  );
};
