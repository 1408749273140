import {
  Client,
  ClientAssignment
} from '@elm-street-technology/crm-axios-client';
import { Drawer, Grid, Spin } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React, { FC, useContext, useMemo } from 'react';
import { LoadingIndicator } from 'src/crm/components/LoadingIndicator';
import {
  ClientRecordHeader,
  ClientRecordNestedDrawers,
  ClientRecordDrawerTabs
} from './index';
import {
  ClientRecordDrawerStateContext,
  ClientRecordDrawerDispatchContext
} from '../ClientRecordDrawer.context';

export interface Props {
  clientId: number;
  onClose: DrawerProps['onClose'];
  onUpdateSuccess: (client: Client) => void;
  onAssignmentSuccess: (clientAssignment: ClientAssignment) => void;
}

export const ClientRecordDrawerTemplate: FC<Props> = ({
  onClose,
  onUpdateSuccess,
  onAssignmentSuccess
}) => {
  const { md } = Grid.useBreakpoint();

  const dispatch = useContext(ClientRecordDrawerDispatchContext);

  const {
    client: { data: client },
    tabKey
  } = useContext(ClientRecordDrawerStateContext);

  const handleTabChange = tabKey =>
    dispatch({
      type: 'ChangeTab',
      payload: tabKey
    });

  const content = useMemo(() => {
    if (!client) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin indicator={<LoadingIndicator />} size="large" />
        </div>
      );
    }

    return (
      <>
        {/* To Do: Move ClientRecordHeader into title prop of Drawer component after refactoring */}
        <ClientRecordHeader onUpdateSuccess={onUpdateSuccess} />
        <ClientRecordDrawerTabs
          client={client}
          tabKey={tabKey}
          onTabChange={handleTabChange}
          onUpdateSuccess={onUpdateSuccess}
          onAssignmentSuccess={onAssignmentSuccess}
        />
      </>
    );
  }, [client, tabKey]);

  return (
    <Drawer
      width={md ? 768 : '100vw'}
      visible
      headerStyle={{ color: '#082746' }}
      bodyStyle={{ background: '#fff', color: '#082746', padding: 0 }}
      onClose={onClose}
      mask={false}
      destroyOnClose
    >
      {content}
      <ClientRecordNestedDrawers onUpdateSuccess={onUpdateSuccess} />
    </Drawer>
  );
};
