import { Dispatch, Reducer } from 'react';
import { AxiosError } from 'axios';
import { Note } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { clientNoteApi } from 'src/common/services';
import { getCreateMessageText } from 'src/common/utils';

export interface NoteCreateState {
  createdNote: Note | null;
  creating: boolean;
  error: AxiosError | null;
}

export type CreateNoteActions =
  | { type: 'CreateNote' }
  | { type: 'CreateNoteSuccess'; payload: Note }
  | { type: 'CreateNoteFailure'; payload: AxiosError };

export type CreateNoteDispatch = Dispatch<CreateNoteActions>;

export const initialCreateNoteState: NoteCreateState = {
  createdNote: null,
  creating: false,
  error: null
};

export const quickCallCreateNoteReducer: Reducer<
  NoteCreateState,
  CreateNoteActions
> = (state, action) => {
  switch (action.type) {
    case 'CreateNote':
      return { ...state, creating: true };
    case 'CreateNoteSuccess':
      return {
        ...state,
        createdNote: action.payload,
        creating: false
      };
    case 'CreateNoteFailure':
      return {
        ...state,
        error: action.payload,
        creating: false
      };
    default:
      return state;
  }
};

export const createClientNote = (
  dispatch: CreateNoteDispatch,
  clientId: number,
  note: string
) => {
  dispatch({ type: 'CreateNote' });

  clientNoteApi
    .createClientNote({ clientId, noteCreateBody: { note } })
    .then(({ data }) => {
      dispatch({ type: 'CreateNoteSuccess', payload: data });
      message.success(getCreateMessageText('client note').success);
    })
    .catch(error => {
      dispatch({
        type: 'CreateNoteFailure',
        payload: error
      });
      message.error(getCreateMessageText('client note').error);
    });
};
