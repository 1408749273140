import { useContext, useMemo } from 'react';
import {
  Client,
  ClientListItem
} from '@elm-street-technology/crm-axios-client';
import { GlobalDrawerDispatchContext } from './GlobalDrawerContext';

export const useGlobalDrawer = () => {
  const dispatch = useContext(GlobalDrawerDispatchContext);

  return useMemo(() => {
    const quickCall = (client: Client | ClientListItem) =>
      dispatch({ type: 'OpenQuickCall', payload: client });

    const quickEmail = (clients: ClientListItem[] | Client[]) => {
      dispatch({ type: 'OpenQuickEmail', payload: clients });
    };

    const quickListingsEmail = (listingIds: string[]) =>
      dispatch({ type: 'OpenQuickListingsEmail', payload: listingIds });

    const quickMessage = (client: Client | ClientListItem) =>
      dispatch({ type: 'OpenQuickMessage', payload: client });

    const closeActive = () => dispatch({ type: 'CloseDrawer' });

    return {
      quickCall,
      quickEmail,
      quickListingsEmail,
      quickMessage,
      closeActive
    };
  }, [dispatch]);
};
