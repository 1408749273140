import { TaskUpdateBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { clientTaskApi } from 'src/common/services';
import { getUpdateMessageText } from 'src/common/utils';
import { TasksDispatch } from '../Tasks.reducer';

export const updateClientTask = (
  dispatch: TasksDispatch,
  clientId: number,
  id: number,
  taskUpdateBody: TaskUpdateBody
) => {
  dispatch({ type: 'Update' });

  clientTaskApi
    .updateClientTask({ clientId, id, taskUpdateBody })
    .then(({ data }) => {
      dispatch({ type: 'UpdateSuccess', payload: data });
      message.success(getUpdateMessageText('client task').success);
    })
    .catch(error => {
      dispatch({
        type: 'UpdateFailure',
        payload: error
      });
      message.error(getUpdateMessageText('client task').error);
    });
};
