import { Reducer } from 'react';

interface ClientStopContactState {
  stopping: boolean;
}

export const initialClientStopContactState: ClientStopContactState = {
  stopping: false
};

export type ClientStopContactActions =
  | { type: 'StopContactPending' }
  | { type: 'StopContactSuccess' }
  | { type: 'StopContactFailure' };

export const clientStopContactReducer: Reducer<
  ClientStopContactState,
  ClientStopContactActions
> = (state, action) => {
  switch (action.type) {
    case 'StopContactPending':
      return {
        ...state,
        stopping: true,
        stopped: false
      };
    case 'StopContactSuccess':
      return {
        ...state,
        stopping: false,
        stopped: true
      };
    case 'StopContactFailure':
      return {
        ...state,
        stopping: false,
        stopped: false
      };
    default:
      return state;
  }
};
