import { createContext } from 'react';
import {
  ClientRecordDrawerDispatch,
  ClientRecordDrawerState
} from './ClientRecordDrawer.types';

export const ClientRecordDrawerStateContext = createContext(
  {} as ClientRecordDrawerState
);

export const ClientRecordDrawerDispatchContext = createContext(
  (() => {}) as ClientRecordDrawerDispatch
);
