import { ClientAffiliateData } from '@elm-street-technology/crm-axios-client';
import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export interface ClientAffiliateDataState {
  data: ClientAffiliateData | null;
  loading: boolean;
}
export type ClientAffiliateDataActions =
  | { type: 'GetAffiliateDataPending' }
  | { type: 'GetAffiliateDataSuccess'; payload: ClientAffiliateData }
  | { type: 'GetAffiliateDataFailure' };

export const initialClientAffiliateDataState: ClientAffiliateDataState = {
  data: null,
  loading: false
};

export const clientAffiliateDataReducer: Reducer<
  ClientAffiliateDataState,
  ClientRecordDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'GetAffiliateDataPending':
      return {
        ...state,
        loading: true
      };
    case 'GetAffiliateDataSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetAffiliateDataFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
