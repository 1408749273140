import { Button } from 'antd';
import { LARGE_MOBILE } from 'src/common/constants';
import { ColorCombination } from 'src/common/utils';
import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 100%;
  margin-top: 5px;
`;

export const TypeTag = styled.span<{ colors: ColorCombination }>`
  height: auto;
  margin-right: 8px;
  padding: 0 2px;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  border: 1px solid ${({ colors }) => colors.border || '#000'};
  border-radius: 2px;
  margin-bottom: 3px;
  color: ${({ colors }) => colors.foreground || '#000'};
  background-color: ${({ colors }) => colors.background || '#000'};
`;

export const StatusTag = styled.div<{ colors: ColorCombination }>`
  width: 88px;
  font-size: 14px;
  color: ${({ colors }) => colors.foreground || '#000'};
  border: 1px solid ${({ colors }) => colors.border || '#000'};
  background-color: ${({ colors }) => colors.background || '#000'};
  padding: 2px 8px;
  border-radius: 4px;
  text-align: center;
`;

export const ListingTypeTag = styled(StatusTag)`
  border-radius: 11px;
  width: 110px;
  white-space: nowrap;
`;

export const NameTitle = styled.span`
  font-size: 18px;
  margin-right: 8px;
  @media only screen and (max-width: ${LARGE_MOBILE}) {
    font-size: 16px;
  }
`;

export const EqualWidthTitle = styled.span`
  font-size: 14px;
  flex: 1;
  @media only screen and (max-width: ${LARGE_MOBILE}) {
    font-size: 12px;
  }
`;

// name renderer

export const NameCellRendererWrapper = styled(FlexWrapper)`
  min-width: 60px;
  max-width: 250px;
`;

// Activity
export const ActivityCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }) => theme.secondaryBlue};
  align-items: flex-start;
`;

export const ActivityValue = styled.span`
  font-size: 18px;
  padding: 0 5px;
  min-width: 20px;
  margin-bottom: 1px;
  color: ${({ theme }) => theme.secondaryBlue};
`;

export const ActivityDelta = styled.span`
  border: solid 1px #79da99;
  background-color: #cbf5d9;
  border-radius: 4px;
  font-size: 13px;
  padding: 0 8px 0 12px;
  min-width: 20px;
  padding: 2px;
  align-items: flex-end;
`;

// Actions
export const OrangeActionButton = styled(Button)`
  background-color: #db7945;
  border: 0;
  :active,
  :focus,
  :hover {
    background-color: #db7945;
  }
`;

export const BlueActionButton = styled(Button)`
  background-color: #2e7fc2;
  border: 0;
  :active,
  :focus,
  :hover {
    background-color: #2e7fc2;
  }
`;

export const ActionCellTitles = styled.div`
  font-size: 14px;
  color: #2e7fc2;
  text-align: center;
`;

export const ActionCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 120px;
  max-width: 300px;
  place-content: space-between;
  margin: 0 auto;
  div {
    margin: 0 6px;
  }
`;

// Tags
export const TagsCellWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 100px;
`;

// Score
export const ScoreCellWrapper = styled.span`
  font-size: 21px;
  font-weight: bolder;
  color: #0a2847;
`;
