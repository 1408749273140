import { Dispatch, Reducer } from 'react';
import { BulkActionSendEmailsBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { captureMessage } from '@sentry/react';
import { bulkActionApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils/textUtils';

interface SendQuickEmailState {
  isSent: boolean;
  isSending: boolean;
  isError: boolean;
  error: Response;
}

export const initialSendQuickEmailState: SendQuickEmailState = {
  isSent: false,
  isSending: false,
  isError: false,
  error: {} as Response
};

export type SendQuickEmailActions =
  | { type: 'SendingQuickEmail' }
  | {
      type: 'SendingQuickEmailSuccess';
    }
  | { type: 'SendingQuickEmailFailure'; payload: Response };

export const sendQuickEmailReducer: Reducer<
  SendQuickEmailState,
  SendQuickEmailActions
> = (state, action) => {
  switch (action.type) {
    case 'SendingQuickEmail':
      return {
        ...state,
        isSending: true
      };
    case 'SendingQuickEmailSuccess':
      return {
        ...state,
        isSent: true,
        isSending: false
      };
    case 'SendingQuickEmailFailure':
      return {
        ...state,
        error: action.payload,
        isSending: false,
        isError: true
      };
    default:
      return state;
  }
};

export const sendQuickEmail = (
  dispatch: Dispatch<SendQuickEmailActions>,
  bulkActionSendEmailsBody: BulkActionSendEmailsBody
) => {
  dispatch({ type: 'SendingQuickEmail' });
  bulkActionApi
    .bulkActionSendEmails({ bulkActionSendEmailsBody })
    .then(() => dispatch({ type: 'SendingQuickEmailSuccess' }))
    .catch(error => {
      captureMessage(error);
      const fetchMessage = getFetchMessageText('Quick Email');
      message.error(fetchMessage.error);
      dispatch({
        type: 'SendingQuickEmailFailure',
        payload: error
      });
    });
};
