import { useState, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import { ClientNameAndEmail } from '@elm-street-technology/crm-axios-client';
import { clientSearchByNameApi } from 'src/common/services';

interface ClientEmailSearchState {
  data: ClientNameAndEmail[];
  loading: boolean;
}

export const useClientEmailSearch = (
  initialClientEmails?: ClientNameAndEmail[]
) => {
  const ref = useRef(axios.CancelToken.source());

  const [state, setState] = useState<ClientEmailSearchState>({
    data: initialClientEmails || [],
    loading: false
  });

  const searchClientEmails = useCallback((q: string) => {
    ref.current.cancel();
    ref.current = axios.CancelToken.source();

    setState(prev => ({
      ...prev,
      loading: true
    }));

    clientSearchByNameApi
      .clientSearchByName(
        { q, searchEmail: true },
        {
          cancelToken: ref.current.token
        }
      )
      .then(({ data }) =>
        setState(prev => ({
          ...prev,
          data,
          loading: false
        }))
      )
      .catch(error => {
        if (!axios.isCancel(error)) {
          setState(prev => ({
            ...prev,
            loading: false
          }));
        }
      });
  }, []);

  return useMemo(() => ({ ...state, searchClientEmails }), [state]);
};
