import { Dispatch, Reducer } from 'react';
import { AxiosError } from 'axios';
import { ClientSavedSearch } from '@elm-street-technology/crm-axios-client';

export interface ClientSavedSearchesState {
  savedSearches: ClientSavedSearch[];
  loading: boolean;
  updating: boolean;
  error: AxiosError | null;
}

export type ClientSavedSearchesActions =
  | { type: 'GetList' }
  | { type: 'GetListSuccess'; payload: ClientSavedSearch[] }
  | { type: 'GetListFailure'; payload: AxiosError }
  | { type: 'Update' }
  | { type: 'UpdateSuccess'; payload: ClientSavedSearch }
  | { type: 'UpdateFailure'; payload: AxiosError }
  | { type: 'Delete' }
  | { type: 'DeleteSuccess'; payload: ClientSavedSearch['id'] }
  | { type: 'DeleteFailure'; payload: AxiosError };

export type ClientSavedSearchesDispatch = Dispatch<ClientSavedSearchesActions>;

export const initialClientSavedSearchesState: ClientSavedSearchesState = {
  savedSearches: [],
  loading: false,
  updating: false,
  error: null
};

export const clientSavedSearchesReducer: Reducer<
  ClientSavedSearchesState,
  ClientSavedSearchesActions
> = (state, action) => {
  switch (action.type) {
    case 'GetList':
      return { ...state, loading: true };
    case 'GetListSuccess':
      return { ...state, savedSearches: action.payload, loading: false };
    case 'GetListFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'Update':
      return { ...state, updating: true };
    case 'UpdateSuccess':
      return replaceUpdatedSavedSearch(state, action);
    case 'UpdateFailure':
      return {
        ...state,
        error: action.payload,
        updating: false
      };
    case 'Delete':
      return {
        ...state,
        updating: true
      };
    case 'DeleteSuccess':
      return removeDeletedSavedSearch(state, action);
    case 'DeleteFailure':
      return {
        ...state,
        error: action.payload,
        updating: false
      };
    default:
      return state;
  }
};

const replaceUpdatedSavedSearch: Reducer<
  ClientSavedSearchesState,
  { type: 'UpdateSuccess'; payload: ClientSavedSearch }
> = (state, action) => {
  const updatedIndex = state.savedSearches.findIndex(
    ({ id }) => id === action.payload.id
  );

  if (updatedIndex > -1) {
    const savedSearches = [...state.savedSearches];
    savedSearches.splice(updatedIndex, 1, action.payload);
    return {
      ...state,
      savedSearches,
      updating: false
    };
  }

  return {
    ...state,
    updating: false
  };
};

const removeDeletedSavedSearch: Reducer<
  ClientSavedSearchesState,
  { type: 'DeleteSuccess'; payload: ClientSavedSearch['id'] }
> = (state, action) => {
  const deletedIndex = state.savedSearches.findIndex(
    ({ id }) => id === action.payload
  );

  if (deletedIndex > -1) {
    const savedSearches = [...state.savedSearches];
    savedSearches.splice(deletedIndex, 1);
    return {
      ...state,
      savedSearches,
      updating: false
    };
  }

  return {
    ...state,
    updating: false
  };
};
