import { ClientContributorApiDeleteClientContributorRequest } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { getDeleteMessageText } from 'src/common/utils';
import { contributorApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const deleteClientContributor = (
  dispatch: ClientRecordDrawerDispatch,
  parameters: ClientContributorApiDeleteClientContributorRequest
) => {
  dispatch({ type: 'DeleteContributorPending' });

  contributorApi
    .deleteClientContributor(parameters)
    .then(() => {
      message.success(getDeleteMessageText('contributor').success);
      dispatch({
        type: 'DeleteContributorSuccess',
        payload: parameters.contributorId
      });
    })
    .catch(error => {
      message.error(getDeleteMessageText('contributor').error);
      dispatch({ type: 'DeleteContributorFailure' });
    });
};
