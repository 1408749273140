import React, { FC } from 'react';
import { Grid, Drawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';

interface Props {
  onClose?: DrawerProps['onClose'];
  title?: DrawerProps['title'];
  footer?: DrawerProps['footer'];
}

export const CommonSmDrawerPanel: FC<Props> = ({
  title,
  footer,
  onClose,
  children
}) => {
  const { sm } = Grid.useBreakpoint();

  return (
    <Drawer
      title={title}
      footer={footer}
      onClose={onClose}
      width={sm ? 576 : '100vw'}
      visible
      mask
      maskClosable={false}
    >
      {children}
    </Drawer>
  );
};
