import React, { FC, useState } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import { Form, Button, Row, Col, Grid, Drawer, message } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { clientApi } from 'src/common/services';
import { getUpdateMessageText } from 'src/common/utils';
import {
  BuyingDetailsSection,
  SellingDetailsSection,
  SellerListingReportsSection,
  ContactInformationSection,
  ImportantDatesSection,
  MailingAddressSection,
  TagsSection
} from './components';
import { formToClientCreateUpdateBody } from './utils';
import { useIsBuyerSeller } from './hooks';

interface Props {
  client: Client;
  onUpdateSuccess: (client: Client) => void;
  onClose: DrawerProps['onClose'];
}

export const UpdateClientDrawer: FC<Props> = ({
  client,
  onUpdateSuccess,
  onClose
}) => {
  const { md } = Grid.useBreakpoint();
  const [updating, setUpdating] = useState(false);

  const [form] = Form.useForm();

  const handleFormFinish = values => {
    setUpdating(true);

    clientApi
      .updateClient({
        clientId: client.id,
        clientUpdateBody: formToClientCreateUpdateBody(values)
      })
      .then(({ data }) => {
        message.success(getUpdateMessageText('client').success);
        onUpdateSuccess(data);
      })
      .catch(error => {
        message.error(getUpdateMessageText('client').error);
        setUpdating(false);
      });
  };

  const { isBuyer, isSeller, setClientType } = useIsBuyerSeller(client.type);

  const handleFormFieldsChange = changedFields => {
    const index = changedFields.findIndex(({ name }) => name[0] === 'type');

    if (index !== -1) {
      setClientType(changedFields[index].value);
    }
  };

  return (
    <Drawer
      width={md ? 768 : '100vw'}
      visible
      destroyOnClose
      title="Update Client"
      headerStyle={{ color: '#082746' }}
      onClose={onClose}
      closable
      mask={false}
      footer={
        <Row justify="end">
          <Col xs={24} sm={6}>
            <Button
              loading={updating}
              disabled={updating}
              onClick={() => form.submit()}
              size="large"
              type="primary"
              block
            >
              Save Client
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        name="update-client-form"
        onFinish={handleFormFinish}
        onFieldsChange={handleFormFieldsChange}
        scrollToFirstError
        layout="vertical"
      >
        <ContactInformationSection client={client} form={form} />
        <ImportantDatesSection client={client} />
        <MailingAddressSection client={client} />
        <TagsSection client={client} />
        {isBuyer && <BuyingDetailsSection form={form} client={client} />}
        {isSeller && <SellingDetailsSection form={form} client={client} />}
        {isSeller && <SellerListingReportsSection client={client} />}
      </Form>
    </Drawer>
  );
};
