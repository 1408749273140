import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icons/Side Menu/Dashboard Icon</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Side-Menu-Icons"
        transform="translate(-339.000000, -782.000000)"
        stroke="#4A525A"
      >
        <g
          id="Icons/Side-Menu/Dashboard-Icon"
          transform="translate(340.000000, 783.000000)"
        >
          <path
            d="M0,2 L0,7 C-3.55256646e-16,8.1045695 0.8954305,9 2,9 L9,9 C10.1045695,9 11,8.1045695 11,7 L11,2 C11,0.8954305 10.1045695,-2.02906125e-16 9,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
            id="Path"
          />
          <path
            d="M13,2 L13,10 C13,11.1045695 13.8954305,12 15,12 L22,12 C23.1045695,12 24,11.1045695 24,10 L24,2 C24,0.8954305 23.1045695,-2.02906125e-16 22,0 L15,0 C13.8954305,2.02906125e-16 13,0.8954305 13,2 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <path
            d="M13,16 L13,22 C13,23.1045695 13.8954305,24 15,24 L22,24 C23.1045695,24 24,23.1045695 24,22 L24,16 C24,14.8954305 23.1045695,14 22,14 L15,14 C13.8954305,14 13,14.8954305 13,16 Z"
            id="Path"
          />
          <path
            d="M0,13 L0,21 C1.3527075e-16,22.1045695 0.8954305,23 2,23 L9,23 C10.1045695,23 11,22.1045695 11,21 L11,13 C11,11.8954305 10.1045695,11 9,11 L2,11 C0.8954305,11 -1.3527075e-16,11.8954305 0,13 Z"
            id="Path"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const BrandDashboardTabIcon = props => (
  <Icon component={Svg} {...props} />
);
