import React, { FC, useContext, useMemo } from 'react';
import {
  QuickMessageDrawer,
  QuickCallDrawer,
  QuickEmailDrawer
} from 'src/crm/drawers';
import {
  GlobalDrawerStateContext,
  GlobalDrawerDispatchContext
} from 'src/common/hooks';

export const GlobalDrawerSwitch: FC = () => {
  const state = useContext(GlobalDrawerStateContext);
  const dispatch = useContext(GlobalDrawerDispatchContext);

  return useMemo(() => {
    const handleDrawerClose = () => dispatch({ type: 'CloseDrawer' });

    const { key, client, clients, listingIds } = state;

    if (key === 'Message' && client) {
      return <QuickMessageDrawer client={client} onClose={handleDrawerClose} />;
    }

    if (key === 'Call' && client) {
      return <QuickCallDrawer client={client} onClose={handleDrawerClose} />;
    }

    if (key === 'Email' && clients.length > 0) {
      return <QuickEmailDrawer clients={clients} onClose={handleDrawerClose} />;
    }

    if (key === 'ListingsEmail' && listingIds.length > 0) {
      return (
        <QuickEmailDrawer
          clients={[]}
          listingIds={listingIds}
          onClose={handleDrawerClose}
        />
      );
    }

    return null;
  }, [state]);
};
