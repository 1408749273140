import { useReducer, useEffect, useState, Reducer } from 'react';
import { captureMessage } from '@sentry/react';
import Axios from 'axios';
import { PaginatedClientListingItems } from '@elm-street-technology/crm-axios-client';
import { clientListingApi } from 'src/common/services';

interface PaginatedData {
  currentItemCount: number;
  totalItems: number;
  totalPages: number;
  pageIndex: number;
  itemsPerPage: number;
  startIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>;
}

interface FetchPaginatedListState {
  isLoading: boolean;
  isError: boolean;
  data: PaginatedData;
}

interface FetchPaginatedListAction {
  type: 'Loading' | 'Error' | 'Success';
  payload?;
}

const initialDataState = {
  currentItemCount: 0,
  totalItems: 0,
  totalPages: 0,
  pageIndex: 1,
  itemsPerPage: 25,
  startIndex: 1,
  items: []
};

export const initialFetchPaginatedListState: FetchPaginatedListState = {
  isLoading: false,
  isError: false,
  data: initialDataState
};

export const fetchPaginatedListReducer: Reducer<
  FetchPaginatedListState,
  FetchPaginatedListAction
> = (state, action) => {
  switch (action.type) {
    case 'Loading':
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case 'Success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case 'Error':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      return { ...state };
  }
};

export const useClientListingsApi = (
  clientId: number,
  type: { matched?: boolean; viewed?: boolean; favorited?: boolean }
): {
  isLoading: boolean;
  isError: boolean;
  data: PaginatedClientListingItems;
  setPage: ({
    pageSize,
    pageIndex
  }: {
    pageSize: number;
    pageIndex: number;
  }) => void;
} => {
  const [state, dispatch] = useReducer(
    fetchPaginatedListReducer,
    initialFetchPaginatedListState
  );

  const [page, setPage] = useState({
    pageSize: state.data.itemsPerPage || 25,
    pageIndex: state.data.pageIndex || 1
  });

  useEffect(() => {
    let mounted = true;

    const fetchClientListings = async () => {
      if (mounted) {
        dispatch({ type: 'Loading' });
      }
      const { matched, viewed, favorited } = type;
      try {
        const { data } = await clientListingApi.listClientListings(
          {
            clientId,
            matched,
            viewed,
            favorited,
            page: page.pageIndex,
            limit: page.pageSize
          },
          {
            withCredentials: true
          }
        );
        if (mounted) {
          dispatch({ type: 'Success', payload: data });
        }
      } catch (error) {
        if (!Axios.isCancel(error)) {
          if (mounted) {
            dispatch({ type: 'Error' });
          }
          captureMessage(error);
        }
      }
    };
    fetchClientListings().then(() => Promise.resolve());
    return () => {
      mounted = false;
    };
  }, [clientId, page]);

  return { ...state, setPage };
};
