import React, { FC, useMemo, useContext, useEffect, useReducer } from 'react';
import { List, Button, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TaskEditor, TaskListItem } from './components';
import { TasksStateContext, TasksDispatchContext } from './Tasks.context';
import { initialTasksState, tasksReducer } from './Tasks.reducer';
import { listClientTasks } from './actions';
import { ClientRecordSectionTitle } from '../index';

interface Props {
  clientId: number;
}

const Tasks: FC<Props> = ({ clientId }) => {
  const { view, tasks, loading } = useContext(TasksStateContext);
  const dispatch = useContext(TasksDispatchContext);

  const content = useMemo(() => {
    if (view === 'Edit') {
      return <TaskEditor clientId={clientId} />;
    }

    return (
      <List
        loading={loading}
        itemLayout="horizontal"
        size="large"
        dataSource={tasks}
        renderItem={task => <TaskListItem task={task} />}
      />
    );
  }, [clientId, view, loading, tasks]);

  const handleNewTaskClick = () => dispatch({ type: 'ShowAdd' });

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Tasks</ClientRecordSectionTitle>}
      extra={
        view === 'List' && (
          <Button
            onClick={handleNewTaskClick}
            size="large"
            icon={<PlusOutlined />}
          >
            New Task
          </Button>
        )
      }
    >
      {content}
    </PageHeader>
  );
};

export const TasksTab: FC<Props> = ({ clientId }) => {
  const [state, dispatch] = useReducer(tasksReducer, initialTasksState);

  useEffect(() => {
    listClientTasks(dispatch, clientId);
  }, [clientId]);

  return (
    <TasksStateContext.Provider value={state}>
      <TasksDispatchContext.Provider value={dispatch}>
        <Tasks clientId={clientId} />
      </TasksDispatchContext.Provider>
    </TasksStateContext.Provider>
  );
};
