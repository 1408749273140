import styled from 'styled-components';

const Chat = styled.div`
  padding-right: 10px;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  .weakColor & {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
  p {
    margin-bottom: 0;
  }
  .conversation {
    box-sizing: border-box;
    display: flex;
    text-rendering: optimizeLegibility;
    margin-left: 90px;
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(180, 196, 200, 0.23);
    padding: 13px;
    font-size: 14px;
    line-height: 1.36;
    min-height: 47px;
    color: #082746;
    background-color: #ffffff;
    margin-bottom: 37px;
    width: fit-content;
    position: relative;
  }
  .conversation-outbound {
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 72px;
    background-color: #0091ff;
    color: #ffffff;
  }
  .body {
    position: relative;
    padding: 0.625rem 1rem;
    background-color: white;
    border-radius: 0.8rem;
    min-width: 100px;
  }
  .body-sent {
    position: relative;
    background-color: #e2f8ff;
    float: right;
    order: 1;
  }
  .date {
    position: absolute;
    font-family: Roboto;
    font-size: 10px;
    line-height: 1;
    color: #9fa9ba;
    bottom: -21px;
    left: 2px;
    min-width: 100px;
  }
  .date-outbound {
    text-align: right;
    left: unset;
    right: 2px;
  }
  input {
    flex: 1 1 0%;
    box-sizing: border-box;
  }
`;

export default Chat;
