import styled, { CSSProperties } from 'styled-components';

interface FlexProps {
  flexDirection?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  alignContent?: CSSProperties['alignContent'];
  justifyItems?: CSSProperties['justifyItems'];
  justifyContent?: CSSProperties['justifyContent'];
}

export const Flex = styled('div')<Partial<FlexProps>>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems};
  justify-items: ${props => props.justifyItems};
  justify-content: ${props => props.justifyContent};
  align-content: ${props => props.alignContent};
`;
