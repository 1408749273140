import { Reducer, Dispatch } from 'react';
import { ClientCommunicationCall } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { callCommunicationApi } from 'src/common/services';
import { AxiosError } from 'axios';
import { getCreateMessageText } from 'src/common/utils';

export interface CreateCallState {
  createdCall: ClientCommunicationCall | null;
  creating: boolean;
  error: AxiosError | null;
}

export type CreateCallActions =
  | { type: 'CreateCall' }
  | { type: 'CreateSuccess'; payload: ClientCommunicationCall }
  | { type: 'CreateFailure'; payload: AxiosError };

type CreateCallDispatch = Dispatch<CreateCallActions>;

export const initialCreateCallState: CreateCallState = {
  createdCall: null,
  creating: false,
  error: null
};

export const createCallReducer: Reducer<CreateCallState, CreateCallActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'CreateCall':
      return {
        ...state,
        creating: true
      };
    case 'CreateSuccess':
      return {
        ...state,
        data: action.payload,
        creating: false
      };
    case 'CreateFailure':
      return {
        ...state,
        error: action.payload,
        creating: false
      };
    default:
      return state;
  }
};

export const createClientCommunicationCall = (
  dispatch: CreateCallDispatch,
  clientId: number
) => {
  dispatch({ type: 'CreateCall' });

  callCommunicationApi
    .createClientCommunicationCall({ clientId })
    .then(({ data }) => dispatch({ type: 'CreateSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'CreateFailure',
        payload: error
      });
      message.error(getCreateMessageText('call').error);
    });
};
