import React from 'react';

const Contributors = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="84"
      viewBox="0 0 70 84"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#7C838C" transform="translate(-450 -3369)">
          <g transform="translate(450 3369)">
            <g>
              <g transform="translate(21.429 24.286)">
                <g>
                  <g>
                    <path
                      strokeWidth="0.5"
                      d="M15.27 7.189a3.64 3.64 0 00-2.238.753 3.08 3.08 0 00-1.162 1.883 3.009 3.009 0 00.406 2.09c.385.636.988 1.141 1.708 1.391l1.394.484-1.476.002c-1.36.002-2.593.507-3.486 1.325-.885.811-1.436 1.93-1.439 3.168l.483 2.281 12.104-.427v-1.855c-.004-1.246-.563-2.372-1.48-3.184-.918-.81-2.192-1.308-3.709-1.797a3.394 3.394 0 001.852-1.363c.404-.617.578-1.36.443-2.116a3.075 3.075 0 00-1.16-1.882 3.64 3.64 0 00-2.24-.753zM4.114 11.35H3.16v6.44h.954v-6.44z"
                    />
                    <path d="M24.6.5H5.153c-.485 0-.924.185-1.242.487a1.577 1.577 0 00-.501 1.146l.496 3.408V2.133c0-.338.142-.644.374-.865.224-.213.532-.347.873-.347h19.446a3.56 3.56 0 012.458.968 3.283 3.283 0 011.033 2.376v20.614a3.283 3.283 0 01-1.033 2.376 3.56 3.56 0 01-2.46.968H5.154c-.343 0-.65-.134-.874-.346a1.19 1.19 0 01-.373-.866l-.496-3.409v3.408c0 .448.193.853.5 1.146.319.302.758.487 1.243.487h19.446a4.09 4.09 0 002.824-1.109 3.679 3.679 0 001.162-2.658V4.266a3.678 3.678 0 00-1.162-2.658A4.089 4.089 0 0024.6.5z" />
                  </g>
                </g>
              </g>
              <path d="M11.548 58.786c-1.473 0-2.837.5-3.893 1.343-1.045.836-1.787 2.009-2.023 3.364-.232 1.331.06 2.644.707 3.738.67 1.133 1.719 2.034 2.97 2.48l2.717.967-2.883.004c-2.374.003-4.525.907-6.083 2.37C1.514 74.504.553 76.509.548 78.726v3.35c0 .192.085.365.22.491a.827.827 0 00.568.219H21.76a.783.783 0 00.557-.21.722.722 0 00.23-.507v-3.344c-.006-2.233-.982-4.25-2.586-5.704-1.6-1.45-3.825-2.34-6.342-2.34l-.151-.976c1.389-.44 2.509-1.32 3.22-2.432a5.3 5.3 0 00.773-3.783c-.235-1.354-.976-2.526-2.021-3.361a6.228 6.228 0 00-3.892-1.343z" />
              <path
                fill="#7C838C"
                d="M10.095 6.976c-1 0-1.928.344-2.645.924a3.8 3.8 0 00-1.373 2.31 3.812 3.812 0 00.48 2.568 3.884 3.884 0 002.014 1.702l2.682.967-2.85.004a6.06 6.06 0 00-4.179 1.647c-1.06 1.008-1.72 2.4-1.724 3.94l.407 2.748 14.783-.369v-2.38c-.004-1.55-.674-2.95-1.774-3.959-1.101-1.01-2.631-1.627-4.363-1.627l-.152-.976a4.026 4.026 0 002.187-1.672c.478-.756.684-1.669.524-2.595A3.793 3.793 0 0012.74 7.9a4.201 4.201 0 00-2.645-.924zM61.905 52.31c-1 0-1.928.343-2.646.923a3.8 3.8 0 00-1.373 2.31 3.812 3.812 0 00.481 2.568 3.884 3.884 0 002.014 1.703l2.682.966-2.85.004a6.06 6.06 0 00-4.18 1.647c-1.06 1.009-1.72 2.4-1.723 3.94l.406 2.748 14.784-.368v-2.38c-.005-1.55-.674-2.95-1.774-3.96-1.102-1.01-2.632-1.627-4.363-1.627l-.153-.976a4.026 4.026 0 002.187-1.671c.478-.757.684-1.67.525-2.596a3.793 3.793 0 00-1.372-2.308 4.201 4.201 0 00-2.645-.923zM60.286.5c-1 0-1.928.344-2.646.924a3.8 3.8 0 00-1.373 2.31 3.812 3.812 0 00.48 2.568 3.884 3.884 0 002.015 1.702l2.682.966-2.85.005a6.06 6.06 0 00-4.18 1.647c-1.06 1.008-1.72 2.4-1.724 3.94l.407 2.748 14.784-.369v-2.38c-.005-1.55-.674-2.95-1.775-3.959-1.1-1.01-2.63-1.627-4.362-1.627l-.153-.977a4.026 4.026 0 002.187-1.671c.478-.756.684-1.669.525-2.596a3.793 3.793 0 00-1.372-2.307A4.201 4.201 0 0060.286.5z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Contributors;
