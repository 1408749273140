import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#BBB">
          <g>
            <g>
              <path d="M5 13.04a.258.258 0 00.404.213l1.777-1.214L5 11v2.04z" />
              <path d="M15.885.069a.259.259 0 00-.289-.043L.255 7.552a.456.456 0 00.005.821L4.303 10.3l8.085-6.645-7.083 7.122 5.037 2.4a.455.455 0 00.62-.243L15.95.353a.257.257 0 00-.065-.284" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
