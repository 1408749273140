import { createContext } from 'react';
import {
  GlobalDrawerDispatch,
  initialGlobalDrawerState
} from './globalDrawerReducer';

export const GlobalDrawerStateContext = createContext(initialGlobalDrawerState);

export const GlobalDrawerDispatchContext = createContext<GlobalDrawerDispatch>(
  () => {}
);
