import React from 'react';

function Icon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 17 12" version="1.1">
      <title>List Icon</title>
      <desc>Created with Sketch.</desc>
      <g
        id="CRM-Agent-Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CRM-Dashboard-Desktop---Client-Details-Copy"
          transform="translate(-1523.000000, -1589.000000)"
          stroke="#595959"
        >
          <g id="Group-16" transform="translate(910.000000, 421.000000)">
            <g id="Group-15-Copy">
              <g id="List-Icon" transform="translate(614.000000, 1169.000000)">
                <path
                  d="M0,5 C6.76353751e-17,5.55228475 0.44771525,6 1,6 L14,6 C14.5522847,6 15,5.55228475 15,5 C15,4.44771525 14.5522847,4 14,4 L1,4 C0.44771525,4 -6.76353751e-17,4.44771525 0,5 Z"
                  id="Path"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0,1 C6.76353751e-17,1.55228475 0.44771525,2 1,2 L14,2 C14.5522847,2 15,1.55228475 15,1 C15,0.44771525 14.5522847,-1.01453063e-16 14,0 L1,0 C0.44771525,1.01453063e-16 -6.76353751e-17,0.44771525 0,1 Z"
                  id="Path"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="3.5"
                  y1="4"
                  x2="3.5"
                  y2="6"
                  id="Path-3"
                  strokeWidth="0.5"
                />
                <path
                  d="M0,9 C6.76353751e-17,9.55228475 0.44771525,10 1,10 L14,10 C14.5522847,10 15,9.55228475 15,9 C15,8.44771525 14.5522847,8 14,8 L1,8 C0.44771525,8 -6.76353751e-17,8.44771525 0,9 Z"
                  id="Path"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="3.5"
                  y1="8"
                  x2="3.5"
                  y2="10"
                  id="Path-3"
                  strokeWidth="0.5"
                />
                <line
                  x1="3.5"
                  y1="0"
                  x2="3.5"
                  y2="2"
                  id="Path-3"
                  strokeWidth="0.5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
