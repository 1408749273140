import React, { FC } from 'react';
import { Notification } from '@elm-street-technology/crm-axios-client';
import { List } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { formatNotificationCategory } from '../Header.utils';

const StyledListItem = styled(List.Item)`
  .ant-list-item-meta-content {
    width: fit-content;
  }
`;

interface NotificationListItemProps {
  item: Notification;
}

export const NotificationListItem: FC<NotificationListItemProps> = ({
  item
}) => (
  <StyledListItem>
    <List.Item.Meta
      style={{ padding: '0 16px' }}
      title={
        item.url ? (
          <a href={item.url}>{formatNotificationCategory(item.category)}</a>
        ) : (
          <span>{formatNotificationCategory(item.category)}</span>
        )
      }
      description={
        <>
          <span>{item.message}</span>
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span>{item.createdAtFromNow}</span>
            <span>
              {item.readAtFromNow && [
                `Read ${item.readAtFromNow}  `,
                <CheckOutlined />
              ]}
            </span>
          </div>
        </>
      }
    />
  </StyledListItem>
);
