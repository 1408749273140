import { Reducer, Dispatch } from 'react';
import { ClientCommunicationText } from '@elm-street-technology/crm-axios-client';
import { AxiosError } from 'axios';

export interface QuickMessageState {
  texts: ClientCommunicationText[];
  loading: boolean;
  sending: boolean;
  error: AxiosError | null;
}

export type QuickMessageActions =
  | { type: 'GetTexts' }
  | { type: 'GetTextsSuccess'; payload: ClientCommunicationText[] }
  | { type: 'GetTextsFailure'; payload: AxiosError }
  | { type: 'SendText' }
  | { type: 'SendTextSuccess' }
  | { type: 'SendTextFailure'; payload: AxiosError };

export type QuickMessageDispatch = Dispatch<QuickMessageActions>;

export const initialQuickMessageState: QuickMessageState = {
  texts: [],
  loading: false,
  sending: false,
  error: null
};

export const quickMessageReducer: Reducer<
  QuickMessageState,
  QuickMessageActions
> = (state, action) => {
  switch (action.type) {
    case 'GetTexts':
      return {
        ...state,
        loading: true
      };
    case 'GetTextsSuccess':
      return {
        ...state,
        texts: action.payload,
        loading: false
      };
    case 'GetTextsFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'SendText':
      return {
        ...state,
        sending: true
      };
    case 'SendTextSuccess':
      return {
        ...state,
        sending: false
      };
    case 'SendTextFailure':
      return {
        ...state,
        error: action.payload,
        sending: false
      };
    default:
      return state;
  }
};
