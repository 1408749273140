import * as React from 'react';

const SvgCMA = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <g
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      >
        <path d="M11.375.875a1.45 1.45 0 011.458 1.441v9.368a1.45 1.45 0 01-1.458 1.441h-8.75a1.45 1.45 0 01-1.458-1.441V2.316A1.45 1.45 0 012.625.875h8.75" />
        <path d="M5.25 10.5h2.917" />
        <path d="M9.917 8.458H4.083" />
        <path
          fillRule="evenodd"
          d="M8.167 4.506V5.76a.365.365 0 01-.365.365H5.615a.365.365 0 01-.365-.365V4.478c0-.178.08-.345.219-.456l.857-.686a.583.583 0 01.73 0l.892.714c.138.11.219.278.219.456v0z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default SvgCMA;
