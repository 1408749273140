import React, { FC } from 'react';
import { Input, Form } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import { ClientFormSection } from './index';

interface Props {
  client?: Client;
}

export const SellerListingReportsSection: FC<Props> = ({ client }) => {
  return (
    <ClientFormSection title="Seller Listings Reports">
      <Form.Item
        label="Competing within Miles"
        name={['sellerFields', 'competingWithinMiles']}
        initialValue={client?.sellerFields?.competingWithinMiles}
      >
        <Input
          size="large"
          autoComplete="new-password"
          type="number"
          min={0}
          allowClear
        />
      </Form.Item>

      <Form.Item
        label="Competing Percent Price Range"
        name={['sellerFields', 'competingPctPriceRange']}
        initialValue={client?.sellerFields?.competingPctPriceRange}
      >
        <Input
          size="large"
          autoComplete="new-password"
          type="number"
          min={0}
          allowClear
        />
      </Form.Item>
    </ClientFormSection>
  );
};
