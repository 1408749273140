import { Dispatch, Reducer } from 'react';
import { AxiosError } from 'axios';
import { Note } from '@elm-street-technology/crm-axios-client';

export interface NotesState {
  notes: Note[];
  loading: boolean;
  error: AxiosError | null;
  view: 'List' | 'Edit';
  editNote: Note | null;
}

export type NotesActions =
  | { type: 'GetNotes' }
  | { type: 'GetNotesSuccess'; payload: Note[] }
  | { type: 'GetNotesFailure'; payload: AxiosError }
  | { type: 'UpdateNote' }
  | { type: 'UpdateNoteSuccess'; payload: Note }
  | { type: 'UpdateNoteFailure'; payload: AxiosError }
  | { type: 'CreateNote' }
  | { type: 'CreateNoteSuccess'; payload: Note }
  | { type: 'CreateNoteFailure'; payload: AxiosError }
  | { type: 'DeleteNote' }
  | { type: 'DeleteNoteSuccess'; payload: Note['id'] }
  | { type: 'DeleteNoteFailure'; payload: AxiosError }
  | { type: 'ShowList' }
  | { type: 'ShowEdit'; payload: Note }
  | { type: 'ShowAdd' };

export type NotesDispatch = Dispatch<NotesActions>;

export const initialNotesState: NotesState = {
  notes: [],
  loading: false,
  error: null,
  view: 'List',
  editNote: null
};

export const notesReducer: Reducer<NotesState, NotesActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'GetNotes':
      return { ...state, loading: true };
    case 'GetNotesSuccess':
      return {
        ...state,
        notes: sortNotesByCreatedDateDescending(action.payload),
        loading: false
      };
    case 'GetNotesFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'UpdateNote':
      return { ...state, loading: true };
    case 'UpdateNoteSuccess':
      return replaceUpdatedNote(state, action);
    case 'UpdateNoteFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'CreateNote':
      return { ...state, loading: true };
    case 'CreateNoteSuccess':
      return {
        ...state,
        notes: sortNotesByCreatedDateDescending([
          action.payload,
          ...state.notes
        ]),
        loading: false,
        view: 'List',
        editNote: null
      };
    case 'CreateNoteFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'DeleteNote':
      return {
        ...state,
        loading: true
      };
    case 'DeleteNoteSuccess':
      return removeDeletedNote(state, action);
    case 'DeleteNoteFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'ShowList':
      return {
        ...state,
        view: 'List',
        editNote: null
      };
    case 'ShowAdd':
      return {
        ...state,
        view: 'Edit',
        editNote: null
      };
    case 'ShowEdit':
      return {
        ...state,
        view: 'Edit',
        editNote: action.payload
      };
    default:
      return state;
  }
};

const replaceUpdatedNote: Reducer<
  NotesState,
  { type: 'UpdateNoteSuccess'; payload: Note }
> = (state, action) => {
  const updatedIndex = state.notes.findIndex(
    ({ id }) => id === action.payload.id
  );

  if (updatedIndex > -1) {
    const notes = [...state.notes];
    notes.splice(updatedIndex, 1, action.payload);
    return {
      ...state,
      notes,
      loading: false,
      view: 'List',
      editNote: null
    };
  }

  return {
    ...state,
    loading: false,
    view: 'List',
    editNote: null
  };
};

const removeDeletedNote: Reducer<
  NotesState,
  { type: 'DeleteNoteSuccess'; payload: Note['id'] }
> = (state, action) => {
  const deletedIndex = state.notes.findIndex(({ id }) => id === action.payload);

  if (deletedIndex > -1) {
    const notes = [...state.notes];
    notes.splice(deletedIndex, 1);
    return {
      ...state,
      notes,
      loading: false
    };
  }

  return {
    ...state,
    loading: false
  };
};

const sortNotesByCreatedDateDescending = (notes: Note[]) => {
  const sortedNotes = [...notes];

  sortedNotes.sort(({ createdIsoDateStr: a }, { createdIsoDateStr: b }) =>
    a < b ? 1 : a > b ? -1 : 0
  );

  return sortedNotes;
};
