import * as React from 'react';

function SvgPrice(props) {
  return (
    <svg width={35} height={33} {...props}>
      <g transform="translate(-2 -7)" fill="none" fillRule="evenodd">
        <path
          d="M3.794 22.981a3.49 3.49 0 00-1.05 2.453 3.49 3.49 0 00.993 2.489l10.74 11.03a1.496 1.496 0 002.019.119c9.246-7.485 15.094-12.868 17.53-16.16 1.182-1.597 1.794-4.056 2.035-6.466.315-3.15.015-6.253-.623-7.227-.638-.975-3.483-1.345-6.42-1.406-3.293-.068-6.868.34-8.31 1.238-2.648 1.648-8.238 6.25-16.914 13.93z"
          stroke="#082746"
        />
        <circle stroke="#082746" cx={28.5} cy={15.5} r={3.5} />
        <text
          transform="rotate(47 17.731 24.318)"
          fontFamily="Roboto-Regular, Roboto"
          fontSize={15}
          fill="#082746"
        >
          <tspan x={13.52} y={29.318}>
            $
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgPrice;
