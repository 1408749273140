import React, { FC, useEffect } from 'react';
import { TablePaginationConfig } from 'antd/lib/table';
import { capitalize, upperCase } from 'lodash';
import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingIndicator } from 'src/crm/components/LoadingIndicator';
import { useClientListingsApi } from './ActivityListingsSection.reducer';
import { ClientListings } from '../../ClientListings';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 15px;
    font-weight: bold;
    padding-top: 11px;
    color: #082746;
  }

  div.property {
    word-wrap: 'break-word';
    word-break: 'break-word';
    font-size: 10px;
    color: #082746;
  }

  .divider {
    min-height: 70px;
    background-color: #fcfcfc;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 81px 0;
    &.favorited {
      margin: 81px 0 57px;
    }

    button {
      height: 40px;
      width: 156px;
      background-color: #ffffff;
      border-radius: 3px;
      box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.03);
      border: 1px solid rgb(235, 235, 235);
      font-size: 14px;
      font-weight: bold;
      color: #082746;
    }
  }

  h1 {
    font-size: 21px;
    color: #082746;
  }
`;

interface Props {
  action: 'favorited' | 'viewed' | 'matched';
  clientId: number;
  setDashboardCount?: (count: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick?: (id: any) => void;
}

export const ActivityListingsSection: FC<Props> = ({
  action,
  clientId,
  setDashboardCount,
  handleClick
}) => {
  const pastTenseActionStr = capitalize(action);
  const sectionRef = upperCase(action);
  const { actionEvent, showTotalViews } = actionConfiguration(action);
  const { isLoading, data, setPage } = useClientListingsApi(
    clientId,
    actionEvent
  );

  const { totalItems, itemsPerPage, pageIndex } = data;

  useEffect(() => {
    setDashboardCount && setDashboardCount(totalItems);
  }, [totalItems]);

  const onChange = (pagination: TablePaginationConfig) => {
    // scroll
    handleClick && handleClick(sectionRef);
    // update page/pageSize
    setPage({
      pageIndex: pagination.current || pageIndex,
      pageSize: pagination.pageSize || itemsPerPage
    });
  };

  return (
    <Spin spinning={isLoading} indicator={<LoadingIndicator />}>
      <Container>
        <ClientListings
          totalNumber={data.totalItems}
          listings={data.items}
          key={action}
          actionType={action}
          pastTenseActionStr={pastTenseActionStr}
          onChange={onChange}
          showTotalViews={showTotalViews}
          pagination={{
            current: pageIndex,
            pageSize: itemsPerPage,
            total: totalItems,
            simple: true
          }}
        />
      </Container>
    </Spin>
  );
};

const actionConfiguration = (action: 'viewed' | 'favorited' | 'matched') => {
  if (action === 'favorited') {
    return { actionEvent: { favorited: true }, showTotalViews: false };
  }
  if (action === 'viewed') {
    return { actionEvent: { viewed: true }, showTotalViews: true };
  }
  return { actionEvent: { matched: true }, showTotalViews: false };
};
