import {
  ClientUpdateBody,
  Client
} from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { getUpdateMessageText } from 'src/common/utils';
import { clientApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const updateClient = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number,
  clientUpdateBody: ClientUpdateBody,
  onUpdateSuccess: (client: Client) => void
) => {
  dispatch({ type: 'UpdateClientPending' });

  clientApi
    .updateClient({ clientId, clientUpdateBody })
    .then(({ data }) => {
      message.success(getUpdateMessageText('client').success);
      dispatch({ type: 'UpdateClientSuccess', payload: data });
      onUpdateSuccess(data);
    })
    .catch(error => {
      message.error(getUpdateMessageText('client').error);
      dispatch({ type: 'UpdateClientFailure' });
    });
};
