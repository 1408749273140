import React from 'react';

export const ContactsTabMobileIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 40 41"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="rgba(0, 0, 0, 0.65)">
        <g>
          <path
            strokeWidth="0.5"
            d="M20.96 10.012c-1.183 0-2.28.402-3.128 1.08a4.453 4.453 0 00-1.631 2.712 4.43 4.43 0 00.57 3.015 4.601 4.601 0 002.394 1.997l1.358.484-1.441.002c-1.892.003-3.606.723-4.847 1.889-1.235 1.16-2.002 2.762-2.007 4.533v2.616c0 .19.084.362.216.487.138.13.33.208.54.208h15.951a.75.75 0 00.532-.2.704.704 0 00.224-.5v-2.611c-.005-1.785-.784-3.397-2.066-4.559-1.274-1.154-3.044-1.863-5.123-2.351a4.764 4.764 0 002.591-1.958c.57-.89.814-1.964.624-3.054a4.445 4.445 0 00-1.63-2.71 5.005 5.005 0 00-3.127-1.08zM5.74 15.867H4.243v9.262H5.74v-9.262z"
          />
          <path d="M33.764.5H7.072c-.713 0-1.36.28-1.828.737A2.455 2.455 0 004.494 3v4.998h1.053V3c.001-.416.173-.793.452-1.065A1.532 1.532 0 017.072 1.5h26.691a4.643 4.643 0 013.25 1.31A4.463 4.463 0 0138.369 6v29a4.463 4.463 0 01-1.356 3.192 4.644 4.644 0 01-3.25 1.31H7.072c-.421 0-.8-.167-1.075-.435-.28-.272-.45-.65-.45-1.066v-5H4.492V38c0 .689.288 1.312.75 1.763a2.612 2.612 0 001.83.737h26.69a5.717 5.717 0 004-1.611 5.447 5.447 0 001.658-3.89V6a5.447 5.447 0 00-1.657-3.89 5.717 5.717 0 00-4-1.611z" />
          <path
            strokeWidth="0.5"
            d="M8.84 27.584H1.141a.99.99 0 00-.643.225.664.664 0 00-.248.5.66.66 0 00.247.5.994.994 0 00.646.226H8.84c.252 0 .48-.084.645-.226a.661.661 0 00.247-.5.659.659 0 00-.247-.5.992.992 0 00-.645-.225zM7.017 11.965H.97a.712.712 0 00-.507.211.729.729 0 00-.211.515.7.7 0 00.205.507.741.741 0 00.517.218h6.043a.74.74 0 00.513-.217.699.699 0 00.205-.504.73.73 0 00-.21-.518.712.712 0 00-.508-.212z"
          />
        </g>
      </g>
    </g>
  </svg>
);
