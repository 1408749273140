import React from 'react';

export const IncomingText = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="19"
      viewBox="0 0 35 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1018 -4053)">
          <g transform="translate(891 418)">
            <g transform="translate(127 3635)">
              <g fill="#F15953">
                <path d="M10.111 16.65c5.584 0 10.111-3.727 10.111-8.325C20.222 3.727 15.695 0 10.112 0 4.526 0 0 3.727 0 8.325c0 2.1.944 4.018 2.502 5.482v4.86l4.765-2.351c.902.217 1.856.334 2.844.334z" />
              </g>
              <g
                stroke="#F15953"
                strokeLinecap="round"
                strokeWidth="2"
                transform="rotate(-90 18.5 -7.5)"
              >
                <path d="M3.167 8L3.167 0" />
                <path
                  strokeLinejoin="round"
                  d="M5.66666667 2L3.16666667 0 0.666666667 2"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
