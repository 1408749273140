import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="18"
      viewBox="0 0 27 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#082746" fillRule="nonzero" transform="translate(-932 -1145)">
          <g transform="translate(932 1145)">
            <path d="M21.774 6.79A8.438 8.438 0 0013.5 0c-3.251 0-6.07 1.845-7.481 4.54A6.754 6.754 0 000 11.25C0 14.98 3.02 18 6.75 18h14.625A5.627 5.627 0 0027 12.375c0-2.97-2.312-5.377-5.226-5.586zm-8.981 8.253l-3.21-3.21a1 1 0 01.706-1.708h.961v-3.5a1 1 0 011-1h2.5a1 1 0 011 1v3.5h.96a1 1 0 01.708 1.707l-3.21 3.21a1 1 0 01-1.415 0z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
