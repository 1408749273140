import {
  BulkActionApi,
  Configuration,
  FeatureTogglesApi,
  ClientListingApi,
  UserApi,
  ClientApi,
  ClientAbilityApi,
  ClientAssignmentApi,
  ClientAffiliateApi,
  ClientContributorApi,
  ClientPermissionApi,
  ContributorOrganizationMembersApi,
  ClientContributorHistoryApi,
  ListingCountApi,
  PresetApi,
  StatsApi,
  ClientSearchByNameApi,
  UserSavedSearchApi,
  ClientSavedSearchApi,
  ReadListingApi,
  OpenHousesApi,
  ListingEngagementsApi,
  CmaApi,
  ListingNoteApi,
  AttentionApi,
  PerformanceApi,
  UserUserActivityApi,
  RecruitingMarketShareApi,
  RosterApi,
  ProspectiveAgentsApi,
  ProspectiveAgentSavedSearchApi,
  CampaignApi,
  UserClientActivityApi,
  LeadStatusesByAgentApi,
  AgentReportsApi,
  UserTaskApi,
  ContactPipelineApi,
  ContactStatusesApi,
  LeadClaimTimeApi,
  LeadStatusesBySourceApi,
  LeadsClaimedApi,
  LeadStatusesApi,
  EventApi,
  TagApi,
  EmergeMailingListsApi,
  EmergeDripCampaignsApi,
  NotificationApi,
  CombinedCommunicationApi,
  ClientHistoryApi,
  CallCommunicationApi,
  TextCommunicationApi,
  ClientTaskApi,
  ClientNoteApi,
  DocApi,
  CompositeCampaignApi,
  CampaignCompositeTemplateApi,
  CampaignCompositeActionApi,
  SiteRosterApi,
  UsersApi,
  MlsSubscriptionApi,
  MlsOptionsApi,
  UserVoipApi,
  ClientStopContactApi,
  GeoCoordinatesApi,
  AivaAnalyticsApi
} from '@elm-street-technology/crm-axios-client';
import { CRM_HOST } from 'src/common/constants';

const crmConfiguration = new Configuration({
  basePath: CRM_HOST,
  baseOptions: { withCredentials: true }
});

export const clientApi = new ClientApi(crmConfiguration);
export const bulkActionApi = new BulkActionApi(crmConfiguration);
export const abilityApi = new ClientAbilityApi(crmConfiguration);
export const assignmentApi = new ClientAssignmentApi(crmConfiguration);
export const affiliateApi = new ClientAffiliateApi(crmConfiguration);
export const contributorApi = new ClientContributorApi(crmConfiguration);
export const organizationMembersApi = new ContributorOrganizationMembersApi(
  crmConfiguration
);
export const contributorHistoryApi = new ClientContributorHistoryApi(
  crmConfiguration
);
export const permissionApi = new ClientPermissionApi(crmConfiguration);
export const featureTogglesApi = new FeatureTogglesApi(crmConfiguration);
export const clientListingApi = new ClientListingApi(crmConfiguration);
export const clientCmaApi = new CmaApi(crmConfiguration);
export const userApi = new UserApi(crmConfiguration);
export const listingCountsApi = new ListingCountApi(crmConfiguration);
export const statsApi = new StatsApi(crmConfiguration);
export const aivaAnalyticsApi = new AivaAnalyticsApi(crmConfiguration);
export const presetApi = new PresetApi(crmConfiguration);
export const clientSearchByNameApi = new ClientSearchByNameApi(
  crmConfiguration
);
export const userSavedSearchApi = new UserSavedSearchApi(crmConfiguration);
export const clientSavedSearchApi = new ClientSavedSearchApi(crmConfiguration);
export const readListingsApi = new ReadListingApi(crmConfiguration);
export const openHousesApi = new OpenHousesApi(crmConfiguration);
export const listingEngagementsApi = new ListingEngagementsApi(
  crmConfiguration
);
export const listingNoteApi = new ListingNoteApi(crmConfiguration);
export const attentionApi = new AttentionApi(crmConfiguration);
export const performanceApi = new PerformanceApi(crmConfiguration);
export const agentActivityApi = new UserUserActivityApi(crmConfiguration);
export const marketShareApi = new RecruitingMarketShareApi(crmConfiguration);
export const rosterApi = new RosterApi(crmConfiguration);
export const importProspectiveAgentsApi = new ProspectiveAgentsApi(
  crmConfiguration
);
export const prospectiveAgentSavedSearchApi = new ProspectiveAgentSavedSearchApi(
  crmConfiguration
);

export const userClientActivityApi = new UserClientActivityApi(
  crmConfiguration
);
export const leadStatusesByAgentApi = new LeadStatusesByAgentApi(
  crmConfiguration
);
export const agentReportsApi = new AgentReportsApi(crmConfiguration);
export const userTaskApi = new UserTaskApi(crmConfiguration);
export const contactPipelineApi = new ContactPipelineApi(crmConfiguration);
export const contactStatusesApi = new ContactStatusesApi(crmConfiguration);
export const leadClaimTimeApi = new LeadClaimTimeApi(crmConfiguration);
export const leadStatusesBySourceApi = new LeadStatusesBySourceApi(
  crmConfiguration
);
export const leadsClaimedApi = new LeadsClaimedApi(crmConfiguration);
export const leadStatusesApi = new LeadStatusesApi(crmConfiguration);
export const eventApi = new EventApi(crmConfiguration);
export const tagApi = new TagApi(crmConfiguration);
export const emergeMailingListsApi = new EmergeMailingListsApi(
  crmConfiguration
);
export const emergeDripCampaignsApi = new EmergeDripCampaignsApi(
  crmConfiguration
);
export const notificationApi = new NotificationApi(crmConfiguration);
export const combinedCommunicationApi = new CombinedCommunicationApi(
  crmConfiguration
);
export const clientHistoryApi = new ClientHistoryApi(crmConfiguration);
export const callCommunicationApi = new CallCommunicationApi(crmConfiguration);
export const textCommunicationApi = new TextCommunicationApi(crmConfiguration);
export const clientTaskApi = new ClientTaskApi(crmConfiguration);
export const clientNoteApi = new ClientNoteApi(crmConfiguration);
export const docApi = new DocApi(crmConfiguration);
export const campaignsApi = new CampaignApi(crmConfiguration);
export const compositeCampaignApi = new CompositeCampaignApi(crmConfiguration);
export const campaignCompositeTemplateApi = new CampaignCompositeTemplateApi(
  crmConfiguration
);
export const campaignCompositeActionApi = new CampaignCompositeActionApi(
  crmConfiguration
);
export const siteRosterApi = new SiteRosterApi(crmConfiguration);
export const usersApi = new UsersApi(crmConfiguration);
export const mlsSubscriptionApi = new MlsSubscriptionApi(crmConfiguration);
export const mlsOptionsApi = new MlsOptionsApi(crmConfiguration);
export const voipApi = new UserVoipApi(crmConfiguration);
export const clientStopContactApi = new ClientStopContactApi(crmConfiguration);
export const geoCoordinatesApi = new GeoCoordinatesApi(crmConfiguration);
