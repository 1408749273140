import React, { useState, useContext, FC } from 'react';
import Icon, {
  UserOutlined,
  TeamOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import {
  ClientAssignmentOptions,
  ClientAbility,
  ClientAssignment
} from '@elm-street-technology/crm-axios-client';
import { Row, Col, Select, Divider, Modal, PageHeader, Button } from 'antd';
import styled from 'styled-components';
import { OfficeOutline } from 'src/crm/assets/icons/profile';
import { FloatLabel } from 'src/crm/components';
import {
  ClientRecordDrawerStateContext,
  ClientRecordDrawerDispatchContext
} from '../../../ClientRecordDrawer.context';
import {
  updateClientAssignment,
  releaseClientAssignment
} from '../../../actions';
import { ClientRecordSectionTitle } from '../../index';

const AssignedLabel = styled.div`
  span + span {
    margin-left: 4px;
  }
`;

const AssignedUserContainer = styled.div`
  margin-left: 20px;
`;

interface Props {
  clientId: number;
  assignment: ClientAssignment;
  onAssignmentSuccess: (clientAssignment: ClientAssignment) => void;
}

export const AssignedToSection: FC<Props> = ({
  clientId,
  assignment,
  onAssignmentSuccess
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [assignInput, setAssignInput] = useState<any>(undefined);
  const dispatch = useContext(ClientRecordDrawerDispatchContext);
  const {
    abilities: { data: clientAbilities }
  } = useContext(ClientRecordDrawerStateContext);

  const canAssign = clientAbilities.includes(ClientAbility.Transferclient);

  const openAssignModal = assignInput => {
    Modal.confirm({
      title: `Are you sure you want to ${
        assignment?.assignedToUserDisplayName ? 'reassign' : 'assign'
      } this Client to ${assignInput.children}?`,
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        danger: true
      },
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        updateClientAssignment(
          dispatch,
          clientId,
          assignInput.value,
          onAssignmentSuccess
        );
      }
    });
  };

  const openReleaseModal = () => {
    Modal.confirm({
      title: `Are you sure you want to release this agent from the client?`,
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        danger: true
      },
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        releaseClientAssignment(dispatch, clientId, onAssignmentSuccess);
      }
    });
  };

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Assigned To</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      {assignment?.assignedToTeamDisplayName ||
      assignment?.assignedToOfficeDisplayName ? (
        <AssignedLabel>
          {assignment?.assignedToTeamDisplayName ? (
            <TeamOutlined />
          ) : (
            <Icon component={OfficeOutline} />
          )}
          <span>
            {assignment?.assignedToTeamDisplayName ||
              assignment?.assignedToOfficeDisplayName}
          </span>
        </AssignedLabel>
      ) : null}

      {assignment?.assignedToUserDisplayName ? (
        <AssignedUserContainer>
          <Row justify="space-between" align="middle">
            <Col>
              <AssignedLabel>
                <UserOutlined />
                <span>{assignment?.assignedToUserDisplayName}</span>
              </AssignedLabel>
            </Col>

            <Col>
              {assignment?.canRelease ? (
                <Button
                  danger
                  size="large"
                  onClick={e => {
                    openReleaseModal();
                  }}
                >
                  Release Client
                </Button>
              ) : null}
            </Col>
          </Row>
        </AssignedUserContainer>
      ) : null}

      {canAssign && (
        <>
          <div
            style={{
              fontSize: 16,
              fontWeight: 400
            }}
          >
            {assignment?.assignedToUserDisplayName
              ? 'Reassign this Client to:'
              : 'Assign this Client to a User:'}
          </div>
          <Row>
            <Col span={24}>
              <FloatLabel
                name="teamMemberSearch"
                label="Select a team member"
                value={assignInput ? assignInput.value : 0}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option && option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                    return false;
                  }}
                  onSelect={(value, option) => {
                    setAssignInput(option);
                  }}
                >
                  {getAssignOptions(
                    assignment?.assignmentOptions ||
                      assignment?.reassignmentOptions
                  ) || []}
                </Select>
              </FloatLabel>
            </Col>
          </Row>

          <Row justify="end">
            <Col>
              <Button
                size="large"
                disabled={!assignInput}
                type="primary"
                onClick={e => {
                  openAssignModal(assignInput);
                }}
              >
                Update
              </Button>
            </Col>
          </Row>
        </>
      )}
    </PageHeader>
  );
};

const getAssignOptions = (
  optionGroups: Array<ClientAssignmentOptions> | null
) => {
  if (!optionGroups || optionGroups.length < 1) return [];

  const sortedOptionGroups = [...optionGroups].sort(
    ({ optGroupLabel: groupA }, { optGroupLabel: groupB }) => {
      if (groupA > groupB) return 1;
      if (groupA < groupB) return -1;
      return 0;
    }
  );

  return sortedOptionGroups.map(({ optGroupLabel, children }) => (
    <Select.OptGroup label={optGroupLabel} key={optGroupLabel}>
      {children.map(childOption => (
        <Select.Option key={childOption.value} value={childOption.value}>
          {childOption.label}
        </Select.Option>
      ))}
    </Select.OptGroup>
  ));
};
