import * as React from 'react';

function SvgLocation(props) {
  return (
    <svg width="26px" height="35px" viewBox="0 0 26 35" version="1.1">
      <title>Location</title>
      <g
        id="Icons/BuyerSeller/Location-Icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          transform="translate(1.000000, 1.000000)"
          stroke="#082746"
        >
          <ellipse
            id="Oval"
            cx="12"
            cy="12.1956522"
            rx="6.35294118"
            ry="6.45652174"
          />
          <path
            d="M12,33 C12,33 24,18.9311249 24,12.1956522 C24,5.46017946 18.627417,0 12,0 C5.372583,0 0,5.46017946 0,12.1956522 C0,18.9311249 12,33 12,33 Z"
            id="Oval"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLocation;
