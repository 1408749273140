import React from 'react';

const OfficeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <g
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      >
        <path d="M9.591 4.386h1.296" />
        <path d="M8.943 6.345h1.944" />
        <path d="M4.147 3.08v1.306" />
        <path d="M2.79 6.345V4.908a.52.52 0 01.517-.522h1.555a.52.52 0 01.518.522v1.437" />
        <path d="M7 12.876V6.955c0-.337-.27-.61-.605-.61h-4.62a.607.607 0 00-.605.61v5.921" />
        <path d="M3.113 8.958h1.944" />
        <path d="M3.113 10.917h1.944" />
        <path d="M7 4.386V3.57a.98.98 0 01.63-.917l3.887-1.47a.965.965 0 01.895.112.982.982 0 01.418.805v10.811" />
      </g>
    </g>
  </svg>
);

export default OfficeIcon;
