import { Button, Grid } from 'antd';
import React, { FC } from 'react';
import DarkFilterLargeIcon from 'src/crm/assets/icons/DarkFilterLargeIcon';
import DarkFilterSmallIcon from 'src/crm/assets/icons/DarkFilterSmallIcon';

interface Props {
  onClick?: React.MouseEventHandler<HTMLElement>;
  showDot: boolean;
}

export const FilterDotButton: FC<Props> = ({ onClick, showDot, children }) => {
  const { md } = Grid.useBreakpoint();

  return (
    <Button
      type="text"
      icon={
        <span
          style={{
            height: 8,
            width: 8,
            borderRadius: '50%',
            backgroundColor: showDot ? '#4ea5f2' : 'transparent',
            marginRight: 4
          }}
        />
      }
      onClick={onClick}
      size="large"
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div style={{ fontSize: 16, marginRight: 14 }}>{children}</div>
      {md ? <DarkFilterLargeIcon /> : <DarkFilterSmallIcon />}
    </Button>
  );
};
