import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Note } from '@elm-street-technology/crm-axios-client';
import { Row, Avatar, List, Col, Modal } from 'antd';
import { format, parseISO } from 'date-fns';
import React, { useContext, FC } from 'react';
import { NotesDispatchContext } from '../Notes.context';
import { deleteClientNote } from '../actions';

interface Props {
  note: Note;
}

export const NoteCardItem: FC<Props> = ({ note }) => {
  const dispatch = useContext(NotesDispatchContext);

  const handleDeleteClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this note?',
      icon: <ExclamationCircleOutlined />,
      cancelText: 'No',
      okButtonProps: {
        danger: true
      },
      okText: 'Yes - Delete',
      onOk: () => deleteClientNote(dispatch, note.clientId, note.id)
    });
  };

  const handleEditClick = () => dispatch({ type: 'ShowEdit', payload: note });

  const createdDate = format(parseISO(note.createdIsoDateStr), 'Pp');

  return (
    <List.Item
      actions={[
        <EditFilled onClick={handleEditClick} />,
        <DeleteFilled onClick={handleDeleteClick} />
      ]}
    >
      <List.Item.Meta
        title={
          <div
            // Docs: https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-danger.md,
            // According to Docs, if the html is sanitized, then should disable react/no-danger rule.
            // Sanitized on the server-side
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: note.description || '' }}
            style={{ color: '#082746' }}
          />
        }
        description={
          <Row align="middle" gutter={[8, 0]}>
            <Col>
              <Avatar size="small" icon={<UserOutlined />} />
            </Col>
            <Col>{`${note.createdByDisplayName} \u00b7 ${createdDate}`}</Col>
          </Row>
        }
      />
    </List.Item>
  );
};
