import { message } from 'antd';
import { docApi } from 'src/common/services';
import { getDeleteMessageText } from 'src/common/utils';
import { DocsDispatch } from '../DocsTab.reducer';

export const deleteDoc = (
  dispatch: DocsDispatch,
  clientId: number,
  id: number
) => {
  dispatch({ type: 'DeleteDoc' });

  docApi
    .deleteDoc({ clientId, id })
    .then(() => {
      dispatch({ type: 'DeleteDocSuccess', payload: id });
      message.success(getDeleteMessageText('client document').success);
    })
    .catch(error => {
      dispatch({
        type: 'DeleteDocFailure',
        payload: error
      });
      message.error(getDeleteMessageText('client document').error);
    });
};
