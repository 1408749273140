import { message } from 'antd';
import { ClientAssignment } from '@elm-street-technology/crm-axios-client';
import { assignmentApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const releaseClientAssignment = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number,
  onAssignmentSuccess: (clientAssignment: ClientAssignment) => void
) => {
  dispatch({ type: 'UpdateAssignmentPending' });

  assignmentApi
    .updateClientAssignment({ clientId, reassignmentBody: {} })
    .then(({ data }) => {
      message.success(`Successfully released client!`);
      dispatch({ type: 'UpdateAssignmentSuccess', payload: data });
      onAssignmentSuccess(data);
    })
    .catch(error => {
      message.error(`Error releasing client.`);
      dispatch({ type: 'UpdateAssignmentFailure' });
    });
};
