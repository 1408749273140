import React, { FC } from 'react';
import { ClientHistory } from '@elm-street-technology/crm-axios-client';
import { List, PageHeader, Divider } from 'antd';
import { removeHtmlTags } from 'src/common/utils/displaystringUtils';
import { ClientRecordSectionTitle } from '../../index';

interface Props {
  assignmentHistory: ClientHistory[];
}

export const AssignmentHistorySection: FC<Props> = ({ assignmentHistory }) => (
  <PageHeader
    title={
      <ClientRecordSectionTitle>Assignment History</ClientRecordSectionTitle>
    }
    footer={<Divider />}
  >
    <List
      dataSource={assignmentHistory}
      renderItem={historyItem => {
        const { metadata, fromNow } = historyItem;
        return (
          <List.Item>
            <List.Item.Meta
              title={metadata.title && removeHtmlTags(metadata.title)}
              description={fromNow}
              avatar={
                <div
                  style={{
                    backgroundColor: '#ffeee8',
                    height: 35,
                    width: 35,
                    borderRadius: '50%'
                  }}
                />
              }
            />
          </List.Item>
        );
      }}
    />
  </PageHeader>
);
