import { ClientCommunicationText } from '@elm-street-technology/crm-axios-client';
import { Layout, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChatActive from 'src/crm/assets/icons/ChatActive';
import ChatInactive from 'src/crm/assets/icons/ChatInactive';
import { FloatLabel } from 'src/crm/components';
import ChatStyled from './Chat.styled';

const ChatInputContainer = styled('div')`
  position: relative;

  svg {
    position: absolute;
    width: 16px;
    height: 13px;
    right: 15px;
    bottom: 10px;
  }
`;

const ChatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  position: relative;
  background-color: white;
  width: 100%;
`;

type ChatProps = {
  texts: ClientCommunicationText[];
  sendText: (message: string) => void;
};

export const Chat = ({ texts, sendText }: ChatProps) => {
  const textsRef = React.createRef<HTMLDivElement>();
  const [messageText, setMessageText] = useState<string>();

  useEffect(() => {
    // Scroll to the bottom.
    if (!(textsRef && textsRef.current)) {
      return;
    }
    const { current } = textsRef;
    const { scrollHeight, clientHeight } = current;
    const maxScrollTop = scrollHeight - clientHeight;
    textsRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }, [texts, textsRef]);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    setMessageText(value);
  };

  const handleTextAreaPressEnter = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (!e.shiftKey) {
      e.preventDefault();
      if (!e.currentTarget.value) {
        return;
      }
      sendText(e.currentTarget.value);
      setMessageText('');
    }
  };

  const handleSendClick = () => {
    if (messageText) {
      sendText(messageText);
      setMessageText('');
    }
  };

  return (
    <Layout className="flex-vertical overflow-hidden chat">
      <ChatsContainer ref={textsRef}>
        <div className="flex-vertical" />
        <ChatStyled className="flex-vertical">
          {texts.map(({ id, createdAt, direction, message }) => (
            <div
              key={`text-${id}`}
              className={`conversation conversation-${direction}`}
            >
              <p color="inherit">{message}</p>
              <p className={`date date-${direction}`}>
                {moment(createdAt).format('D MMM YYYY - h:mm a')}
              </p>
            </div>
          ))}
        </ChatStyled>
      </ChatsContainer>
      <div className="px-3 py-2" style={{ background: 'white' }}>
        <div
          className="flex-vertical"
          style={{
            display: 'flex',
            alignItems: 'stretch'
          }}
        >
          <ChatInputContainer>
            <FloatLabel
              label="Type a message"
              name="chatMessage"
              value={messageText}
            >
              <Input.TextArea
                value={messageText}
                onChange={handleTextAreaChange}
                onPressEnter={handleTextAreaPressEnter}
                autoSize
                style={{
                  height: 40,
                  borderRadius: 3,
                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03)',
                  backgroundColor: 'white',
                  fontSize: 13,
                  lineHeight: 1.54,
                  color: 'black'
                }}
              />
            </FloatLabel>
            {messageText ? (
              <div onClick={handleSendClick}>
                <ChatActive />
              </div>
            ) : (
              <div>
                <ChatInactive />
              </div>
            )}
          </ChatInputContainer>
        </div>
      </div>
    </Layout>
  );
};
