import { useMatch } from 'react-router-dom';

export const useClientRecordProfileTabMatch = () => {
  const profileMatch = useMatch('/clients/:clientId/profile');
  const clientAssignmentMatch = useMatch('/clients/:clientId/assignment');
  const leadAssignmentMatch = useMatch('/leads/:clientId/assignment');
  const contributorMatch = useMatch('/clients/:clientId/contributors');
  const siteInviteMatch = useMatch('/leads/:clientId/site-invite');

  return (
    profileMatch ||
    clientAssignmentMatch ||
    leadAssignmentMatch ||
    contributorMatch ||
    siteInviteMatch
  );
};

export const useClientRecordActivityTabMatch = () => {
  const activityMatch = useMatch('/clients/:clientId/activity');
  const savedSearchesMatch = useMatch('/clients/:clientId/saved-searches');
  const clientRecordMatch = useMatch('/clients/:clientId');

  return activityMatch || savedSearchesMatch || clientRecordMatch;
};

export const useClientRecordTasksTabMatch = () => {
  const clientTaskMatch = useMatch('/clients/:clientId/tasks/:taskId');
  const leadTaskMatch = useMatch('/leads/:clientId/tasks/:taskId');
  const clientTasksMatch = useMatch('/clients/:clientId/tasks');
  const leadTasksMatch = useMatch('/leads/:clientId/tasks');

  return clientTaskMatch || leadTaskMatch || clientTasksMatch || leadTasksMatch;
};

export const useClientRecordDocsTabMatch = () => {
  const clientNewDocMatch = useMatch('/clients/:clientId/documents/new');
  const leadNewDocMatch = useMatch('/leads/:clientId/documents/new');

  return clientNewDocMatch || leadNewDocMatch;
};

export const useClientRecordNotesTabMatch = () => {
  const clientNewNoteMatch = useMatch('/clients/:clientId/notes/new');
  const leadNewNoteMatch = useMatch('/leads/:clientId/notes/new');
  const leadEditNoteMatch = useMatch('/leads/:clientId/notes/:noteId/edit');

  return clientNewNoteMatch || leadNewNoteMatch || leadEditNoteMatch;
};

export const useNewClientMatch = () => {
  const clientsNew = useMatch('/clients/new');
  const leadsNew = useMatch('/leads/new');
  const contactsNew = useMatch('/contacts/new');

  return clientsNew || leadsNew || contactsNew;
};

export const useEditClientMatch = () => {
  const clientsEdit = useMatch('/clients/:clientId/edit');
  const leadsEdit = useMatch('/leads/:clientId/edit');
  const contactsEdit = useMatch('/contacts/:clientId/edit');

  return clientsEdit || leadsEdit || contactsEdit;
};
