import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { MenuItem } from '@elm-street-technology/crm-axios-client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { Tooltip } from 'antd';
import AdminIcon from 'src/crm/assets/nav/AdminIcon';
import AgentSettings from 'src/crm/assets/nav/AgentSettings';
import SvgAssets from 'src/crm/assets/nav/assets';
import CampaignIcon from 'src/crm/assets/nav/CampaignIcon';
import SvgCMA from 'src/crm/assets/nav/cma';
import SvgContacts from 'src/crm/assets/nav/contacts';
import SvgDashboard from 'src/crm/assets/nav/Dashboard';
import SvgHelp from 'src/crm/assets/nav/help';
import IntegrationIcon from 'src/crm/assets/nav/IntegrationsIcon';
import SvgMLSSearch from 'src/crm/assets/nav/mlsSearch';
import SvgMySite from 'src/crm/assets/nav/mySite';
import MyTeamIcon from 'src/crm/assets/nav/MyTeamIcon';
import OfficeIcon from 'src/crm/assets/nav/OfficeIcon';
import RecruitingIcon from 'src/crm/assets/nav/RecruitingIcon';
import { makeUrl } from 'src/common/utils';
import { CRMMenu } from './Nav.styled';

export interface ItemProps {
  key: string;
  label: string;
  icon?: IconComponentProps['component'];
  local?: boolean;
  formPost: boolean | null;
  href: string;
  classes?: string;
  target: string | null;
  inputs?: MenuItem['inputs'];
  navigate: ReturnType<typeof useNavigate>;
  hasTooltip?: boolean;
  upgrade?: boolean | null;
}

const makeInternalItem = (
  { href, label, icon, navigate, hasTooltip, upgrade }: ItemProps,
  collapsed: boolean,
  hasChildren = true
) => (
  <CRMMenu.Item
    key={label}
    onClick={() => navigate(href)}
    className={`${hasChildren ? '' : 'no-children'}`}
  >
    {icon ? <Icon component={icon} /> : null}
    {hasTooltip && !collapsed ? (
      <Tooltip title={label} placement="top">
        <span style={{ color: upgrade ? '#888f96' : '#111830' }}>{label}</span>
      </Tooltip>
    ) : (
      <span style={{ color: upgrade ? '#888f96' : '#111830' }}>{label}</span>
    )}
  </CRMMenu.Item>
);

const makeExternalItem = (
  item: ItemProps,
  collapsed: boolean,
  hasChildren = true,
  newTab = false
) => {
  if (item.formPost) {
    return (
      <CRMMenu.Item
        key={item.label}
        className={`${hasChildren ? '' : 'no-children'}`}
      >
        <form
          method="POST"
          action={item.href}
          target={item.target || undefined}
        >
          {item.inputs?.map(({ type, name, value }) => (
            <input
              key={`${name}-${type}-${value}`}
              type={type || undefined}
              name={name || undefined}
              value={value || undefined}
            />
          ))}
          <span>
            <button
              type="submit"
              style={{
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                color: item.upgrade ? '#888f96' : '#111830'
              }}
            >
              {item.label}
            </button>
          </span>
        </form>
      </CRMMenu.Item>
    );
  }

  const handleClick = () => {
    if (newTab) {
      window.open(item.href, '_blank');
    } else {
      window.location.href = makeUrl(item.href);
    }
  };

  return (
    <CRMMenu.Item key={item.label} onClick={handleClick}>
      {item.icon ? <Icon component={item.icon} /> : null}
      {item.hasTooltip && !collapsed ? (
        <Tooltip title={item.label} placement="top">
          <span style={{ color: item.upgrade ? '#888f96' : '#111830' }}>
            {item.label}
          </span>
        </Tooltip>
      ) : (
        <span style={{ color: item.upgrade ? '#888f96' : '#111830' }}>
          {item.label}
        </span>
      )}
    </CRMMenu.Item>
  );
};

export interface SubMenuProps {
  key: string;
  items: ItemProps[];
  icon?: IconComponentProps['component'];
  label: string;
  href?: string;
  classes?: string;
  local?: boolean;
  navigate: ReturnType<typeof useNavigate>;
  hasTooltip?: boolean;
  upgrade?: boolean | null;
}

const makeSubMenu = (menuItem: SubMenuProps, collapsed: boolean) => {
  const {
    key,
    label,
    icon,
    items,
    local,
    href,
    hasTooltip,
    navigate
  } = menuItem;
  const itemChildren = items.map(item =>
    item.local
      ? makeInternalItem(item, collapsed)
      : makeExternalItem(item, collapsed, true, item.target === '_blank')
  );

  const handleTitleClick = () => {
    if (href) {
      if (!local) {
        window.location.replace(makeUrl(href));
      } else {
        navigate(href);
      }
    }
  };

  return (
    <CRMMenu.SubMenu
      key={key}
      icon={icon ? <Icon component={icon} /> : null}
      title={
        hasTooltip && !collapsed ? (
          <Tooltip title={label} placement="top">
            <span className="submenu-header">{label}</span>
          </Tooltip>
        ) : (
          <span className="submenu-header">{label}</span>
        )
      }
      onTitleClick={handleTitleClick}
    >
      {itemChildren}
    </CRMMenu.SubMenu>
  );
};

export const isSubMenuProps = (
  candidate: SubMenuProps | ItemProps
): candidate is SubMenuProps =>
  !!(
    (candidate as SubMenuProps).key && (candidate as SubMenuProps).items?.length
  );

const makeMenuItemsJSX = (
  data: Array<SubMenuProps | ItemProps>,
  collapsed: boolean
) =>
  data.map(item =>
    isSubMenuProps(item)
      ? makeSubMenu(item, collapsed)
      : makeMenuItem(item, collapsed)
  );

const makeMenuItem = (item: ItemProps, collapsed: boolean) =>
  item.local
    ? makeInternalItem(item, collapsed, false)
    : makeExternalItem(item, collapsed, false);

export const makeMenuItems = (
  data: Array<SubMenuProps | ItemProps>,
  collapsed: boolean
) => {
  const startIndex = data.findIndex(i =>
    i.classes?.includes('ref__mainNavOfficeName')
  );

  if (startIndex > -1) {
    let officeItems = data.filter(i =>
      i.classes?.includes('ref__mainNavOfficeName')
    );
    const temp = [...data];
    officeItems = temp.splice(startIndex, officeItems.length);
    const officeJSX = makeOfficeMenu(officeItems as SubMenuProps[], collapsed);
    const jsx = makeMenuItemsJSX(temp, collapsed);
    jsx.splice(startIndex, 0, officeJSX);
    return jsx;
  }
  const jsx = makeMenuItemsJSX(data, collapsed);
  return jsx;
};

export const MAP_ICON = new Map<string, ItemProps['icon']>([
  ['dashboard', SvgDashboard],
  ['users', () => null],
  ['clients', SvgContacts],
  ['assets', SvgAssets],
  ['agent-settings', () => <AgentSettings />],
  ['campaigns', CampaignIcon],
  ['admin', AdminIcon],
  ['mls-search', SvgMLSSearch],
  ['help', SvgHelp],
  ['integrations', IntegrationIcon],
  ['cmas', SvgCMA],
  ['my-site', SvgMySite],
  ['recruiting', RecruitingIcon],
  ['my-team', MyTeamIcon]
]);

export const LOCALS: string[] = [
  'dashboard',
  'clients',
  'clients-contacts',
  'clients-leads',
  'clients-create-client',
  'mls-search',
  'mls-search-listings',
  'mls-search-my-listings',
  'mls-search-my-company-listings',
  'mls-search-my-team-listings',
  'site-roster',
  'recruiting',
  'recruiting-recruiting-search',
  'campaigns-sms-campaigns'
];

const makeOfficeMenu = (officeSubs: SubMenuProps[], collapsed: boolean) => (
  <CRMMenu.SubMenu
    className="offices"
    key="offices"
    title={
      <>
        <Icon component={OfficeIcon} />
        <span>Offices</span>
      </>
    }
  >
    {officeSubs.map(office => makeSubMenu(office, collapsed))}
  </CRMMenu.SubMenu>
);

export const makeMenuData = (
  navigate: ReturnType<typeof useNavigate>,
  items: MenuItem[],
  prefix?: string
): Array<SubMenuProps | ItemProps> =>
  items?.map(item => {
    const siteRosterItem = 'site-roster';
    const key = prefix ? slug(`${prefix}-${item.label}`) : slug(item.label);

    const isLocalPath =
      LOCALS.includes(key) || slug(item.label).includes(siteRosterItem);

    item.label = item.upgrade ? `${item.label} - Upgrade` : item.label;

    return {
      ...item,
      items: makeMenuData(navigate, item.children, key),
      key,
      classes: item.classes || '',
      icon: MAP_ICON.get(key),
      local: isLocalPath,
      navigate
    };
  });

export const slug = (string: string) =>
  slugify(string, {
    replacement: '-',
    remove: undefined,
    lower: true,
    strict: false,
    locale: 'vi'
  });
