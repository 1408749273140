import { ClientContributor } from '@elm-street-technology/crm-axios-client';
import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export interface ClientContributorsState {
  data: ClientContributor[];
  loading: boolean;
  updating: boolean;
  saveComplete: boolean;
}

export type ClientContributorsActions =
  | { type: 'GetContributorsPending' }
  | { type: 'GetContributorsSuccess'; payload: ClientContributor[] }
  | { type: 'GetContributorsFailure' }
  //
  | { type: 'CreateContributorPending' }
  | { type: 'CreateContributorSuccess'; payload: ClientContributor }
  | { type: 'CreateContributorFailure' }
  //
  | { type: 'DeleteContributorPending' }
  | { type: 'DeleteContributorSuccess'; payload: number }
  | { type: 'DeleteContributorFailure' };

export const initialClientContributorsState: ClientContributorsState = {
  data: [],
  loading: false,
  updating: false,
  saveComplete: false
};

export const clientContributorsReducer: Reducer<
  ClientContributorsState,
  ClientRecordDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'GetContributorsPending':
      return {
        ...state,
        loading: true
      };
    case 'GetContributorsSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetContributorsFailure':
      return {
        ...state,
        loading: false
      };
    //
    case 'CreateContributorPending':
      return {
        ...state,
        updating: true,
        saveComplete: false
      };
    case 'CreateContributorSuccess':
      return {
        ...state,
        updating: false,
        saveComplete: true,
        data: [...state.data, action.payload]
      };
    case 'CreateContributorFailure':
      return {
        ...state,
        updating: false
      };
    //
    case 'DeleteContributorPending':
      return {
        ...state,
        loading: true
      };
    case 'DeleteContributorSuccess':
      return {
        ...state,
        loading: false,
        data: state.data.filter(({ userId }) => userId !== action.payload)
      };
    case 'DeleteContributorFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
