import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Down</title>
    <g
      id="Component-Assets"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="ASSETS" transform="translate(-486.000000, -1535.000000)">
        <g id="Down" transform="translate(486.000000, 1535.000000)">
          <rect
            id="Rectangle"
            fill="#FF3C41"
            x="0"
            y="0"
            width="10"
            height="10"
            rx="2"
          />
          <path
            d="M5.00304618,0.00146721831 C5.20080672,0.00853206155 5.38921626,0.0907523832 5.52270343,0.242995631 C5.52634029,0.247143813 5.5299166,0.251321325 5.53343245,0.255527182 L5.62876636,0.331335199 C5.66072749,0.350702742 5.68804034,0.372389018 5.70974895,0.395873273 L8.68011299,2.78271404 C8.98090394,3.02394264 9.02007925,3.45320615 8.76772765,3.74085733 C8.51544168,4.02867733 8.06700727,4.06635934 7.76627258,3.82490565 L5.767,2.219 L5.76717871,6.87801255 C5.76717871,7.04066248 4.16688907,7.04066248 4.16688907,6.87801255 L4.166,2.22 L2.16785146,3.82490565 C1.89719023,4.04221397 1.50689224,4.03342238 1.24758177,3.8196702 L1.16634013,3.74085733 C0.913988533,3.45320615 0.953163838,3.02394264 1.25395479,2.78271404 L1.25395479,2.78271404 L4.22431882,0.395873273 C4.24602744,0.372389018 4.27334029,0.350702742 4.30530142,0.331335199 L4.40063297,0.255527182 C4.40414958,0.251321325 4.40772668,0.247143813 4.41136435,0.242995631 C4.54481679,0.0907523832 4.73320998,0.00853206155 4.92560052,0.000629580294 C4.92760505,0.00168461728 4.92928959,0.00157305068 4.93097434,0.00146721831 L5.00304618,0.00146721831 L5.00304618,0.00146721831 Z"
            id="Combined-shape-2247"
            fill="#FFFFFF"
            transform="translate(4.967034, 3.500000) scale(1, -1) translate(-4.967034, -3.500000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export const VolDecreaseArrowIcon = props => (
  <Icon component={Svg} {...props} />
);
