import React, { FC } from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import Call from 'src/crm/assets/icons/call';

export interface EkataQuickActionButtonProps {
  onClick: () => void;
}

export const EkataQuickActionButton: FC<EkataQuickActionButtonProps> = ({
  onClick
}) => (
  <Row justify="center" gutter={4} style={{ textAlign: 'center' }}>
    <Col span={24}>
      <Button
        style={{
          backgroundColor: '#db7945',
          borderColor: '#db7945'
        }}
        icon={<Icon component={Call} />}
        onClick={onClick}
        name="ekata-client-action"
        shape="circle"
        size="large"
      />
      <Tooltip
        placement="topLeft"
        title="Phone number acquired via public records. Accuracy not guaranteed."
      >
        <InfoCircleOutlined
          style={{ position: 'absolute', color: '#f5222d' }}
        />
      </Tooltip>
    </Col>
    <Col span={24} style={{ fontSize: 14, color: 'rgb(24, 144, 255)' }}>
      Call
    </Col>
  </Row>
);
