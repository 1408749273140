import React, { FC, useMemo } from 'react';
import {
  ClientStatusEnum,
  Client
} from '@elm-street-technology/crm-axios-client';
import { Button, PageHeader, Row, Col, Divider } from 'antd';
import { ArchiveGraphic } from 'src/crm/assets/icons/profile';
import { ClientRecordSectionTitle } from '../../index';

interface Props {
  client: Client;
  updating: boolean;
  onUnarchive: (client: Client) => void;
  onArchive: (client: Client) => void;
}

export const ArchiveSection: FC<Props> = ({
  client,
  updating,
  onUnarchive,
  onArchive
}) => {
  const isArchived = useMemo(
    () => client.status === ClientStatusEnum.Archived,
    [client.status]
  );

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Archive</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      <Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
        <Col span={24}>
          <ArchiveGraphic />
        </Col>
        <Col
          span={24}
          style={{ fontSize: 16, fontWeight: 400, color: '#082746' }}
        >
          {isArchived
            ? 'Want to see this lead again?'
            : "Don't want to see this lead anymore?"}
        </Col>
        <Col span={24}>
          <Button
            onClick={() =>
              isArchived ? onUnarchive(client) : onArchive(client)
            }
            loading={updating}
            disabled={updating}
            size="large"
            danger={!isArchived}
          >
            {isArchived ? 'Unarchive' : 'Archive'}
          </Button>
        </Col>
      </Row>
    </PageHeader>
  );
};
