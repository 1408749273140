import React, { FC, useState, useEffect } from 'react';
import {
  Input,
  Select,
  Form,
  Row,
  Col,
  Checkbox,
  Radio,
  FormInstance
} from 'antd';
import {
  Client,
  ClientTypeEnum
} from '@elm-street-technology/crm-axios-client';
import { includes } from 'lodash';
import { ClientFormSection } from './index';
import { typeOptions } from '../constants';

interface Props {
  client?: Client;
  form: FormInstance;
}

export const ContactInformationSection: FC<Props> = ({ client, form }) => {
  const [isContact, setIsContact] = useState(client?.isContact || false);

  useEffect(() => {
    if (isContact) {
      form.setFields([
        {
          name: 'source',
          value: '',
          validating: false,
          errors: []
        }
      ]);
    }
  }, [isContact]);

  const primaryPhone = client?.phoneNumbers.find(({ isPrimary }) => isPrimary);

  const secondaryPhone = client?.phoneNumbers.find(
    ({ isPrimary }) => !isPrimary
  );

  const isSourceNotEditable =
    isContact || includes(['Consumer Site', 'PPC'], client?.source);
  return (
    <ClientFormSection title="Contact Information">
      <Form.Item
        label="First Name (Required)"
        name="firstName"
        rules={[{ required: true, message: 'First name is required' }]}
        initialValue={client?.firstName}
      >
        <Input size="large" autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        label="Last Name (Required)"
        name="lastName"
        rules={[{ required: true, message: 'Last name is required' }]}
        initialValue={client?.lastName}
      >
        <Input size="large" autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        hidden
        name={['primaryEmailAddress', 'id']}
        initialValue={client?.emailAddresses?.[0]?.id}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={['primaryEmailAddress', 'isVerified']}
        initialValue={client?.emailAddresses?.[0]?.isVerified}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={['primaryEmailAddress', 'isBlacklisted']}
        initialValue={client?.emailAddresses?.[0]?.isBlacklisted}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name={['primaryEmailAddress', 'email']}
        initialValue={client?.emailAddresses?.[0]?.email}
        rules={[
          {
            type: 'email',
            message: 'Primary email is not valid'
          }
        ]}
      >
        <Input size="large" autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        hidden
        name={['secondaryEmailAddress', 'id']}
        initialValue={client?.emailAddresses?.[1]?.id}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={['secondaryEmailAddress', 'isVerified']}
        initialValue={client?.emailAddresses?.[1]?.isVerified}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={['secondaryEmailAddress', 'isBlacklisted']}
        initialValue={client?.emailAddresses?.[1]?.isBlacklisted}
      >
        <Input />
      </Form.Item>
      <Row gutter={[8, 0]}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Secondary Email"
            name={['secondaryEmailAddress', 'email']}
            rules={[
              {
                type: 'email',
                message: 'Secondary email is not valid'
              }
            ]}
            initialValue={client?.emailAddresses?.[1]?.email}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['secondaryEmailAddress', 'cc']}
            valuePropName="checked"
            noStyle
            initialValue={client?.emailAddresses?.[1]?.cc || false}
          >
            <Checkbox>CC this Email on Client Morning Reports</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {/* Primary Phone Row */}
      <Form.Item
        hidden
        name={['primaryPhoneNumber', 'id']}
        noStyle
        initialValue={primaryPhone?.id}
      >
        <Input />
      </Form.Item>
      <Row gutter={[8, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Phone Number"
            name={['primaryPhoneNumber', 'phone']}
            initialValue={primaryPhone?.phone}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={3}>
          <Form.Item
            label="Ext"
            name={['primaryPhoneNumber', 'extension']}
            initialValue={primaryPhone?.extension ?? undefined}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={5}>
          <Form.Item
            label="Phone Type"
            name={['primaryPhoneNumber', 'label']}
            initialValue={primaryPhone?.label}
            rules={[
              form => {
                const phone = form.getFieldValue([
                  'primaryPhoneNumber',
                  'phone'
                ]);

                return {
                  required: Boolean(phone),
                  message: 'Phone type required'
                };
              }
            ]}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item>
            <div>
              <Form.Item
                name={['primaryPhoneNumber', 'isPrimary']}
                valuePropName="checked"
                noStyle
                initialValue
              >
                <Checkbox checked disabled>
                  Primary Number
                </Checkbox>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name={['primaryPhoneNumber', 'canReceiveTextMessages']}
                valuePropName="checked"
                noStyle
                initialValue={primaryPhone?.canReceiveTextMessages || false}
              >
                <Checkbox>Can Receive Texts</Checkbox>
              </Form.Item>
            </div>
          </Form.Item>
        </Col>
      </Row>
      {/* Secondary Phone Row */}
      <Form.Item
        hidden
        name={['secondaryPhoneNumber', 'id']}
        noStyle
        initialValue={secondaryPhone?.id}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={['secondaryPhoneNumber', 'isPrimary']}
        initialValue={false}
        noStyle
      >
        <Input />
      </Form.Item>
      <Row gutter={[8, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Secondary Phone Number"
            name={['secondaryPhoneNumber', 'phone']}
            initialValue={secondaryPhone?.phone}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={3}>
          <Form.Item
            label="Ext"
            name={['secondaryPhoneNumber', 'extension']}
            initialValue={secondaryPhone?.extension ?? undefined}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={5}>
          <Form.Item
            label="Phone Type"
            name={['secondaryPhoneNumber', 'label']}
            initialValue={secondaryPhone?.label}
            rules={[
              form => {
                const phone = form.getFieldValue([
                  'secondaryPhoneNumber',
                  'phone'
                ]);

                return {
                  required: Boolean(phone),
                  message: 'Phone type required'
                };
              }
            ]}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item
            name={['secondaryPhoneNumber', 'canReceiveTextMessages']}
            valuePropName="checked"
            initialValue={secondaryPhone?.canReceiveTextMessages || false}
          >
            <Checkbox>Can Receive Texts</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Client Type"
        name="type"
        initialValue={getClientTypeInitialValue(client?.type)}
      >
        <Select
          size="large"
          options={typeOptions}
          getPopupContainer={trigger => trigger.parentNode}
        />
      </Form.Item>
      {!(client?.source === 'PPC' || client?.source === 'Consumer Site') && (
        <Form.Item label="Is this a Lead or a Contact?">
          <Radio.Group
            value={isContact}
            onChange={e => {
              const { value } = e.target;
              setIsContact(value);
            }}
            size="large"
            options={[
              {
                label: 'Lead',
                value: false
              },
              {
                label: 'Contact',
                value: true
              }
            ]}
            optionType="button"
          />
        </Form.Item>
      )}

      <Form.Item
        label="Source"
        name="source"
        initialValue={client?.source}
        rules={[
          {
            required: !isContact,
            message: 'Source is required if client is a lead.'
          }
        ]}
      >
        <Input
          size="large"
          autoComplete="new-password"
          disabled={isSourceNotEditable}
        />
      </Form.Item>
      <Form.Item
        label="Preferred method of contact"
        name="preferredContactMethod"
        initialValue={client?.preferredContactMethod ?? undefined}
      >
        <Radio.Group
          size="large"
          optionType="button"
          options={[
            {
              label: 'Email',
              value: 'email'
            },
            {
              label: 'Text',
              value: 'text'
            },
            {
              label: 'Call',
              value: 'call'
            }
          ]}
        />
      </Form.Item>
    </ClientFormSection>
  );
};

const getClientTypeInitialValue = (type): ClientTypeEnum => {
  switch (type) {
    case 'buyer':
      return ClientTypeEnum.Buyer;
    case 'seller':
      return ClientTypeEnum.Seller;
    case 'buyer/seller':
      return ClientTypeEnum.BuyerSeller;
    case 'vendor':
      return ClientTypeEnum.Vendor;
    case 'family':
      return ClientTypeEnum.Family;
    case 'friend':
      return ClientTypeEnum.Friend;
    case 'personal':
      return ClientTypeEnum.Personal;
    case 'past_client':
      return ClientTypeEnum.PastClient;
    case 'crm_agent':
      return ClientTypeEnum.PastClient;
    case 'prospective_agent':
      return ClientTypeEnum.ProspectiveAgent;
    case 'renter':
      return ClientTypeEnum.Renter;
    default:
      return ClientTypeEnum.None;
  }
};
