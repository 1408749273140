import { message } from 'antd';
import { textCommunicationApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils';
import { QuickMessageDispatch } from './QuickMessage.reducer';

export const getPreviousTexts = (
  dispatch: QuickMessageDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetTexts' });

  textCommunicationApi
    .listClientCommunicationTexts({ clientId })
    .then(({ data }) => dispatch({ type: 'GetTextsSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'GetTextsFailure',
        payload: error
      });
      message.error(getFetchMessageText('messages').error);
    });
};

export const sendText = (
  dispatch: QuickMessageDispatch,
  clientId: number,
  createdMessage: string
) => {
  dispatch({ type: 'SendText' });

  textCommunicationApi
    .createClientCommunicationText({
      clientId,
      clientCommunicationTextCreateBody: {
        message: createdMessage
      }
    })
    .then(() => {
      dispatch({ type: 'SendTextSuccess' });
    })
    .catch(error => {
      dispatch({
        type: 'SendTextFailure',
        payload: error
      });
      message.error('error sending message');
    });
};
