import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="23"
      viewBox="0 0 17 23"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#082746" stroke="#082746">
          <path d="M8.5 23S17 13.194 17 8.5a8.5 8.5 0 10-17 0C0 13.194 8.5 23 8.5 23z" />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
