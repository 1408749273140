import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import AiSummaryProfile from 'src/crm/assets/icons/ai-summary-profile';

interface Props {
  client: Client;
}

export const Summary: FC<Props> = ({ client }) => {
  return (
    <div
      style={{
        borderRadius: '2px',
        border: '1px solid var(--daybreak-blue-3, #91D5FF)',
        background: 'var(--daybreak-blue-1, #E6F7FF)',
        margin: '0 24px',
        padding: '9px 16px'
      }}
    >
      <Row gutter={[8, 8]}>
        <Col span={24} style={{ fontSize: 14 }}>
          <div>
            <AiSummaryProfile />{' '}
            <span
              style={{
                borderRadius: '2px',
                border: '1px solid #91D5FF',
                background: 'linear-gradient(180deg, #E6F7FF 0%, #E6FFFB 100%)',
                padding: '1px 8px',
                fontSize: 12,
                lineHeight: '20px'
              }}
            >
              <span
                style={{
                  background:
                    'linear-gradient(180deg, #1890FF 0%, #13C2C2 100%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                AI Summary
              </span>
            </span>
          </div>
          <div style={{ paddingLeft: '28px' }}>{client.leadProfileSummary}</div>
        </Col>
      </Row>
    </div>
  );
};
