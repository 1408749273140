import React from 'react';

export const ListingsTabMobileIconSvg = () => (
  <svg
    width="23px"
    height="26px"
    viewBox="0 0 23 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Listing / Overview</title>
    <g
      id="CRM-Listing-Responsive"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Listing-V2---Overview"
        transform="translate(-38.000000, -69.000000)"
        stroke="#082746"
      >
        <g id="Listing-/-Overview" transform="translate(38.000000, 69.000000)">
          <g id="Group">
            <line
              x1="0.769230769"
              y1="7.2"
              x2="11.5384615"
              y2="0.8"
              id="Line-3"
            />
            <line x1="11.2307692" y1="0.8" x2="22" y2="7.2" id="Line-4" />
          </g>
          <g
            id="Icons/Side-Menu/Dashboard-Icon"
            transform="translate(3.000000, 9.000000)"
          >
            <path
              d="M0,2 L0,4 C1.3527075e-16,5.1045695 0.8954305,6 2,6 L5.33333333,6 C6.43790283,6 7.33333333,5.1045695 7.33333333,4 L7.33333333,2 C7.33333333,0.8954305 6.43790283,-2.02906125e-16 5.33333333,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
              id="Path"
            />
            <path
              d="M8.66666667,2 L8.66666667,6 C8.66666667,7.1045695 9.56209717,8 10.6666667,8 L14,8 C15.1045695,8 16,7.1045695 16,6 L16,2 C16,0.8954305 15.1045695,-2.02906125e-16 14,0 L10.6666667,0 C9.56209717,2.02906125e-16 8.66666667,0.8954305 8.66666667,2 Z"
              id="Path"
              fill="#FFFFFF"
            />
            <path
              d="M8.66666667,11.3333333 L8.66666667,14 C8.66666667,15.1045695 9.56209717,16 10.6666667,16 L14,16 C15.1045695,16 16,15.1045695 16,14 L16,11.3333333 C16,10.2287638 15.1045695,9.33333333 14,9.33333333 L10.6666667,9.33333333 C9.56209717,9.33333333 8.66666667,10.2287638 8.66666667,11.3333333 Z"
              id="Path"
            />
            <path
              d="M0,9.33333333 L0,13.3333333 C-3.55256646e-16,14.4379028 0.8954305,15.3333333 2,15.3333333 L5.33333333,15.3333333 C6.43790283,15.3333333 7.33333333,14.4379028 7.33333333,13.3333333 L7.33333333,9.33333333 C7.33333333,8.22876383 6.43790283,7.33333333 5.33333333,7.33333333 L2,7.33333333 C0.8954305,7.33333333 -1.3527075e-16,8.22876383 0,9.33333333 Z"
              id="Path"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
