import React, { FC, CSSProperties } from 'react';

const style: CSSProperties = {
  color: '#082746',
  fontSize: 21,
  fontWeight: 'normal'
};

export const ClientRecordSectionTitle: FC = ({ children }) => (
  <span style={style}>{children}</span>
);
