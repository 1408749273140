import { ClientContributorFromOrganizationCreateBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { getCreateMessageText } from 'src/common/utils';
import { organizationMembersApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const createOrgClientContributor = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number,
  clientContributorFromOrganizationCreateBody: ClientContributorFromOrganizationCreateBody
) => {
  dispatch({ type: 'CreateContributorPending' });

  organizationMembersApi
    .createClientContributorFromOrganizationMember({
      clientId,
      clientContributorFromOrganizationCreateBody
    })
    .then(({ data }) => {
      message.success(getCreateMessageText('contributor').success);
      dispatch({
        type: 'CreateContributorSuccess',
        payload: data
      });
    })
    .catch(error => {
      message.error(getCreateMessageText('contributor').error);
      dispatch({
        type: 'CreateContributorFailure'
      });
    });
};
