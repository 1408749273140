import styled from 'styled-components';
import { Col } from 'antd';
import { Hint, HorizontalGridLines } from 'react-vis';

export const GRAPH_COLORS = {
  new: '#6DBEFC',
  pending: '#FCD66D',
  active: '#7FE4CD'
};

const POPOVER_BACKGROUND = '#082746';

export const HorizontalGridLinesStyled = styled(HorizontalGridLines)`
  line {
    stroke: #c2c9d1;
    stroke-width: 0.6;
    opacity: 0.75;
  }
`;

export const HintStyled = styled(Hint)`
  z-index: 1000;
  transform: translate(0, -20px);
`;

export const HintContent = styled.div`
  position: relative;
  left: 25px;

  min-width: 175px;
  border-radius: 5px;
  padding: 8px;
  padding-left: 18px;
  padding-right: 18px;
  background-color: ${POPOVER_BACKGROUND};
  color: #ffffff;
  font-size: 11px;

  h3 {
    font-size: 24px;
    color: #ffffff;
  }
`;

export const HintTriangle = styled.div`
  position: relative;
  top: -5px;
  left: 25px;

  width: 0;
  height: 0;
  border-top: 30px solid ${POPOVER_BACKGROUND};
  border-right: 30px solid transparent;
`;

export const ColorCircle = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ theme }) => GRAPH_COLORS[theme.status]};
`;

export const PopoverTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 32px solid ${POPOVER_BACKGROUND};
  border-right: 32px solid transparent;
`;

export const LabelCol = styled(Col)`
  font-size: 10px;
  color: #485465;
  width: 40px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    overflow: visible;
    white-space: normal;
  }
`;
