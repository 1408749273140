import React, { FC } from 'react';
import {
  ClientSellerFieldsListingTypeEnum,
  ClientSellerFields
} from '@elm-street-technology/crm-axios-client';
import { PageHeader, Divider } from 'antd';
import styled from 'styled-components';
import { numberToThousands } from 'src/common/utils';
import {
  Bath as BathIcon,
  Bed as BedIcon,
  BusinessOp as BusinessOpIcon,
  Commercial as CommecialIcon,
  Condo as CondoIcon,
  House as HouseIcon,
  Land as LandIcon,
  LivingArea as LivingAreaIcon,
  Location as LocationIcon,
  LotSize as LotSizeIcon,
  Mobile as MobileIcon,
  MultiFamilyHouse as MultiFamilyHouseIcon,
  Price as PriceIcon,
  ResidIncome as ResidIncomIcon,
  TownHouse as TownHouseIcon,
  YearBuilt as YearBuiltIcon
} from 'src/crm/assets/icons/buy_sell_prefs';
import { ClientRecordSectionTitle } from '../../index';

const Container = styled('div')`
  font-family: Roboto;
  color: #082746;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: -1 0px 7px 0px rgba(0, 0, 0, 0.126);

  svg {
    margin-bottom: 5px;
  }

  .looking-for-container,
  .sell-type-container {
    background-color: #f6f7f8;
    border-radius: 4px;
    border: 1px solid rgb(246, 247, 248);
    display: flex;

    .sell-type {
      flex: 1;
      display: flex;
      padding-left: unset;
      justify-content: center;
      align-items: center;
      margin-top: 39px;
      margin-bottom: 41px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    > .buy-types {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-left: 41px;
      flex: 1;
      > div {
        :only-child {
          margin-left: -188px;
        }
        padding: 10px 0;
        flex: 0 0 25%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > span {
      font-size: 15px;
      font-weight: 500;
      padding-left: 72px;
      margin-top: 39px;
      margin-bottom: 41px;
    }
  }

  .prefs {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .pref {
    }
  }
`;

const Preference = styled.div`
  padding: 37px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.333333%;
  min-height: 100px;
  min-width: 100px;
  &:not(:nth-child(3n + 3))::after {
    position: absolute;
    right: 0;
    height: 54px;
    width: 1px;
    border: 1px solid rgb(223, 223, 223);
    content: '';
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const sellingPropertyDisplayOrder = [
  'location',
  'numBeds',
  'numBaths',
  'livingAreaFormatted',
  'lotSizeAreaFormatted',
  'yearBuilt',
  'estimatedListPrice'
];

interface Props {
  sellerFields: ClientSellerFields;
}

export const SellingSection: FC<Props> = ({ sellerFields }) => {
  const sellPrefs = sellingPropertyDisplayOrder.reduce((elements, property) => {
    if (sellerFields !== null) {
      switch (property) {
        case 'location':
          return [
            ...elements,
            <Preference key="sell-location">
              <div>
                <LocationIcon />
                <div>{sellerFields.city || ''}</div>
                <div>
                  {sellerFields.state || ''}, {sellerFields.zipCode || ''}
                </div>
              </div>
            </Preference>
          ];
        case 'numBaths':
          return [
            ...elements,
            <Preference key="sell-numbaths">
              <div>
                <BathIcon />
                <div>{sellerFields.baths}</div>
                <div>Baths</div>
              </div>
            </Preference>
          ];
        case 'numBeds':
          return [
            ...elements,
            <Preference key="sell-numbeds">
              <div>
                <BedIcon />
                <div>{sellerFields.beds}</div>
                <div>Beds</div>
              </div>
            </Preference>
          ];
        case 'livingAreaFormatted':
          return [
            ...elements,
            <Preference key="sell-livingareaft">
              <div>
                <LivingAreaIcon />
                {sellerFields.livingArea}
                <div>Living Area</div>
              </div>
            </Preference>
          ];
        case 'lotSizeAreaFormatted':
          return [
            ...elements,
            <Preference key="sell-lotsize">
              <div>
                <LotSizeIcon />
                {sellerFields.lotSizeAreaFt}
                <div>Lot Size</div>
              </div>
            </Preference>
          ];
        case 'yearBuilt':
          return [
            ...elements,
            <Preference key="sell-yearbuilt">
              <div>
                <YearBuiltIcon />
                <div>{sellerFields.yearBuilt}</div>
                <div>Year built</div>
              </div>
            </Preference>
          ];
        case 'estimatedListPrice':
          return [
            ...elements,
            <Preference key="sell-estimatedlistprice">
              <div>
                <PriceIcon />
                {numberToThousands(sellerFields.estimatedListPrice || 0)}
                <div>Est. List Price</div>
              </div>
            </Preference>
          ];
        default:
          throw new Error(`Unknown type for ${property}!`);
      }
    }
    return elements;
  }, [] as JSX.Element[]);

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Selling</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      <Container>
        <div className="sell-type-container">
          <div className="sell-type">
            {getPreferencesComponent(sellerFields.listingType)}
          </div>
        </div>
        <div className="prefs">{sellPrefs}</div>
      </Container>
    </PageHeader>
  );
};

const getPreferencesComponent = (type?: ClientSellerFieldsListingTypeEnum) => {
  switch (type) {
    case ClientSellerFieldsListingTypeEnum.SingleFamily:
      return (
        <div key="type-house">
          <HouseIcon />
          Single Family
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.Townhouse:
      return (
        <div key="type-townhouse">
          <TownHouseIcon />
          Townhouse
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.BusinessOpportunity:
      return (
        <div key="type-businessop">
          <BusinessOpIcon />
          Business Op
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.Condo:
      return (
        <div key="type-condo">
          <CondoIcon />
          Condo
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.Multifamily:
      return (
        <div key="type-multifamily">
          <MultiFamilyHouseIcon />
          Multi-family
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.Land:
      return (
        <div key="type-land">
          <LandIcon />
          Land
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.Mobile:
      return (
        <div key="type-mobile">
          <MobileIcon />
          Mobile
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.Commercial:
      return (
        <div key="type-commercial">
          <CommecialIcon />
          Commercial
        </div>
      );
    case ClientSellerFieldsListingTypeEnum.ResidentialIncome:
      return (
        <div key="type-residincome">
          <ResidIncomIcon />
          Resid. Income
        </div>
      );
    default:
      return 'N/A';
  }
};
