import { Reducer, Dispatch } from 'react';
import { ContactStatusChartData } from '@elm-street-technology/crm-axios-client';
import { contactStatusesApi } from 'src/common/services';
import { AxiosError } from 'axios';

interface GetState<T> {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
}

type GetActions<T> =
  | { type: 'Get' }
  | { type: 'GetSuccess'; payload: T }
  | { type: 'GetFailure'; payload: AxiosError };

type ContactStatusChartDataState = GetState<ContactStatusChartData>;
type ContactStatusChartDataActions = GetActions<ContactStatusChartData>;
type ContactStatusChartDataDispatch = Dispatch<ContactStatusChartDataActions>;

export const initialContactStatusChartDataState: ContactStatusChartDataState = {
  data: null,
  loading: false,
  error: null
};

export const contactStatusChartDataReducer: Reducer<
  ContactStatusChartDataState,
  ContactStatusChartDataActions
> = (state, action) => {
  switch (action.type) {
    case 'Get':
      return {
        ...state,
        loading: true
      };
    case 'GetSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const getContactStatusChartData = (
  dispatch: ContactStatusChartDataDispatch,
  userId: number,
  timeframe?: string
) => {
  dispatch({ type: 'Get' });

  contactStatusesApi
    .getContactStatusChartData({ userId, timeframe })
    .then(({ data }) => dispatch({ type: 'GetSuccess', payload: data }))
    .catch(error =>
      dispatch({
        type: 'GetFailure',
        payload: error
      })
    );
};
