import { ClientContributorCreateBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { getCreateMessageText } from 'src/common/utils';
import { contributorApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const createClientContributor = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number,
  clientContributorCreateBody: ClientContributorCreateBody
) => {
  dispatch({ type: 'CreateContributorPending' });

  contributorApi
    .createClientContributor({ clientId, clientContributorCreateBody })
    .then(({ data }) => {
      message.success(getCreateMessageText('contributor').success);
      dispatch({ type: 'CreateContributorSuccess', payload: data });
    })
    .catch(error => {
      message.error(getCreateMessageText('contributor').error);
      dispatch({ type: 'CreateContributorFailure' });
    });
};
