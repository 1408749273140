import { message } from 'antd';
import { clientNoteApi } from 'src/common/services';
import { getDeleteMessageText } from 'src/common/utils';
import { NotesDispatch } from '../Notes.reducer';

export const deleteClientNote = (
  dispatch: NotesDispatch,
  clientId: number,
  id: number
) => {
  dispatch({ type: 'DeleteNote' });

  clientNoteApi
    .deleteClientNote({ clientId, id })
    .then(() => {
      dispatch({ type: 'DeleteNoteSuccess', payload: id });
      message.success(getDeleteMessageText('client note').success);
    })
    .catch(error => {
      dispatch({
        type: 'DeleteNoteFailure',
        payload: error
      });
      message.error(getDeleteMessageText('client note').error);
    });
};
