import { message } from 'antd';
import { clientSavedSearchApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils';
import { ClientSavedSearchesDispatch } from '../SavedSearchesSection.reducer';

export const listClientSavedSearches = (
  dispatch: ClientSavedSearchesDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetList' });

  clientSavedSearchApi
    .listClientSavedSearches({ clientId })
    .then(({ data }) => dispatch({ type: 'GetListSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'GetListFailure',
        payload: error
      });
      message.error(getFetchMessageText('client saved searches').error);
    });
};
