import Popover from '@material-ui/core/Popover';
import {
  Badge,
  Dropdown,
  Menu,
  notification as notificationModal,
  Row,
  List,
  Button,
  Avatar
} from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, FC, useCallback, useMemo, useReducer } from 'react';
import styled from 'styled-components';
import SvgElevateIcon from 'src/crm/assets/elevate/elevate_logo';
import SvgNotificationIcon from 'src/crm/assets/header/notification';
import SvgHelpIcon from 'src/crm/assets/header/help';
import { strToInitials } from 'src/common/utils';
import { CRM_HOST, TABLET } from 'src/common/constants';
import { useLastMessage } from 'use-socketio';
import { useMediaQuery } from 'react-responsive';
import { useUserAccount } from 'src/common/hooks';
import {
  initialNotificationsState,
  notificationsReducer,
  listNotifications,
  addNewNotification
} from './Notifications.reducer';
import {
  ElevateColIconWrapper,
  Head,
  HeaderColIconsWrapper,
  HeaderIconsWrapper,
  HelpBadge,
  HelpText
} from './Header.styled';
import { NotificationListItem, NotificationMessage } from './components';
import {
  openNotifications,
  openEmails,
  formatNotificationCategory
} from './Header.utils';
import { ZEWindow } from './Header.types';

const CircleButton = styled(Button)`
  background-color: ${({ theme }: { theme: Record<string, string> }) =>
    theme.headerButtonBackground};
  border: none;
  &:hover,
  :active {
    background-color: #e7e8e9;
  }
  &:focus {
    background-color: #f2f5f7;
  }
`;

export const Header: FC = () => {
  const { user } = useUserAccount();

  const isMobile = useMediaQuery({ maxWidth: TABLET });

  const [{ notifications, count, loading }, dispatch] = useReducer(
    notificationsReducer,
    initialNotificationsState
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { data: lastNotification } = useLastMessage('notification');

  useEffect(() => {
    listNotifications(dispatch);

    // @ts-ignore
    if (((window as unknown) as ZEWindow)?.zE) {
      ((window as unknown) as ZEWindow).zE('webWidget', 'identify', {
        name: user.displayName,
        email: user.email
      });
    }
  }, []);

  useEffect(() => {
    if (lastNotification?.category) {
      addNewNotification(dispatch, lastNotification);

      notificationModal.open({
        message: (
          <NotificationMessage url={lastNotification.url}>
            {formatNotificationCategory(lastNotification.category)}
          </NotificationMessage>
        ),
        description: lastNotification?.message
      });
    }
  }, [lastNotification]);

  const handleNotificationsButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    []
  );

  const notificationBadge = (
    <Badge
      style={{ backgroundColor: '#0EBEFF' }}
      showZero={false}
      offset={[1, 2]}
      count={count}
    >
      <CircleButton
        name="notifications-list"
        onClick={handleNotificationsButtonClick}
        className="notifications-button"
        type="primary"
        shape="circle"
      >
        <Icon component={SvgNotificationIcon} />
      </CircleButton>
    </Badge>
  );

  const handleHelpButtonClick = useCallback(() => {
    // @ts-ignore
    if (((window as unknown) as ZEWindow)?.zE) {
      ((window as unknown) as ZEWindow).zE.activate();
    }
  }, []);

  const helpBadge = (
    <HelpBadge>
      {isMobile ? (
        <CircleButton
          id="zendesk-help-button"
          name="zendesk-help"
          type="primary"
          shape="circle"
          title="Help"
          onClick={handleHelpButtonClick}
        >
          <Icon component={SvgHelpIcon} />
        </CircleButton>
      ) : (
        <CircleButton
          id="zendesk-help-button"
          name="zendesk-help"
          type="primary"
          href="mailto:support@tryelevate.com"
          shape="round"
          title="Help"
          onClick={handleHelpButtonClick}
        >
          <Icon component={SvgHelpIcon} />
          <HelpText>Help</HelpText>
        </CircleButton>
      )}
    </HelpBadge>
  );

  const userMenuItems = useMemo(
    () =>
      user.topNav.map(item => (
        <Menu.Item key={item.label}>
          <a href={`${CRM_HOST}${item.href}`}>{item.label}</a>
        </Menu.Item>
      )),
    []
  );

  const userBadge = (
    <Dropdown overlay={<Menu>{userMenuItems}</Menu>} trigger={['click']}>
      <a onClick={e => e.preventDefault()}>
        <Avatar
          src={user.avatarUrl}
          style={{
            cursor: 'pointer'
          }}
        >
          {strToInitials(user.displayName)}
        </Avatar>
      </a>
    </Dropdown>
  );

  const handlePopoverClose = useCallback(() => setAnchorEl(null), []);

  return (
    <Head>
      <Row
        style={{
          height: '100%',
          position: 'relative',
          justifyContent: 'space-between'
        }}
      >
        <ElevateColIconWrapper>
          <Icon style={{ margin: 'auto' }} component={SvgElevateIcon} />
        </ElevateColIconWrapper>
        <HeaderColIconsWrapper>
          <Row
            style={{
              height: '100%',
              justifyContent: 'space-around',
              alignContent: 'center',
              alignItems: 'center',
              marginLeft: '16px'
            }}
          >
            <HeaderIconsWrapper>
              {notificationBadge}
              {helpBadge}
              {userBadge}
            </HeaderIconsWrapper>
          </Row>
        </HeaderColIconsWrapper>
      </Row>
      {anchorEl && (
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <List
            dataSource={
              anchorEl.name === 'notifications-list'
                ? openNotifications(notifications)
                : openEmails(notifications)
            }
            renderItem={item => <NotificationListItem item={item} />}
            loading={loading}
          />
        </Popover>
      )}
    </Head>
  );
};
