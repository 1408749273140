import React, { useState, useEffect, useContext, useMemo } from 'react';
import { PageHeader, Button, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ClientAbility } from '@elm-street-technology/crm-axios-client';

import { useUserAccount } from 'src/common/hooks';
import {
  listOrgMemberContributors,
  createClientContributor,
  createOrgClientContributor,
  deleteClientContributor
} from '../../../../actions';
import {
  ClientRecordDrawerDispatchContext,
  ClientRecordDrawerStateContext
} from '../../../../ClientRecordDrawer.context';
import {
  ContributorsTable,
  AddOrganizationContributor,
  AddCustomContributor,
  ContributorsEmptyPlaceholder
} from './components';
import { ClientRecordSectionTitle } from '../../../index';

interface Props {
  clientId: number;
}

export const ContributorsSection: React.FC<Props> = ({ clientId }) => {
  const { user } = useUserAccount();
  const dispatch = useContext(ClientRecordDrawerDispatchContext);
  const {
    contributors: { data: contributors, loading, updating, saveComplete },
    organizationMembers: { data: organizationMembers },
    abilities: { data: clientAbilities }
  } = useContext(ClientRecordDrawerStateContext);

  const [showForm, setShowForm] = useState(false);

  const canManageContributors = clientAbilities.includes(
    ClientAbility.Managecontributors
  );

  useEffect(() => {
    if (canManageContributors) {
      listOrgMemberContributors(dispatch, clientId);
    }
  }, [canManageContributors]);

  useEffect(() => {
    if (saveComplete) {
      setShowForm(false);
    }
  }, [saveComplete]);

  const orgMembersFiltered = useMemo(
    () =>
      organizationMembers.map(organizations => ({
        optGroupLabel: organizations.optGroupLabel,
        children: organizations.children.filter(orgMember => {
          if (orgMember.label === user.displayName) {
            return false;
          }
          for (const contributorObject of contributors) {
            if (
              `${contributorObject.firstName} ${contributorObject.lastName}` ===
              orgMember.label
            ) {
              return false;
            }
          }
          return true;
        })
      })),
    [organizationMembers, user]
  );

  const handleAddCustomContributor = values => {
    createClientContributor(dispatch, clientId, {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      organization: values.organization
    });

    setShowForm(false);
  };

  const handleAddOrganizationContributor = values => {
    createOrgClientContributor(dispatch, clientId, {
      userId: values.organizationalMember
    });
    setShowForm(false);
  };

  const handleNewContributorClick = () => setShowForm(true);

  const handleDeleteContributor = (contributorId: number) =>
    deleteClientContributor(dispatch, { clientId, contributorId });

  const content = showForm ? (
    <>
      <AddOrganizationContributor
        organizationMembers={orgMembersFiltered}
        onAddContributor={handleAddOrganizationContributor}
        updating={updating}
      />
      <AddCustomContributor
        onAddCustomContributor={handleAddCustomContributor}
        onCancel={() => setShowForm(false)}
        updating={updating}
      />
    </>
  ) : (
    <>
      {contributors.length ? (
        <ContributorsTable
          loading={loading}
          contributors={contributors}
          onDeleteContributor={handleDeleteContributor}
          canManageContributors={canManageContributors}
        />
      ) : (
        <ContributorsEmptyPlaceholder />
      )}
    </>
  );

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Contributors</ClientRecordSectionTitle>}
      footer={<Divider />}
      extra={
        canManageContributors && (
          <Button
            onClick={handleNewContributorClick}
            icon={<PlusOutlined />}
            size="large"
          >
            New Contributor
          </Button>
        )
      }
    >
      {content}
    </PageHeader>
  );
};
