import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    width="11px"
    height="12px"
    viewBox="0 0 11 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>membership</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Cards-/-Mobile-/-Broker-Recruiting"
        transform="translate(-160.000000, -78.000000)"
        fillRule="nonzero"
      >
        <g id="membership" transform="translate(160.718750, 78.000000)">
          <path
            d="M8.33333333,1.43069459 L6.36363636,1.43069459 L6.36363636,0.35275268 C6.36363636,0.158660883 6.160393,0.00128173828 5.90944603,0.00119018437 C5.90944603,0.00119018437 4.12121212,0 4.12121212,0 C3.87002842,0 3.66666667,0.157745367 3.66666667,0.3515625 L3.66666667,1.43069459 L1.66666667,1.43069459 C0.747632576,1.43069459 0,2.00894166 0,2.71966552 L0,10.711029 C0,11.4217529 0.747632576,12 1.66666667,12 L8.33333333,12 C9.25236742,12 10,11.4217529 10,10.711029 L10,2.71966552 C10,2.00894166 9.25236742,1.43069459 8.33333333,1.43069459 Z"
            id="Shape"
            fill="#082746"
          />
          <path
            d="M7,10 C7.27617187,10 7.5,9.82005181 7.5,9.59825521 L7.5,8.79476562 C7.5,8.0551995 7.00403647,7.4047705 6.246875,7.05366229 C6.16856099,6.98159783 3.87299833,6.98159783 3.75572917,7.05680091 C2.97096353,7.42632242 2.5,8.1084516 2.5,8.82144399 L2.5,9.59825521 C2.5,9.82005181 2.72382813,10 3,10 L7,10 Z M5,7 C5.82842712,7 6.5,6.32842712 6.5,5.5 C6.5,4.67157288 5.82842712,4 5,4 C4.17157288,4 3.5,4.67157288 3.5,5.5 C3.5,6.32842712 4.17157288,7 5,7 Z"
            id="Shape"
            stroke="#FFFFFF"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const ProspectiveAgentTypeIcon = props => (
  <Icon component={Svg} {...props} />
);
