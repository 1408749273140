import * as React from 'react';

function SvgResidIncome(props) {
  return (
    <svg width="34px" height="30px" viewBox="0 0 34 30" version="1.1">
      <title>Residential Income</title>
      <g
        id="Property-Type/Residential-Income-Icon/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Property-Type/Residential-Income-Icon">
          <g
            id="Group"
            stroke="#082746"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d="M4.83333333,14.5 L4.83333333,27 C4.83333333,28.1045695 5.72876383,29 6.83333333,29 L27,29 C28.1045695,29 29,28.1045695 29,27 L29,14.5 L29,14.5"
              id="Path"
            />
            <line
              x1="1.10763889"
              y1="11.6"
              x2="16.6145833"
              y2="1.28888889"
              id="Line-3"
            />
            <line
              x1="17.21875"
              y1="1.28888889"
              x2="32.7256944"
              y2="11.6"
              id="Line-4"
            />
          </g>
          <path
            d="M16.7671387,17.4028479 L17.2329912,17.4028479 C19.0344351,17.4028479 20.5,18.5084477 20.5,19.8674294 C20.5,20.9989994 19.4835294,21.9542655 18.1035094,22.2424868 L18.1035094,22.970588 C18.1035094,23.2467304 17.8796518,23.470588 17.6035094,23.470588 L16.3965556,23.470588 C16.1204132,23.470588 15.8965556,23.2467304 15.8965556,22.970588 L15.8965556,22.2424868 L15.8965556,22.2424868 C14.5165355,21.9542165 13.5,20.9989994 13.5,19.8674294 L15.7069538,19.8674294 C15.7069538,20.3083365 16.1824846,20.6671186 16.7670088,20.6671186 L17.2329263,20.6671186 C17.8174504,20.6671186 18.2929812,20.3083365 18.2929812,19.8674294 C18.2929812,19.4265223 17.8174504,19.0677401 17.2329263,19.0677401 L16.7670088,19.0677401 C14.9655649,19.0677401 13.5,17.9621403 13.5,16.6031586 C13.5,15.4715886 14.5164706,14.5163225 15.8965556,14.2281012 L15.8965556,13.5 C15.8965556,13.2238576 16.1204132,13 16.3965556,13 L17.6035094,13 C17.8796518,13 18.1035094,13.2238576 18.1035094,13.5 L18.1035094,14.2281012 L18.1035094,14.2281012 C19.4835294,14.5163715 20.5,15.4715886 20.5,16.6031586 L18.2930462,16.6031586 C18.2930462,16.1622515 17.8175154,15.8034694 17.2329912,15.8034694 L16.7671387,15.8034694 C16.1826145,15.8034694 15.7070837,16.1622515 15.7070837,16.6031586 C15.7070837,17.0440657 16.1826145,17.4028479 16.7671387,17.4028479 Z"
            id="Shape"
            fill="#0A2847"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgResidIncome;
