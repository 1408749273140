import { User, Client } from '@elm-street-technology/crm-axios-client';
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import React, { FC } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import List from '@material-ui/core/List';
import styled from 'styled-components';
import ChevronDown from 'src/crm/assets/icons/chevron-down';
import ChevronUp from 'src/crm/assets/icons/chevron-up';
import ReplyArrow from 'src/crm/assets/icons/reply-arrow';
import { CallSent } from 'src/crm/assets/icons/profile';
import { ClientCommunicationCallType } from '../CommunicationSection.types';

const ChevronDownIcon = styled(ChevronDown)`
  width: 21px;
  height: 21px;
`;

const ChevronUpIcon = styled(ChevronUp)`
  width: 21px;
  height: 21px;
`;

const ReplyArrowIcon = styled(ReplyArrow)`
  margin-right: 8px;
`;

const MessageList = styled(List)`
  color: ${({ theme }) => theme.secondaryBlue};
`;

const CommunicationsButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondaryBlue};
  border-color: ${({ theme }) => theme.secondaryBlue};
  color: #fff;
  margin-left: auto;
  margin-right: 20px;
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.secondaryBlue};
    border-color: ${({ theme }) => theme.secondaryBlue};
    color: #fff;
  }
`;

const ButtonSection = styled.div`
  display: flex;
`;

interface Props {
  client: Client;
  communicationCall: ClientCommunicationCallType;
  handleClick: (id: string) => void;
  user: User;
  expanded: boolean;
  handleCallBack: (client: Client) => void;
}

export const CallListItem: FC<Props> = ({
  communicationCall,
  handleClick,
  client,
  user,
  expanded,
  handleCallBack
}) => {
  const isCurrentUser = user.id === communicationCall.userId;

  const missedInbound =
    !communicationCall.wasConnected &&
    communicationCall.direction === 'inbound';

  const header =
    communicationCall.direction === 'outbound'
      ? `${
          isCurrentUser ? 'You' : user.displayName
        } called ${client.displayName || 'Unknown'}`
      : `${client.displayName || 'Unknown'} called ${
          isCurrentUser ? 'You' : user.displayName
        }${missedInbound ? ' - MISSED' : ''}`;

  return (
    <MessageList key={`${communicationCall.id}-${communicationCall.type}`}>
      <ListItem
        button
        onClick={() =>
          handleClick(`${communicationCall.id}-${communicationCall.type}`)
        }
      >
        <ListItemIcon>
          {communicationCall.direction === 'inbound' ? (
            <CallSent />
          ) : (
            <CallSent />
          )}
        </ListItemIcon>
        <ListItemText
          primary={header}
          secondary={moment(communicationCall.createdAt).format('MM/DD/YY')}
        />
        {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ButtonSection>
          <CommunicationsButton
            icon={<ReplyArrowIcon />}
            onClick={() => handleCallBack(client)}
          >
            &nbsp; Call
          </CommunicationsButton>
        </ButtonSection>
      </Collapse>
    </MessageList>
  );
};
