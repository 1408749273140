import React from 'react';

export const ExportClients = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-560 -100)">
          <g transform="translate(0 1)">
            <g transform="translate(560 99)">
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M18.697 0H15.68a.301.301 0 00-.303.3c0 .166.136.3.303.3h2.292l-5.888 5.894a.298.298 0 00.007.419.305.305 0 00.423.003l5.882-5.887v2.263c0 .166.135.3.302.3a.301.301 0 00.303-.3V.3c0-.166-.136-.3-.303-.3zM15.521 10.883a.479.479 0 00-.479.479v5.244c0 .793-.643 1.435-1.436 1.436H2.394a1.438 1.438 0 01-1.436-1.436V5.394c0-.793.643-1.435 1.436-1.436h5.244a.479.479 0 100-.958H2.394A2.397 2.397 0 000 5.394v11.212A2.397 2.397 0 002.394 19h11.212A2.397 2.397 0 0016 16.606v-5.244a.479.479 0 00-.479-.479z"
              />
              <g
                stroke="currentColor"
                strokeWidth="0.5"
                transform="translate(5 7)"
              >
                <path d="M2.768 4.057H4.13c.739 0 1.39.274 1.86.72.472.45.758 1.074.76 1.764h0v.836l-6.17.362-.33-1.23c.01-.673.291-1.28.738-1.723a2.53 2.53 0 011.78-.73h0zM3.5.25c.423 0 .815.151 1.119.406A1.694 1.694 0 014.05 3.57h0l-.637.211-.558-.208a1.64 1.64 0 01-.765-.61L2 2.822a1.75 1.75 0 01-.205-1.141c.068-.412.283-.77.585-1.024A1.736 1.736 0 013.5.25z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ExportClients;
