import { makeUrl } from 'src/common/utils';

export const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const sanitizeEmails = (emails: string[]) =>
  emails.filter(email => validateEmail(email));

export const bulkEmailUrl = makeUrl('/leads/bulkemail');

export const emailSignatureUrl = makeUrl('/signature');

export const createLabel = ({
  name,
  email
}: {
  name: string | null;
  email: string | null;
}) => `${name} - ${email}`;
