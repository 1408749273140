import React, { useState, FC } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { FloatLabel } from 'src/crm/components';

interface Props {
  onAddCustomContributor: (values) => void;
  onCancel: () => void;
  updating: boolean;
}

const initialCreateContributorState = {
  firstName: '',
  lastName: '',
  organization: '',
  email: '',
  phone: ''
};

export const AddCustomContributor: FC<Props> = ({
  onAddCustomContributor,
  onCancel,
  updating
}) => {
  const [form] = Form.useForm();
  const [newValues, setNewValues] = useState(initialCreateContributorState);

  return (
    <Form
      form={form}
      name="contributor-form"
      onFinish={onAddCustomContributor}
      onValuesChange={changedValues =>
        setNewValues({
          ...newValues,
          ...(changedValues as typeof initialCreateContributorState)
        })
      }
    >
      <div style={{ marginBottom: 24 }}>
        <div
          style={{
            fontSize: 16,
            color: '#082746',
            fontWeight: 500
          }}
        >
          Create a Contributor
        </div>
        <div
          style={{
            fontSize: 16,
            color: '#082746'
          }}
        >
          This can be someone on your organization or a third party.
        </div>
      </div>

      <FloatLabel
        label="First Name"
        value={newValues.firstName}
        name="firstName"
      >
        <Form.Item name="firstName" rules={[{ required: true }]}>
          <Input type="Text" />
        </Form.Item>
      </FloatLabel>
      <FloatLabel label="Last Name" value={newValues.lastName} name="lastName">
        <Form.Item name="lastName" rules={[{ required: true }]}>
          <Input type="Text" />
        </Form.Item>
      </FloatLabel>
      <FloatLabel
        label="Organization"
        value={newValues.organization}
        name="organization"
      >
        <Form.Item name="organization">
          <Input type="Text" />
        </Form.Item>
      </FloatLabel>
      <FloatLabel label="Email" value={newValues.email} name="email">
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input type="email" />
        </Form.Item>
      </FloatLabel>
      <FloatLabel label="Phone" value={newValues.phone} name="phone">
        <Form.Item name="phone">
          <Input type="tel" />
        </Form.Item>
      </FloatLabel>

      <Row gutter={[8, 8]} wrap={false} justify="end">
        <Col>
          <Button size="large" onClick={() => onCancel()}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={updating}
          >
            Update
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
