import { Spin as AntdSpin } from 'antd';
import { SpinProps as AntdSpinProps } from 'antd/lib/spin';
import React, { FC } from 'react';
import styled from 'styled-components';

const WrappedSpin: FC<{ centered?: boolean } & AntdSpinProps> = ({
  centered,
  ...props
}) => {
  return <AntdSpin {...props} />;
};

export const Spin = styled(WrappedSpin)`
  margin-top: ${props => (props.centered ? 'auto' : 'inherit')};
  margin-bottom: ${props => (props.centered ? 'auto' : 'inherit')};
  align-self: ${props => (props.centered ? 'center' : 'inherit')};
  justify-self: ${props => (props.centered ? 'center' : 'inherit')};
  margin-left: ${props => (props.centered ? 'auto' : 'inherit')};
  margin-right: ${props => (props.centered ? 'auto' : 'inherit')};
`;
