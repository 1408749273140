import styled from 'styled-components';
import { XAxis, YAxis, HorizontalGridLines } from 'react-vis';

export const XAxisStyled = styled(XAxis)`
  text {
    font-size: 10px;
    fill: #485465;
  }
`;
export const YAxisStyled = styled(YAxis)`
  text {
    font-size: 10px;
    fill: #485465;
  }
`;

export const HorizontalGridLinesStyled = styled(HorizontalGridLines)`
  line {
    stroke: #c2c9d1;
    stroke-width: 0.6;
    opacity: 0.75;
  }
`;

export const getClaimedColors = (label: string) =>
  label === 'Unclaimed' ? UNCLAIMED_RED : CLAIMED_TEAL;

export const getContactedColors = (label: string) =>
  label === 'Unclaimed' ? UNCLAIMED_RED : CONTACTED_BLUE;

const CONTACTED_BLUE = '#6DBEFC';
const CLAIMED_TEAL = '#7FE4CD';
const UNCLAIMED_RED = '#D95D58';
