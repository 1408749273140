import * as React from 'react';

function SvgTownhouse(props) {
  return (
    <svg width="40px" height="31px" viewBox="0 0 40 31" version="1.1">
      <title>Townhouse</title>
      <g
        id="Icons/Desktop/Propertytype/Townhouse"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="Townhouse-Icon" stroke="#082746">
          <path
            d="M3.47826087,14.5 L3.47826087,28 C3.47826087,29.1045695 4.37369137,30 5.47826087,30 L17.1304348,30 C18.2350043,30 19.1304348,29.1045695 19.1304348,28 L19.1304348,14.5 L19.1304348,14.5"
            id="Path"
          />
          <line
            x1="0.760869565"
            y1="12.4"
            x2="11.4130435"
            y2="1.37777778"
            id="Line-3"
          />
          <line
            x1="11.1956522"
            y1="1.37777778"
            x2="21.8478261"
            y2="12.4"
            id="Line-4"
          />
          <line
            x1="8.04175293"
            y1="20.5277778"
            x2="8.04175293"
            y2="17.0833333"
            id="Line-3"
          />
          <line
            x1="14.9982747"
            y1="20.5277778"
            x2="14.9982747"
            y2="17.0833333"
            id="Line-3"
          />
          <line
            x1="32.389579"
            y1="20.5277778"
            x2="32.389579"
            y2="17.0833333"
            id="Line-3"
          />
          <line
            x1="25.4330573"
            y1="20.5277778"
            x2="25.4330573"
            y2="17.0833333"
            id="Line-3"
          />
          <polygon
            id="Line-3"
            points="13.0434783 30 13.0434783 24.8333333 9.56521739 24.8333333 9.56521739 30"
          />
          <polygon
            id="Line-3"
            points="30.4347826 30 30.4347826 24.8333333 26.9565217 24.8333333 26.9565217 30"
          />
          <path
            d="M20.8695652,14.5 L20.8695652,28 C20.8695652,29.1045695 21.7649957,30 22.8695652,30 L34.5217391,30 C35.6263086,30 36.5217391,29.1045695 36.5217391,28 L36.5217391,14.5 L36.5217391,14.5"
            id="Path"
          />
          <line
            x1="18.1521739"
            y1="12.4"
            x2="28.8043478"
            y2="1.37777778"
            id="Line-3"
          />
          <line
            x1="28.5869565"
            y1="1.37777778"
            x2="39.2391304"
            y2="12.4"
            id="Line-4"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgTownhouse;
