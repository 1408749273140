import { ClientContributorHistoryRecord } from '@elm-street-technology/crm-axios-client';
import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export interface ClientContributorHistoryState {
  data: ClientContributorHistoryRecord[];
  loading: boolean;
}

export type ClientContributorHistoryActions =
  | { type: 'GetContributorHistoryPending' }
  | {
      type: 'GetContributorHistorySuccess';
      payload: ClientContributorHistoryRecord[];
    }
  | { type: 'GetContributorHistoryFailure' };

export const initialClientContributorHistoryState: ClientContributorHistoryState = {
  data: [],
  loading: false
};
export const clientContributorHistoryReducer: Reducer<
  ClientContributorHistoryState,
  ClientRecordDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'GetContributorHistoryPending':
      return {
        ...state,
        loading: true
      };
    case 'GetContributorHistorySuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetContributorHistoryFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
