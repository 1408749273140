import { message } from 'antd';
import { clientNoteApi } from 'src/common/services';
import { getFetchMessageText } from 'src/common/utils';
import { NotesDispatch } from '../Notes.reducer';

export const listClientNotes = (dispatch: NotesDispatch, clientId: number) => {
  dispatch({ type: 'GetNotes' });

  clientNoteApi
    .listClientNotes({ clientId })
    .then(({ data }) => dispatch({ type: 'GetNotesSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'GetNotesFailure',
        payload: error
      });
      message.error(getFetchMessageText('client notes').error);
    });
};
