import * as React from 'react';

const SvgAssets = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <path
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M8.167 6.417h-3.5M5.833 8.75H4.667m4.666-4.667H4.667m7-.116v6.066c0 .98 0 1.47-.191 1.845a1.75 1.75 0 01-.765.765c-.374.19-.864.19-1.844.19H5.133c-.98 0-1.47 0-1.844-.19a1.75 1.75 0 01-.765-.765c-.19-.374-.19-.865-.19-1.845V3.967c0-.98 0-1.47.19-1.845a1.75 1.75 0 01.765-.765c.374-.19.864-.19 1.844-.19h3.734c.98 0 1.47 0 1.844.19.33.168.597.436.765.765.19.375.19.865.19 1.845z"
      />
    </g>
  </svg>
);

export default SvgAssets;
