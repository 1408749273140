import * as React from 'react';

function SvgLivingArea(props) {
  return (
    <svg width="37px" height="33px" viewBox="0 0 37 33" version="1.1">
      <title>Living Area</title>
      <g
        id="Icons/Living-Area/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Living-Area-Icon" stroke="#082746">
          <path
            d="M35.09375,18.5 L35.59375,32 L20.6875,32.5 L20.1875,19 L35.09375,18.5 Z"
            id="Rectangle"
          />
          <path
            d="M1.8203125,30.75 L14.5859375,30.75 C15.1382222,30.75 15.5859375,30.3022847 15.5859375,29.75 L15.5859375,16.75 C15.5859375,16.1977153 16.0336528,15.75 16.5859375,15.75 L29.3515625,15.75 C29.9038472,15.75 30.3515625,15.3022847 30.3515625,14.75 L30.3515625,1.75 C30.3515625,1.19771525 29.9038472,0.75 29.3515625,0.75 L1.8203125,0.75 C1.26802775,0.75 0.8203125,1.19771525 0.8203125,1.75 L0.8203125,29.75 C0.8203125,30.3022847 1.26802775,30.75 1.8203125,30.75 Z"
            id="Path-2"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLivingArea;
