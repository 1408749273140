import { ClientAbility } from '@elm-street-technology/crm-axios-client';
import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export interface ClientAbilitiesState {
  data: ClientAbility[];
  loading: boolean;
}
export type ClientAbilitiesActions =
  | { type: 'GetClientAbilitiesPending' }
  | { type: 'GetClientAbilitiesSuccess'; payload: ClientAbility[] }
  | { type: 'GetClientAbilitiesFailure' };

export const initialClientAbilitiesState: ClientAbilitiesState = {
  data: [],
  loading: false
};

export const clientAbilitiesReducer: Reducer<
  ClientAbilitiesState,
  ClientRecordDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'GetClientAbilitiesPending':
      return {
        ...state,
        loading: true
      };
    case 'GetClientAbilitiesSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetClientAbilitiesFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
