import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <circle id="b" cx="16.763" cy="16.763" r="16.763" />
        <filter
          id="a"
          width="192.5%"
          height="192.5%"
          x="-46.2%"
          y="-34.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <g>
              <g>
                <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                <use fill="#2E7FC2" xlinkHref="#b" />
              </g>
            </g>
            <g
              fill="#FFF"
              fillRule="nonzero"
              transform="translate(10.59 10.033)"
            >
              <path d="M14.167 11.462L11.929 9.22c-.446-.445-1.184-.432-1.645.031l-1.128 1.13-.222-.124c-.712-.396-1.687-.938-2.712-1.966-1.029-1.03-1.57-2.01-1.966-2.723l-.121-.218.757-.757.372-.374c.462-.463.474-1.203.03-1.649L3.056.325C2.61-.12 1.872-.107 1.41.357L.78.992l.017.018c-.212.27-.389.582-.52.918-.121.32-.197.626-.231.932-.296 2.456.824 4.7 3.862 7.745 4.2 4.21 7.584 3.891 7.73 3.876a3.775 3.775 0 001.846-.754l.014.012.64-.627c.46-.463.473-1.203.029-1.65z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
