import React, { FC, useEffect } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import { Grid, Divider, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';

import { navigateToListingsPdpDrawer } from 'src/common/utils';

import { useCmaListings } from './useCmaListings';
import { CmaListingsTable, CmaListingCardList } from './components';
import { ClientRecordSectionTitle } from '../index';

interface Props {
  client: Client;
}

export const SimilarListingsSection: FC<Props> = ({ client }) => {
  const { md } = Grid.useBreakpoint();

  const navigate = useNavigate();
  const { data, loading, getCmaListings } = useCmaListings();

  useEffect(() => {
    if (client.sellerFields) {
      getCmaListings(client.sellerFields);
    }
  }, [client.sellerFields]);

  const handleListingClick = listing =>
    navigateToListingsPdpDrawer(navigate, listing.id);

  return (
    <PageHeader
      title={
        <ClientRecordSectionTitle>
          {data.length ? `${data.length} ` : ''} Similar Listings
        </ClientRecordSectionTitle>
      }
      footer={<Divider />}
    >
      {md ? (
        <CmaListingsTable
          loading={loading}
          cmaListings={data}
          onListingClick={handleListingClick}
          pagination={pagination}
        />
      ) : (
        <CmaListingCardList
          loading={loading}
          cmaListings={data}
          onListingClick={handleListingClick}
          pagination={pagination}
        />
      )}
    </PageHeader>
  );
};

const pagination = {
  pageSize: 5,
  simple: true
};
