import * as React from 'react';

function SvgMobile(props) {
  return (
    <svg width="29px" height="30px" viewBox="0 0 29 30" version="1.1">
      <title>Mobile Home</title>
      <g
        id="Property-Type/Mobile-Home-Icon/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Property-Type/Mobile-Home-Icon">
          <g id="Group-5">
            <g
              id="Group"
              stroke="#082746"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M4.14285714,12.4285714 L4.14285714,23.1047144 C4.14285714,24.0725539 4.9274461,24.8571429 5.89528564,24.8571429 L5.89528564,24.8571429 L5.89528564,24.8571429 C6.35775834,23.989833 7.15456789,23.5295155 8.28571429,23.4761905 C9.20818755,23.4241577 9.98153574,23.8461075 10.6057589,24.7420401 L11.1104585,24.8571429 L18.3386611,24.8571429 C18.8603542,23.9365079 19.652229,23.4761905 20.7142857,23.4761905 C21.7763424,23.4761905 22.5561406,23.9145418 23.0536804,24.7912445 C24.0139753,24.8263336 24.8208934,24.0763061 24.8559825,23.1160113 C24.856756,23.094842 24.8571429,23.0736603 24.8571429,23.0524769 L24.8571429,12.4285714 L24.8571429,12.4285714"
                id="Path"
              />
              <line
                x1="0.949404762"
                y1="9.94285714"
                x2="14.2410714"
                y2="1.1047619"
                id="Line-3"
              />
              <line
                x1="14.7589286"
                y1="1.1047619"
                x2="28.0505952"
                y2="9.94285714"
                id="Line-4"
              />
            </g>
            <circle
              id="Oval"
              stroke="#0A2847"
              cx="8.28571429"
              cy="26.2380952"
              r="2.76190476"
            />
            <circle
              id="Oval"
              stroke="#0A2847"
              cx="20.7142857"
              cy="26.2380952"
              r="2.76190476"
            />
          </g>
          <line
            x1="18.8141062"
            y1="15.8809524"
            x2="18.8141062"
            y2="13.1190476"
            id="Line-3"
            stroke="#082746"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <polyline
            id="Path"
            stroke="#082746"
            strokeLinejoin="round"
            points="12.8428571 25.136247 12.8428571 19.3333333 16.2926702 19.3333333 16.2926702 25.136247"
          />
          <line
            x1="10.5283919"
            y1="15.8809524"
            x2="10.5283919"
            y2="13.1190476"
            id="Line-3"
            stroke="#082746"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgMobile;
