import { Reducer } from 'react';

interface NavState {
  collapsedWidth: string;
  collapsed: boolean;
  openKeys: string[] | undefined;
}

type NavStateAction =
  | { type: 'DesktopBreakpoint' }
  | { type: 'MobileBreakpoint' }
  | { type: 'DesktopCollapse' }
  | { type: 'MobileCollapse' };

export const initializeNavReducer = (initialOpenKeys: string[]) => {
  const navReducer: Reducer<NavState, NavStateAction> = (state, action) => {
    switch (action.type) {
      case 'DesktopBreakpoint':
        return {
          ...state,
          collapsedWidth: '46',
          openKeys: state.collapsed ? undefined : initialOpenKeys,
          collapsed: state.collapsed
        };
      case 'MobileBreakpoint':
        return {
          ...state,
          collapsedWidth: '46',
          openKeys: undefined,
          collapsed: true
        };
      case 'DesktopCollapse':
        return {
          ...state,
          collapsed: !state.collapsed,
          openKeys: state.collapsed ? initialOpenKeys : undefined
        };
      case 'MobileCollapse':
        return {
          ...state,
          collapsed: true,
          collapsedWidth: '46'
        };
      default:
        return state;
    }
  };

  return navReducer;
};
