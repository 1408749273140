import { Reducer, Dispatch } from 'react';
import { AxiosError } from 'axios';
import {
  Notification,
  NotificationCategoryEnum
} from '@elm-street-technology/crm-axios-client';
import { notificationApi } from 'src/common/services';

export interface NotificationsState {
  notifications: Array<Notification>;
  count: number;
  loading: boolean;
  error: AxiosError | null;
}

export type NotificationsActions =
  | { type: 'Get' }
  | { type: 'GetSuccess'; payload: Array<Notification> }
  | { type: 'GetFailure'; payload: AxiosError }
  | { type: 'AddNewNotification'; payload: Notification };

type NotificationsDispatch = Dispatch<NotificationsActions>;

export const initialNotificationsState: NotificationsState = {
  notifications: [],
  count: 0,
  loading: false,
  error: null
};

export const notificationsReducer: Reducer<
  NotificationsState,
  NotificationsActions
> = (state, action) => {
  switch (action.type) {
    case 'Get':
      return {
        ...state,
        loading: true
      };
    case 'GetSuccess':
      return {
        ...state,
        notifications: action.payload,
        count: countNotifications(action.payload),
        loading: false
      };
    case 'GetFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'AddNewNotification':
      const notifications = [action.payload, ...state.notifications];
      return {
        ...state,
        notifications,
        count: countNotifications(notifications)
      };
    default:
      return state;
  }
};

export const listNotifications = (dispatch: NotificationsDispatch) => {
  dispatch({ type: 'Get' });

  notificationApi
    .listNotifications()
    .then(({ data }) => dispatch({ type: 'GetSuccess', payload: data }))
    .catch(error => {
      dispatch({
        type: 'GetFailure',
        payload: error
      });
    });
};

export const addNewNotification = (
  dispatch: NotificationsDispatch,
  payload: Notification
) => dispatch({ type: 'AddNewNotification', payload });

export const countNotifications = (notifications: Notification[]) =>
  notifications.filter(
    ({ category, readIsoDateStr }) =>
      category !== NotificationCategoryEnum.LeadEmailParsed && !readIsoDateStr
  ).length;
