import { Reducer, Dispatch } from 'react';
import { LeadStatusBySourceChartData } from '@elm-street-technology/crm-axios-client';
import { leadStatusesBySourceApi } from 'src/common/services';

import { AxiosError } from 'axios';

interface ListState<T> {
  data: Array<T>;
  loading: boolean;
  error: AxiosError | null;
}

type ListActions<T> =
  | { type: 'List' }
  | { type: 'ListSuccess'; payload: Array<T> }
  | { type: 'ListFailure'; payload: AxiosError };

type LeadStatusBySourceChartDataState = ListState<LeadStatusBySourceChartData>;
type LeadStatusBySourceChartDataActions = ListActions<
  LeadStatusBySourceChartData
>;
type LeadStatusBySourceChartDataDispatch = Dispatch<
  LeadStatusBySourceChartDataActions
>;

export const initialLeadStatusBySourceChartDataState: LeadStatusBySourceChartDataState = {
  data: [],
  loading: false,
  error: null
};

export const leadStatusBySourceChartDataReducer: Reducer<
  LeadStatusBySourceChartDataState,
  LeadStatusBySourceChartDataActions
> = (state, action) => {
  switch (action.type) {
    case 'List':
      return {
        ...state,
        loading: true
      };
    case 'ListSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'ListFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const getLeadStatusBySourceChartData = (
  dispatch: LeadStatusBySourceChartDataDispatch,
  userId: number,
  timeframe?: string
) => {
  dispatch({ type: 'List' });

  leadStatusesBySourceApi
    .getLeadStatusBySourceChartData({ userId, timeframe })
    .then(({ data }) => dispatch({ type: 'ListSuccess', payload: data }))
    .catch(error =>
      dispatch({
        type: 'ListFailure',
        payload: error
      })
    );
};
