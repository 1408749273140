import React from 'react';

const RecruitingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g clipPath="url(#clip0_9_23975)">
      <g
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      >
        <path d="M7 8.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z" />
        <path d="M11.317 8.75a.962.962 0 00.192 1.062l.035.035a1.166 1.166 0 11-1.65 1.65l-.036-.034a.962.962 0 00-1.061-.193.962.962 0 00-.584.88v.1a1.167 1.167 0 01-2.333 0v-.053a.962.962 0 00-.63-.88.961.961 0 00-1.062.192l-.035.035a1.168 1.168 0 11-1.65-1.65l.035-.036a.962.962 0 00.192-1.061.963.963 0 00-.88-.584h-.1a1.167 1.167 0 010-2.333h.053a.963.963 0 00.88-.63.963.963 0 00-.192-1.062l-.035-.035a1.167 1.167 0 111.65-1.65l.036.034a.962.962 0 001.061.193h.047a.962.962 0 00.583-.88v-.1a1.167 1.167 0 012.334 0v.052a.963.963 0 00.583.881.962.962 0 001.062-.192l.035-.035a1.167 1.167 0 111.65 1.65l-.034.036a.962.962 0 00-.193 1.061v.047a.963.963 0 00.88.583h.1a1.167 1.167 0 010 2.334h-.053a.963.963 0 00-.88.583z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9_23975">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default RecruitingIcon;
