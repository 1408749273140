import React, { FC } from 'react';
import { Input, Form, Row, Col, Select } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import { FormInstance } from 'antd/lib/form';
import { stateAbbreviationOptions } from 'src/common/constants';
import { motivationLevelOptions } from '../constants';
import {
  BuyerListingTypesFormItem,
  BuyerWorkingWithOtherAgentFormItem,
  BuyerIsFirstTimeBuyerFormItem,
  ClientFormSection,
  BuyerIsPrequalifiedFormItem
} from './index';

interface Props {
  form: FormInstance;
  client?: Client;
}

export const BuyingDetailsSection: FC<Props> = ({ form, client }) => (
  <ClientFormSection
    title="Buying Details"
    subtitle="Know your client's buying info? If so, enter it here. If not, skip this section."
  >
    <Form.Item
      label="Ready to Buy after"
      name={['buyerFields', 'timeframeStartDate']}
      initialValue={client?.buyerFields?.timeframeStartDate}
    >
      <Input size="large" autoComplete="new-password" type="date" />
    </Form.Item>

    <BuyerIsPrequalifiedFormItem
      onChange={isPreQualified =>
        form.setFieldsValue({
          buyerFields: { isPreQualified }
        })
      }
      initialValue={client?.buyerFields?.isPreQualified}
    />

    <BuyerListingTypesFormItem
      onChange={listingTypes =>
        form.setFieldsValue({
          buyerFields: { listingTypes }
        })
      }
      initialValue={client?.buyerFields?.listingTypes}
    />

    <Row gutter={[8, 0]}>
      <Col xs={24} sm={10}>
        <Form.Item
          label="City"
          name={['buyerFields', 'city']}
          initialValue={client?.buyerFields?.city}
        >
          <Input size="large" autoComplete="new-password" />
        </Form.Item>
      </Col>

      <Col xs={24} sm={4}>
        <Form.Item
          label="State"
          name={['buyerFields', 'state']}
          initialValue={client?.buyerFields?.state}
        >
          <Select size="large" options={stateAbbreviationOptions} />
        </Form.Item>
      </Col>

      <Col xs={24} sm={10}>
        <Form.Item
          label="Zip Code"
          name={['buyerFields', 'zipCode']}
          initialValue={client?.buyerFields?.zipCode}
        >
          <Input size="large" autoComplete="new-password" />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Min Price"
          name={['buyerFields', 'minPrice']}
          initialValue={client?.buyerFields?.minPrice}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Max Price"
          name={['buyerFields', 'maxPrice']}
          initialValue={client?.buyerFields?.maxPrice}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Lot Size Min (acres)"
          name={['buyerFields', 'minSquareFootage']}
          initialValue={client?.buyerFields?.minSquareFootage}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Lot Size Max (acres)"
          name={['buyerFields', 'maxSquareFootage']}
          initialValue={client?.buyerFields?.maxSquareFootage}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Home Size Min (sqft)"
          name={['buyerFields', 'minLivingArea']}
          initialValue={client?.buyerFields?.minLivingArea}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Home Size Max (sqft)"
          name={['buyerFields', 'maxLivingArea']}
          initialValue={client?.buyerFields?.maxLivingArea}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Beds Min"
          name={['buyerFields', 'minBeds']}
          initialValue={client?.buyerFields?.minBeds}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Beds Max"
          name={['buyerFields', 'maxBeds']}
          initialValue={client?.buyerFields?.maxBeds}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Baths Min"
          name={['buyerFields', 'minBaths']}
          initialValue={client?.buyerFields?.minBaths}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          label="Baths Max"
          name={['buyerFields', 'maxBaths']}
          initialValue={client?.buyerFields?.maxBaths}
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name={['buyerFields', 'yearBuilt']}
          initialValue={client?.buyerFields?.yearBuilt}
          label="Year Built"
        >
          <Input
            size="large"
            autoComplete="new-password"
            type="number"
            min={0}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Motivation Level"
          name={['buyerFields', 'motivationLevel']}
          initialValue={client?.buyerFields?.motivationLevel}
        >
          <Select size="large" options={motivationLevelOptions} />
        </Form.Item>
      </Col>
    </Row>

    {/* <Col span={24}>
          <FloatLabel
            name="sellerFields.homeCondition"
            label="Home Condition"
            value={floatValues?.homeCondition}
          >
            <Form.Item
              name={['buyerFields', 'homeCondition']}
              
              initialValue={client?.buyerFields?.homeCondition}
            >
              <Select
                size="large"
                options={homeConditionOptions}
                onChange={homeCondition =>
                  setFloatValues(previous => ({
                    ...previous,
                    homeCondition
                  }))
                }
              />
            </Form.Item>
          </FloatLabel>
        </Col> */}

    <BuyerWorkingWithOtherAgentFormItem
      onChange={workingWithOtherAgent =>
        form.setFieldsValue({
          buyerFields: { workingWithOtherAgent }
        })
      }
      initialValue={client?.buyerFields?.workingWithOtherAgent}
    />
    <BuyerIsFirstTimeBuyerFormItem
      onChange={isFirstTimeBuyer =>
        form.setFieldsValue({
          buyerFields: { isFirstTimeBuyer }
        })
      }
      initialValue={client?.buyerFields?.isFirstTimeBuyer}
    />
  </ClientFormSection>
);
