import { Alert } from 'antd';
import React from 'react';

export const DefaultError = () => {
  return (
    <Alert
      type="error"
      message="We're sorry!  An unexpected error has occurred.  Please contact support for help."
    />
  );
};
