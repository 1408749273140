// The breakpoints start at less than 414 all of that is moblile (mobile first)
// Anything greater than 1224 that would be desktop
export const SMALL_PHONE = '320px';
export const MEDIUM_PHONE = '375px';
export const LARGE_MOBILE = '425px';

export const TABLET = '768px';

export const LAPTOP = '1024px';
export const LARGE_LAPTOP = '1440px';
