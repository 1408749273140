import { Reducer, Dispatch } from 'react';
import { ClientHistory } from '@elm-street-technology/crm-axios-client';
import { clientHistoryApi } from 'src/common/services';

export interface HistoryTabState {
  data: ClientHistory[];
  loading: boolean;
}

export type HistoryTabActions =
  | { type: 'GetClientHistory' }
  | { type: 'GetClientHistoryPending'; payload: ClientHistory[] }
  | { type: 'GetClientHistoryFailure' };

type HistoryTabDispatch = Dispatch<HistoryTabActions>;

export const initialClientHistoryState: HistoryTabState = {
  data: [],
  loading: false
};

export const clientHistoryReducer: Reducer<
  HistoryTabState,
  HistoryTabActions
> = (state, action) => {
  switch (action.type) {
    case 'GetClientHistory':
      return {
        ...state,
        loading: true
      };
    case 'GetClientHistoryPending':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetClientHistoryFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const listClientHistory = (
  dispatch: HistoryTabDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetClientHistory' });

  clientHistoryApi
    .listClientHistory({ clientId })
    .then(({ data }) =>
      dispatch({ type: 'GetClientHistoryPending', payload: data })
    )
    .catch(error =>
      dispatch({
        type: 'GetClientHistoryFailure'
      })
    );
};
