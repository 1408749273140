import { Dispatch } from 'react';
import { message } from 'antd';
import { clientStopContactApi } from 'src/common/services';
import { ClientStopContactActions } from './ClientStopContact.reducer';

export default function stopContact(
  dispatch: Dispatch<ClientStopContactActions>,
  clientId: number
) {
  dispatch({ type: 'StopContactPending' });
  clientStopContactApi
    .clientStopContact({ clientId }, {})
    .then(() => {
      message.success('Success stopping contact');
      dispatch({ type: 'StopContactSuccess' });
    })
    .catch(error => {
      message.error('Error stopping contact');
      dispatch({ type: 'StopContactFailure' });
    });
}
