import React from 'react';

export const InviteClients = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-644 -96)">
          <g transform="translate(644 96)">
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M15.639 12.716a.482.482 0 00-.483.482v5.284c0 .799-.648 1.446-1.447 1.447H2.412c-.799 0-1.446-.648-1.447-1.447V7.185c0-.799.648-1.446 1.447-1.447h5.284a.482.482 0 100-.965H2.412A2.415 2.415 0 000 7.185v11.297a2.415 2.415 0 002.412 2.412H13.71a2.415 2.415 0 002.412-2.412v-5.284a.482.482 0 00-.482-.482z"
            />
            <g
              stroke="currentColor"
              strokeWidth="0.5"
              transform="translate(5.038 .212)"
            >
              <path d="M2.79 12.677h1.372c.744 0 1.402.276 1.875.727.476.453.764 1.082.766 1.777h0v.845l-6.22.364-.333-1.24c.01-.68.293-1.292.745-1.738a2.551 2.551 0 011.794-.735h0zm.737-3.836c.426 0 .822.153 1.128.41a1.708 1.708 0 01-.572 2.938h0l-.643.214-.563-.21a1.655 1.655 0 01-.772-.615l-.09-.143a1.765 1.765 0 01-.207-1.152c.069-.416.285-.776.59-1.032a1.75 1.75 0 011.129-.41zM10.234 10.181a4.724 4.724 0 01-3.469-1.495 4.772 4.772 0 113.47 1.496h0z" />
              <path strokeLinecap="round" d="M10.235 2.069L10.235 8.751" />
              <path strokeLinecap="round" d="M6.894 5.42L13.576 5.42" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InviteClients;
