import React, { FC } from 'react';
import { ClientPermission } from '@elm-street-technology/crm-axios-client';
import { Table, PageHeader, Checkbox, Divider } from 'antd';
import { ClientRecordSectionTitle } from '../../index';

interface Props {
  permissions: ClientPermission[];
}

export const PermissionsSection: FC<Props> = ({ permissions }) => (
  <PageHeader
    title={<ClientRecordSectionTitle>Permissions</ClientRecordSectionTitle>}
    footer={<Divider />}
  >
    <Table
      dataSource={permissions}
      pagination={false}
      columns={[
        {
          title: 'Name',
          dataIndex: 'displayName'
        },
        {
          title: 'View',
          dataIndex: 'canView',

          render: record => {
            return record ? <Checkbox checked /> : <Checkbox />;
          }
        },
        {
          title: 'Modify',
          dataIndex: 'canModify',

          render: record => {
            return record ? <Checkbox checked /> : <Checkbox />;
          }
        },
        {
          title: 'Transfer',
          dataIndex: 'canTransfer',

          render: record => {
            return record ? <Checkbox checked /> : <Checkbox />;
          }
        }
      ]}
    />
  </PageHeader>
);
