import React, { useMemo, FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BellFilled,
  DeleteOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import {
  ClientSavedSearch,
  PropertyPreferences,
  ClientAbility
} from '@elm-street-technology/crm-axios-client';
import { Modal, Switch, Card } from 'antd';
import styled from 'styled-components';

import { TABLET } from 'src/common/constants';
import { FeatureTogglesContext } from 'src/common/contexts';
import {
  listingTypesToDisplay,
  propertyPrefAdditionalToString,
  navigateToEditClientSavedSearch,
  navigateToListings,
  formatDollarPriceRange
} from 'src/common/utils';

import { ClientRecordDrawerStateContext } from '../../../../ClientRecordDrawer.context';

const StyledTitle = styled.div`
  cursor: pointer;
`;

const StyledSavedSearchCard = styled(Card)`
  margin-bottom: 12px;
  width: 313px;
  .ant-card-head {
    background-color: #f7f9fa;

    .ant-card-head-title {
      h2 {
        font-size: 16px;
        line-height: 18px;
        color: #082746;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: #949494;
      }
    }

    .ant-card-extra {
      > span.anticon {
        margin-right: 8px;
      }
      .ant-switch-inner {
        font-size: 10px;
      }
      .ant-switch-checked {
        background-color: #0091ff;
      }
    }
  }

  .ant-card-body {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .ant-card-actions {
    .ant-switch-checked {
      background-color: #428fdc;
    }
  }

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: ${TABLET}) {
    max-width: 320px;
  }
`;
const CardBodyTitleLable = styled.span`
  margin-bottom: 4px;
  height: 16px;
  font-size: 14px;
  color: #949494;
`;
const CardBodyData = styled.span`
  font-size: 15px;
  color: #082746;
`;

const ActionButton = styled('span')<{ enableRealtimeReports?: boolean }>`
  display: ${({ enableRealtimeReports }) =>
    enableRealtimeReports ? 'inline-flex' : 'flex'};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: ${({ enableRealtimeReports }) =>
    enableRealtimeReports ? '12px' : 'auto'};

  p {
    font-size: 14px;
    color: ${({ theme }) => theme.lightBlueGrey};
  }
  span.anticon {
    font-size: ${({ enableRealtimeReports }) =>
      enableRealtimeReports ? '18px' : 'auto'};
    svg {
      margin-right: ${({ enableRealtimeReports }) =>
        enableRealtimeReports ? '0' : '8px'};
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

interface Props {
  savedSearch: ClientSavedSearch;
  updating: boolean;
  clientId: number;

  onDelete: () => void;
  onMorningReportsToggle: () => void;
  onRealTimeReportsToggle?: () => void;
}

export const SavedSearchCard: FC<Props> = ({
  clientId,
  updating,
  savedSearch,
  onDelete,
  onMorningReportsToggle,
  onRealTimeReportsToggle
}) => {
  const navigate = useNavigate();
  const {
    abilities: { data: clientAbilities }
  } = useContext(ClientRecordDrawerStateContext);

  const canManage = clientAbilities.includes(ClientAbility.Manageclient);

  const {
    featureToggles: { 'realtime-reports': enableRealtimeReports }
  } = useContext(FeatureTogglesContext);

  const {
    name,
    propertyPrefs,
    shouldReceiveNotifications,
    enableRealtimeNotifications
  } = savedSearch;

  const {
    type,
    cities,
    priceRange,
    numBaths,
    builtYear,
    livingAreaFt,
    lotSizeAreaFt,
    numBeds,
    ...rest
  } = propertyPrefs || {};

  const displayCities = cities?.join(', ') || '';

  const priceRangeDisplay = formatDollarPriceRange(
    priceRange?.min?.value || 0,
    priceRange?.max?.value || 0
  );

  const bedsDisplay = (beds: PropertyPreferences['numBeds']) =>
    beds && beds.min ? `${beds.min}+ Beds` : null;

  const bathsDisplay = (baths: PropertyPreferences['numBaths']) =>
    baths?.min && baths.min >= 1 ? `${baths.min}+ Baths` : null;

  const sqftDisplay = (sqft: PropertyPreferences['livingAreaFt']) =>
    sqft?.min ? `>${sqft.min} sqft` : null;

  const lotSizeFtDisplay = (lotSizeFt: PropertyPreferences['lotSizeAreaFt']) =>
    lotSizeFt?.min ? `>${lotSizeFt.min} lot` : null;

  const builtDisplay = (built: PropertyPreferences['builtYear']) =>
    built?.min ? `Built: >${built?.min}` : null;

  const buildAdditionalDisplay = () => {
    const mainItems = [
      bedsDisplay(numBeds),
      bathsDisplay(numBaths),
      sqftDisplay(livingAreaFt),
      lotSizeFtDisplay(lotSizeAreaFt),
      builtDisplay(builtYear)
    ].filter(value => !!value);
    const additionalPropertyItems = propertyPrefAdditionalToString(rest || {});
    if (mainItems.length) {
      if (additionalPropertyItems) {
        return `${mainItems.join(', ')}, ${additionalPropertyItems}`;
      }
      return `${mainItems.join(', ')}`;
    }
    return additionalPropertyItems;
  };

  const additionalDisplay = buildAdditionalDisplay();

  const handleDeleteClick = useCallback(() => {
    if (!updating) {
      Modal.confirm({
        title: 'Are you sure you want to delete this saved search?',
        icon: <ExclamationCircleOutlined />,
        cancelText: 'No',
        okButtonProps: {
          danger: true
        },
        okText: 'Yes - Delete',
        onOk: () => onDelete()
      });
    }
  }, [onDelete, updating]);

  const handleEditClick = () => {
    navigateToEditClientSavedSearch(navigate, clientId, savedSearch);
  };

  const handleTitleClick = () => {
    navigateToListings(navigate, savedSearch.queryString);
  };

  const extra = useMemo(() => {
    // edit and delete buttons in corner
    if (canManage && enableRealtimeReports) {
      return (
        <>
          <ActionButton
            onClick={handleEditClick}
            enableRealtimeReports={enableRealtimeReports}
          >
            <EditFilled />
          </ActionButton>
          <ActionButton
            onClick={handleDeleteClick}
            enableRealtimeReports={enableRealtimeReports}
          >
            <DeleteFilled />
          </ActionButton>
        </>
      );
    }

    // morning reports switch in corner
    if (canManage) {
      return (
        <>
          <BellFilled style={{ cursor: 'default' }} />
          <Switch
            disabled={updating}
            loading={updating}
            checked={shouldReceiveNotifications}
            onChange={onMorningReportsToggle}
            size="small"
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        </>
      );
    }

    return undefined;
  }, [
    updating,
    canManage,
    enableRealtimeReports,
    shouldReceiveNotifications,
    handleDeleteClick,
    handleEditClick,
    onMorningReportsToggle
  ]);

  const actions = useMemo(() => {
    if (canManage && enableRealtimeReports) {
      const morningReportsAction = (
        <>
          <p>Morning Reports</p>
          <Switch
            disabled={updating}
            loading={updating}
            checked={shouldReceiveNotifications}
            onChange={onMorningReportsToggle}
            size="small"
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        </>
      );

      const realtimeAction = (
        <>
          <p>Instant Alerts</p>
          <Switch
            disabled={updating}
            loading={updating}
            checked={enableRealtimeNotifications}
            onChange={onRealTimeReportsToggle}
            size="small"
            checkedChildren="ON"
            unCheckedChildren="OFF"
          />
        </>
      );

      return [morningReportsAction, realtimeAction];
    }

    if (canManage) {
      const editAction = (
        <ActionButton onClick={handleEditClick}>
          <EditFilled />
          <p>Edit</p>
        </ActionButton>
      );

      const deleteAction = (
        <ActionButton onClick={handleDeleteClick}>
          <DeleteOutlined />
          <p>Delete</p>
        </ActionButton>
      );

      return [editAction, deleteAction];
    }

    return [];
  }, [
    updating,
    canManage,
    enableRealtimeReports,
    enableRealtimeNotifications,
    shouldReceiveNotifications,
    handleEditClick,
    handleDeleteClick,
    onMorningReportsToggle,
    onRealTimeReportsToggle
  ]);

  return (
    <StyledSavedSearchCard
      title={
        <StyledTitle onClick={handleTitleClick}>
          <h2>{name}</h2>
        </StyledTitle>
      }
      extra={extra}
      actions={actions}
    >
      <CardBodyTitleLable>Listing Type</CardBodyTitleLable>
      <CardBodyData>
        {type && type.length ? listingTypesToDisplay(type) : ''}
      </CardBodyData>

      {displayCities && (
        <>
          <CardBodyTitleLable>Cities</CardBodyTitleLable>
          <CardBodyData>{displayCities}</CardBodyData>
        </>
      )}

      {priceRangeDisplay && (
        <>
          <CardBodyTitleLable>Price</CardBodyTitleLable>
          <CardBodyData>{priceRangeDisplay}</CardBodyData>
        </>
      )}

      <CardBodyTitleLable>Additional</CardBodyTitleLable>
      <CardBodyData>{additionalDisplay}</CardBodyData>
    </StyledSavedSearchCard>
  );
};
