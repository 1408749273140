export const typeOptions = [
  { value: 'none', label: 'None' },
  { value: 'buyer', label: 'Buyer' },
  { value: 'seller', label: 'Seller' },
  { value: 'buyer/seller', label: 'Buyer/Seller' },
  { value: 'vendor', label: 'Vendor' },
  { value: 'family', label: 'Family' },
  { value: 'friend', label: 'Friend' },
  { value: 'personal', label: 'Personal' },
  { value: 'past_client', label: 'Past Client' },
  { value: 'crm_agent', label: 'CRM Agent' },
  { value: 'prospective_agent', label: 'Prospective Agent' },
  { value: 'renter', label: 'Renter' }
];

export const sellerIntentOptions = [
  'Sell immediately',
  'Sell within 3 months',
  'Sell within 3-6 months',
  'Sell within 4-12 months',
  'Sell within 6-12 months',
  'Sell in a year or more',
  'Refinance',
  'Stay where I\u2019m at',
  'Communicate with a local Real Estate Pro'
].map(value => ({ value, label: value }));

export const motivationLevelOptions = ['low', 'medium', 'high'].map(value => ({
  value,
  label: value
}));

export const homeConditionOptions = [
  'Perfect Condition',
  'Good Condition',
  'Average Condition',
  'Poor Condition',
  'Fixer-Upper'
].map(value => ({ value, label: value }));
