import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { abilityApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const listClientAbilities = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetClientAbilitiesPending' });

  abilityApi
    .listClientAbilities({ clientId })
    .then(({ data }) =>
      dispatch({ type: 'GetClientAbilitiesSuccess', payload: data })
    )
    .catch(error => {
      message.error(getFetchMessageText('client abilities').error);
      dispatch({ type: 'GetClientAbilitiesFailure' });
    });
};
