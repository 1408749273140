import { message } from 'antd';
import { clientSavedSearchApi } from 'src/common/services';
import { getDeleteMessageText } from 'src/common/utils';
import { ClientSavedSearchesDispatch } from '../SavedSearchesSection.reducer';

export const deleteClientSavedSearch = (
  dispatch: ClientSavedSearchesDispatch,
  clientId: number,
  searchId: number
) => {
  dispatch({ type: 'Delete' });

  clientSavedSearchApi
    .deleteClientSavedSearch({ clientId, searchId })
    .then(() => {
      dispatch({ type: 'DeleteSuccess', payload: searchId });
      message.success(getDeleteMessageText('client saved search').success);
    })
    .catch(error => {
      dispatch({
        type: 'DeleteFailure',
        payload: error
      });
      message.error(getDeleteMessageText('client saved search').error);
    });
};
