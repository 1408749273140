import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    width="11px"
    height="7px"
    viewBox="0 0 11 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>email icon 2</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Cards-/-Mobile-/-Broker-Recruiting"
        transform="translate(-160.000000, -61.000000)"
        fill="#082746"
      >
        <g id="email-icon-2" transform="translate(160.632812, 61.000000)">
          <path
            d="M9,2.22044605e-15 L1,2.22044605e-15 C0.45,2.22044605e-15 0.005,0.39375 0.005,0.875 L9.86864911e-16,6.125 C9.86864911e-16,6.60625 0.45,7 1,7 L9,7 C9.55,7 10,6.60625 10,6.125 L10,0.875 C10,0.39375 9.55,2.22044605e-15 9,2.22044605e-15 L9,2.22044605e-15 Z M9,1.75 L5,3.9375 L1,1.75 L1,0.875 L5,3.0625 L9,0.875 L9,1.75 L9,1.75 Z"
            id="email-icon"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const ProspectiveAgentEmailIcon = props => (
  <Icon component={Svg} {...props} />
);
