import * as React from 'react';

export default function(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
      <defs>
        <circle id="path-1" cx="19.7214347" cy="19.7214347" r="19.7214347" />
        <filter
          x="-39.3%"
          y="-29.2%"
          width="178.6%"
          height="178.6%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="4"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.03 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Buttons-/Call-Copy"
          transform="translate(-12.000000, -11.000000)"
        >
          <g id="Icon/Call">
            <g id="Icon/message">
              <g id="Oval">
                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
                  <use fill="#2E7FC2" fillRule="evenodd" />
                </g>
              </g>
            </g>
            <g
              id="call-answer"
              transform="translate(12.459016, 11.803279)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M16.6669688,13.4850801 L14.0339889,10.8458397 C13.509548,10.3223215 12.6410638,10.3382273 12.0983531,10.8823642 L10.7718464,12.2115819 C10.6880397,12.165288 10.6012942,12.1169323 10.5100913,12.0656311 C9.67241627,11.6004343 8.52591525,10.9628261 7.31946135,9.75275495 C6.10943182,8.54013098 5.47267729,7.38926487 5.00711177,6.54915175 C4.95798371,6.46014778 4.91091285,6.37433478 4.86442977,6.2928419 L5.75471057,5.40186937 L6.19240583,4.96264234 C6.73594929,4.41772003 6.75093751,3.54751346 6.22772122,3.02247344 L3.59474125,0.382938476 C3.07152495,-0.141365156 2.20264891,-0.125459316 1.65910544,0.419462999 L0.917041515,1.16747933 L0.937319697,1.18765619 C0.68849562,1.50587118 0.480570777,1.87288373 0.325839426,2.26866424 C0.183206412,2.64539702 0.0944036462,3.00489847 0.0537982998,3.3651363 C-0.293869672,6.25391186 1.02323258,8.89403593 4.59767861,12.4766301 C9.53864884,17.4284521 13.5204218,17.0543703 13.6921986,17.0361081 C14.0663164,16.991287 14.4248582,16.9016939 14.7892287,16.7598668 C15.1806858,16.6066013 15.5466237,16.3984999 15.8639234,16.149652 L15.8801362,16.1640851 L16.6318983,15.4262799 C17.1743152,14.8814558 17.1899402,14.0109547 16.6669688,13.4850801 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
