import React, { FC } from 'react';
import { Input, Form, Row, Col, Select } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import { FormInstance } from 'antd/lib/form';
import { stateAbbreviationOptions } from 'src/common/constants';
import {
  SellerListingTypeFormItem,
  SellerWorkingWithOtherAgentFormItem,
  ClientFormSection
} from './index';
import {
  sellerIntentOptions,
  motivationLevelOptions,
  homeConditionOptions
} from '../constants';

interface Props {
  form: FormInstance;
  client?: Client;
}

export const SellingDetailsSection: FC<Props> = ({ form, client }) => {
  const validateLivingArea = (value: string) => {
    return Number(value?.replace(/[^\d]/g, ''));
  };
  const livingAreaRules = [
    { required: false },
    ({ getFieldValue }) => ({
      validator() {
        const numberOnlyRegEx = /^[0-9]+$/;
        if (
          numberOnlyRegEx.test(getFieldValue(['sellerFields', 'livingArea']))
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Only numbers allowed in this field'));
      }
    })
  ];

  return (
    <ClientFormSection
      title="Selling Details"
      subtitle="Know your client's selling info? If so, enter it here. If not, skip this section."
    >
      <Form.Item
        label="Ready to Sell after"
        name={['sellerFields', 'timeframeStartDate']}
        initialValue={client?.sellerFields?.timeframeStartDate}
      >
        <Input size="large" autoComplete="new-password" type="date" />
      </Form.Item>

      <Form.Item
        label="Intent"
        name={['sellerFields', 'intent']}
        initialValue={client?.sellerFields?.intent}
      >
        <Select size="large" options={sellerIntentOptions} />
      </Form.Item>

      <SellerListingTypeFormItem
        onChange={listingType => {
          form.setFieldsValue({
            sellerFields: { listingType }
          });
        }}
        initialValue={client?.sellerFields?.listingType}
      />

      <Form.Item
        label="Street Address"
        name={['sellerFields', 'streetAddress1']}
        initialValue={client?.sellerFields?.streetAddress1}
      >
        <Input size="large" autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        label="Street Address 2"
        name={['sellerFields', 'streetAddress2']}
        initialValue={client?.sellerFields?.streetAddress2}
      >
        <Input size="large" autoComplete="new-password" />
      </Form.Item>

      <Row gutter={[8, 0]}>
        <Col xs={24} sm={10}>
          <Form.Item
            label="City"
            name={['sellerFields', 'city']}
            initialValue={client?.sellerFields?.city}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={4}>
          <Form.Item
            label="State"
            name={['sellerFields', 'state']}
            initialValue={client?.sellerFields?.state}
          >
            <Select size="large" options={stateAbbreviationOptions} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={10}>
          <Form.Item
            label="Zip Code"
            name={['sellerFields', 'zipCode']}
            initialValue={client?.sellerFields?.zipCode}
          >
            <Input size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Lot Size (acres)"
        name={['sellerFields', 'squareFootage']}
        initialValue={client?.sellerFields?.squareFootage}
      >
        <Input size="large" autoComplete="new-password" type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Home Size (sqft)"
        name={['sellerFields', 'livingArea']}
        initialValue={validateLivingArea(
          String(client?.sellerFields?.livingArea)
        )}
        rules={livingAreaRules}
      >
        <Input size="large" autoComplete="new-password" type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Beds"
        name={['sellerFields', 'beds']}
        initialValue={client?.sellerFields?.beds ?? undefined}
      >
        <Input size="large" autoComplete="new-password" type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Baths"
        name={['sellerFields', 'baths']}
        initialValue={client?.sellerFields?.baths ?? undefined}
      >
        <Input size="large" autoComplete="new-password" type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Year Built"
        name={['sellerFields', 'yearBuilt']}
        initialValue={client?.sellerFields?.yearBuilt ?? undefined}
      >
        <Input size="large" autoComplete="new-password" type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Estimated List Price"
        name={['sellerFields', 'estimatedListPrice']}
        initialValue={client?.sellerFields?.estimatedListPrice ?? undefined}
      >
        <Input size="large" autoComplete="new-password" type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Motivation Level"
        name={['sellerFields', 'motivationLevel']}
        initialValue={client?.sellerFields?.motivationLevel}
      >
        <Select size="large" options={motivationLevelOptions} />
      </Form.Item>

      <Form.Item
        label="Home Condition"
        name={['sellerFields', 'homeCondition']}
        initialValue={client?.sellerFields?.homeCondition ?? undefined}
      >
        <Select size="large" options={homeConditionOptions} />
      </Form.Item>

      <Form.Item
        label="Reason for Selling"
        name={['sellerFields', 'reasonForSelling']}
        initialValue={client?.sellerFields?.reasonForSelling ?? undefined}
      >
        <Input size="large" autoComplete="new-password" />
      </Form.Item>

      <SellerWorkingWithOtherAgentFormItem
        onChange={workingWithOtherAgent =>
          form.setFieldsValue({
            sellerFields: { workingWithOtherAgent }
          })
        }
        initialValue={client?.sellerFields?.workingWithOtherAgent}
      />
    </ClientFormSection>
  );
};
