import { DocUpdateBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { docApi } from 'src/common/services';
import { getUpdateMessageText } from 'src/common/utils';
import { DocsDispatch } from '../DocsTab.reducer';

export const updateDoc = (
  dispatch: DocsDispatch,
  clientId: number,
  id: number,
  docUpdateBody: DocUpdateBody
) => {
  dispatch({ type: 'UpdateDoc' });

  docApi
    .updateDoc({ clientId, id, docUpdateBody })
    .then(({ data }) => {
      dispatch({ type: 'UpdateDocSuccess', payload: data });
      message.success(getUpdateMessageText('client document').success);
    })
    .catch(error => {
      dispatch({
        type: 'UpdateDocFailure',
        payload: error
      });
      message.error(getUpdateMessageText('client document').error);
    });
};
