import { TablePaginationConfig } from 'antd/lib/table';
import { LatLngTuple } from 'leaflet';

// TODO(spond): Not sure why this doesn't work in tables.
export const DEFAULT_DASHBOARD_PAGINATION: TablePaginationConfig = {
  simple: true,
  pageSize: 5,
  defaultCurrent: 1
};

export const DEFAULT_ACTIVITY_PAGINATION: TablePaginationConfig = {
  simple: true,
  pageSize: 4,
  defaultCurrent: 1
};

export const DEFAULT_PAGINATION: TablePaginationConfig = {
  simple: true,
  pageSize: 25,
  defaultCurrent: 1
};

export const ACTIVE_CLIENT_STATUSES: Array<
  | 'new'
  | 'marketing'
  | 'engaged'
  | 'active'
  | 'pending'
  | 'sold'
  | 'cold'
  | 'archived'
  | 'none'
> = ['new', 'marketing', 'engaged', 'active', 'sold', 'pending', 'none'];

export const PARAM_SHOW_DASHBOARD = 'showDashboard';

export const enLocale = {
  tableFrom: {
    search: 'Query',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select'
  },
  alert: {
    clear: 'Clear'
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact'
  }
};

export const DEFAULT_VIEWPORT = {
  center: [41.850033, -87.6500523] as LatLngTuple,
  zoom: 8
};

export const PERMANENT_TAGS = [
  'Concierge - Contacting',
  'Concierge - Not Interested',
  'Concierge - Interested',
  'Concierge - Stop Contacting'
];
