import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { LARGE_MOBILE } from 'src/common/constants';

interface LayoutProps {
  buttons?: JSX.Element;
  formItem?: JSX.Element;
}

const DockedButtonsWrapped = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    > div:first-child {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .buttons {
      display: flex;
      align-self: flex-end;
      bottom: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-top: 25px;
      right: 0;
      > button {
        min-width: 144px;
      }
    }

    .form-item {
      align-self: stretch;

      .ant-row.ant-form-item {
        flex: 1;

        .ant-form-item-control-input-content {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  @media only screen and (max-width: ${LARGE_MOBILE}) {
    padding: 0;
  }
`;

export function DockedButtons({
  children,
  buttons,
  formItem
}: PropsWithChildren<LayoutProps>) {
  return (
    <DockedButtonsWrapped>
      <div className="content">
        <div>{children}</div>
        {buttons && <div className="buttons">{buttons}</div>}
        {formItem && <div className="buttons form-item">{formItem}</div>}
      </div>
    </DockedButtonsWrapped>
  );
}
