import { Reducer } from 'react';
import { ClientAssignment } from '@elm-street-technology/crm-axios-client';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export interface ClientAssignmentState {
  data: ClientAssignment | null;
  loading: boolean;
  updating: boolean;
}

export type ClientAssignmentActions =
  | { type: 'GetAssignmentPending' }
  | { type: 'GetAssignmentSuccess'; payload: ClientAssignment }
  | { type: 'GetAssignmentFailure' }
  | { type: 'UpdateAssignmentPending' }
  | { type: 'UpdateAssignmentSuccess'; payload: ClientAssignment }
  | { type: 'UpdateAssignmentFailure' };

export const initialClientAssignmentState: ClientAssignmentState = {
  data: null,
  loading: false,
  updating: false
};

export const clientAssignmentReducer: Reducer<
  ClientAssignmentState,
  ClientRecordDrawerActions
> = (state, action): ClientAssignmentState => {
  switch (action.type) {
    case 'GetAssignmentPending':
      return {
        ...state,
        loading: true
      };
    case 'GetAssignmentSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetAssignmentFailure':
      return {
        ...state,
        loading: false
      };
    case 'UpdateAssignmentPending':
      return {
        ...state,
        updating: true
      };
    case 'UpdateAssignmentSuccess':
      return {
        ...state,
        data: action.payload,
        updating: false
      };
    case 'UpdateAssignmentFailure':
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
};
