import { useNavigate } from 'react-router-dom';
import {
  UserSavedSearch,
  BulkActionLeadFilter,
  ClientSavedSearch
} from '@elm-street-technology/crm-axios-client';

export const navigateToEditUserSavedSearch = (
  navigate: ReturnType<typeof useNavigate>,
  savedSearch: UserSavedSearch
) =>
  navigate(`/saved-searches/edit/${savedSearch.id}`, {
    state: { savedSearch }
  });

export const navigateToListings = (
  navigate: ReturnType<typeof useNavigate>,
  queryString: string
) => navigate(`/listings?${queryString}`);

export const navigateToClientRecordDrawer = (
  navigate: ReturnType<typeof useNavigate>,
  clientId: number
) => navigate(`/clients/${clientId}`);

export const navigateToListingsPdpDrawer = (
  navigate: ReturnType<typeof useNavigate>,
  listingId: string
) => navigate(`/listings/${listingId}`);

export const navigateToListingsMonthlySummary = (
  navigate: ReturnType<typeof useNavigate>,
  listingId: string
) => {
  window.location.href = `/listings/${listingId}/monthly-summary`;
};

export const navigateToCreateBulkSavedSearch = (
  navigate: ReturnType<typeof useNavigate>,
  leadFilter: BulkActionLeadFilter
) =>
  navigate(`/clients/saved-searches/new`, {
    state: { leadFilter }
  });

export const navigateToEditClientSavedSearch = (
  navigate: ReturnType<typeof useNavigate>,
  clientId: number,
  savedSearch: ClientSavedSearch
) =>
  navigate(`/clients/${clientId}/saved-searches/edit/${savedSearch.id}`, {
    state: { savedSearch }
  });

export const navigateToCreateClientSavedSearch = (
  navigate: ReturnType<typeof useNavigate>,
  clientId: number
) => navigate(`/clients/${clientId}/saved-searches`);

export const navigateToClients = (navigate: ReturnType<typeof useNavigate>) =>
  navigate(`/clients`);

export const navigateToCreateSavedLink = (
  navigate: ReturnType<typeof useNavigate>
) => navigate(`/idx-sites/saved-links/add`);

export const navigateToEditSavedLink = (
  navigate: ReturnType<typeof useNavigate>,
  savedLinkId: number
) => navigate(`/idx-sites/saved-links/${savedLinkId}/edit`);

export const navigateToSavedLinkPreview = (previewUrl: string) =>
  window.open(previewUrl, '_blank')?.focus();
