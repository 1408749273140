import axios from 'axios';
import { message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { docApi } from 'src/common/services';
import { getCreateMessageText } from 'src/common/utils';
import { DocsDispatch } from '../DocsTab.reducer';

export const createDoc = (
  dispatch: DocsDispatch,
  clientId: number,
  name: string,
  description: string,
  file: UploadFile
) => {
  dispatch({ type: 'CreateDoc' });

  const reader = new FileReader();
  reader.onload = async () => {
    try {
      const { data } = await docApi.createLeadDocSignedURL({
        clientId,
        filename: file.name,
        contentType: file.type
      });

      if (!data.url || !data.filePath) {
        throw new Error('no signed URL created');
      }

      await axios.put(data.url, reader.result, {
        headers: { 'content-type': file.type }
      });

      const { data: doc } = await docApi.createDoc({
        clientId,
        docCreateBody: {
          name,
          description,
          path: data.filePath,
          filename: file.name
        }
      });
      dispatch({ type: 'CreateDocSuccess', payload: doc });
      message.success(getCreateMessageText('client document').success);
    } catch (error) {
      dispatch({
        type: 'CreateDocFailure',
        payload: error
      });
      message.error(getCreateMessageText('client document').error);
    }
  };
  reader.readAsArrayBuffer((file as unknown) as Blob);
};
