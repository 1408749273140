import {
  UserEntity,
  UserUpdateBody
} from '@elm-street-technology/crm-axios-client';
import { Reducer, Dispatch } from 'react';
import { usersApi } from 'src/common/services';
import { message } from 'antd';
import { getUpdateMessageText } from 'src/common/utils/textUtils';

export type ProfileActions =
  | { type: 'GetUserPending' }
  | { type: 'GetUserSuccess'; payload: UserEntity }
  | { type: 'GetUserFailure' }
  | { type: 'UpdateUserPending' }
  | { type: 'UpdateUserSuccess'; payload: UserEntity }
  | { type: 'UpdateUserFailure' }
  | { type: 'CloseVoipLinkDrawer' }
  | { type: 'OpenVoipLinkDrawer' };

export interface ProfileDrawerState {
  key: 'LinkVoip' | '';
}

export interface ProfileState {
  userEntity: UserEntity | null;
  loading: boolean;
  updating: boolean;
  drawer: ProfileDrawerState;
}

export const initialProfileState: ProfileState = {
  userEntity: null,
  loading: false,
  updating: false,
  drawer: { key: '' }
};

export type ProfileDispatch = Dispatch<ProfileActions>;
export const profileReducer: Reducer<ProfileState, ProfileActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'GetUserPending':
      return {
        ...state,
        loading: true
      };
    case 'GetUserSuccess':
      return {
        ...state,
        userEntity: action.payload,
        loading: false
      };
    case 'GetUserFailure':
      return {
        ...state,
        loading: false
      };
    case 'UpdateUserPending':
      return {
        ...state,
        updating: true
      };

    case 'UpdateUserSuccess':
      return {
        ...state,
        userEntity: action.payload,
        updating: false
      };

    case 'UpdateUserFailure':
      return {
        ...state,
        updating: false
      };

    case 'CloseVoipLinkDrawer':
      return {
        ...state,
        drawer: { key: '' }
      };

    case 'OpenVoipLinkDrawer':
      return {
        ...state,
        drawer: { key: 'LinkVoip' }
      };

    default:
      return state;
  }
};
// Actions
export const getUser = (dispatch: ProfileDispatch, userId: number) => {
  dispatch({ type: 'GetUserPending' });
  usersApi
    .getUser({ userId })
    .then(({ data }) => dispatch({ type: 'GetUserSuccess', payload: data }))
    .catch(() => dispatch({ type: 'GetUserFailure' }));
};

export const updateUser = (
  dispatch: ProfileDispatch,
  userId: number,
  userUpdateBody: UserUpdateBody
): Promise<void> => {
  dispatch({ type: 'UpdateUserPending' });
  return usersApi
    .updateUser({
      userId,
      userUpdateBody
    })
    .then(({ data }) => {
      dispatch({ type: 'UpdateUserSuccess', payload: data });
      message.success(getUpdateMessageText('profile').success);
    })
    .catch(error => {
      dispatch({
        type: 'UpdateUserFailure'
      });
      message.error(getUpdateMessageText('profile').error);
    });
};
