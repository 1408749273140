import React, { FC, useEffect, useState, CSSProperties } from 'react';
import { Form, Row, Col, Tag } from 'antd';
import Icon from '@ant-design/icons';
import {
  BusinessOp as BusinessOpSvg,
  Commercial as CommercialSvg,
  Condo as CondoSvg,
  House as SingleFamilySvg,
  Mobile as MobileSvg,
  MultiFamilyHouse as MultiFamilySvg,
  ResidIncome as ResidIncomeSvg,
  TownHouse as TownhouseSvg
} from 'src/crm/assets/icons/buy_sell_prefs';
import { LandIconSvg } from 'src/crm/assets/icons/buy_sell_prefs/LandIconSvg';

const style = {
  tag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 58,
    width: 85
  } as CSSProperties
};

interface Props {
  onChange: (listingType?: string) => void;
  initialValue?: string;
}

export const SellerListingTypeFormItem: FC<Props> = ({
  onChange,
  initialValue
}) => {
  const [listingType, setListingType] = useState(initialValue);

  useEffect(() => {
    onChange(listingType);
  }, [listingType]);

  const handleBuyerListingTypeClick = (listingType: string) => e =>
    setListingType(previous =>
      previous === listingType ? undefined : listingType
    );

  return (
    <Form.Item
      label="Property Type"
      name={['sellerFields', 'listingType']}
      noStyle
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Tag
            color={listingType === 'Single Family' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Single Family')}
            style={style.tag}
          >
            <>
              <Icon component={SingleFamilySvg} />
              Single Family
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Condo' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Condo')}
            style={style.tag}
          >
            <>
              <Icon component={CondoSvg} />
              Condo
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Townhouse' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Townhouse')}
            style={style.tag}
          >
            <>
              <Icon component={TownhouseSvg} />
              Townhouse
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'MultiFamily' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('MultiFamily')}
            style={style.tag}
          >
            <>
              <Icon component={MultiFamilySvg} />
              Multi-Family
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Mobile' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Mobile')}
            style={style.tag}
          >
            <>
              <Icon component={MobileSvg} />
              Mobile
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Commercial' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Commercial')}
            style={style.tag}
          >
            <>
              <Icon component={CommercialSvg} />
              Commercial
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Business Opportunity' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Business Opportunity')}
            style={style.tag}
          >
            <>
              <Icon component={BusinessOpSvg} />
              Business Op.
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Residential Income' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Residential Income')}
            style={style.tag}
          >
            <>
              <Icon component={ResidIncomeSvg} />
              Resid. Income
            </>
          </Tag>
        </Col>

        <Col>
          <Tag
            color={listingType === 'Land' ? 'blue' : 'default'}
            onClick={handleBuyerListingTypeClick('Land')}
            style={style.tag}
          >
            <>
              <Icon component={LandIconSvg} />
              Land
            </>
          </Tag>
        </Col>
      </Row>
    </Form.Item>
  );
};
