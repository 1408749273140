import { Form, Input, Button, Row, Col } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { Doc } from '@elm-street-technology/crm-axios-client';
import { Flex, FloatLabel } from 'src/crm/components';
import { DocsStateContext, DocsDispatchContext } from '../DocsTab.context';
import { updateDoc } from '../actions';

import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '.';

interface Props {
  clientId: number;
  editDoc: Doc;
}

export const DocEditor: FC<Props> = ({ clientId, editDoc }) => {
  const { loading } = useContext(DocsStateContext);
  const dispatch = useContext(DocsDispatchContext);
  const [floatValues, setFloatValues] = useState({
    name: editDoc.name || '',
    description: editDoc.description || ''
  });

  const [form] = Form.useForm();

  const handleCancelClick = () => {
    dispatch({ type: 'ShowList' });
  };

  const handleFormFinish = values => {
    const { description, name } = values;

    if (editDoc) {
      updateDoc(dispatch, clientId, editDoc.id, {
        name,
        description
      });
    }
  };

  const handleFormValuesChange = formValues =>
    setFloatValues(previous => ({
      ...previous,
      ...formValues
    }));

  const nameInput = (
    <FloatLabel label="name" name="name" value={floatValues.name}>
      <Form.Item
        name="name"
        initialValue={editDoc.name || ''}
        rules={[{ required: true }]}
      >
        <Input maxLength={MAX_NAME_LENGTH} disabled={loading} />
      </Form.Item>
    </FloatLabel>
  );

  const descriptionTextArea = (
    <FloatLabel
      label="description"
      name="description"
      value={floatValues.description}
    >
      <Form.Item
        name="description"
        initialValue={editDoc.description || ''}
        rules={[{ required: true }]}
      >
        <Input.TextArea
          showCount
          maxLength={MAX_DESCRIPTION_LENGTH}
          disabled={loading}
        />
      </Form.Item>
    </FloatLabel>
  );

  const buttons = (
    <Row gutter={[8, 8]} justify="end">
      <Col>
        <Button size="large" onClick={handleCancelClick}>
          Cancel
        </Button>
      </Col>
      <Col>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            loading={loading}
            disabled={loading}
            htmlType="submit"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <Form
      form={form}
      initialValues={undefined}
      validateMessages={{
        required: 'This field is required!'
      }}
      onFinish={handleFormFinish}
      onValuesChange={handleFormValuesChange}
    >
      <Flex
        alignContent="center"
        justifyContent="center"
        flexDirection="column"
      >
        {nameInput}
        {descriptionTextArea}
        {buttons}
      </Flex>
    </Form>
  );
};
