import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export type ClientRecordNestedDrawerKey = '' | 'UpdateClient';

export type ClientRecordNestedDrawerActions =
  | { type: 'OpenUpdateClientDrawer' }
  | { type: 'CloseNestedDrawer' };

export const clientRecordNestedDrawerReducer: Reducer<
  ClientRecordNestedDrawerKey,
  ClientRecordDrawerActions
> = (state, action) => {
  if (action.type === 'OpenUpdateClientDrawer') {
    return 'UpdateClient';
  }
  if (action.type === 'CloseNestedDrawer') {
    return '';
  }

  return state;
};
