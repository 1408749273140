import { TaskCreateBody } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { clientTaskApi } from 'src/common/services';
import { getCreateMessageText } from 'src/common/utils';
import { TasksDispatch } from '../Tasks.reducer';

export const createClientTask = (
  dispatch: TasksDispatch,
  clientId: number,
  taskCreateBody: TaskCreateBody
) => {
  dispatch({ type: 'Create' });

  clientTaskApi
    .createClientTask({ clientId, taskCreateBody })
    .then(({ data }) => {
      dispatch({ type: 'CreateSuccess', payload: data });
      message.success(getCreateMessageText('client task').success);
    })
    .catch(error => {
      dispatch({
        type: 'CreateFailure',
        payload: error
      });
      message.error(getCreateMessageText('client task').error);
    });
};
