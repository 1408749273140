import React, { FC, useEffect, useState, CSSProperties } from 'react';
import { Row, Col, Tag, Form } from 'antd';

const style = {
  tag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 35,
    width: 85
  } as CSSProperties
};

interface Props {
  onChange: (isPrequalified?: boolean) => void;
  initialValue?: boolean;
}

export const BuyerIsPrequalifiedFormItem: FC<Props> = ({
  onChange,
  initialValue
}) => {
  const [isPrequalified, setIsPrequalified] = useState(initialValue);

  useEffect(() => {
    onChange(isPrequalified);
  }, [isPrequalified]);

  const handleBuyerWorkingWithOtherAgentClick = (value: boolean) => e =>
    setIsPrequalified(previous => (previous === value ? undefined : value));

  return (
    <Form.Item
      label="Is Prequalified?"
      name={['buyerFields', 'isPreQualified']}
    >
      <Row gutter={[4, 4]}>
        <Col>
          <Tag
            color={isPrequalified === true ? 'blue' : 'default'}
            onClick={handleBuyerWorkingWithOtherAgentClick(true)}
            style={style.tag}
          >
            Yes
          </Tag>
        </Col>

        <Col>
          <Tag
            color={isPrequalified === false ? 'blue' : 'default'}
            onClick={handleBuyerWorkingWithOtherAgentClick(false)}
            style={style.tag}
          >
            No
          </Tag>
        </Col>
      </Row>
    </Form.Item>
  );
};
