import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

interface ButtonProps {
  actiontype?: 'Default' | 'Outlined';
  externalbackground?: string;
  externalcolor?: string;
}

const Button = styled(AntdButton)<ButtonProps>`
  background-color: ${({ theme, actiontype, externalbackground }) =>
    actiontype === 'Outlined'
      ? theme.whiteBackGround
      : externalbackground || theme.secondaryBlue};

  color: ${({ theme, actiontype, externalcolor: externalColor }) =>
    externalColor ||
    (actiontype === 'Outlined' ? theme.secondaryBlue : theme.whiteBackGround)};
  border-color: ${({ theme, actiontype, externalbackground }) =>
    externalbackground ||
    (actiontype === 'Outlined' ? theme.secondaryBlue : 'inherit')};
  border-radius: 3px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.03);
  font-size: 12px;
  :hover,
  :active,
  :focus {
    border-color: ${({ theme, actiontype, externalbackground }) =>
      externalbackground ||
      (actiontype === 'Outlined' ? theme.secondaryBlue : 'inherit')};
    color: ${({ theme, actiontype }) =>
      actiontype === 'Outlined' ? theme.secondaryBlue : theme.whiteBackGround};
    background-color: ${({ theme, actiontype, externalbackground }) =>
      externalbackground ||
      (actiontype === 'Outlined'
        ? theme.whiteBackGround
        : theme.secondaryBlue)};
  }
  > span {
    color: ${({ theme, actiontype }) =>
      actiontype === 'Outlined' ? theme.secondaryBlue : theme.whiteBackGround};
  }
`;

export const StatusContainer = styled.div`
  flex-direction: column;
  flex: 1;
  margin: 12px;

  .ant-steps {
    margin-left: 40px;
  }

  .ant-steps-item-active .ant-steps-icon-dot {
    background: #47cf73;
  }

  .ant-steps-item-content {
    width: 200px;
    .ant-steps-item-title {
      font-size: 13px;
    }
  }
`;

export const CallNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
  > textarea {
    flex: 1;
    min-height: 200px;
  }
  ${Button} {
    width: 144px;
    height: 40px;
    margin: 12px 0 0 auto;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    border: ${({ theme }) => `solid 1px ${theme.border}`};
    background-color: ${({ theme }) => theme.primaryBlue};

    font-size: 15px;
    color: ${({ theme }) => theme.whiteBackGround};
  }
`;
