import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <circle id="b" cx="17" cy="17" r="17" />
        <filter
          id="a"
          width="161.8%"
          height="161.8%"
          x="-30.9%"
          y="-22.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            <use fill="#E1E1E1" xlinkHref="#b" />
          </g>
          <path
            fill="#FFF"
            d="M17.567 22.897c4.068 0 7.366-2.715 7.366-6.065 0-3.35-3.298-6.065-7.366-6.065-4.069 0-7.367 2.715-7.367 6.065 0 1.53.688 2.927 1.823 3.994v3.54l3.471-1.712a8.85 8.85 0 002.073.243z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
