import { ClientListItem } from '@elm-street-technology/crm-axios-client';
import { cloneDeep } from 'lodash';
import { ColumnsState, ProColumns } from '@ant-design/pro-table';

interface ClientTableListItem extends ClientListItem {
  clientDates: {
    lastLogin: string | null;
    registrationDate: string | null;
  };
}

interface DnDColumnsToDisplay {
  isBroker: boolean;
  isTeamAdmin: boolean;
  isSeller: boolean;
  isMobile: boolean;
  isLargeMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  columns: ProColumns<ClientTableListItem>[];
}

export const dndColumnsToDisplay = ({
  isBroker,
  isTeamAdmin,
  isSeller,
  isMobile,
  isLargeMobile,
  isTablet,
  isLaptop,
  columns
}: DnDColumnsToDisplay) => {
  let renderedColumns = [...columns];

  if (isBroker || isTeamAdmin) {
    renderedColumns = renderedColumns.filter(
      ({ title }) => !['Action'].includes(title as string)
    );
  } else {
    renderedColumns = renderedColumns.filter(
      ({ title }) => !['Assigned to', 'Assigned at'].includes(title as string)
    );
  }
  if (isSeller) {
    renderedColumns = renderedColumns.filter(
      ({ title }) =>
        !['Views', 'Favs', 'Matches', 'Source', 'Score'].includes(
          title as string
        )
    );
  } else {
    renderedColumns = renderedColumns.filter(
      ({ title }) => !['Address', 'Intent'].includes(title as string)
    );
  }

  // Agent table titles
  const mobileTitles = ['Name', 'Score', 'Status', 'Action'];
  const largeMobileTitles = [...mobileTitles, 'Last Login/ Reg. Date'];
  const tabletAbsentTitles = ['Favs', 'Views', 'Matches', 'Tags', 'Source'];
  // Broker table titles
  const mobileBrokerTitles = ['Name', 'Assigned to', 'Assigned at'];

  if (isBroker || isTeamAdmin) {
    if (isMobile) {
      renderedColumns = renderedColumns.filter(({ title }) =>
        mobileBrokerTitles.includes(title as string)
      );
    }
    if (isLargeMobile) {
      const largeMobileBrokerTitles = [
        ...mobileBrokerTitles,
        ...largeMobileTitles
      ];
      renderedColumns = renderedColumns.filter(({ title }) =>
        largeMobileBrokerTitles.includes(title as string)
      );
    }
    if (isTablet || isLaptop) {
      renderedColumns = renderedColumns.filter(
        ({ title }) => !tabletAbsentTitles.includes(title as string)
      );
    }
  } else {
    if (isMobile) {
      renderedColumns = renderedColumns.filter(({ title }) =>
        mobileTitles.includes(title as string)
      );
    }

    if (isLargeMobile) {
      renderedColumns = renderedColumns.filter(({ title }) =>
        largeMobileTitles.includes(title as string)
      );
    }
    if (isTablet || isLaptop) {
      renderedColumns = renderedColumns.filter(
        ({ title }) => !tabletAbsentTitles.includes(title as string)
      );
    }
  }
  return renderedColumns;
};

export const getSortDirectionFromSorter = ({ order }) => {
  if (order === 'ascend') {
    return 'asc';
  }
  if (order === 'descend') {
    return 'desc';
  }

  return undefined;
};

export const getSortNameFromSorter = ({ columnKey, order }) => {
  if (!order) {
    return undefined;
  }

  if (columnKey) {
    return columnKey;
  }
  return undefined;
};

export const reorderedColumns = (
  columns: ProColumns[],
  columnState: ColumnsState
) => {
  const oldColumns = cloneDeep(columns);
  const newColumns: ProColumns[] = [];
  // eslint-disable-next-line array-callback-return,consistent-return
  Object.values(columnState).forEach((value, index) => {
    if (typeof value === 'object') {
      const { order } = value;
      if (order !== undefined) {
        const item = oldColumns[index];
        if (order !== index) {
          newColumns.splice(order, 0, item);
        } else {
          newColumns.push(item);
        }
      }
    }
  });
  return newColumns;
};
