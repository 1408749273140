import { Dispatch, Reducer } from 'react';
import { message } from 'antd';
import { bulkActionApi } from 'src/common/services';

interface ClientInviteState {
  invited: boolean;
  inviting: boolean;
}

export const initialClientInviteState: ClientInviteState = {
  inviting: false,
  invited: false
};

export type ClientInviteActions =
  | { type: 'ClientInvitePending' }
  | { type: 'ClientInviteSuccess' }
  | { type: 'ClientInviteFailure' };

export const clientInviteReducer: Reducer<
  ClientInviteState,
  ClientInviteActions
> = (state, action) => {
  switch (action.type) {
    case 'ClientInvitePending':
      return {
        ...state,
        inviting: true,
        invited: false
      };
    case 'ClientInviteSuccess':
      return {
        ...state,
        inviting: false,
        invited: true
      };
    case 'ClientInviteFailure':
      return {
        ...state,
        inviting: false,
        invited: false
      };
    default:
      return state;
  }
};

export const inviteClient = (
  dispatch: Dispatch<ClientInviteActions>,
  clientId: number
) => {
  dispatch({ type: 'ClientInvitePending' });
  bulkActionApi
    .bulkActionSendInvites({
      bulkActionSendInvitesBody: {
        leadFilter: {
          selectedLeads: [clientId]
        }
      }
    })
    .then(() => {
      message.success('Success sending invite');
      dispatch({ type: 'ClientInviteSuccess' });
    })
    .catch(error => {
      message.error('Error sending invite');
      dispatch({ type: 'ClientInviteFailure' });
    });
};

interface ClientDeleteState {
  deleting: boolean;
}

export const initialClientDeleteState: ClientDeleteState = {
  deleting: false
};

export type ClientDeleteActions =
  | { type: 'ClientDeletePending' }
  | { type: 'ClientDeleteSuccess' }
  | { type: 'ClientDeleteFailure' };

export const clientDeleteReducer: Reducer<
  ClientDeleteState,
  ClientDeleteActions
> = (state, action) => {
  switch (action.type) {
    case 'ClientDeletePending':
      return {
        ...state,
        deleting: true
      };
    case 'ClientDeleteSuccess':
      return {
        ...state,
        deleting: false
      };
    case 'ClientDeleteFailure':
      return {
        ...state,
        deleting: false
      };
    default:
      return state;
  }
};
