import React, { FC } from 'react';
import {
  ClientBuyerFieldsListingTypesEnum,
  ClientSellerFieldsListingTypeEnum,
  ClientBuyerFields
} from '@elm-street-technology/crm-axios-client';
import { PageHeader, Divider } from 'antd';
import styled from 'styled-components';
import { numberToThousands } from 'src/common/utils';
import {
  Bath as BathIcon,
  Bed as BedIcon,
  BusinessOp as BusinessOpIcon,
  Commercial as CommecialIcon,
  Condo as CondoIcon,
  FirstTimeBuyer as FirstTimeBuyerIcon,
  House as HouseIcon,
  Land as LandIcon,
  LivingArea as LivingAreaIcon,
  Location as LocationIcon,
  LotSize as LotSizeIcon,
  Mobile as MobileIcon,
  MultiFamilyHouse as MultiFamilyHouseIcon,
  Prequalified as PrequalifiedIcon,
  Price as PriceIcon,
  ResidIncome as ResidIncomIcon,
  TownHouse as TownHouseIcon,
  YearBuilt as YearBuiltIcon
} from 'src/crm/assets/icons/buy_sell_prefs';
import { ClientRecordSectionTitle } from '../../index';

const Container = styled('div')`
  font-family: Roboto;
  color: #082746;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: -1 0px 7px 0px rgba(0, 0, 0, 0.126);

  svg {
    margin-bottom: 5px;
  }

  .looking-for-container,
  .sell-type-container {
    background-color: #f6f7f8;
    border-radius: 4px;
    border: 1px solid rgb(246, 247, 248);
    display: flex;

    .sell-type {
      flex: 1;
      display: flex;
      padding-left: unset;
      justify-content: center;
      align-items: center;
      margin-top: 39px;
      margin-bottom: 41px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    > .buy-types {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-left: 41px;
      flex: 1;
      > div {
        :only-child {
          margin-left: -188px;
        }
        padding: 10px 0;
        flex: 0 0 25%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > span {
      font-size: 15px;
      font-weight: 500;
      padding-left: 72px;
      margin-top: 39px;
      margin-bottom: 41px;
    }
  }

  .prefs {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .pref {
    }
  }
`;

const Preference = styled.div`
  padding: 37px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.333333%;
  min-height: 100px;
  min-width: 100px;
  &:not(:nth-child(3n + 3))::after {
    position: absolute;
    right: 0;
    height: 54px;
    width: 1px;
    border: 1px solid rgb(223, 223, 223);
    content: '';
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const buyPrefsDisplayOrder = [
  'locations',
  'numBaths',
  'numBeds',
  'livingAreaFt',
  'lotSizeAreaFt',
  'builtYear',
  'priceRange',
  'firstTimeBuyer',
  'prequalified'
];

interface Props {
  buyerFields: ClientBuyerFields;
}

export const BuyingSection: FC<Props> = ({ buyerFields }) => {
  const buyPrefs = buyPrefsDisplayOrder.reduce((elements, property) => {
    if (buyerFields !== null) {
      switch (property) {
        case 'locations':
          return [
            ...elements,
            <Preference key="buy-location">
              <div>
                <LocationIcon />
                <div>{buyerFields.city || ''}</div>
                <div>
                  {buyerFields.state || ''}, {buyerFields.zipCode || ''}
                </div>
              </div>
            </Preference>
          ];
        case 'numBaths':
          return [
            ...elements,
            <Preference key="buy-minbaths">
              <div>
                <BathIcon />
                <div>{buyerFields.minBaths}+</div>
                <div>Baths</div>
              </div>
            </Preference>
          ];
        case 'numBeds':
          return [
            ...elements,
            <Preference key="buy-minbeds">
              <div>
                <BedIcon />
                <div>{buyerFields.minBeds}+</div>
                <div>Beds</div>
              </div>
            </Preference>
          ];
        case 'livingAreaFt':
          return [
            ...elements,
            <Preference key="buy-livingarearangeft">
              <div>
                <LivingAreaIcon />
                {numberToThousands(buyerFields.minLivingArea || 0)} -{' '}
                {numberToThousands(buyerFields.maxLivingArea || 0)}
                <div>Living Area</div>
              </div>
            </Preference>
          ];
        case 'lotSizeAreaFt':
          return [
            ...elements,
            <Preference key="buy-lotsizearea">
              <div>
                <LotSizeIcon />
                {numberToThousands(buyerFields.minSquareFootage || 0)} -{' '}
                {numberToThousands(buyerFields.maxSquareFootage || 0)}
                <div>Lot Size</div>
              </div>
            </Preference>
          ];
        case 'builtYear':
          return [
            ...elements,
            <Preference key="buy-yearbuilt">
              <div>
                <YearBuiltIcon />
                <div>{buyerFields.yearBuilt}</div>
                <div>Year built</div>
              </div>
            </Preference>
          ];
        case 'priceRange':
          return [
            ...elements,
            <Preference key="buy-pricerange">
              <div>
                <PriceIcon />
                {numberToThousands(buyerFields.minPrice || 0)} -{' '}
                {numberToThousands(buyerFields.maxPrice || 0)}
                <div>Price</div>
              </div>
            </Preference>
          ];
        case 'firstTimeBuyer':
          return [
            ...elements,
            <Preference key="buy-firsttimebuyer">
              <div>
                <FirstTimeBuyerIcon />
                <div>{buyerFields.isFirstTimeBuyer ? 'Yes' : 'No'}</div>
                <div>First Time Buyer</div>
              </div>
            </Preference>
          ];
        case 'prequalified':
          return [
            ...elements,
            <Preference key="buy-prequalified">
              <div>
                <PrequalifiedIcon />
                <div>{buyerFields.isPreQualified ? 'Yes' : 'No'}</div>
                <div>Prequalified</div>
              </div>
            </Preference>
          ];
        default:
          throw new Error(`Unknown type for ${property}!`);
      }
    }
    return elements;
  }, [] as JSX.Element[]);

  const lookingForListingTypes =
    buyerFields.listingTypes.length > 0 ? (
      buyerFields.listingTypes
        .map(type => getPreferencesComponent(type))
        .filter(item => item !== null)
    ) : (
      <div key="type-condo">N/A</div>
    );

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Buying</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      <Container>
        <div className="looking-for-container">
          <span>Looking for</span>{' '}
          <div className="buy-types">{lookingForListingTypes}</div>
        </div>

        <div className="prefs">{buyPrefs}</div>
      </Container>
    </PageHeader>
  );
};

const getPreferencesComponent = (
  type: ClientBuyerFieldsListingTypesEnum | ClientSellerFieldsListingTypeEnum
) => {
  switch (type) {
    case ClientBuyerFieldsListingTypesEnum.SingleFamily:
      return (
        <div key="type-house">
          <HouseIcon />
          Single Family
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.Townhouse:
      return (
        <div key="type-townhouse">
          <TownHouseIcon />
          Townhouse
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.BusinessOpportunity:
      return (
        <div key="type-businessop">
          <BusinessOpIcon />
          Business Op
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.Condo:
      return (
        <div key="type-condo">
          <CondoIcon />
          Condo
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.Multifamily:
      return (
        <div key="type-multifamily">
          <MultiFamilyHouseIcon />
          Multi-family
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.Land:
      return (
        <div key="type-land">
          <LandIcon />
          Land
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.Mobile:
      return (
        <div key="type-mobile">
          <MobileIcon />
          Mobile
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.Commercial:
      return (
        <div key="type-commercial">
          <CommecialIcon />
          Commercial
        </div>
      );
    case ClientBuyerFieldsListingTypesEnum.ResidentialIncome:
      return (
        <div key="type-residincome">
          <ResidIncomIcon />
          Resid. Income
        </div>
      );
    default:
      return null;
  }
};
