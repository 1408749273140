import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    width="20px"
    height="17px"
    viewBox="0 0 20 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Cards-/-Mobile-/-Broker-Recruiting"
        transform="translate(-160.000000, -33.000000)"
        fill="#082746"
      >
        <g id="Group" transform="translate(160.546875, 33.000000)">
          <g
            id="Icon/Call-Clean"
            transform="translate(0.000000, 7.000000)"
            fillRule="nonzero"
          >
            <g id="call-answer">
              <path
                d="M9.77581825,7.90951812 L8.23147423,6.36150211 C7.92386952,6.05443857 7.41447014,6.06376796 7.09614939,6.38292515 L6.31810223,7.16256246 C6.26894639,7.13540933 6.21806678,7.10704684 6.1645728,7.07695669 C5.67324416,6.80410091 5.00077721,6.43011918 4.2931456,5.72036589 C3.58341674,5.00911529 3.20993572,4.33408805 2.93686363,3.84132939 C2.90804814,3.78912514 2.88043927,3.73879252 2.85317516,3.6909938 L3.37535908,3.16840415 L3.63208419,2.91078061 C3.95089334,2.59116271 3.9596845,2.08075308 3.65279802,1.77279692 L2.108454,0.224608145 C1.80156752,-0.0829161013 1.2919383,-0.0735867141 0.973129155,0.246031182 L0.537880119,0.684771532 L0.549774053,0.696606033 C0.403829162,0.883251366 0.281873244,1.09851834 0.191117356,1.33065883 C0.107457607,1.5516271 0.0553713694,1.76248853 0.031554772,1.97378187 C-0.172365865,3.66815984 0.600165262,5.21669415 2.69671534,7.31802345 C5.59478441,10.2224575 7.93024743,10.0030441 8.03100111,9.99233262 C8.25043558,9.96604332 8.46073412,9.91349354 8.67445145,9.8303065 C8.90405609,9.74041037 9.11869276,9.61835088 9.30480123,9.47239204 L9.31431063,9.4808576 L9.75524807,9.04810649 C10.0733964,8.72854618 10.0825611,8.21796379 9.77581825,7.90951812 Z"
                id="Path"
              />
            </g>
          </g>
          <text
            id="O"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="12"
            fontWeight="400"
          >
            <tspan x="9.375" y="11">
              O
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const ProspectiveAgentOfficePhoneIcon = props => (
  <Icon component={Svg} {...props} />
);
