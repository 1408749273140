import React from 'react';

function Icon() {
  return (
    <svg width="16px" height="15px" viewBox="0 0 16 15" version="1.1">
      <title>Living Area Icon</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Card/Template/Favorited-Listings"
          transform="translate(-46.000000, -280.000000)"
          stroke="#082746"
        >
          <g
            id="Icons/BuyerSeller/Living-Area-Icon"
            transform="translate(47.000000, 281.000000)"
          >
            <path
              d="M14,8.13636364 L8.68181818,8.63636364 L9.18181818,13.5 L14.5,13 L14,8.13636364 Z"
              id="Rectangle"
            />
            <path
              d="M1.34090909,13.0454545 L5.47727273,13.0454545 C6.02955748,13.0454545 6.47727273,12.5977393 6.47727273,12.0454545 L6.47727273,7.68181818 C6.47727273,7.12953343 6.92498798,6.68181818 7.47727273,6.68181818 L11.6136364,6.68181818 C12.1659211,6.68181818 12.6136364,6.23410293 12.6136364,5.68181818 L12.6136364,1.31818182 C12.6136364,0.765897068 12.1659211,0.318181818 11.6136364,0.318181818 L1.34090909,0.318181818 C0.788624341,0.318181818 0.340909091,0.765897068 0.340909091,1.31818182 L0.340909091,12.0454545 C0.340909091,12.5977393 0.788624341,13.0454545 1.34090909,13.0454545 Z"
              id="Path-2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
