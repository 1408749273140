import { Skeleton } from 'antd';
import React, { FC, useEffect, useReducer, useMemo } from 'react';
import {
  Client,
  ClientListItem
} from '@elm-street-technology/crm-axios-client';
import { useLastMessage } from 'use-socketio';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

import { CommonSmDrawerPanel } from 'src/common/components';
import { Chat } from 'src/crm/components/Chat';

import { LastLogin } from './QuickMessage.styled';
import {
  initialQuickMessageState,
  quickMessageReducer
} from './QuickMessage.reducer';
import { getPreviousTexts, sendText } from './QuickMessage.actions';

export interface QuickMessageProps {
  client: Client | ClientListItem;
  onClose: () => void;
}

export const QuickMessageDrawer: FC<QuickMessageProps> = ({
  client,
  onClose
}) => {
  const { data: lastText } = useLastMessage('text');

  const [{ texts: initialTexts, loading }, dispatch] = useReducer(
    quickMessageReducer,
    initialQuickMessageState
  );

  const texts = useMemo(() => {
    const texts = reject(initialTexts, isEmpty);
    if (lastText && lastText.clientId === client.id) {
      texts.push(lastText);
    }
    return texts;
  }, [client, initialTexts, lastText]);

  useEffect(() => {
    getPreviousTexts(dispatch, client.id);
  }, [client]);

  return (
    <CommonSmDrawerPanel
      title={
        <Skeleton loading={loading} active>
          <div className="ant-drawer-title">Text {client.displayName}</div>
          <LastLogin>{client.lastLogin}</LastLogin>
        </Skeleton>
      }
      onClose={onClose}
    >
      <Skeleton loading={loading} active>
        <Chat
          texts={texts}
          sendText={(message: string) => {
            sendText(dispatch, client.id, message);
          }}
        />
      </Skeleton>
    </CommonSmDrawerPanel>
  );
};
