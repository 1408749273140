import { CancelToken } from 'axios';
import { clientApi } from 'src/common/services';
import { ClientApiListClientsRequest } from '@elm-street-technology/crm-axios-client';
import { ClientsDispatch } from '../Clients.types';

export const listClients = (
  dispatch: ClientsDispatch,
  parameters: ClientApiListClientsRequest,
  cancelToken?: CancelToken
) => {
  dispatch({ type: 'GetClientsPending' });
  clientApi
    .listClients(parameters, { cancelToken })
    .then(({ data }) => dispatch({ type: 'GetClientsSuccess', payload: data }))
    .catch(error => dispatch({ type: 'GetClientsFailure' }));
};
