import * as d3 from 'd3';
import React, { FC, useLayoutEffect, useRef } from 'react';
import { getClientFunnelColor, clientStatusToDisplay } from 'src/common/utils';
import { ClientApiListClientsRequest } from '@elm-street-technology/crm-axios-client';

const FUNNEL_PATH =
  'm177.714419,496.669086c-1.515337,-0.80818 -3.636808,-2.929651 -4.546011,-4.748056c-1.717382,-3.030674 -1.818404,-10.304291 -2.323517,-106.780738l-0.505112,-103.54802l-3.737831,-7.576684c-2.828629,-5.657258 -62.431879,-95.567246 -73.948439,-111.427771c-4.849078,-6.768505 -10.708381,-15.355414 -46.268286,-68.493227c-21.416761,-32.024119 -39.903871,-60.00734 -41.116141,-62.330857c-5.657258,-11.11247 0,-26.063794 10.708381,-28.589356c5.556235,-1.313292 430.153627,-1.212269 434.901683,0.101022c4.343966,1.212269 9.193044,6.364415 10.910425,11.617583c0.707157,2.121472 1.010225,6.465437 0.80818,9.799178l-0.40409,5.960325l-44.247837,65.866643c-24.346412,36.267063 -46.571353,69.301406 -49.501005,73.443327c-19.29529,27.882198 -68.089137,101.426548 -70.210608,106.073581l-2.626584,5.556235l0,74.756619c0,73.645372 0,74.857642 -2.121472,79.30263c-1.111247,2.525561 -2.727606,5.051123 -3.535786,5.657258c-0.80818,0.606135 -10.001223,5.556235 -20.608581,11.011448c-10.506336,5.455213 -21.012671,10.910425 -23.235165,12.122695c-8.788954,4.748056 -64.856418,33.842524 -65.260508,33.842524c-0.202045,-0.101022 -1.616359,-0.707157 -3.131696,-1.616359z';

interface PipelineFunnelSvgProps {
  data;
  width: number;
  height: number;
  fields: NonNullable<ClientApiListClientsRequest['status']>;
  hiddenFields: NonNullable<ClientApiListClientsRequest['status']>;
  wrapLength: number;
  onLegendCLick?: (status: ClientApiListClientsRequest['status']) => void;
}

export const PipelineFunnelSvg: FC<PipelineFunnelSvgProps> = ({
  data,
  width,
  height,
  fields,
  wrapLength,
  hiddenFields,
  onLegendCLick
}) => {
  const d3Container = useRef<SVGSVGElement>(null);

  useLayoutEffect(() => {
    const options = {
      width,
      height,
      fields,
      hiddenFields,
      wrapLength,
      onLegendCLick,
      clipPathId: `funnel-${Math.floor(Math.random() * 1000)}`
    };

    const visibleTotal = options.fields.reduce(
      (acc, field) => acc + data[`${field}`],
      0
    );
    if (visibleTotal < 1) {
      makeEmptyFunnelChart(d3Container.current, options);
    } else {
      makeFunnelChart(d3Container.current, options, data, visibleTotal);
    }
  }, []);

  return <svg ref={d3Container} />;
};

const makeEmptyFunnelChart = (selector, options) => {
  const { width, height } = options;

  const svg = d3.select(selector);

  svg.selectAll('*').remove();

  svg
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('width', '100%')
    .attr('height', height);

  svg
    .append('path')
    .attr('d', FUNNEL_PATH)
    .style('stroke-width', '4px')
    .style('stroke', '#d3d7db')
    .style('fill', 'transparent')
    .attr('transform', `scale(${height / 500})`);
};

const makeFunnelChart = (selector, options, data, visibleTotal: number) => {
  const { width, height, fields, clipPathId } = options;

  const svg = d3.select(selector);

  svg.selectAll('*').remove();

  svg
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('width', '100%')
    .attr('height', height);

  svg
    .append('clipPath')
    .attr('id', clipPathId)
    .append('path')
    .attr('d', FUNNEL_PATH)
    .attr('transform', `scale(${height / 500})`);

  const y = d3
    .scaleLinear()
    .rangeRound([height, -9])
    .domain([0, visibleTotal])
    .nice();

  const z = d3
    .scaleOrdinal()
    .range(fields.map(field => getClientFunnelColor(field)).reverse());

  const stack = d3.stack().keys([...fields].reverse());

  svg
    .append('g')
    .attr('clip-path', `url(#${clipPathId})`)
    .selectAll('g')
    .data(stack([data]))
    .enter()
    .append('g')
    .attr('fill', d => z(d.key))
    .selectAll('rect')
    .data(d => d)
    .enter()
    .append('rect')
    .attr('y', d => y(d[1]))
    .attr('width', '100%')
    .attr('height', d => y(d[0]) - y(d[1]));

  makeLegend(svg, options, data);
};

const makeLegend = (selector, options, rawData) => {
  const { fields, hiddenFields, onLegendCLick, height, wrapLength } = options;

  const size = height;

  const legendData = [...fields, ...hiddenFields].map(field => ({
    key: field,
    name: clientStatusToDisplay(field),
    value: rawData[field],
    color: getClientFunnelColor(field)
  }));

  const data = legendData
    .map((d, i) =>
      i % wrapLength === 0 ? legendData.slice(i, i + wrapLength) : null
    )
    .filter(d => d);

  const groups = selector
    .append('g')
    .selectAll('g')
    .data(data)
    .enter()
    .append('g')
    .attr('transform', (d, i) => `translate(${i * 125},0)`);

  groups
    .selectAll('g')
    .data(d => d)
    .enter()
    .append('g')
    .on('click', d => {
      if (onLegendCLick) onLegendCLick(d.key);
    })
    .each(function Fn(d, i) {
      const anchor = d3.select(this);
      const radius = 13;
      anchor
        .append('circle')
        .attr('cx', size + 2 * radius)
        .attr('cy', () => radius + i * radius * 3)
        .attr('r', radius)
        .style('fill', () => d.color);

      anchor
        .append('text')
        .attr('x', size + 3.5 * radius)
        .attr('y', () => radius + i * radius * 3 + radius * 0.3)
        .text(() => d.value)
        .attr('font-family', 'sans-serif')
        .attr('font-size', `${radius * 1.4}px`)
        .attr('fill', 'black')
        .append('tspan')
        .text(() => d.name)
        .attr('font-size', `${radius}px`)
        .attr('dx', '0.25em')
        .attr('dy', '-0.2em')
        .attr('fill', '#444444');
    });
};
