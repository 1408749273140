import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg width="16px" height="12px" viewBox="0 0 16 12" version="1.1">
    <title>Dashboard Icon</title>
    <g
      id="CRM-Listing-Responsive"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Listing-V2---Map-View"
        transform="translate(-41.000000, -637.000000)"
        stroke="#082746"
      >
        <g id="Buttons-/-Map-View" transform="translate(25.000000, 623.000000)">
          <g id="Card-Icon" transform="translate(16.000000, 15.000000)">
            <path
              d="M0.961218837,2 C0.961218837,3.1045695 1.85664934,4 2.96121884,4 L12.9612188,4 C14.0657883,4 14.9612188,3.1045695 14.9612188,2 C14.9612188,0.8954305 14.0657883,-2.02906125e-16 12.9612188,0 L2.96121884,0 C1.85664934,2.02906125e-16 0.961218837,0.8954305 0.961218837,2 Z"
              id="Path"
            />
            <path
              d="M0.961218837,8 C0.961218837,9.1045695 1.85664934,10 2.96121884,10 L12.9612188,10 C14.0657883,10 14.9612188,9.1045695 14.9612188,8 C14.9612188,6.8954305 14.0657883,6 12.9612188,6 L2.96121884,6 C1.85664934,6 0.961218837,6.8954305 0.961218837,8 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ListingsListViewMobileIcon = props => (
  <Icon component={Svg} {...props} />
);
