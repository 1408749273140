import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="23"
      viewBox="0 0 19 23"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#082746" transform="translate(0 -1)">
          <g>
            <g transform="translate(0 10)">
              <path d="M15.3 0H1.7C.765 0 .008.751.008 1.67L0 11.688c0 .918.765 1.67 1.7 1.67h13.6c.935 0 1.7-.752 1.7-1.67V1.67C17 .75 16.235 0 15.3 0zm0 3.34L8.5 7.512 1.7 3.34V1.67l6.8 4.175 6.8-4.174v1.67z" />
            </g>
            <text
              fontFamily="Roboto-Medium, Roboto"
              fontSize="9"
              fontWeight="400"
            >
              <tspan x="13.942" y="8">
                2
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
