import {
  ClientUpdateBodyBuyerFields,
  ClientUpdateBodySellerFields
} from '@elm-street-technology/crm-axios-client';
import isFinite from 'lodash/isFinite';

export const formToClientCreateUpdateBody = values => {
  const primaryPhoneNumber =
    values?.primaryPhoneNumber?.phone !== undefined
      ? values.primaryPhoneNumber
      : undefined;

  const secondaryPhoneNumber =
    values?.secondaryPhoneNumber?.phone !== undefined
      ? values.secondaryPhoneNumber
      : undefined;

  const primaryEmailAddress =
    values?.primaryEmailAddress?.email !== undefined
      ? values?.primaryEmailAddress
      : undefined;

  const secondaryEmailAddress =
    values?.secondaryEmailAddress?.email !== undefined
      ? values.secondaryEmailAddress
      : undefined;

  const buyerFields =
    values?.buyerFields &&
    formToClientUpdateBodyBuyerFields(values.buyerFields);

  const sellerFields =
    values?.sellerFields &&
    formToClientUpdateBodySellerFields(values.sellerFields);

  return {
    ...values,
    primaryPhoneNumber,
    secondaryPhoneNumber,
    primaryEmailAddress,
    secondaryEmailAddress,
    buyerFields,
    sellerFields
  };
};

const formToClientUpdateBodyBuyerFields = (
  values
): ClientUpdateBodyBuyerFields => ({
  ...values,
  minPrice: isFinite(Number(values?.minPrice))
    ? Number(values.minPrice)
    : undefined,
  maxPrice: isFinite(Number(values?.maxPrice))
    ? Number(values.maxPrice)
    : undefined,
  minSquareFootage: isFinite(Number(values?.minSquareFootage))
    ? Number(values.minSquareFootage)
    : undefined,
  maxSquareFootage: isFinite(Number(values?.maxSquareFootage))
    ? Number(values.maxSquareFootage)
    : undefined,
  minLivingArea: isFinite(Number(values?.minLivingArea))
    ? Number(values.minLivingArea)
    : undefined,
  maxLivingArea: isFinite(Number(values?.maxLivingArea))
    ? Number(values.maxLivingArea)
    : undefined,
  minBeds: isFinite(Number(values?.minBeds))
    ? Number(values.minBeds)
    : undefined,
  maxBeds: isFinite(Number(values?.maxBeds))
    ? Number(values.maxBeds)
    : undefined,
  minBaths: isFinite(Number(values?.minBaths))
    ? Number(values.minBaths)
    : undefined,
  maxBaths: isFinite(Number(values?.maxBaths))
    ? Number(values.maxBaths)
    : undefined,
  yearBuilt: isFinite(Number(values?.yearBuilt))
    ? Number(values.yearBuilt)
    : undefined
});

export const formToClientUpdateBodySellerFields = (
  values
): ClientUpdateBodySellerFields => ({
  ...values,
  estimatedListPrice: isFinite(Number(values?.estimatedListPrice))
    ? Number(values.estimatedListPrice)
    : undefined,
  squareFootage: isFinite(Number(values?.squareFootage))
    ? Number(values.squareFootage)
    : undefined,
  beds: isFinite(Number(values?.beds)) ? Number(values.beds) : undefined,
  baths: isFinite(Number(values?.baths)) ? Number(values.baths) : undefined,
  yearBuilt: isFinite(Number(values?.yearBuilt))
    ? Number(values.yearBuilt)
    : undefined,
  competingWithinMiles: isFinite(Number(values?.competingWithinMiles))
    ? Number(values.competingWithinMiles)
    : undefined,
  competingPctPriceRange: isFinite(Number(values?.competingPctPriceRange))
    ? Number(values.competingPctPriceRange)
    : undefined
});
