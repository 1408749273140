import { format } from 'date-fns';
import { Tag } from 'antd';
import React from 'react';
import { ColorCombination, numberToDollar } from 'src/common/utils';
import {
  ActivityCellWrapper,
  ActivityDelta,
  ActivityValue,
  EqualWidthTitle,
  FlexWrapper,
  NameCellRendererWrapper,
  NameTitle,
  StatusTag,
  TagsCellWrapper,
  TypeTag,
  ListingTypeTag
} from './cellRenderers.styled';

export interface ClientSnapshotCell {
  name: string;
  type: string;
  typeColor: ColorCombination;
  prequal: boolean;
  age?: string;
  formattedBudget?: string | null;
  budget?: number | null;
  estimatedListPrice?: number | null;
}

interface ClientsDateCell {
  lastLogin: string | null;
  registrationDate: string | null;
}

interface ClientsAddressCell {
  streetAddress1: string | null;
  streetAddress2: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
}

interface ClientsIntentCell {
  intent?: string | null;
}

export const ClientDatesCellRender: React.FC<ClientsDateCell> = ({
  lastLogin,
  registrationDate
}) => {
  return (
    <>
      {lastLogin && (
        <FlexWrapper>{format(new Date(lastLogin), 'MM/dd/yyyy')}</FlexWrapper>
      )}
      {registrationDate && (
        <FlexWrapper>
          {format(new Date(registrationDate), 'MM/dd/yyyy')}
        </FlexWrapper>
      )}
    </>
  );
};

export const ClientAddressCellRender: React.FC<ClientsAddressCell> = ({
  streetAddress1,
  streetAddress2,
  zipCode,
  city,
  state
}) => {
  return (
    <>
      <NameCellRendererWrapper>
        <FlexWrapper>
          {`${streetAddress1 || ''} ${streetAddress2 || ''}`}
        </FlexWrapper>
        <FlexWrapper>
          {`${city ? `${city}, ` : ''}${state || ''} 
                  ${zipCode || ''}`}
        </FlexWrapper>
      </NameCellRendererWrapper>
    </>
  );
};

export const ClientIntentCellRender: React.FC<ClientsIntentCell> = ({
  intent
}) => {
  return (
    <>
      <NameCellRendererWrapper>
        <FlexWrapper>{intent || ''}</FlexWrapper>
      </NameCellRendererWrapper>
    </>
  );
};

export const ClientSnapshotCellRenderer: React.FC<ClientSnapshotCell> = ({
  name,
  type,
  prequal,
  typeColor,
  age,
  formattedBudget,
  budget,
  estimatedListPrice
}) => {
  return (
    <NameCellRendererWrapper>
      <FlexWrapper>
        <NameTitle>{name}</NameTitle>
      </FlexWrapper>
      <FlexWrapper>
        <EqualWidthTitle>{age && `Age: ${age}`}</EqualWidthTitle>
        {type === 'Buyer' && (
          <EqualWidthTitle>
            Price: {budget ? formattedBudget : 'Not Specified'}
          </EqualWidthTitle>
        )}
        {type === 'Seller' && (
          <EqualWidthTitle>
            Price:{' '}
            {estimatedListPrice
              ? numberToDollar(estimatedListPrice)
              : 'Not Specified'}
          </EqualWidthTitle>
        )}
      </FlexWrapper>
      <FlexWrapper>
        <TypeTag colors={typeColor}>{type}</TypeTag>
        {prequal && (
          <TypeTag
            colors={{
              foreground: '#ffb37a',
              background: '#ffffff',
              border: '#ffb37a'
            }}
          >
            Pre-Qualified
          </TypeTag>
        )}
      </FlexWrapper>
    </NameCellRendererWrapper>
  );
};

// Status

interface StatusCell {
  color: ColorCombination;
  children: React.ReactChild;
}

export const StatusCellRenderer: React.FC<StatusCell> = ({
  color,
  children
}) => {
  return <StatusTag colors={color}>{children}</StatusTag>;
};

interface TypeCell {
  color: ColorCombination;
  children: React.ReactChild;
}

export const ListingTypeCellRenderer: React.FC<TypeCell> = ({
  color,
  children
}) => {
  return <ListingTypeTag colors={color}>{children}</ListingTypeTag>;
};

interface ActivityCellRendererProps {
  value: number;
  delta: number;
}

export const ActivityCellRenderer: React.FC<ActivityCellRendererProps> = ({
  value,
  delta
}) => {
  return (
    <ActivityCellWrapper>
      <ActivityValue>{value}</ActivityValue>
      {delta > 0 && <ActivityDelta>+{delta}</ActivityDelta>}
    </ActivityCellWrapper>
  );
};

export const TagsCellRenderer: React.FC<{ tags?: string[] }> = ({ tags }) => {
  return (
    <TagsCellWrapper>
      {tags?.map(tag => {
        return (
          <Tag
            key={`${tag}-${new Date().getMilliseconds()}`}
            style={{ marginBottom: '3px' }}
          >
            {tag}
          </Tag>
        );
      })}
    </TagsCellWrapper>
  );
};
