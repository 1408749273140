import React, { FC } from 'react';
import { ClientContributorHistoryRecord } from '@elm-street-technology/crm-axios-client';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { List, PageHeader, Divider, Avatar } from 'antd';
import { ClientRecordSectionTitle } from '../../index';

interface Props {
  contributorHistory: ClientContributorHistoryRecord[];
}

export const ContributorHistorySection: FC<Props> = ({
  contributorHistory
}) => (
  <PageHeader
    title={
      <ClientRecordSectionTitle>Contributor History</ClientRecordSectionTitle>
    }
    footer={<Divider />}
  >
    <List
      itemLayout="horizontal"
      dataSource={contributorHistory}
      renderItem={contributor => (
        <List.Item>
          <List.Item.Meta
            title={contributor.title}
            description={contributor.fromNow}
            avatar={
              contributor.category === 'added' ? (
                <Avatar
                  shape="circle"
                  icon={<PlusOutlined style={{ color: 'rgb(75,146,235)' }} />}
                  style={{ backgroundColor: 'rgb(234,244,253)' }}
                />
              ) : contributor.category === 'removed' ? (
                <Avatar
                  shape="circle"
                  icon={<MinusOutlined style={{ color: 'rgb(75,146,235)' }} />}
                  style={{ backgroundColor: 'rgb(234,244,253)' }}
                />
              ) : (
                <Avatar
                  shape="circle"
                  style={{ backgroundColor: 'rgb(234,244,253)' }}
                />
              )
            }
          />
        </List.Item>
      )}
    />
  </PageHeader>
);
