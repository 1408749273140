import * as React from 'react';

function SvgFirtTimeBuyer(props) {
  return (
    <svg width={37} height={33} {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#082746" strokeLinecap="round" strokeLinejoin="round">
          <path d="M1.25 11.7l17.5-10.4M18.25 1.3l17.5 10.4M7 16v15a2 2 0 002 2h20a2 2 0 002-2V16h0" />
        </g>
        <text fontFamily="Roboto-Regular, Roboto" fontSize={12} fill="#082746">
          <tspan x={9.577} y={25}>
            1st
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgFirtTimeBuyer;
