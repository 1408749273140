import { Client } from '@elm-street-technology/crm-axios-client';
import { AxiosError } from 'axios';
import { Reducer } from 'react';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export type ClientActions =
  | { type: 'GetClientPending' }
  | { type: 'GetClientSuccess'; payload: Client }
  | { type: 'GetClientFailure'; payload: AxiosError }
  | { type: 'UpdateClientPending' }
  | { type: 'UpdateClientSuccess'; payload: Client }
  | { type: 'UpdateClientFailure' };

export interface ClientState {
  data: Client | null;
  loading: boolean;
  updating: boolean;
  error: AxiosError | null;
}

export const initialClientState: ClientState = {
  data: null,
  loading: false,
  updating: false,
  error: null
};

export const clientReducer: Reducer<ClientState, ClientRecordDrawerActions> = (
  state,
  action
) => {
  switch (action.type) {
    case 'GetClientPending':
      return {
        ...state,
        loading: true
      };
    case 'GetClientSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetClientFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'UpdateClientPending':
      return {
        ...state,
        updating: true
      };
    case 'UpdateClientSuccess':
      return {
        ...state,
        updating: false,
        data: action.payload
      };
    case 'UpdateClientFailure':
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
};
