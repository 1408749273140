import React, { FC, useEffect, useReducer } from 'react';
import { Skeleton, Row } from 'antd';
import { ClientApiListClientsRequest } from '@elm-street-technology/crm-axios-client';
import { useUserAccount } from 'src/common/hooks';
import {
  initialLeadStatusChartDataState,
  leadStatusChartDataReducer,
  getLeadStatusChartData
} from './LeadStatusChartData.reducer';
import { PipelineFunnelSvg } from '../PipelineFunnelSvg';

interface LeadsPipelineFunnelChartProps {
  width: number;
  height: number;
  timeframe?: string;
  onLegendCLick?: (status: ClientApiListClientsRequest['status']) => void;
}

export const LeadsPipelineFunnelChart: FC<LeadsPipelineFunnelChartProps> = ({
  width,
  height,
  timeframe,
  onLegendCLick
}) => {
  const { user } = useUserAccount();

  // here
  const [{ data, loading, error }, dispatch] = useReducer(
    leadStatusChartDataReducer,
    initialLeadStatusChartDataState
  );

  useEffect(() => {
    getLeadStatusChartData(dispatch, user.id, timeframe);
  }, [timeframe, user]);

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height }}>
        Data could not be retrieved
      </Row>
    );
  }
  if (loading) {
    return (
      <Row justify="center" style={{ height }}>
        <Skeleton />
      </Row>
    );
  }
  return data ? (
    <PipelineFunnelSvg
      data={normalize(data)}
      width={width}
      height={height}
      fields={['new', 'engaged', 'active', 'pending', 'sold']}
      hiddenFields={['cold']}
      wrapLength={6}
      onLegendCLick={onLegendCLick}
    />
  ) : null;
};

const normalize = data => ({
  new: data?.newClient || 0,
  marketing: data?.marketing || 0,
  engaged: data?.engaged || 0,
  active: data?.active || 0,
  pending: data?.pending || 0,
  sold: data?.sold || 0,
  cold: data?.cold || 0,
  archived: data?.archived || 0,
  none: data?.none || 0
});
