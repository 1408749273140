import React, { FC } from 'react';
import { CRM_HOST } from 'src/common/constants';
import { emailSignatureUrl } from './utils';

export const EmailSignaturePreview: FC = () => (
  <>
    <iframe
      src={`${CRM_HOST}/v2/email-signatures/default.html`}
      title="Signature"
      id="emailSignatureFrame"
      style={{
        border: 'none',
        height: 168,
        width: '100%'
      }}
    />
    <p
      style={{
        fontSize: 11,
        color: '#d95d58',
        marginTop: 15
      }}
    >
      This signature can be customized by clicking here:{' '}
      <a href={emailSignatureUrl}> Edit Email Signature</a>
    </p>
  </>
);
