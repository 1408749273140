import React, { FC, useMemo } from 'react';
import { ClientAffiliateData } from '@elm-street-technology/crm-axios-client';
import { Row, Col, PageHeader, Divider } from 'antd';
import {
  AFFILIATE_DATA_KEY_ORDER,
  affiliateDataToDisplay
} from './AffiliateDataSection.utils';
import { ClientRecordSectionTitle } from '../../../index';

interface Props {
  affiliateData: ClientAffiliateData;
}

export const AffiliateDataSection: FC<Props> = ({ affiliateData }) => (
  <PageHeader
    title={<ClientRecordSectionTitle>Affiliate Data</ClientRecordSectionTitle>}
    footer={<Divider />}
    style={{ color: '#082746' }}
  >
    <div
      style={{
        marginBottom: 32,
        fontSize: 14,
        fontWeight: 400
      }}
    >
      Our System was able to find the following additional information about
      this client
    </div>
    {useMemo(
      () =>
        AFFILIATE_DATA_KEY_ORDER.filter(key => affiliateData[key]).map(key => {
          let dataValue = affiliateData[key];

          if (Array.isArray(dataValue)) dataValue = dataValue.join(', ');

          return (
            <Row key={key} align="middle" justify="start">
              <Col span={8}>
                <div style={{ fontSize: 15, fontWeight: 700 }}>
                  {affiliateDataToDisplay(key)}
                </div>
              </Col>
              <Col>
                <div style={{ fontSize: 15, fontWeight: 400 }}>{dataValue}</div>
              </Col>
            </Row>
          );
        }),
      [affiliateData]
    )}
  </PageHeader>
);
