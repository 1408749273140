import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhonelinkIcon from '@material-ui/icons/Phonelink';
import RemoveIcon from '@material-ui/icons/Remove';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import SearchIcon from '@material-ui/icons/Search';
import TextsmsIcon from '@material-ui/icons/Textsms';

// To Do: match switch cases to ACTIVITY_TYPE constant found in server/lib/constants.js in CRM
export const getHistoryColors = (category: string) => {
  switch (category) {
    case 'ADD_GROUP_LISTING':
      return '#8d6e63';
    case 'ASSIGNED':
      return '#4b565f';
    case 'CALL':
      return '#ff7043';
    case 'TEXT':
      return '#ff7043';
    case 'CHECK':
      return '#42a5f5';
    case 'CREATE_NOTE':
      return '#7e57c2';
    case 'DELETE_SAVE_SEARCH':
      return '#888f96';
    case 'EDITED_CONTACT':
      return '#7e57c2';
    case 'EMAIL':
      return '#ff7043';
    case 'EMAIL_SPAM':
      return '#ffa726';
    case 'FAVORITE':
      return '#ec407a';
    case 'UN_FAVORITE':
      return '#ec407a';
    case 'HIDE_LISTING':
      return '#26c6da';
    case 'CONTACT_AGENT':
      return '#66bb6a';
    case 'LOGGEDIN':
      return '#26a69a';
    case 'REMOVE_GROUP_LISTING':
      return '#8d6e63';
    case 'SAVE_SEARCH':
      return '#ec407a';
    case 'SEARCH':
      return '#5c6bc0';
    case 'STATUS':
      return '#5c6bc0';
    case 'UPLOAD':
      return '#26a69a';
    case 'UNSUBSCRIBE':
      return '#ffa726';
    case 'UN_ASSIGNED':
      return '#4b565f';
    case 'VIEWED_LISTING':
    case 'VIWED_LISTING':
      return '#42a5f5';
    case 'ADDED':
      return '#42a5f5';
    case 'REMOVED':
      return '#42a5f5';
    default:
      return '#a7a7a7';
  }
};

export const getHistoryIcons = (category: string) => {
  switch (category) {
    case 'ADD_GROUP_LISTING':
      return FavoriteIcon;
    case 'ASSIGNED':
      return GroupAddIcon;
    case 'CALL':
      return PhoneInTalkIcon;
    case 'TEXT':
      return TextsmsIcon;
    case 'CHECK':
      return CheckIcon;
    case 'CREATE_NOTE':
      return NoteAddIcon;
    case 'DELETE_SAVE_SEARCH':
      return DeleteIcon;
    case 'EDITED_CONTACT':
      return EditIcon;
    case 'EMAIL':
      return EmailIcon;
    case 'EMAIL_SPAM':
      return BlockIcon;
    case 'FAVORITE':
      return FavoriteIcon;
    case 'UN_FAVORITE':
      return FavoriteBorderIcon;
    case 'HIDE_LISTING':
      return ArchiveIcon;
    case 'CONTACT_AGENT':
      return ContactPhoneIcon;
    case 'LOGGEDIN':
      return PhonelinkIcon;
    case 'REMOVE_GROUP_LISTING':
      return DeleteIcon;
    case 'SAVE_SEARCH':
      return SearchIcon;
    case 'SEARCH':
      return SearchIcon;
    case 'STATUS':
      return CheckIcon;
    case 'UPLOAD':
      return CloudUploadIcon;
    case 'UNSUBSCRIBE':
      return BlockIcon;
    case 'UN_ASSIGNED':
      return DeleteIcon;
    case 'VIEWED_LISTING':
    case 'VIWED_LISTING':
      return RemoveRedEyeIcon;
    case 'ADDED':
      return AddIcon;
    case 'REMOVED':
      return RemoveIcon;
    default:
      return null;
  }
};
