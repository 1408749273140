import { Reducer } from 'react';
import {
  clientReducer,
  clientAbilitiesReducer,
  clientAffiliateDataReducer,
  clientPermissionReducer,
  clientContributorsReducer,
  clientAssignmentReducer,
  organizationMembersReducer,
  clientContributorHistoryReducer,
  clientRecordNestedDrawerReducer
} from './reducers';
import {
  ClientRecordDrawerState,
  ClientRecordDrawerActions
} from './ClientRecordDrawer.types';

export const clientRecordDrawerReducer: Reducer<
  ClientRecordDrawerState,
  ClientRecordDrawerActions
> = (state, action) => ({
  drawerKey: clientRecordNestedDrawerReducer(state.drawerKey, action),
  tabKey: action.type === 'ChangeTab' ? action.payload : state.tabKey,
  client: clientReducer(state.client, action),
  abilities: clientAbilitiesReducer(state.abilities, action),
  affiliateData: clientAffiliateDataReducer(state.affiliateData, action),
  permissions: clientPermissionReducer(state.permissions, action),
  contributors: clientContributorsReducer(state.contributors, action),
  assignment: clientAssignmentReducer(state.assignment, action),
  organizationMembers: organizationMembersReducer(
    state.organizationMembers,
    action
  ),
  contributorHistory: clientContributorHistoryReducer(
    state.contributorHistory,
    action
  )
});
