import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <circle id="b" cx="17" cy="17" r="17" />
        <filter
          id="a"
          width="161.8%"
          height="161.8%"
          x="-30.9%"
          y="-22.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <use fill="#000" filter="url(#a)" xlinkHref="#b" />
            <use fill="#E1E1E1" xlinkHref="#b" />
          </g>
          <path
            fill="#FFF"
            d="M13.005 0H1.445A1.43 1.43 0 00.007 1.42L0 9.933c0 .78.65 1.42 1.445 1.42h11.56c.795 0 1.445-.64 1.445-1.42V1.42C14.45.64 13.8 0 13.005 0zm0 2.838l-5.78 3.548-5.78-3.548V1.42l5.78 3.548 5.78-3.548v1.42z"
            transform="translate(10.2 11.9)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
