import * as React from 'react';

function SvgCommercial(props) {
  return (
    <svg width="27px" height="30px" viewBox="0 0 27 30" version="1.1">
      <title>Commercial</title>
      <g
        id="Property-Type/Commercial-Icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Account-Icon"
          transform="translate(1.000000, 0.000000)"
          stroke="#082746"
        >
          <line
            x1="4.62400794"
            y1="12.5"
            x2="4.62400794"
            y2="14.5"
            id="Line-3"
          />
          <line x1="10.5" y1="14.5" x2="10.5" y2="12.5" id="Line-3" />
          <line x1="10.5" y1="9.5" x2="10.5" y2="7.5" id="Line-3" />
          <line x1="10.5" y1="19.5" x2="10.5" y2="17.5" id="Line-3" />
          <line x1="10.5" y1="24.5" x2="10.5" y2="22.5" id="Line-3" />
          <line x1="4.5" y1="24.5" x2="4.5" y2="22.5" id="Line-3" />
          <line x1="4.5" y1="17.5" x2="4.5" y2="19.5" id="Line-3" />
          <path
            d="M0,10.1323808 L0,27 C1.3527075e-16,28.1045695 0.8954305,29 2,29 L13,29 C14.1045695,29 15,28.1045695 15,27 L15,3.53238076 C15,2.42781126 14.1045695,1.53238076 13,1.53238076 C12.6375121,1.53238076 12.2818393,1.63089642 11.9710085,1.81739491 L0.971008489,8.41739491 C0.368597668,8.7788414 -8.60345855e-17,9.42985505 0,10.1323808 Z"
            id="Path"
          />
        </g>
        <g
          id="Account-Icon"
          transform="translate(22.000000, 17.500000) scale(-1, 1) translate(-22.000000, -17.500000) translate(18.000000, 6.000000)"
          stroke="#082746"
        >
          <line
            x1="4.31200397"
            y1="12.6666667"
            x2="4.31200397"
            y2="11.3333333"
            id="Line-3"
          />
          <line
            x1="4.31200397"
            y1="8.66666667"
            x2="4.31200397"
            y2="7.33333333"
            id="Line-3"
          />
          <line
            x1="4.31200397"
            y1="16.6666667"
            x2="4.31200397"
            y2="15.3333333"
            id="Line-3"
          />
          <line
            x1="4.31200397"
            y1="20.6666667"
            x2="4.31200397"
            y2="19.3333333"
            id="Line-3"
          />
          <path
            d="M0,8.03381741 L0,21 C2.09738034e-15,22.1045695 0.8954305,23 2,23 L6,23 C7.1045695,23 8,22.1045695 8,21 L8,4.4648519 C8,3.3602824 7.1045695,2.4648519 6,2.4648519 C5.50888307,2.4648519 5.03493454,2.64555409 4.66847985,2.97252013 L0.66847985,6.54148565 C0.243186103,6.92095033 -9.57979873e-16,7.4638453 0,8.03381741 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCommercial;
