import React from 'react';

function Icon() {
  return (
    <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
      <title>Small Lot Size Icon</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Card/Template/Favorited-Listings"
          transform="translate(-157.000000, -280.000000)"
          stroke="#082746"
        >
          <g
            id="Icons/BuyerSeller/Small/-Lot-Size-Icon"
            transform="translate(157.000000, 280.000000)"
          >
            <g id="Group-4">
              <rect id="Rectangle" x="0.5" y="0.5" width="14" height="14" />
              <line
                x1="7.29166667"
                y1="7.5"
                x2="12.2916667"
                y2="2.5"
                id="Line-3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <line
                x1="7.29166667"
                y1="7.5"
                x2="2.70833333"
                y2="12.0454545"
                id="Line-5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <polyline
                id="Path-3"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="8.24965277 2.5 12.2916671 2.5 12.2916671 6.38034499"
              />
              <polyline
                id="Path-4"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="2.70833333 8.06741147 2.70833333 12.0454545 6.7591198 12.0454545"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default Icon;
