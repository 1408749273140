import * as React from 'react';

function SvgDesktop6(props) {
  return (
    <svg width="34px" height="30px" viewBox="0 0 34 30" version="1.1">
      <title>Single Family</title>
      <g
        id="Icons/Single-Family/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g id="Single-Family-Icon" stroke="#082746">
          <g id="Group" strokeLinecap="round">
            <path
              d="M4.85714286,14.5 L4.85714286,27 C4.85714286,28.1045695 5.75257336,29 6.85714286,29 L27.1428571,29 C28.2474266,29 29.1428571,28.1045695 29.1428571,27 L29.1428571,14.5 L29.1428571,14.5"
              id="Path"
            />
            <line
              x1="1.11309524"
              y1="11.6"
              x2="16.6964286"
              y2="1.28888889"
              id="Line-3"
            />
            <line
              x1="17.3035714"
              y1="1.28888889"
              x2="32.8869048"
              y2="11.6"
              id="Line-4"
            />
          </g>
          <line
            x1="21.1474395"
            y1="19.452381"
            x2="21.1474395"
            y2="16.6904762"
            id="Line-3"
            strokeLinecap="round"
          />
          <polyline
            id="Path"
            points="15.1761905 28.7076755 15.1761905 22.9047619 18.6260035 22.9047619 18.6260035 28.7076755"
          />
          <line
            x1="12.8617252"
            y1="19.452381"
            x2="12.8617252"
            y2="16.6904762"
            id="Line-3"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDesktop6;
