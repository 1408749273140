import {
  Listing,
  ClientStatusEnum
} from '@elm-street-technology/crm-axios-client';
import { Card, Tag } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from 'src/crm/components';
import {
  getClientStatusColor,
  numberToThousands,
  emDash,
  ColorCombination
} from 'src/common/utils';
import SqFt from 'src/crm/assets/SqFt';
import LotSize from 'src/crm/assets/LotSize';
import Bed from 'src/crm/assets/Bed';
import Bath from 'src/crm/assets/Bath';
import { CardImage } from './index';

const WrappedCard = styled(Card)`
  border-radius: 6px;
  width: 321px;
  margin: 12px;
  cursor: pointer;
  .ant-card-body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    color: ${({ theme }) => theme.secondaryBlue};
  }
  > span {
    white-space: nowrap;
    overflow-x: auto;
    text-overflow: ellipsis;
  }
  .ant-card-cover {
    width: 321px;
    height: 162px;
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
`;
const ListingDate = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-right: 14px;
`;
const TypeTitle = styled.span`
  text-transform: uppercase;
  font-size: 13px;
`;
const PriceTag = styled.span`
  font-size: 21px;
  font-weight: bolder;
`;
const StatusTag = styled(Tag)<{
  colorcombination: ColorCombination | null;
}>`
  font-size: 10px;
  border-radius: 4px;
  line-height: 17px;
  height: 17px;
  font-weight: bolder;
  color: ${({ colorcombination }) =>
    colorcombination ? colorcombination.foreground : 'inherit'};
  margin-left: 8px;
  background: ${({ colorcombination }) =>
    colorcombination ? colorcombination.background : 'inherit'};
  border-color: ${({ colorcombination }) =>
    colorcombination ? colorcombination.border : 'inherit'};
  text-transform: capitalize;
`;

const SupdivisionArea = styled.span`
  font-weight: bolder;
  font-size: 16px;
`;

const ListingHighlights = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.secondaryBlue};
  svg {
    width: 15px;
    height: 15px;
  }
`;

interface Props {
  listing: Listing;
  onCardClick?: (listing: Listing) => void;
  lastInteractionDesc: string;
}

export const ClientListingCard: FC<Props> = ({
  listing,
  onCardClick = () => ({}),
  lastInteractionDesc
}) => {
  const {
    status,
    type,
    price,
    beds,
    baths,
    squareFootage,
    livingArea,
    address,
    subdivision,
    mlsListingId,
    listDate,
    viewed,
    firstPhoto
  } = listing;

  const { street, city, state, zipCode } = address;
  const listingDateMoment = listDate ? moment(listDate).format('MM/DD/YY') : '';
  const priceToDollar = price ? numberToThousands(price) : null;
  const statusColor = status
    ? getClientStatusColor(status.toLowerCase() as ClientStatusEnum)
    : null;

  return (
    <WrappedCard
      onClick={() => {
        onCardClick(listing);
      }}
      cover={
        <CardImage
          viewed={viewed || false}
          propertyPhoto={firstPhoto || undefined}
          lastInteractionDesc={lastInteractionDesc}
        />
      }
      actions={[
        <Flex justifyContent="space-around">
          <ListingHighlights>
            <Bed /> {beds || emDash}
          </ListingHighlights>
          <ListingHighlights>
            <Bath /> {baths || emDash}
          </ListingHighlights>
          <ListingHighlights>
            <SqFt /> {livingArea ? numberToThousands(livingArea) : emDash} sf
          </ListingHighlights>
          <ListingHighlights>
            <LotSize />{' '}
            {squareFootage ? numberToThousands(squareFootage) : emDash} sf
          </ListingHighlights>
        </Flex>
      ]}
    >
      <ListingDate>
        <span>Listing {listingDateMoment}</span>
        <span>MLS# {mlsListingId || emDash}</span>
      </ListingDate>
      <TypeTitle> {type || emDash}</TypeTitle>
      <Flex flexDirection="row" alignItems="center">
        <PriceTag>${priceToDollar || emDash}</PriceTag>
        {status && (
          <StatusTag colorcombination={statusColor}> {status} </StatusTag>
        )}
      </Flex>
      <span>{street || ''}</span>
      <span>
        {city || ''}, {state || ''} {zipCode || ''}
      </span>
      <SupdivisionArea>Subdiv/Area: {subdivision || emDash}</SupdivisionArea>
    </WrappedCard>
  );
};
