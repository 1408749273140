/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'antd';
import { TrendingUp } from 'react-feather';
import { ResponsiveBar } from '@nivo/bar';
import { RESPONSIVE_BAR_THEME } from './PerformanceChart.styled';

const salesVolumeKey = 'Volume (US$)';

interface PerformanceChartProps {
  data: any;
}

export const PerformanceChart: FC<PerformanceChartProps> = ({ data }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth
  });
  function debounce(fn: () => void, ms: number) {
    let timer: any;
    return (_: any) => {
      clearTimeout(timer);
      timer = setTimeout(_ => {
        timer = null;
        // @ts-ignore
        fn.apply(this);
      }, ms);
    };
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        width: window.innerWidth
      });
    }, 200);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [dimensions.width]);

  const zeroSales = useMemo(
    () => data?.salesVolume.reduce((acc, curr) => acc + curr.amount, 0) === 0,
    [data]
  );

  if (!data) return null;

  const totalSalesQuotient =
    data?.totalSalesEstimate - data?.totalSalesEstimateDelta;

  const totalSalesPercent =
    (data?.totalSalesEstimate * 100) / totalSalesQuotient;

  const avgSoldPriceQuotient = data?.avgSoldPrice - data?.avgSoldPriceDelta;

  const avgSoldPricePercent = (data?.avgSoldPrice * 100) / avgSoldPriceQuotient;

  const numHomesSoldQuotient = data?.numHomesSold - data?.numHomesSoldDelta;

  const numHomesSoldPercent = (data?.numHomesSold * 100) / numHomesSoldQuotient;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Row align="middle" justify="start" className="mb-2" gutter={24}>
        <Col span={8}>
          {data.totalSalesEstimate && data.totalSalesEstimateDelta ? (
            <>
              <Row align="middle" justify="start">
                <p className="text-gray">Total Sales Estimate</p>
              </Row>
              <Row align="middle" justify="start">
                <h5 className="text-dark-blue text-bold mb-0">
                  ${data.totalSalesEstimate.toLocaleString()}
                </h5>
              </Row>
              <Row align="middle" justify="start">
                <small className="text-black-50">
                  <span>
                    <TrendingUp
                      size={16}
                      strokeWidth={1}
                      className="mr-1 text-success"
                    />
                  </span>
                  <span>{totalSalesPercent.toLocaleString()}%</span>
                </small>
              </Row>
            </>
          ) : null}
        </Col>

        <Col span={8}>
          {data.avgSoldPrice && data.avgSoldPriceDelta ? (
            <>
              <Row align="middle" justify="start">
                <p className="text-gray">Avg. Sale Price</p>
              </Row>
              <Row align="middle" justify="start">
                <h5 className="text-dark-blue text-bold mb-0">
                  ${data.avgSoldPrice.toLocaleString()}
                </h5>
              </Row>
              <Row align="middle" justify="start">
                <small className="text-black-50">
                  <span>
                    <TrendingUp
                      size={16}
                      strokeWidth={1}
                      className="mr-1 text-success"
                    />
                  </span>
                  <span>{avgSoldPricePercent.toLocaleString()}%</span>
                </small>
              </Row>
            </>
          ) : null}
        </Col>

        <Col span={8}>
          {data.numHomesSold && data.numHomesSoldDelta ? (
            <>
              <Row align="middle" justify="start">
                <p className="text-gray">Homes Sold</p>
              </Row>
              <Row align="middle" justify="start">
                <h5 className="text-dark-blue text-bold mb-0">
                  {data.numHomesSold.toLocaleString()}
                </h5>
              </Row>
              <Row align="middle" justify="start">
                <small className="text-black-50">
                  <span>
                    <TrendingUp
                      size={16}
                      strokeWidth={1}
                      className="mr-1 text-success"
                    />
                  </span>
                  <span>{numHomesSoldPercent.toLocaleString()}%</span>
                </small>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>

      <Row align="middle" justify="center">
        {zeroSales ? (
          <Col style={{ minHeight: 325 }}>No sales to display</Col>
        ) : (
          <Col xs={23} style={{ height: 325 }}>
            {data.salesVolume.length > 0 && (
              <ResponsiveBar
                data={getSeriesData(data.salesVolume, salesVolumeKey)}
                keys={[salesVolumeKey]}
                defs={[
                  {
                    id: 'gradientC',
                    type: 'linearGradient',
                    colors: [
                      { offset: 0, color: '#76DDFB' },
                      { offset: 100, color: '#53A8E2' }
                    ]
                  }
                ]}
                fill={[{ match: '*', id: 'gradientC' }]}
                margin={{ top: 20, right: 0, bottom: 30, left: 0 }}
                padding={0.05}
                theme={RESPONSIVE_BAR_THEME}
                colors={['#76DDFB']}
                enableLabel={false}
                enableGridY={false}
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32
                }}
                tooltip={({ id, value, color }) => (
                  <strong style={{ color }}>
                    {id}: {value}
                  </strong>
                )}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="#082746"
                animate
                motionStiffness={90}
                motionDamping={15}
              />
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

const getSeriesData = (salesVolume, key: string) =>
  [...salesVolume]
    .sort(({ startDateIsoStr: a }, { startDateIsoStr: b }) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    })
    .map(({ amount }, i) => ({
      id: getMonthFromIndex(i),
      [key]: amount
    }));

const getMonthFromIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    default:
      return 'Dec';
  }
};
