import React from 'react';

export const OutgoingText = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="19"
      viewBox="0 0 35 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-949 -3890)">
          <g transform="translate(891 418)">
            <g transform="translate(58 3472.071)">
              <path
                stroke="#082746"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M35.5 10.6285714L33 8.62857143 30.5 10.6285714"
                transform="rotate(90 33 9.629)"
              />
              <path
                stroke="#082746"
                strokeLinecap="round"
                strokeWidth="2"
                d="M28.5 13.595L28.5 5.595"
                transform="rotate(90 28.5 9.595)"
              />
              <g fill="#082746">
                <path d="M10.111 16.65c5.584 0 10.111-3.727 10.111-8.325C20.222 3.727 15.695 0 10.112 0 4.526 0 0 3.727 0 8.325c0 2.1.944 4.018 2.502 5.482v4.86l4.765-2.351c.902.217 1.856.334 2.844.334z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
