import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { assignmentApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const showClientAssignment = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetAssignmentPending' });

  assignmentApi
    .showClientAssignment({ clientId })
    .then(({ data }) =>
      dispatch({ type: 'GetAssignmentSuccess', payload: data })
    )
    .catch(error => {
      message.error(getFetchMessageText('assignment').error);
      dispatch({ type: 'GetAssignmentFailure' });
    });
};
