import { Listing } from '@elm-street-technology/crm-axios-client';
import { Empty } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

const CardActionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px 6px 0 0;
`;

const CardSelectionContainer = styled.div<{
  viewOnly?: boolean | undefined;
}>`
  position: absolute;
  border-radius: 6px;
  top: 0;
  right: 0;
  background: ${({ viewOnly }) =>
    viewOnly ? 'rgba(0,0,0, 0.38)' : 'rgba(255,255,255, 0.5)'};
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Triangle = styled.div`
  top: 0;
  left: 0px;
  position: absolute;
  background-color: ${({ theme }) => theme.primaryBlue};
  text-align: left;
  width: 16px;
  height: 16px;
  border-top-right-radius: 30%;
  transform: rotate(-90deg) skewX(0deg);

  :before,
  :after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 16px;
    height: 16px;
    border-top-right-radius: 30%;
  }

  :before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  :after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;

const SelectionTitleContainer = styled(CardSelectionContainer)`
  width: auto;
  padding: 0 6px;
  font-size: 12px;
`;

interface Props {
  propertyPhoto?: string;
  viewed: boolean;
  onCardSelected?: (listing: Listing) => void;
  lastInteractionDesc: string;
}

export const CardImage: FC<Props> = ({
  propertyPhoto,
  viewed,
  lastInteractionDesc
}) => (
  <CardActionContainer>
    {!viewed && <Triangle />}
    <SelectionTitleContainer>{lastInteractionDesc}</SelectionTitleContainer>
    {propertyPhoto ? (
      <img
        alt="listing"
        src={`${propertyPhoto}?auto=format&fit=crop&w=321&h=162`}
      />
    ) : (
      <Empty />
    )}
  </CardActionContainer>
);
