import React, {
  createContext,
  ReactElement,
  FC,
  PropsWithChildren,
  useReducer,
  useEffect,
  useRef,
  Reducer
} from 'react';
import { User } from '@elm-street-technology/crm-axios-client';
import { captureMessage } from '@sentry/react';
import axios from 'axios';
import { featureTogglesApi } from 'src/common/services';

interface FetchListState {
  isLoading: boolean;
  isError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

interface FetchListAction {
  type: 'Loading' | 'Error' | 'Success';
  payload?;
}

const initialFetchListState: FetchListState = {
  isLoading: false,
  isError: false,
  data: []
};

const fetchListReducer: Reducer<FetchListState, FetchListAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'Loading':
      return { ...state, isLoading: true, isError: false, data: [] };
    case 'Success':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload || []
      };
    case 'Error':
      return { ...state, isLoading: false, isError: true, data: [] };
    default:
      return { ...state };
  }
};

const useFeatureTogglesApi = (userId: number) => {
  const [state, dispatch] = useReducer(fetchListReducer, initialFetchListState);
  const { current: source } = useRef(axios.CancelToken.source());

  useEffect(() => {
    let mounted = true;

    const fetch = async () => {
      if (mounted) {
        dispatch({ type: 'Loading' });
      }

      try {
        const { data } = await featureTogglesApi.getFeatureToggles(
          { userId },
          {
            cancelToken: source.token
          }
        );
        if (mounted) {
          dispatch({ type: 'Success', payload: data });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          if (mounted) {
            dispatch({ type: 'Error' });
          }
          captureMessage(error);
        }
      }
    };

    fetch();

    return () => {
      mounted = false;
      source.cancel();
    };
  }, [userId, source]);

  const cancelRequest = () => {
    source.cancel();
  };

  return { ...state, cancelRequest };
};

interface FeatureTogglesProviderProps {
  children: ReactElement;
  user: User;
}

type FeatureToggle =
  | 'realtime-reports'
  | 'aiva-8-stop-contact'
  | 'elev-5314-tms';

interface FeatureTogglesState {
  featureToggles: { [key in FeatureToggle]: boolean };
}

export const FeatureTogglesContext = createContext<FeatureTogglesState>({
  featureToggles: {
    'realtime-reports': false,
    'aiva-8-stop-contact': true,
    'elev-5314-tms': false
  }
});

const transformToggles = toggles => {
  return toggles.reduce((toggles, { name, isEnabled }) => {
    toggles[name] = isEnabled;
    return toggles;
  }, {});
};

export const FeatureTogglesProvider: FC<PropsWithChildren<
  FeatureTogglesProviderProps
>> = ({ user, children }) => {
  const { data } = useFeatureTogglesApi(user.id);
  const featureToggles = transformToggles(data);

  return (
    <FeatureTogglesContext.Provider value={{ featureToggles }}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};
