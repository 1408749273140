import React from 'react';

export const LeadsTabMobileIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="41"
    viewBox="0 0 37 41"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="rgba(0, 0, 0, 0.65)">
        <g>
          <path d="M28.712 23.49v3.76h-1.02v-3.758a9.1 9.1 0 00-5.898 2.584 8.833 8.833 0 00-2.636 5.82h3.825v.986h-3.825a8.834 8.834 0 002.638 5.817 9.1 9.1 0 005.896 2.582v-3.755h1.02v3.758a9.109 9.109 0 005.896-2.585 8.842 8.842 0 002.64-5.817h-3.825v-.989h3.823a8.824 8.824 0 00-2.635-5.817 9.11 9.11 0 00-5.899-2.586z" />
          <path d="M16.253 19.793c3.321 0 6.39.986 8.96 2.662-3.863 1.56-6.597 5.295-6.597 9.647 0 3.098 1.41 5.857 3.606 7.77-1.978.28-3.972.426-5.969.437-4.695 0-8.95-.709-11.942-1.722-1.495-.51-2.67-1.097-3.399-1.648-.726-.551-.912-.984-.912-1.18 0-8.83 7.262-15.964 16.256-15.964l-.003-.002z" />
          <ellipse cx="15.481" cy="9.897" rx="9.852" ry="9.897" />
        </g>
      </g>
    </g>
  </svg>
);
