import React, { FC, CSSProperties } from 'react';

const style = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  } as CSSProperties
};

export const CenteredContainer: FC = ({ children }) => (
  <div style={style.container}>{children}</div>
);
