import * as React from 'react';

function SvgYearBuilt(props) {
  return (
    <svg width={29} height={33} {...props}>
      <g stroke="#082746" fill="none" fillRule="evenodd">
        <path d="M14.196 11.26l-1.276.604a1.495 1.495 0 00-.767.842c-.13.359-.125.766.046 1.14l8.164 17.787a1.495 1.495 0 001.995.708l1.276-.604c.371-.176.637-.485.767-.843s.125-.765-.046-1.138l-8.164-17.789a1.492 1.492 0 00-.848-.765 1.495 1.495 0 00-1.147.058z" />
        <path
          d="M2.295 7.423l12.175-5.49c.5-.24 2.625-.333 3.251-.339 3.264-.03 5.975.01 6.118.322.144.313.453.473-2.552 3.747-.437.475-3.099 3.399-3.559 3.617L5.764 14.949A2.51 2.51 0 012.4 13.725l-1.333-2.904c-.148-.89-.151-1.53-.011-1.918.14-.388.553-.882 1.239-1.48z"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgYearBuilt;
