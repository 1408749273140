import * as React from 'react';

const SvgContacts = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <path
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
        d="M12.2 13.116v-1.359c0-.72-.273-1.412-.761-1.922A2.544 2.544 0 009.6 9.039H4.4c-.69 0-1.351.286-1.839.796a2.782 2.782 0 00-.761 1.922v1.36m7.8-9.515C9.6 5.103 8.436 6.32 7 6.32c-1.436 0-2.6-1.217-2.6-2.718S5.564.884 7 .884c1.436 0 2.6 1.217 2.6 2.718z"
      />
    </g>
  </svg>
);

export default SvgContacts;
