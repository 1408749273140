import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <defs>
        <circle id="b" cx="17" cy="17" r="17" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <g>
              <use fill="#c22e2e" xlinkHref="#b" />
            </g>
            <rect x="11" y="11" width="12" height="12" rx="2" fill="white" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
