import { message } from 'antd';
import { clientNoteApi } from 'src/common/services';
import { getUpdateMessageText } from 'src/common/utils';
import { NotesDispatch } from '../Notes.reducer';

export const updateClientNote = (
  dispatch: NotesDispatch,
  clientId: number,
  id: number,
  note: string
) => {
  dispatch({ type: 'UpdateNote' });

  clientNoteApi
    .updateClientNote({ clientId, id, noteUpdateBody: { note } })
    .then(({ data }) => {
      dispatch({ type: 'UpdateNoteSuccess', payload: data });
      message.success(getUpdateMessageText('client note').success);
    })
    .catch(error => {
      dispatch({
        type: 'UpdateNoteFailure',
        payload: error
      });
      message.error(getUpdateMessageText('client note').error);
    });
};
