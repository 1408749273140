import * as React from 'react';

const SvgMySite = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <path
        stroke="#172945"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M4.667 12.25h4.666M7 9.917v2.333M2.333 1.75h9.334c.644 0 1.166.522 1.166 1.167V8.75c0 .644-.522 1.167-1.166 1.167H2.333A1.167 1.167 0 011.167 8.75V2.917c0-.645.522-1.167 1.166-1.167z"
      />
    </g>
  </svg>
);

export default SvgMySite;
