import React, { FC, useEffect, useState, CSSProperties } from 'react';
import { Row, Col, Tag, Form } from 'antd';

const style = {
  tag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 35,
    width: 85
  } as CSSProperties
};

interface Props {
  onChange: (workingWithOtherAgent?: boolean) => void;
  initialValue?: boolean;
}

export const BuyerWorkingWithOtherAgentFormItem: FC<Props> = ({
  onChange,
  initialValue
}) => {
  const [workingWithOtherAgent, setWorkingWithOtherAgent] = useState(
    initialValue
  );

  useEffect(() => {
    onChange(workingWithOtherAgent);
  }, [workingWithOtherAgent]);

  const handleBuyerWorkingWithOtherAgentClick = (value: boolean) => e =>
    setWorkingWithOtherAgent(previous =>
      previous === value ? undefined : value
    );

  return (
    <Form.Item
      name={['buyerFields', 'workingWithOtherAgent']}
      label="Working with another agent?"
    >
      <Row gutter={[4, 4]}>
        <Col>
          <Tag
            color={workingWithOtherAgent === true ? 'blue' : 'default'}
            onClick={handleBuyerWorkingWithOtherAgentClick(true)}
            style={style.tag}
          >
            Yes
          </Tag>
        </Col>

        <Col>
          <Tag
            color={workingWithOtherAgent === false ? 'blue' : 'default'}
            onClick={handleBuyerWorkingWithOtherAgentClick(false)}
            style={style.tag}
          >
            No
          </Tag>
        </Col>
      </Row>
    </Form.Item>
  );
};
