/* eslint-disable @typescript-eslint/camelcase */
import React, { FC } from 'react';
import { Row, Col, PageHeader, Divider } from 'antd';
import moment from 'moment';
import { ClientSellerFieldsCurrentResidents } from '@elm-street-technology/crm-axios-client';
import styled from 'styled-components';
import { emDash } from 'src/common/utils';
import { ClientRecordSectionTitle } from '../../../index';

const ResidentContainer = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #082746;

  h2 {
    font-size: 13px;
    font-weight: 400;
  }
`;

interface Props {
  currentResidents: ClientSellerFieldsCurrentResidents[];
}

export const CurrentResidentsSection: FC<Props> = ({ currentResidents }) => (
  <PageHeader
    title={
      <ClientRecordSectionTitle>Current Residents</ClientRecordSectionTitle>
    }
    footer={<Divider />}
  >
    <Row gutter={[16, 16]}>
      {currentResidents.map((resident: ClientSellerFieldsCurrentResidents) => {
        const historicalAddresses =
          resident.historical_addresses &&
          resident.historical_addresses.length ? (
            resident.historical_addresses.map(
              ({ street_line_1, city, state_code, postal_code }) => (
                <div style={{ marginBottom: 8 }}>
                  <div>{street_line_1}</div>
                  <div>{`${city}, ${state_code} ${postal_code}`}</div>
                </div>
              )
            )
          ) : (
            <div style={{ marginBottom: 8 }}>{emDash}</div>
          );

        return (
          <Col xs={24} sm={12}>
            <ResidentContainer>
              <h2>Name</h2>
              <div style={{ marginBottom: 8 }}>{resident.name || emDash}</div>
              <h2>Gender</h2>
              <div style={{ marginBottom: 8 }}>{resident.gender || emDash}</div>
              <h2>Age Range</h2>
              <div style={{ marginBottom: 8 }}>
                {resident.age_range || emDash}
              </div>
              <h2>Alternate Names</h2>
              <div style={{ marginBottom: 8 }}>
                {resident.alternate_names && resident.alternate_names.length
                  ? resident.alternate_names.join(', ')
                  : emDash}
              </div>
              <h2>Phones</h2>
              {resident.phones && resident.phones.length ? (
                resident.phones.map(phone => (
                  <div style={{ marginBottom: 8 }}>
                    {`${phone.line_type}: ${phone.phone_number}`}
                  </div>
                ))
              ) : (
                <div style={{ marginBottom: 8 }}>{emDash}</div>
              )}
              <h2>Address Start Date</h2>
              <div style={{ marginBottom: 8 }}>
                {resident.link_to_address_start_date ? (
                  moment(resident.link_to_address_start_date).format('L')
                ) : (
                  <div style={{ marginBottom: 8 }}>{emDash}</div>
                )}
              </div>
              <h2>Historical Addresses</h2>
              {historicalAddresses}
            </ResidentContainer>
          </Col>
        );
      })}
    </Row>
  </PageHeader>
);
