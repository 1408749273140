import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { contributorApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const listClientContributors = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetContributorsPending' });

  contributorApi
    .listClientContributors({ clientId })
    .then(({ data }) =>
      dispatch({ type: 'GetContributorsSuccess', payload: data })
    )
    .catch(error => {
      message.error(getFetchMessageText('contributors').error);
      dispatch({ type: 'GetContributorsFailure' });
    });
};
