import React, { FC } from 'react';
import { Table, TableProps, Empty } from 'antd';
import { CMAListing } from '@elm-street-technology/crm-axios-client';
import styled from 'styled-components';
import {
  getListingStatusColor,
  getListingTypeColor,
  numberToDollar,
  numberToThousands,
  emDash,
  ColorCombination
} from 'src/common/utils';
import { StatusCellRenderer } from 'src/crm/components/cells';

const StatusTag = styled.div<{ colors: ColorCombination }>`
  width: 88px;
  font-size: 14px;
  color: ${({ colors }) => colors.foreground || '#000'};
  border: 1px solid ${({ colors }) => colors.border || '#000'};
  background-color: ${({ colors }) => colors.background || '#000'};
  padding: 2px 8px;
  border-radius: 4px;
  text-align: center;
`;

const ListingTypeTag = styled(StatusTag)`
  border-radius: 11px;
  width: 110px;
  white-space: nowrap;
`;

interface TypeCell {
  color: ColorCombination;
  children: React.ReactChild;
}

const ListingTypeCellRenderer: React.FC<TypeCell> = ({ color, children }) => {
  return <ListingTypeTag colors={color}>{children}</ListingTypeTag>;
};

const Property = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  font-size: 10px;
`;

const CellWrapper = styled.div`
  div {
    width: fit-content;
    font-size: 11px;
    margin: 0 auto;
  }
`;

const renderSquareFootageAsAcres = (squareFootage: number | null) => {
  if (!squareFootage) {
    return emDash;
  }

  const acres = squareFootage / 43560;

  return acres.toFixed(2);
};

const makePropertyRenderer = (listing: CMAListing) => {
  const {
    address: { street, state, zipCode, city },
    firstPhoto,
    price
  } = listing;

  const priceFormatted = price ? numberToDollar(price) : '';
  return (
    <div className="flex">
      {firstPhoto ? (
        <img
          style={{
            height: 93,
            width: 113,
            marginRight: 16,
            objectFit: 'cover'
          }}
          alt="listing"
          src={firstPhoto || ''}
        />
      ) : (
        <Empty
          imageStyle={{ height: '93px', width: '113px', objectFit: 'cover' }}
        />
      )}
      <Property>
        <div
          style={{
            fontSize: 15,
            fontWeight: 'bold'
          }}
        >
          {priceFormatted}
        </div>
        <div>{street}</div>
        <div>{`${city}, ${state} ${zipCode}`}</div>
        <br />
      </Property>
    </div>
  );
};

const renderStatus = (status: CMAListing['status']) => (
  <CellWrapper>
    <StatusCellRenderer color={getListingStatusColor(status)}>
      {status}
    </StatusCellRenderer>
  </CellWrapper>
);

const renderType = (type: CMAListing['type']) => (
  <CellWrapper>
    <ListingTypeCellRenderer color={getListingTypeColor(type)}>
      {type}
    </ListingTypeCellRenderer>
  </CellWrapper>
);

interface Props {
  cmaListings: CMAListing[];
  loading?: boolean;
  pagination?: TableProps<CMAListing>['pagination'];
  onListingClick: (listing: CMAListing) => void;
}

export const CmaListingsTable: FC<Props> = ({
  cmaListings,
  loading,
  pagination,
  onListingClick
}) => (
  <Table
    dataSource={cmaListings}
    rowKey="id"
    loading={loading}
    onRow={listing => ({
      onClick: () => onListingClick(listing)
    })}
    pagination={pagination}
    columns={[
      {
        title: 'Property',
        key: 'property',
        render: record => makePropertyRenderer(record),
        width: 230
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: renderStatus,
        width: 60,
        align: 'left'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: renderType,
        width: 80,
        align: 'left'
      },
      {
        title: 'Beds',
        dataIndex: 'beds',
        width: 15,
        align: 'right'
      },
      {
        title: 'Baths',
        dataIndex: 'baths',
        width: 15,
        align: 'right'
      },
      {
        title: 'SQFT',
        dataIndex: 'livingArea',
        width: 30,
        align: 'right',
        render: value => (value ? numberToThousands(value) : emDash)
      },
      {
        title: 'Lot Size (acres)',
        dataIndex: 'squareFootage',
        width: 50,
        align: 'right',
        render: renderSquareFootageAsAcres
      }
    ]}
  />
);
