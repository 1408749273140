import { clientApi } from 'src/common/services';
import { ClientsDispatch } from '../Clients.types';

export const listClientFilters = (dispatch: ClientsDispatch) => {
  dispatch({ type: 'GetOptionsPending' });

  clientApi
    .listClientFilters()
    .then(({ data }) => dispatch({ type: 'GetOptionsSuccess', payload: data }))
    .catch(error => dispatch({ type: 'GetOptionsFailure' }));
};
