import React, { FC } from 'react';

const Icon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M3.113 2.275L3.113 3.575M7.069 3.575L7.069 2.275M7.069 6.825L7.069 5.525M11.108 6.175L11.108 5.525M11.108 9.425L11.108 8.775M5.806 13L5.806 11.05 4.459 11.05 4.459 13zM3.029 5.525L3.029 6.825"
        transform="translate(1 1)"
      />
      <path
        d="M0 2v9c0 1.105.895 2 2 2h6.098c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2H2C.895 0 0 .895 0 2z"
        transform="translate(1 1)"
      />
      <path
        d="M9.058 13h1.733c1.105 0 2-.895 2-2V4.6c0-1.105-.895-2-2-2h-.172 0c-.288 0-.52.233-.52.52v.458h0"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default Icon;
