import { User, Client } from '@elm-street-technology/crm-axios-client';
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import React, { FC } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import List from '@material-ui/core/List';
import styled from 'styled-components';
import ChevronDown from 'src/crm/assets/icons/chevron-down';
import ChevronUp from 'src/crm/assets/icons/chevron-up';
import ReplyArrow from 'src/crm/assets/icons/reply-arrow';
import { TABLET } from 'src/common/constants';
import { IncomingText, OutgoingText } from 'src/crm/assets/icons/profile';
import { ClientCommunicationTextType } from '../CommunicationSection.types';

const ChevronDownIcon = styled(ChevronDown)`
  width: 21px;
  height: 21px;
`;

const ChevronUpIcon = styled(ChevronUp)`
  width: 21px;
  height: 21px;
`;

const ReplyArrowIcon = styled(ReplyArrow)`
  margin-right: 8px;
`;

const ReplyList = styled(List)`
  &.MuiList-root {
    margin-left: 60px;
    @media only screen and (max-width: ${TABLET}) {
      margin-left: 0px;
    }
  }
`;

const MessageList = styled(List)`
  color: ${({ theme }) => theme.secondaryBlue};
`;

const CommunicationsButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondaryBlue};
  border-color: ${({ theme }) => theme.secondaryBlue};
  color: #fff;
  margin-left: auto;
  margin-right: 20px;
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.secondaryBlue};
    border-color: ${({ theme }) => theme.secondaryBlue};
    color: #fff;
  }
`;

const ButtonSection = styled.div`
  display: flex;
`;

interface Props {
  communicationText: ClientCommunicationTextType;
  client: Client;
  handleClick: (id: string) => void;
  user: User;
  expanded: boolean;
  handleTextReply: (client: Client) => void;
}

export const TextListItem: FC<Props> = ({
  communicationText,
  handleClick,
  client,
  user,
  expanded,
  handleTextReply
}) => {
  const disableTextButton = !client.canText || !client.isTextable;

  const isCurrentUser = user.id === communicationText.userId;

  const areThereReplies = communicationText.conversation.length > 0;

  const header =
    communicationText.direction === 'outbound'
      ? `${
          isCurrentUser ? 'You' : user.displayName
        } texted ${client.displayName || 'Unknown'}`
      : `${client.displayName || 'Unknown'} texted ${
          isCurrentUser ? 'You' : user.displayName
        }`;

  return (
    <MessageList key={`${communicationText.id}-${communicationText.type}`}>
      <ListItem
        button
        onClick={() =>
          handleClick(`${communicationText.id}-${communicationText.type}`)
        }
      >
        <ListItemIcon>
          {communicationText.direction === 'inbound' ? (
            <OutgoingText />
          ) : (
            <IncomingText />
          )}
        </ListItemIcon>
        <ListItemText
          primary={header}
          secondary={moment(communicationText.createdAt).format('MM/DD/YY')}
        />
        {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {areThereReplies &&
          communicationText.conversation?.map(reply => {
            const from =
              reply.direction === 'outbound'
                ? user.displayName
                : client.displayName || 'Unknown';
            return (
              <ReplyList disablePadding key={reply.id}>
                <ListItem button>
                  <ListItemIcon>
                    {reply.direction === 'inbound' ? (
                      <OutgoingText />
                    ) : (
                      <IncomingText />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${from}: ${reply.message}`}
                    secondary={`${reply.fromNow}`}
                  />
                </ListItem>
              </ReplyList>
            );
          })}
        <ButtonSection>
          <CommunicationsButton
            disabled={disableTextButton}
            icon={<ReplyArrowIcon />}
            onClick={() => handleTextReply(client)}
          >
            &nbsp; Reply
          </CommunicationsButton>
        </ButtonSection>
      </Collapse>
    </MessageList>
  );
};
