import { Reducer } from 'react';
import { OrganizationMembers } from '@elm-street-technology/crm-axios-client';
import { ClientRecordDrawerActions } from '../ClientRecordDrawer.types';

export interface OrganizationMembersState {
  data: OrganizationMembers[];
  loading: boolean;
}

export type OrganizationMembersActions =
  | { type: 'GetOrgMembersPending' }
  | { type: 'GetOrgMembersSuccess'; payload: OrganizationMembers[] }
  | { type: 'GetOrgMembersFailure' };

export const initialOrganizationMembersState: OrganizationMembersState = {
  data: [],
  loading: false
};

export const organizationMembersReducer: Reducer<
  OrganizationMembersState,
  ClientRecordDrawerActions
> = (state, action) => {
  switch (action.type) {
    case 'GetOrgMembersPending':
      return {
        ...state,
        loading: true
      };
    case 'GetOrgMembersSuccess':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GetOrgMembersFailure':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
