import * as React from 'react';

function SvgLotSize(props) {
  return (
    <svg width="36px" height="33px" viewBox="0 0 36 33" version="1.1">
      <title>Lot Size</title>
      <g
        id="Icons/BuyerSeller/Lot-Size-Icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-4" stroke="#082746">
          <rect id="Rectangle" x="0.5" y="0.5" width="35" height="32" />
        </g>
        <g
          id="Group-15"
          transform="translate(18.500000, 16.500000) rotate(-222.000000) translate(-18.500000, -16.500000) translate(2.000000, 12.000000)"
          stroke="#082746"
          strokeLinecap="round"
          strokeWidth="1.5"
        >
          <polyline
            id="Stroke-5"
            strokeLinejoin="round"
            transform="translate(30.191288, 4.777061) rotate(90.000000) translate(-30.191288, -4.777061) "
            points="34.331288 6.47786197 29.9349043 2.56711349 26.051288 6.98700896"
          />
          <line
            x1="16.7370738"
            y1="18.6716391"
            x2="16.6690649"
            y2="-9.64788375"
            id="Stroke-3"
            transform="translate(16.500000, 4.500000) rotate(90.000000) translate(-16.500000, -4.500000) "
          />
          <polyline
            id="Stroke-5"
            strokeLinejoin="round"
            transform="translate(3.277013, 4.665398) rotate(-89.000000) translate(-3.277013, -4.665398) "
            points="7.41483749 6.15664762 2.99411873 2.41510569 -0.860810624 6.91569069"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLotSize;
