import { User, Client } from '@elm-street-technology/crm-axios-client';
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import List from '@material-ui/core/List';
import React, { FC } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import styled from 'styled-components';
import ChevronDown from 'src/crm/assets/icons/chevron-down';
import ChevronUp from 'src/crm/assets/icons/chevron-up';
import ReplyArrow from 'src/crm/assets/icons/reply-arrow';
import { TABLET, CRM_HOST } from 'src/common/constants';

import IncomingMail from 'src/crm/assets/icons/incoming-email';
import OutgoingMail from 'src/crm/assets/icons/outgoing-email';
import { ClientCommunicationEmailType } from '../CommunicationSection.types';

const ChevronDownIcon = styled(ChevronDown)`
  width: 21px;
  height: 21px;
`;

const ChevronUpIcon = styled(ChevronUp)`
  width: 21px;
  height: 21px;
`;

const ReplyArrowIcon = styled(ReplyArrow)`
  margin-right: 8px;
`;

const MessageContent = styled.div`
  display: flex;
  padding: 8px 22px;
`;

const ReplyList = styled(List)`
  &.MuiList-root {
    margin-left: 60px;
    @media only screen and (max-width: ${TABLET}) {
      margin-left: 0px;
    }
  }
`;

const MessageList = styled(List)`
  color: ${({ theme }) => theme.secondaryBlue};
`;

const CommunicationsButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondaryBlue};
  border-color: ${({ theme }) => theme.secondaryBlue};
  color: #fff;
  margin-left: auto;
  margin-right: 20px;
  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.secondaryBlue};
    border-color: ${({ theme }) => theme.secondaryBlue};
    color: #fff;
  }
`;

const ButtonSection = styled.div`
  display: flex;
`;

const Thumbnail = styled.iframe`
  float: left;
  width: 75%;
  height: auto;
`;

interface Props {
  communicationEmail: ClientCommunicationEmailType;
  client: Client;
  handleClick: (id: string) => void;
  user: User;
  expanded: boolean;
  handleEmailReply: (client: Client) => void;
}

export const EmailListItem: FC<Props> = ({
  communicationEmail,
  handleClick,
  client,
  user,
  expanded,
  handleEmailReply
}) => {
  const { conversation, subject } = communicationEmail;

  const areThereReplies = conversation.length > 0;

  const header = areThereReplies
    ? `${subject} (${conversation.length})`
    : subject;

  return (
    <MessageList key={`${communicationEmail.id}-${communicationEmail.type}`}>
      <ListItem
        button
        onClick={() =>
          handleClick(`${communicationEmail.id}-${communicationEmail.type}`)
        }
      >
        <ListItemIcon>
          {communicationEmail.direction === 'inbound' ? (
            <IncomingMail />
          ) : (
            <OutgoingMail />
          )}
        </ListItemIcon>
        <ListItemText
          primary={header}
          secondary={moment(communicationEmail.createdAt).format('MM/DD/YY')}
        />
        {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {areThereReplies &&
          conversation?.map(reply => {
            return (
              <ReplyList disablePadding key={reply.id}>
                <ListItem button>
                  <ListItemIcon>
                    {reply.direction === 'inbound' ? (
                      <IncomingMail />
                    ) : (
                      <OutgoingMail />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${
                      reply.direction === 'inbound'
                        ? client.displayName
                        : user.displayName!
                    } Replied`}
                    secondary={
                      <MessageContent>
                        <Thumbnail
                          name="message-preview"
                          src={`${CRM_HOST}/leads/${client.id}/email-snapshots/${reply.id}`}
                        />
                      </MessageContent>
                    }
                  />
                </ListItem>
              </ReplyList>
            );
          })}
        <ButtonSection>
          <CommunicationsButton
            icon={<ReplyArrowIcon />}
            onClick={() => handleEmailReply(client)}
          >
            &nbsp; Reply
          </CommunicationsButton>
        </ButtonSection>
      </Collapse>
    </MessageList>
  );
};
