import React, { useState, FC, useMemo } from 'react';
import { Select, Form, Button, Row, Col } from 'antd';
import { OrganizationMembers } from '@elm-street-technology/crm-axios-client';
import { FloatLabel } from 'src/crm/components';

interface Props {
  onAddContributor: (values) => void;
  organizationMembers: OrganizationMembers[];
  updating: boolean;
}

export const AddOrganizationContributor: FC<Props> = ({
  onAddContributor,
  organizationMembers,
  updating
}) => {
  const [form] = Form.useForm();
  const [newValue, setNewValue] = useState<{ organizationalMember: string[] }>({
    organizationalMember: []
  });

  return (
    <Form
      form={form}
      name="organizational-contributor-form"
      onFinish={onAddContributor}
      onValuesChange={changedValues =>
        setNewValue(previous => ({ ...previous, ...changedValues }))
      }
    >
      <FloatLabel
        label="Organizational Member"
        value={newValue.organizationalMember}
        name="organizationalMember"
      >
        <Form.Item name="organizationalMember" rules={[{ required: true }]}>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (option && option.children) {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }
              return false;
            }}
          >
            {useMemo(
              () =>
                organizationMembers.map(organization => (
                  <Select.OptGroup
                    label={organization.optGroupLabel}
                    key={organization.optGroupLabel}
                  >
                    {organization.children.map(childOption => (
                      <Select.Option
                        label={childOption.label}
                        value={childOption.value}
                        key={childOption.value}
                      >
                        {childOption.label}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )),
              [organizationMembers]
            )}
          </Select>
        </Form.Item>
      </FloatLabel>
      <Row justify="end">
        <Col>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={updating}
            >
              Update
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
