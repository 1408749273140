import React, { useContext, useEffect, useState, FC } from 'react';
import { Button, Modal } from 'antd';
import { useUserAccount } from 'src/common/hooks';
import { usersApi } from 'src/common/services';
import { getUser } from '../../crm/pages/Profile/Profile.reducer';
import {
  ProfileContext,
  ProfileDispatchContext
} from '../../crm/pages/Profile/Profile.context';

interface Props {
  userId: number;
}

export const GlobalTwilioRefreshModal: FC<Props> = ({ userId }) => {
  const [open, setOpen] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { userEntity } = useContext(ProfileContext);
  const dispatch = useContext(ProfileDispatchContext);
  const { refreshUser } = useUserAccount();

  useEffect(() => {
    if (!userEntity) {
      getUser(dispatch, userId);
    }
  }, [userEntity]);

  const handleOk = () => {
    setButtonDisabled(true);

    usersApi
      .refreshOTP({ userId })
      .then(() => refreshUser())
      .catch(() => setOpen(false))
      .finally(() => setOpen(false));
  };

  const handleCancel = () => {
    sessionStorage.setItem('ignoreNeedForRefreshToken', 'true');
    setOpen(false);
  };

  if (
    userEntity &&
    userEntity.shouldRefreshOTP &&
    !sessionStorage.getItem('ignoreNeedForRefreshToken')
  ) {
    return (
      <>
        <Modal
          visible={open}
          title="Please Generate New One Time Password"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Not right now
            </Button>,
            <Button key="submit" onClick={handleOk} disabled={isButtonDisabled}>
              Refresh
            </Button>
          ]}
        >
          <p>
            In order for you to be able to use the SMS features provided by
            Elevate you need to confirm your cell number by replying to a text
            message. Please click the Refresh button in order to receive a new
            text message code.
          </p>
        </Modal>
      </>
    );
  }

  return null;
};
