import React, { FC, useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

const FloatLabelContainer = styled.div`
  position: relative;
  width: 100%;

  .ant-input-affix-wrapper {
    padding: 14px 6px 2px 8px;
    min-height: 48px;
    .ant-input {
      height: 30px;
    }
  }

  .ant-input {
    height: 48px;
    padding: 12px 6px 2px 8px;
  }

  .ant-select {
    width: 100%;
    min-height: 48px;

    .ant-select-selector {
      min-height: 48px;
      .ant-select-selection-item {
        height: 24px;
        line-height: 24px;
        margin-top: 0;
      }
    }
  }

  .ant-select .ant-select-selector {
    padding: 14px 6px 2px 8px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 14px 6px 2px 8px;
    height: 48px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
  }
`;

const WrappedLabel = styled.label<{ float: boolean }>`
  font-size: ${({ float }) => (float ? '11px' : '16px')} !important;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 8px;
  top: ${({ float }) => (float ? '4px' : '15px')};
  transition: 0.2s ease all;
  opacity: ${({ float }) => (float ? '1' : '0.4')};
`;

interface FloatLabelProps {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  children: React.ReactChild;
}

export const FloatLabel: FC<FloatLabelProps> = ({
  label,
  value,
  name,
  children
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <FloatLabelContainer
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <WrappedLabel
        htmlFor={name}
        float={!isEmpty(value) || isNumber(value) || focus}
      >
        {label}
      </WrappedLabel>
    </FloatLabelContainer>
  );
};
