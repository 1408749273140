import * as React from 'react';

const IntegrationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g clipPath="url(#clip0_9_23977)">
      <path
        fill="#172945"
        d="M13.338 1.327l-.662-.662a.13.13 0 00-.178 0l-1.19 1.189a3.114 3.114 0 00-1.75-.536c-.8 0-1.6.305-2.212.916L5.754 3.826a.125.125 0 000 .176l4.245 4.246a.123.123 0 00.09.036.132.132 0 00.088-.036l1.593-1.593a3.128 3.128 0 00.38-3.96l1.188-1.19a.127.127 0 000-.178zm-2.321 4.577l-.929.928L7.17 3.913l.928-.928c.389-.389.908-.605 1.46-.605.55 0 1.068.214 1.459.605.389.39.604.908.604 1.46 0 .551-.215 1.068-.604 1.459zm-2.972 1.64a.125.125 0 00-.177 0l-1.04 1.041-1.411-1.41 1.042-1.043a.125.125 0 000-.177l-.569-.568a.125.125 0 00-.177 0L4.671 6.429 4 5.757a.123.123 0 00-.089-.036.132.132 0 00-.089.036l-1.59 1.592a3.128 3.128 0 00-.38 3.961L.66 12.5a.125.125 0 000 .176l.663.662a.13.13 0 00.178 0l1.19-1.189c.526.358 1.139.536 1.751.536.8 0 1.6-.305 2.211-.915l1.592-1.593a.125.125 0 000-.176l-.672-.672 1.043-1.042a.125.125 0 000-.177l-.572-.566zM5.9 11.02a2.052 2.052 0 01-1.46.604 2.048 2.048 0 01-1.459-.604 2.053 2.053 0 01-.605-1.46c0-.551.215-1.069.605-1.46l.928-.927L6.83 10.09l-.928.929z"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_23977">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default IntegrationIcon;
