import React from 'react';
import Icon from '@ant-design/icons';

const DarkFilterSmallSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="9px"
    height="9px"
    viewBox="0 0 9 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Controls/Dark Filter</title>
    <g
      id="Controls/Dark-Filter"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Filter" transform="translate(0.000000, 1.000000)" stroke="#082746">
        <line
          x1="0.5"
          y1="1.5"
          x2="8.5"
          y2="1.5"
          id="Line-2"
          strokeLinecap="square"
        />
        <line
          x1="0.5"
          y1="5.5"
          x2="8.5"
          y2="5.5"
          id="Line-2"
          strokeLinecap="square"
        />
        <circle id="Oval" fill="#D8D8D8" cx="6.5" cy="1.5" r="1.5" />
        <circle id="Oval" fill="#FFFFFF" cx="2.5" cy="5.5" r="1.5" />
      </g>
    </g>
  </svg>
);

export default props => <Icon component={DarkFilterSmallSvg} {...props} />;
