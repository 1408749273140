import React, { FC, useEffect, useMemo, useState, useReducer } from 'react';
import { Skeleton, Row } from 'antd';
import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  DiscreteColorLegend
} from 'react-vis';
import { useUserAccount } from 'src/common/hooks';
import {
  initialLeadClaimChartDataState,
  leadClaimChartDataReducer,
  getLeadClaimChartData
} from './LeadClaimChartData.reducer';
import {
  getClaimedColors,
  getContactedColors,
  XAxisStyled as XAxis,
  YAxisStyled as YAxis,
  HorizontalGridLinesStyled as HorizontalGridLines
} from './LeadClaimTimeChart.styled';

type LeadsClaimedChartProps = {
  height: number;
  timeframe?: string;
};

const legendItems = [
  {
    title: 'Time to Claim',
    color: '#7FE4CD',
    strokeWidth: 2
  },
  {
    title: 'Time to First Contact',
    color: '#6DBEFC',
    strokeWidth: 2
  }
];

export const LeadClaimTimeChart: FC<LeadsClaimedChartProps> = ({
  height,
  timeframe
}) => {
  const { user } = useUserAccount();

  const [{ data, loading, error }, dispatch] = useReducer(
    leadClaimChartDataReducer,
    initialLeadClaimChartDataState
  );
  const [, setFocusedValue] = useState(null);

  useEffect(() => {
    getLeadClaimChartData(dispatch, user.id, timeframe);
  }, [timeframe, user]);

  const claimedSeriesData = useMemo(
    () =>
      [
        {
          x: '<5 mins',
          y: data?.zeroToFiveMin.timeToClaim || 0
        },
        {
          x: '5-10 mins',
          y: data?.fiveToTenMin.timeToClaim || 0
        },
        {
          x: '10-15 mins',
          y: data?.tenToFifteenMin.timeToClaim || 0
        },
        {
          x: '>15 mins',
          y: data?.overFifteenMin.timeToClaim || 0
        },
        {
          x: 'Unclaimed',
          y: data?.unclaimed || 0
        }
      ].map(value => ({
        ...value,
        color: getClaimedColors(value.x),
        opacity: 0.9
      })),
    [data]
  );

  const contactedSeriesData = useMemo(
    () =>
      [
        {
          x: '<5 mins',
          y: data?.zeroToFiveMin.timeToContact || 0
        },
        {
          x: '5-10 mins',
          y: data?.fiveToTenMin.timeToContact || 0
        },
        {
          x: '10-15 mins',
          y: data?.tenToFifteenMin.timeToContact || 0
        },
        {
          x: '>15 mins',
          y: data?.overFifteenMin.timeToContact || 0
        },
        {
          x: 'Unclaimed',
          y: data?.unclaimed || 0
        }
      ].map(value => ({
        ...value,
        color: getContactedColors(value.x),
        opacity: 0.9
      })),
    [data]
  );

  if (error) {
    return (
      <Row justify="center" align="middle" style={{ height }}>
        Data could not be retrieved
      </Row>
    );
  }
  if (loading) {
    return (
      <Row justify="center" style={{ height }}>
        <Skeleton />
      </Row>
    );
  }

  return (
    <div style={{ width: '90%' }}>
      <FlexibleWidthXYPlot xType="ordinal" height={height}>
        <VerticalBarSeries
          animation
          data={claimedSeriesData}
          colorType="literal"
          onValueMouseOver={event => setFocusedValue(event.y)}
        />
        <VerticalBarSeries
          animation
          data={contactedSeriesData}
          colorType="literal"
          onValueMouseOver={event => setFocusedValue(event.y)}
        />
        <XAxis tickPadding={10} />
        <YAxis tickFormat={value => value} tickPadding={10} tickSize={0} />
        <HorizontalGridLines tickTotal={6} />
      </FlexibleWidthXYPlot>
      <DiscreteColorLegend
        items={legendItems}
        orientation="horizontal"
        width={180}
      />
    </div>
  );
};
