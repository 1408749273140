import * as React from 'react';

function SvgCondo(props) {
  return (
    <svg width="29px" height="31px" viewBox="0 0 29 31" version="1.1">
      <title>Condo</title>
      <g
        id="Property-Type/Condo-Icon/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Account-Icon"
          transform="translate(1.000000, 1.000000)"
          stroke="#082746"
        >
          <line
            x1="6.70481151"
            y1="5.075"
            x2="6.70481151"
            y2="7.975"
            id="Line-3"
          />
          <line x1="15.225" y1="7.975" x2="15.225" y2="5.075" id="Line-3" />
          <line x1="15.225" y1="15.225" x2="15.225" y2="12.325" id="Line-3" />
          <line x1="23.925" y1="13.775" x2="23.925" y2="12.325" id="Line-3" />
          <line x1="23.925" y1="21.025" x2="23.925" y2="19.575" id="Line-3" />
          <polygon
            id="Line-3"
            points="12.5048115 29 12.5048115 24.65 9.60481151 24.65 9.60481151 29"
          />
          <line x1="6.525" y1="12.325" x2="6.525" y2="15.225" id="Line-3" />
          <path
            d="M0,2 L0,27 C1.3527075e-16,28.1045695 0.8954305,29 2,29 L19.75,29 C20.8545695,29 21.75,28.1045695 21.75,27 L21.75,2 C21.75,0.8954305 20.8545695,-2.02906125e-16 19.75,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
            id="Path"
          />
          <path
            d="M19.5091421,29 L25.55,29 C26.6545695,29 27.55,28.1045695 27.55,27 L27.55,7.8 C27.55,6.6954305 26.6545695,5.8 25.55,5.8 L22.870951,5.8 L22.870951,5.8 C22.2518669,5.8 21.75,6.30186686 21.75,6.92095101 L21.75,7.98266335 L21.75,7.98266335"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCondo;
