import React, { useContext, useEffect, useState, FC } from 'react';
import { Input, Form, Button, Modal } from 'antd';
import { FloatLabel } from 'src/crm/components';
import { UserUpdateBody } from '@elm-street-technology/crm-axios-client';
import { getUser, updateUser } from '../../crm/pages/Profile/Profile.reducer';
import {
  ProfileContext,
  ProfileDispatchContext
} from '../../crm/pages/Profile/Profile.context';

interface Props {
  userId: number;
}

export const GlobalPhoneNumberModal: FC<Props> = ({ userId }) => {
  const [open, setOpen] = useState(true);
  const { userEntity } = useContext(ProfileContext);
  const dispatch = useContext(ProfileDispatchContext);

  useEffect(() => {
    if (!userEntity) {
      getUser(dispatch, userId);
    }
  }, [userEntity]);

  const handleOk = () => {
    updateUser(dispatch, userId, {
      ...userEntity,
      ...floatValues
    } as UserUpdateBody);
    setOpen(false);
  };

  const handleCancel = () => {
    sessionStorage.setItem('ignoreEmptyPhoneNumber', 'true');
    setOpen(false);
  };

  const [floatValues, setFloatValues] = useState<{
    cellPhone: string | null;
  }>({
    cellPhone: ''
  });

  const [form] = Form.useForm();

  const handleFormValuesChange = formValues => {
    setFloatValues(previous => ({
      ...previous,
      ...formValues
    }));
  };
  if (
    userEntity &&
    !userEntity.cellPhone &&
    !sessionStorage.getItem('ignoreEmptyPhoneNumber')
  ) {
    return (
      <>
        <Modal
          visible={open}
          title="Please Add a Phone Number"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Not right now
            </Button>,
            <Button key="submit" onClick={handleOk}>
              Submit
            </Button>
          ]}
        >
          <p>
            Many of Elevate&apos;s features require a phone number, and we
            noticed you have not added one yet. Let&apos;s fix that for you now!
          </p>
          <Form
            name="profile-profile-info"
            form={form}
            onValuesChange={handleFormValuesChange}
          >
            <div className="pt-3">
              <FloatLabel
                label="Cell Phone"
                name="cellPhone"
                value={floatValues.cellPhone}
              >
                <Form.Item name="cellPhone">
                  <Input size="large" />
                </Form.Item>
              </FloatLabel>
            </div>
          </Form>
        </Modal>
      </>
    );
  }

  return null;
};
