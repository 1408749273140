import { message } from 'antd';
import { clientNoteApi } from 'src/common/services';
import { getCreateMessageText } from 'src/common/utils';
import { NotesDispatch } from '../Notes.reducer';

export const createClientNote = (
  dispatch: NotesDispatch,
  clientId: number,
  note: string
) => {
  dispatch({ type: 'CreateNote' });

  clientNoteApi
    .createClientNote({ clientId, noteCreateBody: { note } })
    .then(({ data }) => {
      dispatch({ type: 'CreateNoteSuccess', payload: data });
      message.success(getCreateMessageText('client note').success);
    })
    .catch(error => {
      dispatch({
        type: 'CreateNoteFailure',
        payload: error
      });
      message.error(getCreateMessageText('client note').error);
    });
};
