import { Dispatch, Reducer } from 'react';
import { AxiosError } from 'axios';
import { Doc } from '@elm-street-technology/crm-axios-client';

export interface DocsState {
  docs: Doc[];
  loading: boolean;
  error: AxiosError | null;
  view: 'List' | 'Edit' | 'Add';
  editDoc: Doc | null;
}

export type DocsActions =
  | { type: 'GetDocs' }
  | { type: 'GetDocsSuccess'; payload: Doc[] }
  | { type: 'GetDocsFailure'; payload: AxiosError }
  | { type: 'UpdateDoc' }
  | { type: 'UpdateDocSuccess'; payload: Doc }
  | { type: 'UpdateDocFailure'; payload: AxiosError }
  | { type: 'CreateDoc' }
  | { type: 'CreateDocSuccess'; payload: Doc }
  | { type: 'CreateDocFailure'; payload: AxiosError }
  | { type: 'DeleteDoc' }
  | { type: 'DeleteDocSuccess'; payload: Doc['id'] }
  | { type: 'DeleteDocFailure'; payload: AxiosError }
  | { type: 'ShowList' }
  | { type: 'ShowEdit'; payload: Doc }
  | { type: 'ShowAdd' };

export type DocsDispatch = Dispatch<DocsActions>;

export const initialDocsState: DocsState = {
  docs: [],
  loading: false,
  error: null,
  view: 'List',
  editDoc: null
};

export const docsReducer: Reducer<DocsState, DocsActions> = (state, action) => {
  switch (action.type) {
    case 'GetDocs':
      return { ...state, loading: true };
    case 'GetDocsSuccess':
      return {
        ...state,
        docs: sortDocsByUpdatedDateDescending(action.payload),
        loading: false
      };
    case 'GetDocsFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case 'UpdateDoc':
      return { ...state, loading: true };
    case 'UpdateDocSuccess':
      return replaceUpdatedDoc(state, action);
    case 'UpdateDocFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case 'CreateDoc':
      return { ...state, loading: true };
    case 'CreateDocSuccess':
      return {
        ...state,
        docs: sortDocsByUpdatedDateDescending([action.payload, ...state.docs]),
        loading: false,
        view: 'List',
        editTask: null
      };
    case 'CreateDocFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case 'DeleteDoc':
      return {
        ...state,
        loading: true
      };
    case 'DeleteDocSuccess':
      return removeDeletedDoc(state, action);
    case 'DeleteDocFailure':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case 'ShowList':
      return {
        ...state,
        view: 'List',
        editDoc: null
      };
    case 'ShowAdd':
      return {
        ...state,
        view: 'Add',
        editDoc: null
      };
    case 'ShowEdit':
      return {
        ...state,
        view: 'Edit',
        editDoc: action.payload
      };
    default:
      return state;
  }
};

const replaceUpdatedDoc: Reducer<
  DocsState,
  { type: 'UpdateDocSuccess'; payload: Doc }
> = (state, action) => {
  const updatedIndex = state.docs.findIndex(
    ({ id }) => id === action.payload.id
  );

  if (updatedIndex > -1) {
    const docs = [...state.docs];
    docs.splice(updatedIndex, 1, action.payload);
    return {
      ...state,
      docs,
      loading: false,
      view: 'List',
      editDoc: null
    };
  }

  return {
    ...state,
    loading: false,
    view: 'List',
    editDoc: null
  };
};

const removeDeletedDoc: Reducer<
  DocsState,
  { type: 'DeleteDocSuccess'; payload: Doc['id'] }
> = (state, action) => {
  const deletedIndex = state.docs.findIndex(({ id }) => id === action.payload);

  if (deletedIndex > -1) {
    const docs = [...state.docs];
    docs.splice(deletedIndex, 1);
    return {
      ...state,
      docs,
      loading: false
    };
  }

  return {
    ...state,
    loading: false
  };
};

const sortDocsByUpdatedDateDescending = (docs: Doc[]) => {
  const sortedDocs = [...docs];

  sortedDocs.sort(({ updatedDateIsoStr: a }, { updatedDateIsoStr: b }) =>
    a < b ? 1 : a > b ? -1 : 0
  );

  return sortedDocs;
};
