import React from 'react';

function Icon() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 14 12" version="1.1">
      <title>Card View</title>
      <desc>Created with Sketch.</desc>
      <g
        id="CRM-Agent-Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="CRM-Dashboard-Desktop---Client-Details-Copy"
          transform="translate(-1541.000000, -2169.000000)"
          stroke="#595959"
        >
          <g id="Group-16" transform="translate(910.000000, 421.000000)">
            <g id="Group-15-Copy">
              <g
                id="Dashboard-Icon"
                transform="translate(632.000000, 1749.000000)"
              >
                <g id="Card-Icon">
                  <path
                    d="M0,2 L0,2.71428571 C1.3527075e-16,3.81885521 0.8954305,4.71428571 2,4.71428571 L3.47368421,4.71428571 C4.57825371,4.71428571 5.47368421,3.81885521 5.47368421,2.71428571 L5.47368421,2 C5.47368421,0.8954305 4.57825371,-2.02906125e-16 3.47368421,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                    id="Path"
                  />
                  <path
                    d="M6.84210526,2 L6.84210526,2.71428571 C6.84210526,3.81885521 7.73753576,4.71428571 8.84210526,4.71428571 L10.3157895,4.71428571 C11.420359,4.71428571 12.3157895,3.81885521 12.3157895,2.71428571 L12.3157895,2 C12.3157895,0.8954305 11.420359,-2.02906125e-16 10.3157895,0 L8.84210526,0 C7.73753576,2.02906125e-16 6.84210526,0.8954305 6.84210526,2 Z"
                    id="Path"
                  />
                  <path
                    d="M6.84210526,7.59821429 L6.84210526,8.3125 C6.84210526,9.4170695 7.73753576,10.3125 8.84210526,10.3125 L10.3157895,10.3125 C11.420359,10.3125 12.3157895,9.4170695 12.3157895,8.3125 L12.3157895,7.59821429 C12.3157895,6.49364479 11.420359,5.59821429 10.3157895,5.59821429 L8.84210526,5.59821429 C7.73753576,5.59821429 6.84210526,6.49364479 6.84210526,7.59821429 Z"
                    id="Path"
                  />
                  <path
                    d="M0,7.59821429 L0,8.3125 C4.03753542e-16,9.4170695 0.8954305,10.3125 2,10.3125 L3.47368421,10.3125 C4.57825371,10.3125 5.47368421,9.4170695 5.47368421,8.3125 L5.47368421,7.59821429 C5.47368421,6.49364479 4.57825371,5.59821429 3.47368421,5.59821429 L2,5.59821429 C0.8954305,5.59821429 -1.3527075e-16,6.49364479 0,7.59821429 Z"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
