import React from 'react';

export const MyTeamListingsTabMobileIconSvg = () => (
  <svg
    width="27px"
    height="26px"
    viewBox="0 0 27 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 6</title>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Side-Menu-Icons"
        transform="translate(-312.000000, -617.000000)"
        stroke="#082746"
      >
        <g id="Group-6" transform="translate(312.000000, 617.000000)">
          <g
            id="Group-5"
            transform="translate(6.000000, 13.000000)"
            strokeWidth="0.5"
          >
            <g id="Group-4" transform="translate(10.705882, 0.902778)">
              <path
                d="M2.24270693,2.77961108 C2.75028411,2.77961108 3.21111642,2.99794294 3.54472089,3.35445968 C3.87108949,3.70324359 4.07514289,4.1848445 4.07659549,4.71956786 L4.07659549,5.88215946 L0.911519451,5.88215946 C0.799882995,5.33103045 0.766079449,5.19672028 0.60456415,4.84533732 C0.514958309,4.65039623 0.211319203,4.14230096 0.126005447,4.00194064 C-0.175045702,3.06985528 0.977431124,2.78042929 1.480936,2.77961108 L2.24270693,2.77961108 Z"
                id="Path"
                strokeLinejoin="round"
              />
              <ellipse
                id="Oval"
                cx="1.97519182"
                cy="1.18489583"
                rx="1.38107417"
                ry="1.18489583"
              />
            </g>
            <g id="Group-3" transform="translate(4.411765, 0.000000)">
              <path
                d="M4.2789666,3.88230414 C5.01397366,3.88230414 5.67889599,4.18395576 6.16115346,4.66908889 C6.65204765,5.16291025 6.95434088,5.84608483 6.95651485,6.60167813 L6.95651485,7.91029793 L0.419201895,7.91029793 L0.409780335,6.66482528 C0.394063046,5.88617341 0.698742455,5.17852473 1.2029714,4.67060879 C1.68447893,4.18558042 2.34859247,3.88342887 3.08372406,3.88230414 L4.2789666,3.88230414 Z"
                id="Path"
                strokeLinejoin="round"
              />
              <ellipse
                id="Oval"
                cx="3.68286445"
                cy="1.75"
                rx="1.92513369"
                ry="1.75"
              />
            </g>
            <g id="Group" transform="translate(0.300000, 0.902778)">
              <ellipse
                id="Oval"
                cx="2.85421995"
                cy="1.18489583"
                rx="1.38107417"
                ry="1.18489583"
              />
              <path
                d="M3.64536547,2.70273273 C5.37343658,3.04129885 5.27384378,3.59364468 5.18065079,3.82741719 C5.05797111,4.02138574 4.5980207,4.90722198 4.56521029,5.12081572 L4.30316565,5.88215946 L0.461062713,5.88215946 L0.461062713,4.69607437 C0.445100679,4.13969106 0.699300897,3.63503769 1.1173474,3.27290014 C1.52358087,2.92099577 2.08440519,2.7035482 2.70429694,2.70273273 L3.64536547,2.70273273 Z"
                id="Path"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <line
            x1="0.909090909"
            y1="8.86363636"
            x2="13.6363636"
            y2="0.984848485"
            id="Line-3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="13.2727273"
            y1="0.984848485"
            x2="26"
            y2="8.86363636"
            id="Line-4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.09090909,12.1212121 L5.09090909,23 C5.09090909,24.1045695 5.98633959,25 7.09090909,25 L20.5454545,25 C21.650024,25 22.5454545,24.1045695 22.5454545,23 L22.5454545,12.1212121 L22.5454545,12.1212121"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);
