import {
  Configuration,
  PagesApi,
  WrappersApi,
  SavedLinksApi,
  AccountApi,
  PreferencesApi,
  CssApi
} from '@elm-street-technology/idx-middleware-axios-client';
import { User } from '@elm-street-technology/crm-axios-client';
import {
  IDX_MIDDLEWARE_HOST,
  IDX_MIDDLEWARE_API_KEY,
  IDX_MIDDLEWARE_AUTH0_USER_ID
} from 'src/common/constants';

// To Do: connect elevateId to auth0UserID
const getConfig = (elevateId: string) =>
  new Configuration({
    basePath: IDX_MIDDLEWARE_HOST,
    baseOptions: {
      headers: {
        auth0UserID: IDX_MIDDLEWARE_AUTH0_USER_ID,
        accessToken: IDX_MIDDLEWARE_API_KEY
      }
    }
  });

export const pagesApi = ({ elevateId }: User) =>
  new PagesApi(getConfig(elevateId));

export const wrappersApi = ({ elevateId }: User) =>
  new WrappersApi(getConfig(elevateId));

export const savedLinksApi = ({ elevateId }: User) =>
  new SavedLinksApi(getConfig(elevateId));

export const accountApi = ({ elevateId }: User) =>
  new AccountApi(getConfig(elevateId));

export const preferencesApi = ({ elevateId }: User) =>
  new PreferencesApi(getConfig(elevateId));

export const cssApi = ({ elevateId }: User) => new CssApi(getConfig(elevateId));
