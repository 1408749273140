import React from 'react';

export const MyCompanyListingsTabMobileIconSvg = () => (
  <svg
    width="27px"
    height="27px"
    viewBox="0 0 27 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icons/My Company Listings</title>
    <g
      id="CRM-Listing-Responsive"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mobile-Listing-V2---Overview"
        transform="translate(-215.000000, -70.000000)"
      >
        <g
          id="Icons/My-Company-Listings"
          transform="translate(216.000000, 71.000000)"
        >
          <g
            id="Icons/BuyerSeller/Prequalified-Icon"
            transform="translate(17.000000, 16.304348)"
          >
            <g
              id="Account-Icon"
              stroke="#082746"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line
                x1="0.304054054"
                y1="3.08300395"
                x2="4.56081081"
                y2="0.342555995"
                id="Line-3"
              />
              <line
                x1="4.43918919"
                y1="0.342555995"
                x2="8.69594595"
                y2="3.08300395"
                id="Line-4"
              />
              <path
                d="M1.7027027,4.21607378 L1.7027027,6.69565217 C1.7027027,7.80022167 2.5981332,8.69565217 3.7027027,8.69565217 L5.54054054,8.69565217 C6.64511004,8.69565217 7.54054054,7.80022167 7.54054054,6.69565217 L7.54054054,4.21607378 L7.54054054,4.21607378"
                id="Path"
              />
            </g>
            <path
              d="M4.01975828,6.52173913 L4.01721988,6.52173913 C3.95306745,6.52108695 3.89214573,6.49521699 3.84899284,6.45043372 L3.06001037,5.63194321 C2.97416612,5.54324623 2.98131981,5.40563548 3.07570233,5.3249821 C3.17008485,5.24432872 3.31615852,5.25106795 3.40154124,5.33976493 L4.02345051,5.98455718 L5.60164622,4.41562067 C5.68956735,4.32844546 5.83564102,4.32496714 5.92794667,4.40735968 C6.02048307,4.48996961 6.02440606,4.62758036 5.9367157,4.71475558 L4.18729302,6.45391203 C4.14367861,6.49739094 4.08298765,6.52173913 4.01975828,6.52173913"
              id="Icon"
              fill="#082746"
            />
          </g>
          <g
            id="Icons/BuyerSeller/Prequalified-Icon"
            transform="translate(17.000000, 5.434783)"
          >
            <g
              id="Account-Icon"
              stroke="#082746"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line
                x1="0.304054054"
                y1="3.08300395"
                x2="4.56081081"
                y2="0.342555995"
                id="Line-3"
              />
              <line
                x1="4.43918919"
                y1="0.342555995"
                x2="8.69594595"
                y2="3.08300395"
                id="Line-4"
              />
              <path
                d="M1.7027027,4.21607378 L1.7027027,6.69565217 C1.7027027,7.80022167 2.5981332,8.69565217 3.7027027,8.69565217 L5.54054054,8.69565217 C6.64511004,8.69565217 7.54054054,7.80022167 7.54054054,6.69565217 L7.54054054,4.21607378 L7.54054054,4.21607378"
                id="Path"
              />
            </g>
            <path
              d="M4.01975828,6.52173913 L4.01721988,6.52173913 C3.95306745,6.52108695 3.89214573,6.49521699 3.84899284,6.45043372 L3.06001037,5.63194321 C2.97416612,5.54324623 2.98131981,5.40563548 3.07570233,5.3249821 C3.17008485,5.24432872 3.31615852,5.25106795 3.40154124,5.33976493 L4.02345051,5.98455718 L5.60164622,4.41562067 C5.68956735,4.32844546 5.83564102,4.32496714 5.92794667,4.40735968 C6.02048307,4.48996961 6.02440606,4.62758036 5.9367157,4.71475558 L4.18729302,6.45391203 C4.14367861,6.49739094 4.08298765,6.52173913 4.01975828,6.52173913"
              id="Icon"
              fill="#082746"
            />
          </g>
          <g
            id="Property-Type/Condo-Icon"
            stroke="#082746"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g id="Account-Icon">
              <line
                x1="4.62400794"
                y1="4.375"
                x2="4.62400794"
                y2="6.875"
                id="Line-3"
              />
              <line x1="10.5" y1="6.875" x2="10.5" y2="4.375" id="Line-3" />
              <line x1="10.5" y1="13.125" x2="10.5" y2="10.625" id="Line-3" />
              <polygon
                id="Line-3"
                points="8.62400794 25 8.62400794 21.25 6.62400794 21.25 6.62400794 25"
              />
              <line x1="4.5" y1="10.625" x2="4.5" y2="13.125" id="Line-3" />
              <path
                d="M0,2 L0,23 C1.3527075e-16,24.1045695 0.8954305,25 2,25 L13,25 C14.1045695,25 15,24.1045695 15,23 L15,2 C15,0.8954305 14.1045695,-2.02906125e-16 13,0 L2,0 C0.8954305,2.02906125e-16 -1.3527075e-16,0.8954305 0,2 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
