import React from 'react';

export const LandIconSvg = () => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 5.8L8.3125 0.8"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6875 0.8L16.5 5.8"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 10.8L8.3125 5.8"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6875 5.8L16.5 10.5"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 15.5L8.3125 10.8"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6875 10.8L16.5 15.5"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.48656 0.709229L8.44313 21.5099"
      stroke="#082746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
