import { message } from 'antd';
import { getFetchMessageText } from 'src/common/utils';
import { permissionApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const listClientPermissions = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number
) => {
  dispatch({ type: 'GetPermissionsPending' });

  permissionApi
    .listClientPermissions({ clientId })
    .then(({ data }) =>
      dispatch({ type: 'GetPermissionsSuccess', payload: data })
    )
    .catch(error => {
      message.error(getFetchMessageText('client permissions').error);
      dispatch({ type: 'GetPermissionsFailure' });
    });
};
