export const getFetchMessageText = (dataName: string) => ({
  loading: `Fetching ${dataName}...`,
  error: `Error fetching ${dataName}.`,
  success: `Successfully fetched ${dataName}!`
});

export const getUpdateMessageText = (dataName: string) => ({
  loading: `Updating ${dataName}...`,
  error: `Error updating ${dataName}.`,
  success: `Successfully updated ${dataName}!`
});

export const getDeleteMessageText = (dataName: string) => ({
  loading: `Deleting ${dataName}...`,
  error: `Error deleting ${dataName}.`,
  success: `Successfully deleted ${dataName}!`
});

export const getCreateMessageText = (dataName: string) => ({
  loading: `Creating ${dataName}...`,
  error: `Error creating ${dataName}.`,
  success: `Successfully created ${dataName}!`
});

export const getSearchMessageText = (dataName: string) => ({
  loading: `Searching ${dataName}...`,
  error: `Error searching ${dataName}.`,
  success: `Successfully searched ${dataName}!`
});

export function numberToThousands(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberToDollar(n: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(n);
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.substring(1);
}

export function strToInitials(str: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let initials: any = str.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
}

export const emDash = '\u2014';
