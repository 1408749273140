import { Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';

const SavedLinks = React.lazy(() =>
  import('src/idx-sites/pages/SavedLinks/index')
);

const AddSavedLink = React.lazy(() =>
  import('src/idx-sites/pages/AddSavedLink')
);

const EditSavedLink = React.lazy(() =>
  import('src/idx-sites/pages/EditSavedLink')
);

const PageManagement = React.lazy(() =>
  import('src/idx-sites/pages/PageManagement')
);

const Wrappers = React.lazy(() => import('src/idx-sites/pages/Wrappers'));

const CustomCss = React.lazy(() => import('src/idx-sites/pages/CustomCss'));

const Subheaders = React.lazy(() => import('src/idx-sites/pages/Subheaders'));

const Account = React.lazy(() => import('src/idx-sites/pages/Account'));

const AccessControl = React.lazy(() =>
  import('src/idx-sites/pages/AccessControl')
);

const LeadRegistration = React.lazy(() =>
  import('src/idx-sites/pages/LeadRegistration')
);

const Clients = React.lazy(() => import('src/crm/pages/Clients'));

const Listings = React.lazy(() => import('src/crm/pages/Listings/Listings'));

const EditClientSavedSearch = React.lazy(() =>
  import('src/crm/pages/Listings/EditClientSavedSearch')
);

const CreateClientSavedSearch = React.lazy(() =>
  import('src/crm/pages/Listings/CreateClientSavedSearch')
);

const CreateBulkSavedSearch = React.lazy(() =>
  import('src/crm/pages/Listings/CreateBulkSavedSearch')
);

const EditUserSavedSearch = React.lazy(() =>
  import('src/crm/pages/Listings/EditUserSavedSearch')
);

const Recruiting = React.lazy(() =>
  import('src/crm/pages/Recruiting/Recruiting')
);

const Campaigns = React.lazy(() => import('src/crm/pages/Campaigns'));

const RosterMembers = React.lazy(() =>
  import('src/crm/pages/RosterMembers/RosterMembers')
);

const Profile = React.lazy(() => import('src/crm/pages/Profile/Profile'));

const Dashboard = React.lazy(() => import('src/crm/pages/Dashboard'));

const clientsRoutes = (
  <Route path="clients">
    <Route path="/" element={<Clients />} />
    <Route path="new" element={<Clients />} />
    <Route path="search-by-name" element={<Clients />} />
    <Route path="saved-searches/new" element={<CreateBulkSavedSearch />} />
    <Route path=":clientId">
      <Route path="/" element={<Clients />} />
      <Route path="edit" element={<Clients />} />
      <Route path="call" element={<Clients />} />
      <Route path="text" element={<Clients />} />
      <Route path="email" element={<Clients />} />
      <Route path="profile" element={<Clients />} />
      <Route path="assignment" element={<Clients />} />
      <Route path="site-invite" element={<Clients />} />
      <Route path="contributors" element={<Clients />} />
      <Route path="activity" element={<Clients />} />
      <Route path="saved-searches">
        <Route path="/" element={<CreateClientSavedSearch />} />
        <Route path="edit/:searchId" element={<EditClientSavedSearch />} />
      </Route>
      <Route path="tasks">
        <Route path="/" element={<Clients />} />
        <Route path=":taskId" element={<Clients />} />
      </Route>
      <Route path="notes">
        <Route path="/" element={<Clients />} />
        <Route path="new" element={<Clients />} />
        <Route path=":noteId/edit" element={<Clients />} />
      </Route>
      <Route path="documents">
        <Route path="/" element={<Clients />} />
        <Route path="new" element={<Clients />} />
      </Route>
    </Route>
  </Route>
);

const leadsRoutes = (
  <Route path="leads">
    <Route path="/" element={<Clients />} />
    <Route path="new" element={<Clients />} />
    <Route path="search-by-name" element={<Clients />} />
    <Route path="saved-searches/new" element={<CreateBulkSavedSearch />} />
    <Route path=":clientId">
      <Route path="/" element={<Clients />} />
      <Route path="edit" element={<Clients />} />
      <Route path="call" element={<Clients />} />
      <Route path="text" element={<Clients />} />
      <Route path="email" element={<Clients />} />
      <Route path="profile" element={<Clients />} />
      <Route path="assignment" element={<Clients />} />
      <Route path="site-invite" element={<Clients />} />
      <Route path="contributors" element={<Clients />} />
      <Route path="activity" element={<Clients />} />
      <Route path="saved-searches">
        <Route path="/" element={<CreateClientSavedSearch />} />
        <Route path="edit/:searchId" element={<EditClientSavedSearch />} />
      </Route>
      <Route path="tasks">
        <Route path="/" element={<Clients />} />
        <Route path=":taskId" element={<Clients />} />
      </Route>
      <Route path="notes">
        <Route path="/" element={<Clients />} />
        <Route path="new" element={<Clients />} />
        <Route path=":noteId/edit" element={<Clients />} />
      </Route>
      <Route path="documents">
        <Route path="/" element={<Clients />} />
        <Route path="new" element={<Clients />} />
      </Route>
    </Route>
  </Route>
);

const contactsRoutes = (
  <Route path="contacts">
    <Route path="/" element={<Clients />} />
    <Route path="new" element={<Clients />} />
    <Route path="search-by-name" element={<Clients />} />
    <Route path="saved-searches/new" element={<CreateBulkSavedSearch />} />
    <Route path=":clientId">
      <Route path="/" element={<Clients />} />
      <Route path="edit" element={<Clients />} />
      <Route path="call" element={<Clients />} />
      <Route path="text" element={<Clients />} />
      <Route path="email" element={<Clients />} />
      <Route path="profile" element={<Clients />} />
      <Route path="assignment" element={<Clients />} />
      <Route path="site-invite" element={<Clients />} />
      <Route path="contributors" element={<Clients />} />
      <Route path="activity" element={<Clients />} />
      <Route path="saved-searches">
        <Route path="/" element={<CreateClientSavedSearch />} />
        <Route path="edit/:searchId" element={<EditClientSavedSearch />} />
      </Route>
      <Route path="tasks">
        <Route path="/" element={<Clients />} />
        <Route path=":taskId" element={<Clients />} />
      </Route>
      <Route path="notes">
        <Route path="/" element={<Clients />} />
        <Route path="new" element={<Clients />} />
        <Route path=":noteId/edit" element={<Clients />} />
      </Route>
      <Route path="documents">
        <Route path="/" element={<Clients />} />
        <Route path="new" element={<Clients />} />
      </Route>
    </Route>
  </Route>
);

const listingsRoutes = (
  <Route path="listings">
    <Route path="/" element={<Listings />} />
    <Route path=":listingId" element={<Listings />} />
    <Route path="my-listings" element={<Listings />} />
    <Route path="my-listings/:listingId" element={<Listings />} />
    <Route path="my-company-listings" element={<Listings />} />
    <Route path="my-company-listings/:listingId" element={<Listings />} />
    <Route path="my-team-listings" element={<Listings />} />
    <Route path="my-team-listings/:listingId" element={<Listings />} />
  </Route>
);

const siteManagerRoutes = (
  <Route path="idx-sites">
    <Route path="pages/*" element={<PageManagement />} />
    <Route path="pages/new" element={null} />
    <Route path="pages/:pageId/templates" element={null} />
    <Route path="locations" element={null} />
    <Route path="wrappers" element={<Wrappers />} />
    <Route path="wrappers/:wrapperId" element={<Wrappers />} />
    <Route path="css" element={<CustomCss />} />
    <Route path="css/:cssId" element={<CustomCss />} />
    <Route path="subheaders" element={<Subheaders />} />
    <Route path="subheaders/:subheaderId" element={<Subheaders />} />
    <Route path="account" element={<Account />} />
    <Route path="access-control" element={<AccessControl />} />
    <Route path="lead-registration" element={<LeadRegistration />} />
    <Route path="saved-links" element={<SavedLinks />} />
    <Route path="saved-links/add" element={<AddSavedLink />} />
    <Route path="saved-links/:savedLinkId/edit" element={<EditSavedLink />} />
  </Route>
);

export const routes = (
  <Suspense fallback={<div />}>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      {listingsRoutes}
      <Route
        path="saved-searches/edit/:searchId"
        element={<EditUserSavedSearch />}
      />
      <Route path="recruiting">
        <Route path="/" element={<Recruiting />} />
        <Route path="/marketshare" element={<Recruiting />} />
      </Route>
      {clientsRoutes}
      {leadsRoutes}
      {contactsRoutes}
      <Route path="campaigns" element={<Campaigns />} />
      <Route path="sites/:siteId/roster-members" element={<RosterMembers />} />
      <Route path="profile" element={<Profile />} />
      {siteManagerRoutes}
    </Routes>
  </Suspense>
);
