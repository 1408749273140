import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#082746" strokeWidth="2">
          <path d="M6.5 0L6.5 14" />
          <path d="M0 7L13 7" />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
