/* eslint-disable no-underscore-dangle,@typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { User, UserRolesEnum } from '@elm-street-technology/crm-axios-client';

export const identifyPendoUser = (user: User) => {
  const w: any = window;
  const isSuperAdmin = user.roles.includes(UserRolesEnum.SuperAdmin);
  if (w.pendo && !isSuperAdmin && process.env.NODE_ENV === 'production') {
    w.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: user.displayName,
        tenant: user.tenant,
        signup_date: user.createdAt,
        plan_type:
          user.products?.map(product => product.name).join(' , ') ?? undefined,
        is_super_admin: isSuperAdmin,
        is_broker: user.roles?.includes(UserRolesEnum.Broker),
        is_agent: !user.roles?.includes(UserRolesEnum.Broker),
        is_agent_with_organization: user.roles?.includes(
          UserRolesEnum.TeamAdmin
        ),
        is_impersonating: user.topNav
          .map(nav => nav.href)
          .includes('/stop-impersonate')

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: user.elevateId
      }
    });
  }
};
