import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Recruiting 4</title>
    <defs>
      <circle id="path-1" cx="9.61714286" cy="9.61714286" r="9.61714286" />
    </defs>
    <g
      id="CRM-Agent-Dashboard"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Side-Menu-Icons" transform="translate(-374.000000, -785.000000)">
        <g id="Recruiting-4" transform="translate(375.000000, 785.000000)">
          <g id="Group-6" transform="translate(0.457143, 0.914286)">
            <line
              x1="16.9714286"
              y1="16.9714286"
              x2="22.6285714"
              y2="22.6285714"
              id="Shape"
              stroke="#4A525A"
              strokeLinecap="round"
            />
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <use
              id="Oval"
              stroke="#4A525A"
              strokeLinecap="round"
              xlinkHref="#path-1"
            />
            <g id="Group-5" mask="url(#mask-2)">
              <g transform="translate(0.542857, 4.085714)">
                <g id="Group-4" transform="translate(12.094917, 1.000000)">
                  <path
                    d="M3.15163943,3.51880436 C3.80541514,3.51880436 4.39898194,3.79519809 4.82867521,4.24652478 C5.24904844,4.68806221 5.51187577,5.29773721 5.51374677,5.97466173 L5.51374677,7.44642604 L1.43702723,7.44642604 C1.29323589,6.74873305 1.36361013,6.2697632 1.15557324,5.82493553 C1.04015804,5.57815297 0.9027334,5.35764552 0.792846544,5.17995861 C0.405083,4 1.52192358,3.51984015 2.17045402,3.51880436 L3.15163943,3.51880436 Z"
                    id="Path"
                    stroke="#4A525A"
                    strokeLinejoin="round"
                  />
                  <circle
                    id="Oval"
                    stroke="#4A525A"
                    cx="2.80508331"
                    cy="1.5"
                    r="1.5"
                  />
                </g>
                <g id="Group-3" transform="translate(4.500000, 0.000000)">
                  <path
                    d="M5.22836232,4.43691902 C6.12644907,4.43691902 6.93890104,4.78166372 7.52815938,5.33610159 C8.12797072,5.90046886 8.49733526,6.6812398 8.49999158,7.544775 L8.49999158,9.04034049 L0.512212316,9.04034049 L0.500700347,7.61694318 C0.481495784,6.72705532 0.853775937,5.91831398 1.46988068,5.33783862 C2.05822269,4.78352048 2.86968643,4.43820442 3.76792533,4.43691902 L5.22836232,4.43691902 Z"
                    id="Path"
                    stroke="#4A525A"
                    strokeLinejoin="round"
                  />
                  <circle id="Oval" stroke="#4A525A" cx="4.5" cy="2" r="2" />
                </g>
                <g id="Group" transform="translate(0.000000, 1.000000)">
                  <circle
                    id="Oval"
                    stroke="#4A525A"
                    cx="3.1"
                    cy="1.5"
                    r="1.5"
                  />
                  <path
                    d="M3.95927194,3.42148144 C5.83614917,3.85008382 4.96699903,5.30889786 4.86578109,5.60483844 C4.73253733,5.85038984 4.70935178,6.30266732 4.67371603,6.57306292 L4.67371603,7.44642604 L0.500765336,7.44642604 L0.500765336,5.94492052 C0.483428793,5.24057593 0.759518474,4.60171804 1.21356343,4.14327578 C1.65477811,3.69778805 2.26389564,3.42251377 2.93716695,3.42148144 L3.95927194,3.42148144 Z"
                    id="Path"
                    stroke="#4A525A"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SearchTabIcon = props => <Icon component={Svg} {...props} />;
