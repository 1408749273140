import * as React from 'react';

function SvgBusinessOp(props) {
  return (
    <svg width="73px" height="30px" viewBox="0 0 73 30" version="1.1">
      <title>Business Opportunity</title>
      <g
        id="Property-Type/Business-Opportunity-Icon/Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="Property-Type/Business-Opportunity-Icon" stroke="#082746">
          <g id="Group" transform="translate(19.333333, 0.000000)">
            <path
              d="M4.83333333,14.5 L4.83333333,27 C4.83333333,28.1045695 5.72876383,29 6.83333333,29 L27,29 C28.1045695,29 29,28.1045695 29,27 L29,14.5 L29,14.5"
              id="Path"
            />
            <line
              x1="1.10763889"
              y1="11.6"
              x2="16.6145833"
              y2="1.28888889"
              id="Line-3"
            />
            <line
              x1="17.21875"
              y1="1.28888889"
              x2="32.7256944"
              y2="11.6"
              id="Line-4"
            />
          </g>
          <g id="Group" transform="translate(0.000000, 11.277778)">
            <path
              d="M3.22222222,8.05555556 L3.22222222,15.7222222 C3.22222222,16.8267917 4.11765272,17.7222222 5.22222222,17.7222222 L15.7222222,17.7222222 C16.8267917,17.7222222 17.7222222,16.8267917 17.7222222,15.7222222 L17.7222222,8.05555556 L17.7222222,8.05555556"
              id="Path"
            />
            <line
              x1="0.704861111"
              y1="7.25"
              x2="10.5729167"
              y2="0.805555556"
              id="Line-3"
            />
            <line
              x1="10.3715278"
              y1="0.805555556"
              x2="20.2395833"
              y2="7.25"
              id="Line-4"
            />
          </g>
          <g id="Group" transform="translate(51.555556, 11.277778)">
            <path
              d="M3.22222222,8.05555556 L3.22222222,15.7222222 C3.22222222,16.8267917 4.11765272,17.7222222 5.22222222,17.7222222 L15.7222222,17.7222222 C16.8267917,17.7222222 17.7222222,16.8267917 17.7222222,15.7222222 L17.7222222,8.05555556 L17.7222222,8.05555556"
              id="Path"
            />
            <line
              x1="0.704861111"
              y1="7.25"
              x2="10.5729167"
              y2="0.805555556"
              id="Line-3"
            />
            <line
              x1="10.3715278"
              y1="0.805555556"
              x2="20.2395833"
              y2="7.25"
              id="Line-4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgBusinessOp;
