import React from 'react';

export const MyListingsTabMobileIconSvg = () => (
  <svg
    width="27px"
    height="26px"
    viewBox="0 0 27 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icons/BuyerSeller/Prequalified Icon</title>
    <g
      id="CRM-Listing-Responsive"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mobile-Listing-V2---Overview"
        transform="translate(-123.000000, -69.000000)"
      >
        <g
          id="Icons/BuyerSeller/Prequalified-Icon"
          transform="translate(123.000000, 69.000000)"
        >
          <g
            id="Account-Icon"
            stroke="#082746"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line
              x1="0.909090909"
              y1="8.86363636"
              x2="13.6363636"
              y2="0.984848485"
              id="Line-3"
            />
            <line
              x1="13.2727273"
              y1="0.984848485"
              x2="26"
              y2="8.86363636"
              id="Line-4"
            />
            <path
              d="M5.09090909,12.1212121 L5.09090909,23 C5.09090909,24.1045695 5.98633959,25 7.09090909,25 L20.5454545,25 C21.650024,25 22.5454545,24.1045695 22.5454545,23 L22.5454545,12.1212121 L22.5454545,12.1212121"
              id="Path"
            />
          </g>
          <path
            d="M13.3602856,20.1839827 L13.3523736,20.1839827 C13.152418,20.1817911 12.9625321,20.094858 12.8280296,19.9443687 L10.3688635,17.1939217 C10.101297,16.8958653 10.1235942,16.4334396 10.4177735,16.1624128 C10.7119528,15.891386 11.1672473,15.9140325 11.4333753,16.2120889 L13.3717938,18.378842 L18.2908454,13.1066041 C18.5648852,12.8136614 19.0201798,12.8019729 19.3078857,13.0788439 C19.5963109,13.3564455 19.6085384,13.8188712 19.3352178,14.1118139 L13.8824718,19.9560572 C13.7465307,20.1021633 13.5573641,20.1839827 13.3602856,20.1839827"
            id="Icon"
            fill="#082746"
          />
        </g>
      </g>
    </g>
  </svg>
);
