import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      viewBox="0 0 17 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#082746"
          d="M15.3 0H1.7C.765 0 .008.751.008 1.67L0 11.688c0 .918.765 1.67 1.7 1.67h13.6c.935 0 1.7-.752 1.7-1.67V1.67C17 .75 16.235 0 15.3 0zm0 3.34L8.5 7.512 1.7 3.34V1.67l6.8 4.175 6.8-4.174v1.67z"
        />
      </g>
    </svg>
  );
}

export default Icon;
