import React, { FC, useContext, useMemo } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import {
  ClientRecordDrawerStateContext,
  ClientRecordDrawerDispatchContext
} from '../ClientRecordDrawer.context';
import { UpdateClientDrawer } from '../../ClientFormDrawers';

interface Props {
  onUpdateSuccess: (client: Client) => void;
}

export const ClientRecordNestedDrawers: FC<Props> = ({ onUpdateSuccess }) => {
  const dispatch = useContext(ClientRecordDrawerDispatchContext);

  const {
    client: { data: client },
    drawerKey
  } = useContext(ClientRecordDrawerStateContext);

  const handleNestedDrawerClose = () => dispatch({ type: 'CloseNestedDrawer' });

  return useMemo(() => {
    if (drawerKey === 'UpdateClient' && client) {
      return (
        <UpdateClientDrawer
          client={client}
          onClose={handleNestedDrawerClose}
          onUpdateSuccess={updateClient => {
            dispatch({ type: 'UpdateClientSuccess', payload: updateClient });
            dispatch({ type: 'CloseNestedDrawer' });
            onUpdateSuccess(updateClient);
          }}
        />
      );
    }

    return null;
  }, [drawerKey, onUpdateSuccess, client]);
};
