import React, { FC, useState } from 'react';
import { Form, Button, Row, Col, Grid, Drawer, message } from 'antd';
import { Client } from '@elm-street-technology/crm-axios-client';
import { DrawerProps } from 'antd/lib/drawer';
import { clientApi } from 'src/common/services';
import { getCreateMessageText } from 'src/common/utils';
import {
  BuyingDetailsSection,
  SellingDetailsSection,
  SellerListingReportsSection,
  ContactInformationSection,
  ImportantDatesSection,
  MailingAddressSection,
  TagsSection
} from './components';
import { formToClientCreateUpdateBody } from './utils';
import { useIsBuyerSeller } from './hooks';

interface Props {
  onCreateSuccess: (client: Client) => void;
  onClose: DrawerProps['onClose'];
}

export const AddClientDrawer: FC<Props> = ({ onCreateSuccess, onClose }) => {
  const { md } = Grid.useBreakpoint();
  const [creating, setCreating] = useState(false);

  const [form] = Form.useForm();

  const handleFormFinish = values => {
    setCreating(true);

    clientApi
      .createClient({ clientCreateBody: formToClientCreateUpdateBody(values) })
      .then(({ data }) => {
        message.success(getCreateMessageText('client').success);
        onCreateSuccess(data);
      })
      .catch(error => {
        message.error(getCreateMessageText('client').error);
        setCreating(false);
      });
  };

  const { isBuyer, isSeller, setClientType } = useIsBuyerSeller();

  const handleFormFieldsChange = changedFields => {
    const index = changedFields.findIndex(({ name }) => name[0] === 'type');

    if (index !== -1) {
      setClientType(changedFields[index].value);
    }
  };

  return (
    <Drawer
      width={md ? 768 : '100vw'}
      visible
      title="Add Client"
      headerStyle={{ color: '#082746' }}
      onClose={onClose}
      closable
      mask={false}
      footer={
        <Row justify="end">
          <Col xs={24} sm={6}>
            <Button
              loading={creating}
              disabled={creating}
              onClick={() => form.submit()}
              size="large"
              type="primary"
              block
            >
              Save Client
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        name="add-client-form"
        onFinish={handleFormFinish}
        onFieldsChange={handleFormFieldsChange}
        scrollToFirstError
        layout="vertical"
      >
        <ContactInformationSection form={form} />
        <ImportantDatesSection />
        <MailingAddressSection />
        <TagsSection />
        {isBuyer && <BuyingDetailsSection form={form} />}
        {isSeller && <SellingDetailsSection form={form} />}
        {isSeller && <SellerListingReportsSection />}
      </Form>
    </Drawer>
  );
};
