import React from 'react';

const replyArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <g stroke="#FFF" strokeWidth="2" transform="matrix(-1 0 0 1 13 2)">
          <path
            strokeLinejoin="round"
            d="M12.5 4.03333333L10 2.03333333 7.5 4.03333333"
            transform="rotate(90 10 3.033)"
          />
          <path
            d="M8.656 10.665c-2.26.137-3.892-.401-4.898-1.614C2.752 7.838 2.17 5.317 2.015 1.488"
            transform="rotate(90 5.336 6.087)"
          />
        </g>
      </g>
    </svg>
  );
};

export default replyArrow;
