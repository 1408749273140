import React, { FC, useReducer, useEffect } from 'react';
import { message } from 'antd';
import {
  useClientRecordProfileTabMatch,
  useClientRecordTasksTabMatch,
  useClientRecordDocsTabMatch,
  useClientRecordNotesTabMatch
} from 'src/common/hooks';
import {
  ClientRecordDrawerStateContext,
  ClientRecordDrawerDispatchContext
} from './ClientRecordDrawer.context';
import {
  initialClientState,
  initialClientAbilitiesState,
  initialClientAffiliateDataState,
  initialClientPermissionState,
  initialClientContributorsState,
  initialClientAssignmentState,
  initialOrganizationMembersState,
  initialClientContributorHistoryState
} from './reducers';
import { getClient, listClientAbilities } from './actions';
import { clientRecordDrawerReducer } from './ClientRecordDrawer.reducer';
import {
  ClientRecordDrawerTemplate,
  Props as ClientRecordTemplateProps
} from './components/ClientRecordDrawerTemplate';

interface Props extends ClientRecordTemplateProps {
  onError: () => void;
}

export const ClientRecordDrawer: FC<Props> = props => {
  const profileMatch = useClientRecordProfileTabMatch();
  const tasksMatch = useClientRecordTasksTabMatch();
  const docsMatch = useClientRecordDocsTabMatch();
  const notesMatch = useClientRecordNotesTabMatch();

  const [state, dispatch] = useReducer(clientRecordDrawerReducer, {
    drawerKey: '',
    tabKey: 'Activity',
    client: initialClientState,
    abilities: initialClientAbilitiesState,
    affiliateData: initialClientAffiliateDataState,
    permissions: initialClientPermissionState,
    contributors: initialClientContributorsState,
    assignment: initialClientAssignmentState,
    organizationMembers: initialOrganizationMembersState,
    contributorHistory: initialClientContributorHistoryState
  });

  const { clientId, onError } = props;

  const { client, assignment } = state;

  useEffect(() => {
    if (profileMatch) {
      dispatch({ type: 'ChangeTab', payload: 'Profile' });
    } else if (tasksMatch) {
      dispatch({ type: 'ChangeTab', payload: 'Tasks' });
    } else if (docsMatch) {
      dispatch({ type: 'ChangeTab', payload: 'Docs' });
    } else if (notesMatch) {
      dispatch({ type: 'ChangeTab', payload: 'Notes' });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'CloseNestedDrawer' });

    getClient(dispatch, clientId);
    listClientAbilities(dispatch, clientId);
  }, [clientId]);

  useEffect(() => {
    if (client.error) {
      onError();
    }
  }, [client.error]);

  useEffect(() => {
    if (assignment.data?.canView === false) {
      message.success('You no longer have permissions to view this client.');
      onError();
    }
  }, [assignment.data]);

  return (
    <ClientRecordDrawerStateContext.Provider value={state}>
      <ClientRecordDrawerDispatchContext.Provider value={dispatch}>
        <ClientRecordDrawerTemplate {...props} />
      </ClientRecordDrawerDispatchContext.Provider>
    </ClientRecordDrawerStateContext.Provider>
  );
};

export const DashboardClientRecordDrawer: FC<Props> = props => {
  const [state, dispatch] = useReducer(clientRecordDrawerReducer, {
    drawerKey: '',
    tabKey: 'Activity',
    client: initialClientState,
    abilities: initialClientAbilitiesState,
    affiliateData: initialClientAffiliateDataState,
    permissions: initialClientPermissionState,
    contributors: initialClientContributorsState,
    assignment: initialClientAssignmentState,
    organizationMembers: initialOrganizationMembersState,
    contributorHistory: initialClientContributorHistoryState
  });

  const { clientId, onError } = props;

  const { client, assignment } = state;

  useEffect(() => {
    dispatch({ type: 'CloseNestedDrawer' });

    getClient(dispatch, clientId);
    listClientAbilities(dispatch, clientId);
  }, [clientId]);

  useEffect(() => {
    if (client.error) {
      onError();
    }
  }, [client.error]);

  useEffect(() => {
    if (assignment.data?.canView === false) {
      message.success('You no longer have permissions to view this client.');
      onError();
    }
  }, [assignment.data]);

  return (
    <ClientRecordDrawerStateContext.Provider value={state}>
      <ClientRecordDrawerDispatchContext.Provider value={dispatch}>
        <ClientRecordDrawerTemplate {...props} />
      </ClientRecordDrawerDispatchContext.Provider>
    </ClientRecordDrawerStateContext.Provider>
  );
};
