import React, { useEffect, useState, useReducer, useMemo, FC } from 'react';
import { AutoComplete, Button, Card, Input, Select, message, Form } from 'antd';

import {
  Client,
  ClientListItem,
  ClientNameAndEmail,
  BulkActionSendEmailsBodyTemplateEnum,
  BulkActionLeadFilter
} from '@elm-street-technology/crm-axios-client';

import { CommonSmDrawerPanel } from 'src/common/components';
import { DockedButtons, FloatLabel } from 'src/crm/components';
import { useUserAccount, useClientEmailSearch } from 'src/common/hooks';
import { makeUrl } from 'src/common/utils';

import { sanitizeEmails, bulkEmailUrl, createLabel } from './utils';
import {
  initialQuickEmailTemplatesState,
  quickEmailTemplatesReducer,
  getQuickEmailTemplates,
  initialSendQuickEmailState,
  sendQuickEmailReducer,
  sendQuickEmail
} from './reducers';
import { EmailSignaturePreview } from './EmailSignaturePreview';

interface Props {
  clients: ClientListItem[] | Client[];
  listingIds?: string[];
  appliesToAll?: boolean;
  filter?: BulkActionLeadFilter;
  onSuccess?: () => void;
  onClose: () => void;
}

export const QuickEmailDrawer: FC<Props> = ({
  clients,
  listingIds,
  appliesToAll = false,
  filter = {},
  onSuccess,
  onClose
}) => {
  const { user } = useUserAccount();

  const [{ data: templates }, templatesDispatch] = useReducer(
    quickEmailTemplatesReducer,
    initialQuickEmailTemplatesState
  );

  const [{ isSending, isSent }, quickEmailDispatch] = useReducer(
    sendQuickEmailReducer,
    initialSendQuickEmailState
  );

  const showAdditionalEmailOptions = useMemo(
    () => !appliesToAll || clients.length < 2,
    [clients, appliesToAll]
  );

  const initialSelectedEmails = useMemo(
    () =>
      (clients as Client[]).reduce((memo: ClientNameAndEmail[], client) => {
        if (client.isEmailable && client.canEmail) {
          memo.push({
            name: client.displayName,
            email: client.emailAddresses[0].email,
            id: client.id
          });
        }
        return memo;
      }, []),
    [clients]
  );

  const [quickEmailValues, setQuickEmailValues] = useState(() => {
    const defaultEmailValue = initialSelectedEmails.map(item => ({
      value: item.id.toString()
    }));

    return {
      email: defaultEmailValue,
      templateInput: '',
      cc: [] as string[],
      bcc: [] as string[],
      subject: '',
      message: ''
    };
  });

  const {
    data: clientEmails,
    loading: searchLoading,
    searchClientEmails
  } = useClientEmailSearch(initialSelectedEmails);

  useEffect(() => {
    getQuickEmailTemplates(templatesDispatch);
  }, []);

  useEffect(() => {
    if (isSent) {
      message.success('Email Successfully Sent');
      onSuccess && onSuccess();
      onClose && onClose();
    }
  }, [isSent]);

  const [selectedTemplateName, setSelectedTemplateName] = useState<string>();
  const [form] = Form.useForm();

  const numListingsSelected = useMemo(() => listingIds?.length, [listingIds]);

  const initialEmailMessage = useMemo(() => {
    if (numListingsSelected) {
      return `${user.displayName} is sharing these homes with you!`;
    }
    return null;
  }, [numListingsSelected, user]);

  useEffect(() => {
    const t = templates.find(d => d.subject === selectedTemplateName) || {
      id: undefined,
      template: '',
      subject: ''
    };
    form.setFieldsValue({
      message: t.template || initialEmailMessage || '',
      subject: t.subject
    });
    setQuickEmailValues({
      ...quickEmailValues,
      ...{
        message: t.template || initialEmailMessage || '',
        subject: t.subject
      }
    });
  }, [form, selectedTemplateName, templates, initialEmailMessage]);

  const onTemplateSelectBlur = () => {
    // Reject erroneous inputs.
    if (
      !!selectedTemplateName &&
      !templates
        .map(({ subject }) => subject)
        .includes(String(selectedTemplateName))
    ) {
      form.setFieldsValue({ 'Choose Template': '' });
    }
  };

  const handleTemplateSelect = (value: string) => {
    setSelectedTemplateName(value);
  };

  const templateOptions = templates.map(({ id, subject }) => ({
    key: id,
    value: subject,
    label: subject
  }));

  const searchFilters: {
    name: string;
    key: string | number;
    value: string | number;
  }[] = [];

  Object.keys(filter).forEach(filterKey => {
    if (Array.isArray(filter[filterKey])) {
      filter[filterKey].forEach(filterValue =>
        searchFilters.push({
          name: `${filterKey}[]`,
          key: filterValue,
          value: filterValue
        })
      );
    } else {
      searchFilters.push({
        name: filterKey,
        key: filter[filterKey],
        value: filter[filterKey]
      });
    }
  });

  // validate cc or bcc as valid emails
  const handlCcSelectChange = value => {
    const sanitizedValue = sanitizeEmails(value);

    if (value.length !== sanitizedValue.length) {
      message.info('Invalid CC email entered - it has been removed.');
    }
    setQuickEmailValues({ ...quickEmailValues, cc: sanitizedValue });
  };

  const handleBccSelectChange = (value: string[]) => {
    const sanitizedValue = sanitizeEmails(value);

    if (value.length !== sanitizedValue.length) {
      message.info('Invalid BCC email entered - it has been removed.');
    }
    setQuickEmailValues({ ...quickEmailValues, bcc: sanitizedValue });
  };

  const handleCustomEmailClick = e => {
    if (clients.length === 1) {
      const quickEmailUrl = makeUrl(
        `/leads/${quickEmailValues.email[0].value}/email`
      );
      window.location.assign(quickEmailUrl);
      e.preventDefault();
    }
  };

  const handleFormFinish = values =>
    sendQuickEmail(quickEmailDispatch, {
      email: {
        body: values.message,
        subject: values.subject,
        cc: values.cc,
        bcc: values.bcc
      },
      leadFilter: {
        selectedLeads: values.email.map(item => Number(item.value)),
        appliesToAll,
        ...filter
      },
      template: listingIds?.length
        ? BulkActionSendEmailsBodyTemplateEnum.Listings
        : undefined,
      listingIds
    });

  const handleClientEmailSearch = value => searchClientEmails(value);

  const buttonDisabled = useMemo(
    () => !quickEmailValues.email.length || isSending,
    [quickEmailValues.email, isSending]
  );

  const title = useMemo(() => {
    const clientNames = (clients as Client[]).map(c => c.displayName);

    return (
      <h6
        style={{
          color: '#082746',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {numListingsSelected
          ? `${numListingsSelected} Listings Selected`
          : ` Send Email To ${clientNames.join(', ')}`}
      </h6>
    );
  }, [numListingsSelected, clients]);

  const ccAndBccFormElements = (
    <>
      <FloatLabel name="cc" label="CC (Optional)" value={quickEmailValues.cc}>
        <Form.Item>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode="tags"
            open={false}
            onChange={handlCcSelectChange}
            value={quickEmailValues.cc}
          />
        </Form.Item>
      </FloatLabel>
      <FloatLabel
        name="bcc"
        label="BCC (Optional)"
        value={quickEmailValues.bcc}
      >
        <Form.Item>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode="tags"
            onChange={handleBccSelectChange}
            open={false}
            value={quickEmailValues.bcc}
          />
        </Form.Item>
      </FloatLabel>
    </>
  );

  return (
    <CommonSmDrawerPanel onClose={onClose}>
      <Card
        title={title}
        bodyStyle={{
          height: 'calc(100% - 80px)',
          padding: 0,
          overflowY: 'auto'
        }}
        extra={
          !numListingsSelected && (
            <form id="customEmail" action={bulkEmailUrl} method="post">
              {searchFilters.map(
                ({ name, key, value }) =>
                  value && (
                    <input name={name} type="hidden" key={key} value={value} />
                  )
              )}
              {quickEmailValues.email.map(selectedLead => (
                <input
                  name="selectedLeads[]"
                  type="hidden"
                  key="selectedLeads[]"
                  value={selectedLead.value}
                />
              ))}
              <input
                type="hidden"
                name="appliesToAll"
                value={appliesToAll ? 'true' : 'false'}
              />
              <Button
                size="large"
                htmlType="submit"
                onClick={handleCustomEmailClick}
                disabled={!quickEmailValues.email.length}
              >
                Custom Email
              </Button>
            </form>
          )
        }
      >
        <Form
          form={form}
          noValidate
          initialValues={quickEmailValues}
          onFinish={() => handleFormFinish(quickEmailValues)}
          onValuesChange={values =>
            setQuickEmailValues({
              ...quickEmailValues,
              ...(values as typeof quickEmailValues)
            })
          }
        >
          <DockedButtons
            buttons={
              <Button
                htmlType="submit"
                type="primary"
                disabled={buttonDisabled}
                size="large"
              >
                Send Email
              </Button>
            }
          >
            <h5>Quick Email</h5>

            {!numListingsSelected && (
              <FloatLabel
                name="template"
                label="Choose Template"
                value={quickEmailValues.templateInput}
              >
                <Form.Item name="templateInput">
                  <AutoComplete
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear
                    options={templateOptions}
                    filterOption={(inputValue, option) => {
                      return (
                        option?.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      );
                    }}
                    onSelect={handleTemplateSelect}
                    onBlur={onTemplateSelectBlur}
                  />
                </Form.Item>
              </FloatLabel>
            )}

            {showAdditionalEmailOptions && (
              <>
                <FloatLabel
                  name="email"
                  label="Email"
                  value={quickEmailValues.email}
                >
                  <Form.Item name="email">
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      labelInValue
                      autoClearSearchValue
                      notFoundContent={null}
                      loading={searchLoading}
                      mode="multiple"
                      filterOption={false}
                      onSearch={handleClientEmailSearch}
                      options={clientEmails
                        .filter(({ email }) => Boolean(email))
                        .map(client => ({
                          label: createLabel(client),
                          value: client.id.toString()
                        }))}
                    />
                  </Form.Item>
                </FloatLabel>
                {!numListingsSelected && ccAndBccFormElements}
              </>
            )}

            <FloatLabel
              name="subject"
              label="Subject"
              value={quickEmailValues.subject}
            >
              <Form.Item name="subject" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </FloatLabel>
            <FloatLabel
              name="message"
              label="Message"
              value={quickEmailValues.message}
            >
              <Form.Item name="message" rules={[{ required: true }]}>
                <Input.TextArea />
              </Form.Item>
            </FloatLabel>
            <EmailSignaturePreview />
          </DockedButtons>
        </Form>
      </Card>
    </CommonSmDrawerPanel>
  );
};
