import React from 'react';
import moment from 'moment';
import { Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { Listing } from '@elm-street-technology/crm-axios-client';
import {
  getListingStatusColor,
  getListingTypeColor,
  numberToDollar,
  numberToThousands,
  emDash
} from 'src/common/utils';
import {
  FlexWrapper,
  StatusCellRenderer,
  ListingTypeCellRenderer
} from 'src/crm/components/cells';

const CreatedFromNowContainer = styled.div`
  font-size: 10px;
  color: #485465;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    overflow: visible;
    white-space: hidden;
  }
`;

const Property = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  font-size: 10px;
`;

const PropertyImage = styled.img`
  height: 93px;
  width: 113px;
  margin-right: 16px;
  object-fit: cover;
`;

const CellWrapper = styled.div`
  div {
    width: fit-content;
    font-size: 11px;
    margin: 0 auto;
  }
`;

export const makeColumns = (
  pastTenseAction: string,
  isMobile: boolean,
  showTotalViews: boolean,
  actionType: string
): ColumnsType<Listing> => {
  const cols: ColumnsType<Listing> = [
    {
      title: 'Property',
      key: 'property',
      render: record =>
        makePropertyRenderer(
          pastTenseAction,
          showTotalViews,
          actionType,
          record
        ),
      width: 230
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      width: 60,
      align: 'left'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: renderType,
      width: 80,
      align: 'left'
    },
    {
      title: 'Beds',
      dataIndex: 'beds',
      width: 15,
      align: 'right'
    },
    {
      title: 'Baths',
      dataIndex: 'baths',
      width: 15,
      align: 'right'
    },
    {
      title: 'SQFT',
      dataIndex: 'livingArea',
      width: 30,
      align: 'right',
      render: value => (value ? numberToThousands(value) : emDash)
    },
    {
      title: 'Lot Size (acres)',
      dataIndex: 'squareFootage',
      width: 50,
      align: 'right',
      render: renderSquareFootageAsAcres
    }
  ];

  if (isMobile) {
    return cols.filter(
      ({ key }) => !['type', 'status', 'lotSizeFt'].includes(String(key))
    );
  }

  return cols;
};

const renderSquareFootageAsAcres = (squareFootage: number | null) => {
  if (!squareFootage) {
    return emDash;
  }

  const acres = squareFootage / 43560;

  return acres.toFixed(2);
};

const makePropertyRenderer = (
  pastTenseAction: string,
  showTotalViews: boolean,
  actionType: string,
  listing: Listing
) => {
  const {
    address: { street, state, zipCode, city },
    firstPhoto,
    price,
    totalViews
  } = listing;
  const lastActionAt = actionDate(actionType, listing);
  const priceFormatted = price ? numberToDollar(price) : '';
  return (
    <div className="flex">
      {firstPhoto ? (
        <PropertyImage alt="listing" src={firstPhoto || ''} />
      ) : (
        <Empty
          imageStyle={{ height: '93px', width: '113px', objectFit: 'cover' }}
        />
      )}
      <Property>
        <h2>{priceFormatted}</h2>
        <div>{street}</div>
        <div>{`${city}, ${state} ${zipCode}`}</div>
        <br />
        <FlexWrapper>
          <CreatedFromNowContainer>
            {pastTenseAction} {lastActionAt && moment(lastActionAt).fromNow()}{' '}
            {showTotalViews && `(${totalViews} times)`}
          </CreatedFromNowContainer>
        </FlexWrapper>
      </Property>
    </div>
  );
};

export const actionDate = (
  actionType: string,
  listing: Listing
): string | null => {
  const { lastFavoritedAt, lastMatchedAt, lastViewedAt } = listing;
  switch (actionType) {
    case 'favorited':
      return lastFavoritedAt;
    case 'viewed':
      return lastViewedAt;
    case 'matched':
      return lastMatchedAt;
    default:
      return null;
  }
};

const renderStatus = (status: Listing['status']) => (
  <CellWrapper>
    <StatusCellRenderer color={getListingStatusColor(status)}>
      {status}
    </StatusCellRenderer>
  </CellWrapper>
);

const renderType = (type: Listing['type']) => (
  <CellWrapper>
    <ListingTypeCellRenderer color={getListingTypeColor(type)}>
      {type}
    </ListingTypeCellRenderer>
  </CellWrapper>
);
