import { Divider, PageHeader, Descriptions, Grid } from 'antd';
import React, { FC, useMemo } from 'react';
import { Client } from '@elm-street-technology/crm-axios-client';
import Icon from '@ant-design/icons';
import _ from 'lodash';
import {
  EmailPrimary,
  EmailOther,
  CallOther,
  CallPrimary,
  AddressPrimary
} from 'src/crm/assets/icons/profile';
import { ClientRecordSectionTitle } from '../../index';

interface Props {
  client: Client;
}

export const ProfileSection: FC<Props> = ({ client }) => {
  const { xs } = Grid.useBreakpoint();

  const primaryPhone = useMemo(() => {
    const primary = client.phoneNumbers.find(({ isPrimary }) =>
      Boolean(isPrimary)
    );

    if (primary) {
      const { phone, extension } = primary;
      return `${phone}${extension ? ` x${extension}` : ''}`;
    }

    return '-';
  }, [client]);

  const secondaryPhone = useMemo(() => {
    const secondary = client.phoneNumbers.find(({ isPrimary }) => !isPrimary);

    if (secondary) {
      const { phone, extension } = secondary;
      return `${phone}${extension ? ` x${extension}` : ''}`;
    }

    return '-';
  }, [client]);

  const primaryEmail = useMemo(() => client.emailAddresses?.[0]?.email || '-', [
    client
  ]);

  const secondaryEmail = useMemo(
    () => client.emailAddresses?.[1]?.email || '-',
    [client]
  );

  const streetAddress = useMemo(() => {
    const { streetAddress1, streetAddress2 } = client;
    const addressParts = _.compact([streetAddress1, streetAddress2]);
    return addressParts.length > 0 ? addressParts.join(' ') : '-';
  }, [client]);

  const cityStateZip = useMemo(() => {
    const { city, state, zipCode } = client;
    const addressParts = _.compact([
      city ? `${city},` : null, // Keep the comma as per your requirement
      state,
      zipCode
    ]);
    return addressParts.join(' '); // This will join city, state, and zipCode with a space
  }, [client]);

  return (
    <PageHeader
      title={<ClientRecordSectionTitle>Profile</ClientRecordSectionTitle>}
      footer={<Divider />}
    >
      <Descriptions column={xs ? 1 : 2} colon={false}>
        <Descriptions.Item label={<Icon component={CallPrimary} />}>
          {primaryPhone}
        </Descriptions.Item>

        {secondaryPhone !== '-' && (
          <Descriptions.Item label={<Icon component={CallOther} />}>
            {secondaryPhone}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={<Icon component={EmailPrimary} />}>
          {primaryEmail}
        </Descriptions.Item>

        {secondaryEmail !== '-' && (
          <Descriptions.Item label={<Icon component={EmailOther} />}>
            {secondaryEmail}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={<Icon component={AddressPrimary} />}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{streetAddress}</div>
            <div>{cityStateZip}</div>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </PageHeader>
  );
};
