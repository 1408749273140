import React, { FC } from 'react';
import { Input, Form, Row, Col, Select } from 'antd';
import { stateAbbreviationOptions } from 'src/common/constants';
import { Client } from '@elm-street-technology/crm-axios-client';
import { ClientFormSection } from './index';

interface Props {
  client?: Client;
}

export const MailingAddressSection: FC<Props> = ({ client }) => (
  <ClientFormSection title="Mailing Address">
    <Form.Item
      label="Street Address"
      name="streetAddress1"
      initialValue={client?.streetAddress1 ?? undefined}
    >
      <Input size="large" autoComplete="new-password" />
    </Form.Item>

    <Form.Item
      label="Street Address 2"
      name="streetAddress2"
      initialValue={client?.streetAddress2 ?? undefined}
    >
      <Input size="large" autoComplete="new-password" />
    </Form.Item>

    <Row gutter={[8, 0]}>
      <Col xs={24} sm={10}>
        <Form.Item
          label="City"
          name="city"
          initialValue={client?.city ?? undefined}
        >
          <Input size="large" autoComplete="new-password" />
        </Form.Item>
      </Col>

      <Col xs={24} sm={4}>
        <Form.Item
          label="State"
          name="state"
          initialValue={client?.state ?? undefined}
        >
          <Select size="large" options={stateAbbreviationOptions} />
        </Form.Item>
      </Col>

      <Col xs={24} sm={10}>
        <Form.Item
          label="Zip Code"
          name="zipCode"
          initialValue={client?.zipCode ?? undefined}
        >
          <Input size="large" autoComplete="new-password" />
        </Form.Item>
      </Col>
    </Row>
  </ClientFormSection>
);
