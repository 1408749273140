import React, { FC } from 'react';
import { Button, Row, Col } from 'antd';

export interface QuickActionButtonProps {
  label: string;
  disabled: boolean;
  icon: React.ReactNode;
  onClick: () => void;
  name: string;
}

export const QuickActionButton: FC<QuickActionButtonProps> = ({
  label,
  disabled,
  icon,
  onClick,
  name
}) => (
  <Row justify="center" gutter={4} style={{ textAlign: 'center' }}>
    <Col span={24}>
      <Button
        type="primary"
        disabled={disabled}
        icon={icon}
        onClick={onClick}
        name={name}
        shape="circle"
        size="large"
      />
    </Col>
    <Col span={24} style={{ fontSize: 14, color: 'rgb(24, 144, 255)' }}>
      {label}
    </Col>
  </Row>
);
