import React from 'react';
import Icon from '@ant-design/icons';

const Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="14"
    viewBox="0 0 19 14"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero" stroke="#082746">
        <g>
          <g>
            <path
              fill="#E7F4FF"
              d="M17.994 13.574l-1.9-10.59c-.019-.105-.084-.196-.177-.249L11.134.045c-.062-.035-.131-.05-.2-.044l.87 11.353-.029.014 5.724 2.597c.047.021.097.031.146.031.078 0 .155-.026.219-.076.104-.082.154-.215.13-.346zM5.906 2.617L1.014.04C.955.01.89-.004.826 0l.05.001-.87 11.353 5.754 2.611c.047.021.096.031.146.031h0V2.616h0z"
              transform="translate(-38 -640) translate(25 627) translate(13 13)"
            />
            <path
              fill="#FFF"
              d="M10.934.001c-.047.004-.093.016-.137.04L5.906 2.616v11.38s0 0 0 0c.049 0 .099-.01.145-.032l5.753-2.61L10.934 0z"
              transform="translate(-38 -640) translate(25 627) translate(13 13)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ListingsMapViewMobileIcon = props => (
  <Icon component={Svg} {...props} />
);
