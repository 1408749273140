import { ClientAssignment } from '@elm-street-technology/crm-axios-client';
import { message } from 'antd';
import { getUpdateMessageText } from 'src/common/utils';
import { assignmentApi } from 'src/common/services';
import { ClientRecordDrawerDispatch } from '../ClientRecordDrawer.types';

export const updateClientAssignment = (
  dispatch: ClientRecordDrawerDispatch,
  clientId: number,
  reassignment: string,
  onAssignmentSuccess: (clientAssignment: ClientAssignment) => void
) => {
  dispatch({ type: 'UpdateAssignmentPending' });

  assignmentApi
    .updateClientAssignment({ clientId, reassignmentBody: { reassignment } })
    .then(({ data }) => {
      message.success(getUpdateMessageText('client assignment').success);
      dispatch({ type: 'UpdateAssignmentSuccess', payload: data });
      onAssignmentSuccess(data);
    })
    .catch(error => {
      message.error(getUpdateMessageText('client assignment').error);
      dispatch({ type: 'UpdateAssignmentFailure' });
    });
};
