import * as React from 'react';

const SvgMLSSearch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <g>
      <g>
        <g stroke="#172945" strokeLinecap="round" strokeLinejoin="round">
          <path d="M12.014 5.338V1.43c0-.384-.291-.696-.65-.696h-1.207c-.359 0-.65.312-.65.696v1.606" />
          <path d="M1.15 6.105l5.004-4.593a1.235 1.235 0 011.692 0l5.004 4.593" />
          <path d="M1.986 5.338v6.535c0 .769.582 1.392 1.3 1.392H6.35" />
          <path d="M12.85 13.265l-1.065-1.141" />
          <path d="M10.07 7.696c1.338 0 2.421 1.161 2.421 2.592s-1.084 2.592-2.42 2.592c-1.337 0-2.421-1.16-2.421-2.592 0-1.432 1.084-2.592 2.42-2.592" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMLSSearch;
