import React, { FC, useEffect, useState, CSSProperties } from 'react';
import { Row, Col, Tag, Form } from 'antd';

const tagStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  height: 35,
  width: 85
};

interface Props {
  onChange: (workingWithOtherAgent?: boolean) => void;
  initialValue?: boolean;
}

export const SellerWorkingWithOtherAgentFormItem: FC<Props> = ({
  onChange,
  initialValue
}) => {
  const [workingWithOtherAgent, setWorkingWithOtherAgent] = useState(
    initialValue
  );

  useEffect(() => {
    onChange(workingWithOtherAgent);
  }, [workingWithOtherAgent]);

  const handleSellerWorkingWithOtherAgentClick = (value: boolean) => e =>
    setWorkingWithOtherAgent(previous =>
      previous === value ? undefined : value
    );

  return (
    <Form.Item
      name={['sellerFields', 'workingWithOtherAgent']}
      label="Working with another agent?"
    >
      <Row gutter={[4, 4]}>
        <Col>
          <Tag
            color={workingWithOtherAgent === true ? 'blue' : 'default'}
            onClick={handleSellerWorkingWithOtherAgentClick(true)}
            style={tagStyle}
          >
            Yes
          </Tag>
        </Col>

        <Col>
          <Tag
            color={workingWithOtherAgent === false ? 'blue' : 'default'}
            onClick={handleSellerWorkingWithOtherAgentClick(false)}
            style={tagStyle}
          >
            No
          </Tag>
        </Col>
      </Row>
    </Form.Item>
  );
};
